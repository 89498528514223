import Button from '@material-ui/core/Button';

import '../../../src/DialSightStyles.css'
 const CommonButton=({children,onClick})=> {
  return (      
      <button  className='commom_button' onClick={onClick}>
       {children}
      </button>
  );
}
export default CommonButton;
