// import { get } from "lodash";
import ActionConstants from '../config/AppConstants';
const innitialData = {
  templatesListData: {},
};
export default function template(state = innitialData, action) {
  switch (action.type) {
  case ActionConstants.GET_ALL_TEMPLATES: {
    return {
      ...state,
      templatesListData: action.data,
    };
  }
  case ActionConstants.ADD_EDIT_TEMP: {
    return {
      ...state,
      templateBody: action.data,
    };
  }
  default:
    return { ...state };
  }
}
