import React, { useEffect, useState } from 'react';

import { TextField, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import CommonButton from '../../components/Buttons/commonButton';
import MuiDropDown from '../../components/InputFields/DropDown/autoComplete';
import TxtField from '../../components/InputFields/TextFields/TextField';
import { getCountryRef,getStateByCountryRef } from '../../services/tenant';

function OrganizationDetail({isAdminForm,setIsAdminForm,organizationDetails,setOrganizationDetails}) {
  const [orgData, setOrgData] = useState({
    name: '',
    companyDescription: '',
    website: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    country_lng:1,
    state: '',
    state_lng: '',
    city: '',
    zipCode: '',
    companyID: '',
    companyPhone: '',
    companyEmail: '',
  });
  const dispatch=useDispatch()
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({
    "CountryID_ids": 1,
    "CountryName_chr": "United States",
    "CountryCode_chr": "US",
    "CountryPhoneCode_chr": "1",
    "SortOrder_lng": 1,
    "IsActive_ysn": true,
    "LastUpdatedByUserID_chr": "Vivek",
    "LastUpdatedDateTime_dtm": "2021-08-28T02:01:32.927",
    "Row_Version_Binary_bin": "AAAAAAAAbaQ=",
    "IsDeleted_ysn": false
})
  const [selectedState, setSelectedState] = useState({})
useEffect(()=>{
  dispatch(getCountryRef({}, (res) => {
    console.log('36', res)
    setCountryList(res?.refCountry)
  }))

},[])
useEffect(()=>{
  const country=countryList.filter((data)=>{ return data.CountryID_ids===orgData.country_lng})
  console.log('country53',country,orgData)
  setSelectedCountry(country[0])
  getStateListData(orgData.country_lng)

},[isAdminForm,countryList,orgData.state_lng])
useEffect(()=>{
  const state=stateList.filter((data)=>{ return data.StateID_ids===orgData.state_lng})
  // console.log('state60',state,orgData.state_lng)
  setSelectedState(state[0])
},[isAdminForm,stateList])
  const getStateListData = async (countryID) => {
    dispatch(getStateByCountryRef({ CountryID_lng: countryID }, (data) => {
      console.log('state data', data?.refState)
      setStateList(data?.refState)
      // const state_lng = customerData?.state_lng
      // const result = data?.refState.find((state) => state?.stateID_ids === state_lng)
      // console.log('state result', result)
      // setSelectedState(result)
    }))
  }

useEffect(()=>{
  setOrgData(organizationDetails)
},[organizationDetails])
  const handleOrganizationChange = (event) => {
    const { name, value } = event.target;
    if (name == "companyPhone"  || name=="zipCode") {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setOrgData((prevState) =>( {
        ...prevState,
       [name] : onlyNums
     
     }))
    
  }else{
    setOrgData({
      ...orgData,
      [name]: value,
    })
  }
}

  const handleOrganizationSubmit = (event) => {
    event.preventDefault();
    console.log('Organization Details:', orgData);
    setOrganizationDetails(orgData)
  };

const handleNext=(event)=>{
  event.preventDefault();
  setIsAdminForm(true)
  setOrganizationDetails(orgData)
}
  return (
    
          <div 
          // style={{zoom:'85%'}}
          >
            
            <form onSubmit={handleNext}>
              
              <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="body1" className='text-[#95B632]'>
              Organization Details
            </Typography>
              </Grid>
                <Grid item xs={12}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Name"
                    name="name"
                    value={orgData.name}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  // required={true}
                    fullWidth
                    variant='outlined'
                    label="Description"
                    name="companyDescription"
                    minRows={4}
                    multiline={true}
                    value={orgData.companyDescription}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  // required={true}
                    fullWidth
                    variant='outlined'
                    label="Website URL"
                    name="website"
                    value={orgData.website}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TxtField
                  required={true}
                  type='email'
                    fullWidth
                    variant='outlined'
                    label="Email"
                    name="companyEmail"
                    value={orgData.companyEmail}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TxtField
                  required={true}
                  type= 'tel'
                    fullWidth
                    variant='outlined'
                    label="Phone Number"
                    name="companyPhone"
                    value={orgData.companyPhone}
                    onChange={handleOrganizationChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Address Line 1"
                    name="addressLine1"
                    value={orgData.addressLine1}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TxtField
                  // required={true}
                    fullWidth
                    variant='outlined'
                    label="Address Line 2"
                    name="addressLine2"
                    value={orgData.addressLine2}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
               
               <Grid item xs={12} sm={6}>
              <MuiDropDown
              fullWidth={true}
              autoComplete='off-autcomplete-countryName_chr'
              required={true}
                name="country"
                label="Country"
                options={countryList}
                getOptionLabel={(option) => option?.CountryName_chr}
                value={selectedCountry}
                onChange={(event, value) => {
                  console.log('value',value)
                  if(value!==null)
                 { 
                  setSelectedCountry(value)
                   getStateListData(value?.CountryID_ids)
                   setOrgData((state)=>({
                    ...state,
                    country:value.CountryName_chr,
                    country_lng:value.CountryID_ids
                   }))
                  }
                }}
              />
            </Grid> 
            <Grid item xs={6} >
              <MuiDropDown
              required={true}
              autoComplete='autcomplete-off-stateName_chr'
                name="stateName_chr"
                label='State'
                options={stateList}
                getOptionLabel={(option) => option.StateName_chr}
                value={selectedState}
                onChange={(event, value) => {
                  // console.log('value',value)
                  setSelectedState(value)
                  setOrgData((state) => ({
                    ...state,
                    stateName_chr: value?.StateName_chr,
                    state_lng: value?.StateID_ids
                  })
                  )
                }}
              />
            </Grid>
                <Grid item xs={6}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="City"
                    name="city"
                    value={orgData.city}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Zip Code"
                    name="zipCode"
                    value={orgData.zipCode}
                    onChange={handleOrganizationChange}
                    inputProps={{ maxLength: 6 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="TAN Number"
                    name="companyID"
                    value={orgData.companyID}
                    onChange={handleOrganizationChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <div className='flex justify-end'>
                  <CommonButton variant="contained" color="primary" type='submit'>
                    Next
                  </CommonButton>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
  
  );
};

export default OrganizationDetail;
