import { useEffect, useState } from 'react';

import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { onMessageListener, requestFirebaseNotificationPermission } from '../firebase/firebaseInit';
import { sendFirebaseTokenToServer } from '../services/firebase/firebase';
import { getUnreadNotifications } from '../services/notification/notificationService';
import { checkNotificationPermission } from '../utils';

export default function useNotifications() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [permissionAlert,setpermissionAlert]=useState({})
  const dispatch = useDispatch();
  const {userId,twilioNumber,isImpersonated} = useSelector((state) => state.login.loginData);
  const authorized=useSelector((state) => state.login.authorized)
  const {notificationsCount}=useSelector((state)=>state.firebase)
  const {conversationNumbers}=useSelector((state)=>state.smsConversations)

  useEffect(() => {
    if (!authorized && !userId && !checkNotificationPermission()) {
      console.log('in unauth block')
      return;
    }
      
        requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        console.log(firebaseToken, "see token");
        dispatch({ type: 'ADD_FIREBASE_TOKEN',token: firebaseToken });
        if (firebaseToken && userId)
        { 
          sendFirebaseTokenToServer({
            userId,
            fcmModelDetails: {
              registration_ids: [firebaseToken],
            },
          });
        }
          
      }
      )
      .catch((err) => {
        console.error(err,'err in firebase token',err.message);
        const permissionErr= 'FirebaseError: Messaging: The notification permission was not granted and blocked instead.'
        const errorMessage = err.message || err.toString();
        console.log('check here err',errorMessage)
        if (errorMessage.includes(permissionErr)) {
          alert('Notifications are blocked. Please enable them in your browser settings.');
        }
        // setTokenFound(false);
      });
    
    
  }, [userId, authorized,isImpersonated]);

  // onMessageListener().then((payload) => {
  //   console.log('check alert',payload)
  //   //   setShow(true);
  //   setNotification({
  //     title: payload.notification.title,
  //     body: payload.notification.body,
  //   });
  //   setpermissionAlert({payload})
  // });
  onMessageListener((payload) => {
    console.log(payload,'here is payload 11',payload.data)
    const {NotificationType,LoadId,FromPhone}=payload.data
    const {body,title}=payload.notification 
  console.log('before 65',NotificationType,LoadId,title,body)
  if (NotificationType === 'SMS' && title === 'New message') {
    dispatch({ type: 'IS_MSG_PING', isMessagePing: true, smsPingFrom: FromPhone });
    dispatch({type:'FIREBASE_PING',notificationsCount:notificationsCount+1})
    const msg = body.split(':')[1].trim();
    const cloneData = [...conversationNumbers];
    const indexVal = cloneData.findIndex((data) => data.convPh === FromPhone);
    console.log('cloneData', indexVal);
  
    if (indexVal !== -1) {
      const updatedItem = { ...cloneData[indexVal],unreadCount: cloneData[indexVal].unreadCount+1, latestMessage: msg };
      cloneData.splice(indexVal, 1); // Remove the item from its original position
      cloneData.unshift(updatedItem); // Add the updated item to the first position
      console.log('cloneData', cloneData);
      dispatch({ type: 'CONV_LIST', conversationNumbers: cloneData });
      // setTimeout( dispatch({ type: 'CONV_LIST', conversationNumbers: cloneData }),1000)
    }
  }else if(NotificationType === 'Call' && title === 'Missed Call'){
    console.log('missed call ping',NotificationType,title)
        dispatch({ type: 'IS_MISSED_CALL_PING', isMissedCallPing: true });
  }

    
  })
    .then((payload) => {})
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (notification.title) {
      if (notification.title?.toLowerCase()?.includes('call status')) {
        if (notification.body) {
          const { Status } = JSON.parse(notification.body);
          dispatch({ type: 'CHANGE_TWILIO_CALL_EVENT', payload: { callEvent: Status } });
        }
        // {title: 'Call Status', body: '{"From":"+15087147128","To":"+16139166415","Status":"in-progress"}
      } else {
        showNotificationsPopup({ notification });
      }

      // dispatch({ type: 'SHOW_NOTIFICATION_POPUP', payload: { notification } });
    }
  }, [notification]);

  const showNotificationsPopup = (payload = {}) => {
    dispatch({ type: 'SHOW_NOTIFICATION_POPUP', payload });
  };

  const getUnReadNotifications = () => {
    const data = {
      Calling_UserID_chr: userId,
      UserID_chr: userId,
    };
    dispatch(getUnreadNotifications(data, (data) => data.length && showNotificationsPopup()));
  };

  useEffect(() => {
    dispatch({ type: 'CALL_MODAL_CLOSE' });
    checkNotificationPermission();
    showNotificationsPopup();

    getUnReadNotifications();
  }, []);
}
