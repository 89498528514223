import axios from 'axios';
import escapeHtml from 'escape-html';
import { DateTime } from "luxon";
import { Node, Text } from 'slate';
export const isValidEmail = (inputValue) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return inputValue.match(re);
};
export const currentDateTime = () => {
  const date = new Date();
  return date;
};
export const getPreviousDate = (dateString) => {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Get the previous date
  const previousDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);

  // Format the previous date as yyyy-mm-dd
  const year = previousDate.getFullYear();
  const month = String(previousDate.getMonth() + 1).padStart(2, '0');
  const day = String(previousDate.getDate()).padStart(2, '0');
  const formattedPreviousDate = `${year}-${month}-${day}`;

  return formattedPreviousDate;
};
export const getLastWeekDate = (dateString) => {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Subtract 7 days (or 604800000 milliseconds) to get the date from last week
  const lastWeekDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Format the last week date in the same format as the input date string
  const year = lastWeekDate.getFullYear();
  const month = String(lastWeekDate.getMonth() + 1).padStart(2, '0');
  const day = String(lastWeekDate.getDate()).padStart(2, '0');

  // Get the separator used in the input date string
  const separator = dateString.match(/[^\d]/)[0];

  // Join the parts back into a string using the same separator
  const formattedLastWeekDate = `${year}${separator}${month}${separator}${day}`;

  // Return the formatted last week date
  return formattedLastWeekDate;
};

export const formatDate = (date, language) => {
  if (!date) {
    return 'No date specified';
  }
  if (language) {
    return new Date(date)?.toDateString(language);
  }
  var newDate = new Date(date);
  const iso = newDate.toDateString();
  const time = newDate.toLocaleTimeString();
  return iso.concat(' ', time);
};

export const checkNotificationPermission = () => {
  let isNotificationPermissionGranted = localStorage.getItem('isNotificationPermissionGranted');

  if (!('Notification' in window)) {
  } else if (isNotificationPermissionGranted) {
    isNotificationPermissionGranted = true;
    // localStorage.setItem('isNotificationPermissionGranted', true)
    // return true;
  }

  // Otherwise, we need to ask the user for permission
  else if (!isNotificationPermissionGranted) {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        isNotificationPermissionGranted = true;
        // return true;
        // let notification = new Notification("Hi there!");
      } else {
        isNotificationPermissionGranted = false;
        // return false;
      }
    });
  }
  localStorage.setItem('isNotificationPermissionGranted', isNotificationPermissionGranted);
};

export const uploadImageOnCloudinary = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', 'vzqljjil');
  return new Promise((resolve, reject) => {
    axios
      .post('https://api.cloudinary.com/v1_1/dtneaolg5/image/upload', formData)
      .then((res) => {
        resolve(res.data.secure_url);
      })
      .catch((err) => {});
  });
};

export const convertSlateJSONToString = (nodes) => {
  if (!nodes) return '';

  return nodes?.map((n) => Node.string(n)).join('\n');
};

export const convertSlateJSONToHTML = (node) => {
  if (!node) {
    return '';
  }
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  const children = node.children?.map((n) => convertSlateJSONToHTML(n)).join('');

  switch (node.type) {
    case 'image':
      return `<img src='${node.src}' height='${node.height}' width='${node.width}'>${children}</img>`;
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};
export const formatPhoneNumber = (phoneNumberString) => {
  // Remove all non-digit characters from the phone number
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  // Match the digits using a regular expression
  const match = cleaned.match(/^(1|91)?(\d{3})(\d{3})(\d{4})$/);

  // Format the matched digits into the desired format
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  // If the phone number doesn't match the expected format, return it as is
  return null;
};
export const  INformatPhoneNumber=(phoneNumber)=>{
  // Remove any non-digit characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned phone number has a length of 12 (including the country code)
  if (cleanedPhoneNumber.length === 12) {
    // Extract the country code, area code, and local number
    const countryCode = cleanedPhoneNumber.slice(0, 2);
    const areaCode = cleanedPhoneNumber.slice(2, 7);
    const localNumber = cleanedPhoneNumber.slice(7);

    // Construct the formatted phone number
    const formattedPhoneNumber = `+${countryCode} ${areaCode} ${localNumber}`;

    return formattedPhoneNumber;
  }

  // If the phone number doesn't match the expected format, return the original input
  return phoneNumber;
}
export const AllformatPhoneNumber = (phoneNumberString) => {
  // Remove all non-digit characters from the phone number
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  // Match the digits using a regular expression for US/Canadian numbers
  const usMatch = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);

  // Match the digits using a regular expression for Indian numbers
  const inMatch = cleaned.match(/^(91)?(\d{5})(\d{5})$/);

  // Format the matched digits into the desired format for US/Canadian numbers
  if (usMatch) {
    const intlCode = usMatch[1] ? `+1 ` : "";
    return [intlCode, "(", usMatch[2], ") ", usMatch[3], "-", usMatch[4]].join("");
  }

  // Format the matched digits into the desired format for Indian numbers
  if (inMatch) {
    const intlCode = inMatch[1] ? `+91 ` : "";
    return [intlCode, inMatch[2], " ", inMatch[3]].join("");
  }

  // If the phone number doesn't match either expected format, return it as is
  return phoneNumberString;
};


export const convertTimeZone = (
  datetime,
  // fromTz = "UTC",  //production
  // fromTz = "America/Los_Angeles", //Staging
  fromTz = window.location.hostname==="connect.dialsight.com"?"UTC":"America/Los_Angeles", 
  toTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  format = "yyyy-MM-dd'T'HH:mm:ss.SSS"
) => {
  // console.log("dateTime to ConverTimezone function", datetime);
  // console.log('check time zone',window.location.hostname==="dialsight.loadkarma.com",fromTz)
  if (!datetime) {
    return "";
  }
  if (datetime.length == 21) {
    datetime += "98";
  }
  if (datetime.length == 22) {
    datetime += "9";
  }
  if (datetime.length == 19) {
    datetime += ".899";
  }
  const convertedDT = DateTime.fromFormat(datetime, format, { zone: fromTz })
    .setZone(toTz)
    .toFormat(format);

  const dateObj = new Date(convertedDT);

  // Step 2: Format the Date object to the desired output format
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObj
  );

  return formattedDate.replace(" at", "");
};
export const convertUtcToLocal=(dateString) =>{
  // // Create a new Date object from the UTC time string
  // const utcDate = new Date(utcTime);

  // // Get the local offset in minutes
  // const offsetMinutes = utcDate.getTimezoneOffset();

  // // Calculate the local time by adding the offset to the UTC time
  // const localTime = new Date(utcDate.getTime() - offsetMinutes * 60000);

  // // Format the local time as a string in the desired format
  // const options = {
  //   year: 'numeric',
  //   month: 'short',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  // };
  // const localTimeString = localTime.toLocaleString('en-US', options);
  const dateTime = DateTime.fromISO(dateString);
  const formattedDateTime = dateTime.toFormat('MMM dd, yyyy HH:mm:ss');
  // const currentDateTime = DateTime.local();

  return formattedDateTime;
}



export const convertToUTC=(date) =>{
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  const utcDateTimeString = utcDate.toISOString();
  return utcDateTimeString;
}
export const getStartingTime=(date)=> {
  console.log()
  const startingTimeUTC = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0, 0, 0
    )
  );

  const startingTimeUTCString = startingTimeUTC.toISOString();
  return startingTimeUTCString;
}
export const getEndTime=(date)=> {
  const startingTimeUTC = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23, 59, 59
    )
  );

  const endingTimeUTCString = startingTimeUTC.toISOString();
  return endingTimeUTCString;
}

export const previousDay=(date)=> {
  const previousDate = new Date(date);
  previousDate.setDate(date.getDate() - 1);
  return previousDate;
}
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const standardformatDate = (standardDate) => {
  if (!standardDate) {
    return "";
  }

  const date = new Date(standardDate);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}, ${year}`;
};

export const callTime=(timeInSeconds)=>{
  const tms=parseInt(timeInSeconds)
  const hours = Math.floor(tms / 3600);
    const minutes = Math.floor((tms % 3600) / 60);
    const seconds = tms % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
}
export const formatedNumber= (number)=>{
  return new Intl.NumberFormat('en-US').format(
  number,
)}