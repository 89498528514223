import React, { useEffect, useState } from 'react';

import { Chip,Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment'
import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FcBusinessman } from "react-icons/fc";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ActionConstants from '../../../config/AppConstants';
import constant_label from '../../../constants';
import CustomHistoryHook from '../../../hooks/useHistory';
import { checkLogin } from '../../../services/userService';
import '../styles.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:'5vw 3vh 8vw 3vh',
    gap:'5vh'
  },
  avatar: {
    backgroundColor: alpha('#263003',0.5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display:'flex',
    flexDirection:'column',
    gap:'5vh'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function Tenants(props) {
  const {user,tenants}=useSelector((state)=>state?.login?.loginData)
  const {customHistory}=CustomHistoryHook()
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleTenantSelect=(event,selectedTenant)=>{

    console.log('selectedTenant',selectedTenant)
    dispatch({
      type: ActionConstants.SAVE_TENANT,
      data: {
        domain: selectedTenant?.TenantName_chr,
        tenant: selectedTenant?.TenantName_chr,
        user:user,
        tenants:tenants,
      },
    });
    // localStorage.setItem('domain',selectedTenant?.DomainName)
    localStorage.setItem('domain',selectedTenant?.TenantName_chr)
    localStorage.setItem('tenantName',selectedTenant?.TenantName_chr)
    customHistory(selectedTenant?.TenantName_chr,'/authentication')
  }
  return (
    <>
      <div component="main" className=''>
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}></Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Paper elevation={3}>
            <div className={classes.paper}>
            <Typography 
              variant='h5'
              className='flex flex-col gap-y-6 items-center justify-center'
              > 
              <img src="/connect_fav_icon.svg" alt="favIcon" width="32px"/>
              <img src='/connect_logo_2.svg' width='180px'/>
              Sign in
              </Typography>
              <div className='flex flex-col justify-center items-center gap-y-2'>
              
              {/* <Avatar className={classes.avatar}>               
                <FcBusinessman size={45} className='mt-1'/>
              </Avatar> */}
              <Chip
              color="secondary"
               avatar={<Avatar className='capitalize'>{user.charAt(0)}</Avatar>}
               label={user}
                // onClick={handleClick}
                 />
              </div>
                <div className="border flex flex-col gap-y-2 p-2 w-full">
                  <div className='flex items-center justify-center text-lg p-1 bg-[#263003] text-white'>Your Workspaces</div>
                  {tenants?.map((tenant,index) => (
                    <div key={index} className="cursor-pointer hover:border-[#263003] p-2 border rounded" onClick={(event)=>handleTenantSelect(event,tenant)}>
                      <div className="font-bold flex items-center gap-2">
                        <img
                           src="/connect_fav_icon.svg"
                           alt="favIcon"
                           height="20px"
                           width="20px"
                          />
                        {tenant.TenantName_chr}
                        </div>
                    </div>
                  ))}
                </div>

            </div>
            </Paper>
          </Grid>
          <Grid item  xs={12} sm={3} md={3} lg={4}></Grid>
        </Grid>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  checkLogin,
};
const mapStateToProps = (state) => {
  return {
    data: state.login,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
