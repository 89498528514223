import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CustomHistoryHook = () => {
  const reduxTenant=useSelector((state)=>state.login?.loginData?.tenant)
  const history = useHistory();
  // const tenantName=reduxTenant || localStorage.getItem("tenantName")
  // console.log('tenantName123',tenantName)
  const customHistory = (tenantName,to) => {
    // Assuming your routes have a structure like "/:tenantName/..."
    const path = `/${tenantName}${to}`;
    history.push(path);
  };

  return { customHistory };
};

export default CustomHistoryHook;