import {serviceCallWithToken} from '../serviceCall'
export const holdCall = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/Call/Holdcall',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const unHoldCall = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        
        url: '/api/Call/Unholdcall',
  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const transferCall = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/Call/Transfercall',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const agentConferencecall = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/Call/AgentConferencecall',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const RemoveAgent = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/Call/RemoveAgent',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
 
  export const HangupCall = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      serviceCallWithToken({
        url: '/api/Call/HangupCall',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          callback && callback(response && response.data);
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  