import React, { useEffect, useState } from 'react';

import { FaSadTear } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';

import Loader from '../../../components/loader';
import { resubscribeEmails, unsubscribeEmails } from '../../../services/mail/mailService';

const Resubscribe = () => {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const [email, setEmail] = useState('');
    const { tenantName } = useParams();
    const history=useHistory()
    const dispatch=useDispatch()
    const location = useLocation();
    useEffect(() => {
        
        const params = new URLSearchParams(location.search);
        
        // Get the email parameter
        const emailParam = params.get('email');
        
        if (emailParam) {
          setEmail(emailParam);
        }
      }, []);
      useEffect(() => {
        localStorage.setItem('domain', tenantName)
        dispatch({
          type: 'SAVE_TENANT',
          data: {
            domain:tenantName
          },
        });
      }, [])
      const reSubscribeClick=(event)=>{
        event.preventDefault()
         dispatch(resubscribeEmails({email:email},(res)=>{
            console.log('35',res)
            history.push(`/${tenantName}/re-subscribe/success`)

        },(err)=>{console.log('36',err)
            swal({
                title: "Error",
                text: err,
                icon: "error",
                dangerMode: true,
              });
             }))
      }
    return (
      <>
      {spinner?<Loader/>:null}
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-[50vw] flex flex-col gap-6 bg-white rounded-lg shadow-lg p-6  text-center border-t-4 border-[#95B632]">
                <div className='flex items-center justify-center'><FaSadTear size={48} color='FFBF00'/></div>
                <h1 className="text-2xl font-bold text-gray-800">You've been unsubscribed.</h1>
                <div className="text-gray-600 text-lg">We won't send you any more emails.</div>
               <form className='w-full flex items-center justify-center gap-6' onSubmit={reSubscribeClick}>
               <div className="text-gray-600">Unsubscribe by accident?</div>
               <button
                type='submit'
                className="text-blue-500 hover:cursor-pointer border border-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white"
                >Re-subscribe</button>
               </form>            
            </div>
        </div>
        </>
    );
};

export default Resubscribe;
