import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoDotFill } from "react-icons/go";
import { HiOutlineLightBulb } from 'react-icons/hi';
import { MdInfo, MdOutlineInfo } from 'react-icons/md';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MuiDropDown from '../../components/InputFields/DropDown/autoComplete';
import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import { getStateByCountryRef } from '../../services/customer/customerService';
import { sendMail } from '../../services/mail/mailService';
import { portNumberRequest } from '../../services/twilio/twilioPhoneService';
import BSInfoModal from './BillingStatementModal/component';
import LOAInfo from './LOAInfoModal/component';

const useStyles = makeStyles((theme) => ({
    root: {
      border: 0,
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
  
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontSize: '14px',
        fontFamily: "'Rubik', sans-serif",
        color: '#333',
      },
      '& .MuiDataGrid-columnsContainer': {
        border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
  
      '&  .MuiDataGrid-window ': {
        //   [theme.breakpoints.up('1450')]: {
        //     overflowX: (props) => {
        //       if (!props) return 'hidden';
        //       return props.open ? 'auto' : 'hidden';
        //     },
        //   },
      },
      '& .MuiDataGrid-dataContainer': {
        borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Rubik', sans-serif",
        borderBottom: '0',
      },
      '& .MuiDataGrid-row': {
        color: '#444',
        backgroundColor: '#ffffff',
        outline: 'none',
      },
  
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f7f7',
        color: '#aeacb9',
      },
    },
  
    paper2: {
      width: '100%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
      '& .makeStyles-paper-32': {
        marginTop: '0px',
      },
      overflow: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingY: '10px',
      margin: '10px',
    },
    tableContainer: {
      overflow: 'auto',
    },
    selected: {
      '&:hover': {
        backgroundColor: 'rgba(17, 122, 139, 0.15) !important',
      },
    },
  
    clickable: {
      cursor: 'pointer',
    },
    icons: {
      padding: '0px 3px',
      '&:focus': {
        outline: 'none',
      },
    },
    table: {
      minWidth: 750,
    },
    radio: {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
  
      '&$checked': {
        color: '#263003',
      },
  
      '& .MuiSvgIcon-root': {
        height: '15px',
        width: '15px',
      },
    },
  
    table: {
      minWidth: 340,
    },
    tableCell: {
      paddingRight: 4,
      paddingLeft: 5,
  
      a: {
        color: '#9BA3B9',
      },
      paddingY: '0px',
    },
    onHover: {
      '& .MuiButtonBase-root': {
        '&:hover': {
          color: '#000000DE',
        },
      },
    },
  
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  
    grid: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    heading: {
      display: 'flex',
      justifyItems: 'center',
      columnGap: '4px',
      fontWeight: 'bold',
      borderBottom:'1px solid black',
      background:'#263003',
      color:'white',
    //   marginTop: 30,
      padding: 15,
    },
  }));
export default function PortPhoneNumber() {
    const classes = useStyles()
  const dispatch = useDispatch();
  const myInputRef1=useRef([])
  const myInputRef2=useRef([])
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {userId,email,firstName,lastName} = useSelector((state) => state?.login?.loginData)
  const [open,setOpen]=useState(false)
  const [isOpen,setIsOpen]=useState(false)
  const [stateList,setStateList]=useState([])
  const [selectedState,setSelectedState]=useState({})
  const initialData={
    CallingUserID:userId,
    PortRequestName:'',
    ResidentialOrBusiness:'',
    FirstName:'',
    Address:'',
    City:'',
    State:'',
    PostalCode:'',
    PhoneNumbers:'',
    WirelessNumbers:[],
    }
  const [formData,setFormData]=useState(initialData)
  const [base64File, setBase64File] = useState([]);
  const initialSt={
    st1:false, st2:false, st3:false, st4:false, st5:false,
  }
  const [st,setSt]=useState(initialSt)
  const initialErrData={
    PortRequestName:'',
    FirstName:'',
    Address:'',
    City:'',
    State:'',
    PostalCode:'',
    
  }
  const [err,setErr]=useState(initialErrData)
  const [docs,setDocs]=useState([{id:1,letterOfAuthorization:'',billingDocument:''}])
  const formValidation=(name,value)=>{
    if(name==='PortRequestName' )
    {
      if(value.length)
     { setErr((state)=>({
        ...state,
        PortRequestName:''
      }))
    }
      else{
        setErr((state)=>({
          ...state,
          PortRequestName:'Port Request Name is a required field.'
        }))
      }
    }else if(name==='FirstName')
    if(value.length){
      setErr((state)=>({
        ...state,
        FirstName:''
      }))
    }else{
      setErr((state)=>({
        ...state,
        FirstName:'Name is a required field.'
      }))
    }
    else if(name==='Address')
    if(value.length){
      setErr((state)=>({
        ...state,
        Address:''
      }))
    }else{
      setErr((state)=>({
        ...state,
        Address:'Address is a required field.'
      }))
    }
    else if(name==='City')
    if(value.length){
      setErr((state)=>({
        ...state,
        City:''
      }))
    }else{
      setErr((state)=>({
        ...state,
        City:'City is a required field.'
      }))
    }
    else if(name==='PostalCode')
    if(value.length){
      setErr((state)=>({
        ...state,
        PostalCode:''
      }))
    }else{
      setErr((state)=>({
        ...state,
        PostalCode:'Postal code is a required field.'
      }))
    }
  }
  const handleLOAClick=()=>{
    setOpen(!open)
  }
  const handleBSClick=()=>{
    setIsOpen(!isOpen)
  }
  const handleChange=(event)=>{
    const {name,value}=event.target;
    if(name==='PhoneNumbers'){
      const onlyNums=value.replace(/[^0-9*#]/g, '')
      setFormData((state)=>({
        ...state,
        [name]:onlyNums
      }))
    }else
    {
      formValidation(name,value)
      setFormData((state)=>({
      ...state,
      [name]:value
    }))
  }
  }
  const handleCheckBoxChange=(event)=>{
    const {name,checked,value}=event.target;
    setFormData((state)=>({
      ...state,
      [name]:value
    }))
  }
  console.log('206',formData)
 
  useEffect(()=>{
    dispatch(getStateByCountryRef({ CountryID_lng: 1},(res)=>{
      console.log('212',res?.refState)
      setStateList(res?.refState)
    }))
  },[])
  const initialNumbers={id:1,phoneNumber_chr:'',wirelessAccountNumber_chr:'',pinOrLast4Ssn_chr:''}
  const [wireLessNumbers,setWireLessNumbers]=useState([initialNumbers])
  const initialNumberErr=[{phoneNumber_chr:'',wirelessAccountNumber_chr:'',pinOrLast4Ssn_chr:''}]
  const [numberErr, setNumberErr] = useState(initialNumberErr);
  const handleStatements=(e)=>{
    const {name,checked}=e.target
    console.log('244',name,checked)
    setSt((state)=>({
      ...state,
      [name]:checked
    }))
  }
  const handleAddAnotherWirelessNumber=()=>{
    const cloneArr=[...wireLessNumbers]
    const arrLength=cloneArr.length
    if(arrLength<5)
     {
       setWireLessNumbers((state)=>[...state,{id:arrLength+1,phoneNumber_chr:'',wirelessAccountNumber_chr:'',pinOrLast4Ssn_chr:''}])
      setNumberErr([...numberErr, { phoneNumber_chr:'',wirelessAccountNumber_chr:'',pinOrLast4Ssn_chr:''}]);
      const cloneDocArr=[...docs]
      const newIndex=cloneDocArr.length
      setDocs((state)=>[...state,{id:newIndex+1,letterOfAuthorization:'',billingDocument:''}])
    }
    
}
  console.log('250',wireLessNumbers)
  const [LOAFiles,setLOAFiles]=useState([])
  const [BSFiles,setBSFiles]=useState([])
  
  
  
  const handlePortNumbers=(e,index)=>{
    const {name,value}=e.target
    console.log('320',name,index)
    const cloneArr=[...wireLessNumbers]
    const newErrors = [...numberErr];
    if (name == "phoneNumber_chr") {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      cloneArr[index][`${name}`]=onlyNums
    }else if(name == "pinOrLast4Ssn_chr"){
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      cloneArr[index][`${name}`]=onlyNums
    }
    else{
      cloneArr[index][`${name}`]=value
    }
  
  

  setWireLessNumbers(cloneArr)
  if (value.length) {
    // newInputs[index][name] = value;
    newErrors[index][name] = ''; // Clear error message if valid
  } else {
    var errVal=''
    if(name==='phoneNumber_chr'){
      errVal='Phone number is required.'
    }
    else if(name==='wirelessAccountNumber_chr'){
      errVal='Wireless account number is required.'
    }
    else if(name==='pinOrLast4Ssn_chr'){
      errVal='PIN or last 4 digits of SSN is required.'
    }
    newErrors[index][name] = errVal; // Set error message if not valid
  }
  setNumberErr(newErrors);

  }
  
  const handleAddAnotherDocs=()=>{
    console.log('319',docs)
    const cloneArr=[...docs]
    const newIndex=cloneArr.length
    setDocs((state)=>[...state,{id:newIndex+1,letterOfAuthorization:'',billingDocument:''}])
  }
  const ChooseLOA=(e)=>{
    e.preventDefault()
    myInputRef1.current.click();
  }
  const ChooseBS=(e,index)=>{
    e.preventDefault()
    myInputRef2.current[index].click();
  }
  const UploadLOA = async (e,index) => {
    const { name } = e.target;
    const file = e.target?.files?.[0]; 
    console.log('253', name, file,file.type);  
    const cloneDocArr=[...docs]
    if(file.type==='application/pdf') 
   { 
    cloneDocArr[index][`${name}`]=file
    setDocs(cloneDocArr)
  }else{
    NotificationManager.error('Only PDF format is accepted.')
  }
    };
  useEffect(()=>{
    console.log('check docs',docs)
  },[docs])
  const UploadBillingDoc=async(e,index)=>{
    console.log('413',index)
    const {name,id}=e.target
    const file=e.target?.files?.[0]
    console.log('240',name,id,file)
    const cloneDocArr=[...docs] 
    if(file.type==='application/pdf') 
    { 
     cloneDocArr[index][`${name}`]=file
     setDocs(cloneDocArr)
   }else{
     NotificationManager.error('Only PDF format is accepted.')
   }
  }

  
  console.log('360',docs)
  const handleRemoveWirelessNumber=()=>{
  console.log('349',wireLessNumbers)
  const newArr=[...wireLessNumbers]
  const newErrArr=[...numberErr]
  const newDocArr=[...docs] 
  if(newArr.length>1 && newErrArr.length>1 && newDocArr.length>1)
  {
    setWireLessNumbers(prevArr => prevArr.slice(0, -1))
    setNumberErr(prevArr => prevArr.slice(0, -1))
    setDocs((prevArr)=>prevArr.slice(0, -1))
  }
 
  }
 
  const sendEmail=(convertedToBase64)=>{
    const mailBody=` <div>
    <p>Twilio Support Team,</p>
    <p>I hope this message finds you well. We are writing to request a port of our existing phone numbers to Twilio's services.</p>
    <p>Below are the specific details related to the port request:</p>
    <ul>
        <li><strong>Port Request Name:</strong>${formData?.PortRequestName}</li>
        <li><strong>Name:</strong>${formData?.FirstName}</li>
        <li><strong>Address:</strong>${formData?.Address}</li>
        <li><strong>City:</strong>${formData?.City}</li>
        <li><strong>State:</strong>${formData?.State}</li>
        <li><strong>Postal Code:</strong>${formData?.PostalCode}</li>
    </ul>
    <table border="1">
          <thead>
            <tr>
              <th>PHONE NUMBER</th>
              <th>WIRELESS ACCOUNT NUMBER</th>
              <th>PIN OR LAST 4 OF SSN</th>
            </tr>
          </thead>
          <tbody>
          ${wireLessNumbers.map((item, index) => (
            `<tr key=${index}>
              <td>${item.phoneNumber_chr}</td>
              <td>${item.wirelessAccountNumber_chr}</td>
              <td>${item.pinOrLast4Ssn_chr}</td>
            </tr>`
          )).join('')}
        </tbody>
        </table>
    <p>Please find attached the necessary documentation required for the port request.</p>
    <p>We kindly request your assistance in processing this port request as swiftly as possible.</p>
  </div> `
    console.log('sending email..',mailBody)  
    dispatch(sendMail({
      strFrom: email,
      strFromName: `${firstName} ${lastName}`,
      sendTos: [
        {
          strTo: 'harshdeep.singh@upkarak.com',
        },
        {
          strTo: 'sahil.rana@upkarak.com',
        },
        {
          strTo: 'wahaj.mirza@upkarak.com',
        },
      ],
      strSubject: 'Port Number Request',
      strBody: mailBody,
      attachmentsList: convertedToBase64,
    }))
  }
   const handleFormSubmit=(e)=>{
    e.preventDefault()
  console.log('389',st)
  console.log('390',wireLessNumbers)
    console.log('287',LOAFiles,'287',BSFiles)
    const payloadData=new FormData()
    payloadData.append('CallingUserID',userId)
    payloadData.append('PortRequestName',formData?.PortRequestName)
    payloadData.append('ResidentialOrBusiness',formData?.ResidentialOrBusiness)
    payloadData.append('FirstName',formData?.FirstName)
    payloadData.append('Address',formData?.Address)
    payloadData.append('City',formData?.City)
    payloadData.append('State',formData?.State)
    payloadData.append('PostalCode',formData?.PostalCode)
    payloadData.append('PhoneNumbers',formData?.PhoneNumbers)
    for (const x in wireLessNumbers) {
      payloadData.append(
        `WirelessNumbers[${x}].phoneNumber_chr`,
        wireLessNumbers[x]?.phoneNumber_chr
      );
      payloadData.append(
        `WirelessNumbers[${x}].wirelessAccountNumber_chr`,
         wireLessNumbers[x]?.wirelessAccountNumber_chr
      );
      payloadData.append(
        `WirelessNumbers[${x}].pinOrLast4Ssn_chr`,
        wireLessNumbers[x]?.pinOrLast4Ssn_chr
      );      
    }
    for (const x in docs) {
      //sending only one LOA doc
       payloadData.append(
        `WirelessNumbers[${x}].letterOfAuthorization`,
        docs[0]?.letterOfAuthorization
      );
      payloadData.append(
        `WirelessNumbers[${x}].billingDocument`,
        docs[x]?.billingDocument
      );
      
    }
    console.log('391',payloadData)
    if(!formData?.FirstName){
        setErr((state)=>({
          ...state,
          FirstName:'Name is a required field.'
        }))
    }
    if(!formData?.Address){
      setErr((state)=>({
        ...state,
        Address:'Address is a required field.'
      }))
  }
  if(!formData?.City){
    setErr((state)=>({
      ...state,
      City:'City is a required field.'
    }))
}
if(!selectedState?.stateName_chr){
  setErr((state)=>({
    ...state,
    State:'State is a required field.'
  }))
}
if(!formData?.PostalCode){
  setErr((state)=>({
    ...state,
    PostalCode:'Postal Code is a required field.'
  }))
}

const LOAArr=docs.map((data)=>data.letterOfAuthorization)
const LOAArray=LOAArr.filter(item => item!=="");
const BSArr=docs.map((data)=>data.billingDocument)
const resultedArr=LOAArray.concat(BSArr)
console.log('659',LOAArr,BSArr,LOAArray,resultedArr)
var convertedToBase64 = [];
for (let i = 0; i < resultedArr.length; i++) {
  const file = resultedArr[i];
  if (file instanceof File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64file = e.target.result;
      const fileName = file.name;
      convertedToBase64.push({ strAttachmentFileName: fileName, strAttachmentBase64: base64file });
    };
    reader.readAsDataURL(file);
  } else {
    console.error(`Object at index ${i} is not a File.`);
  }
}
console.log('679', convertedToBase64);
function checkNumbersFields(array) {
  for (const obj of array) {
      for (const key in obj) {
          if (obj[key] === "") {
              return false; // Return false if any field is empty
          }
      }
  }
  return true; // Return true if all fields have values
}

    if(st.st1)
    {
     if(formData?.FirstName && formData?.Address && formData?.City && formData?.State && formData?.PostalCode && checkNumbersFields(wireLessNumbers))
     {
      dispatch(portNumberRequest(payloadData,
        {
       'content-type': 'multipart/form-data',
      }
     ,(res)=>{
       console.log('562',res)
       sendEmail(convertedToBase64);
       setFormData(initialData) 
       setSelectedState(null)
       setWireLessNumbers([initialNumbers])
       setDocs([{id:1,letterOfAuthorization:'',billingDocument:''}])    
       setSt(initialSt) 
       setErr(initialErrData)
       setNumberErr(initialNumberErr)
       NotificationManager.success('Successfully Creating Port Request.') 
     },(err)=>{
       console.log(err)
       if(err?.Address)
       {
         console.log('573',err?.Address[0])
         NotificationManager.error(err?.Address[0])
         setErr((state)=>({
           ...state,
           Address:err?.Address[0]
         }))
         
       }
       else if(err?.City)
       {
         console.log('573',err?.City[0])
         NotificationManager.error(err?.City[0])
         setErr((state)=>({
           ...state,
           City:err?.City[0]
         }))
         
       }
       else if(err?.PostalCode)
       {
         console.log('573',err?.PostalCode[0])
         NotificationManager.error(err?.PostalCode[0])
         setErr((state)=>({
           ...state,
           PostalCode:err?.PostalCode[0]
         }))
         
       }
        
      
       else if(err?.State)
       {
         console.log('573',err?.State[0])
         NotificationManager.error(err?.State[0])
         setErr((state)=>({
           ...state,
           State:err?.State[0]
         }))
         
       }
       else{
         // Convert data object to an array of objects
   const dataArray = Object.entries(err).map(([key, value]) => {
     const [index, field] = key.match(/\d+/g);
     const fieldName = key.replace(/\[\d+\]/g, '');
     return {
       // index: parseInt(index),
       field,
       message: value[0]
     };
   });
   const result=JSON.stringify(dataArray, null, 2)
   console.log('632',result)
   const hasBillingError = dataArray.some(obj => obj.message === 'Billing statement file is required.');
   const hasSSNError = dataArray.some(obj => obj.message === 'PIN or last 4 digits of SSN is required.');
   const hasPhoneError = dataArray.some(obj => obj.message === 'Phone number is required.');
   const hasWirelessAccError = dataArray.some(obj => obj.message === 'Wireless account number is required.');
   const hasAuthorizationError = dataArray.some(obj => obj.message === 'Letter of authorization file is required.');
   if(hasAuthorizationError){
     NotificationManager.error('Please upload Letter of authorization document.')
   }else if(hasBillingError){
     NotificationManager.error('Please upload Billing documents.')
   }
   else if(hasPhoneError){
     NotificationManager.error('Phone number is required.')
   }
   else if(hasWirelessAccError){
     NotificationManager.error('Wireless account number is required.')
   }
   else if(hasSSNError){
     NotificationManager.error('PIN or last 4 digits of SSN is required.')
   }
       }
     }))
     }else{
      NotificationManager.error('Please fill all fields marked as (*).')
     }

  }else{
    NotificationManager.error(' Please accept all terms and conditions.')
  }

  }
  const handleRemoveDocs=()=>{
    const newArr=[...docs]
 
    if(newArr.length>1)
   { setDocs((prevArr)=>prevArr.slice(0, -1))}
  }
  const handleKeyDown = (e) => {
    // Check if the Enter key is pressed
    if (e.key === 'Enter') {
      // Prevent the default behavior of the Enter key
      e.preventDefault();
      // Handle the Enter key press here
      console.log('Enter key pressed');
    }
  };
  
  return (
    <>
      {spinner ? <Loader /> : null}
      <form autoComplete='off' onSubmit={handleFormSubmit}>
      <div className="bg-white  px-2 pt-2 pb-8 mt-2  grid grid-cols-4 items-center gap-4">
           <Typography variant="h5" gutterBottom className={`${classes.heading} col-span-4`}>
            {/* <FaUsers size={28} className="" /> */}
           Port a Number
          </Typography>
          <div className='col-span-4 text-lg font-bold pb-2 border-b border-black'>1. Getting Started</div>
          <p className='col-span-4'>
          You are about to port your phone number(s) into Twilio which means that we will work with your current provider to move your number(s) to your Twilio account. The process typically takes 2-4 weeks, and we will provide updates via email. Once complete, you will be able to use the full set of Twilio features on your current number(s).
          </p>
          <p className='col-span-4'>
          We are currently able to port in numbers from the continental United States only.
          </p>
          <div className='col-span-4'>
            <p className='pb-2'>Please carefully read and agree to the terms and conditions listed below:</p>
            <ul>
            <li className='flex items-center space-x-2'>
              {/* <input name='st1' type='checkbox' checked={st?.st1} onChange={handleStatements} /> */}
              <GoDotFill/>
            <p>
            I will pay standard rates for my phone number and usage after my number ports. Details can be found on Twilio's pricing page
            </p>
            
            </li>
            <li className='flex items-center space-x-2'>
              {/* <input name='st2' type='checkbox' checked={st?.st2} onChange={handleStatements} /> */}
              <GoDotFill/>
            <p>
            I must keep all numbers in service with my current carrier until the port completes to avoid delays or rejections.
            </p>
            </li>
            <li className='flex items-center space-x-2'>
              {/* <input name='st3' type='checkbox' checked={st?.st3} onChange={handleStatements} /> */}
              <GoDotFill/>
            <p>
            I must configure my number in Twilio's console before my number ports.
            </p>
            </li>
            <li className='flex items-center space-x-2'>
              {/* <input name='st4' type='checkbox' checked={st?.st4} onChange={handleStatements} /> */}
              <GoDotFill/>
            <p>
            I am responsible for any termination charges imposed by my current carrier for porting my numbers.
            </p>
            </li>
            <li className='flex items-center space-x-2'>
              {/* <input name='st5' type='checkbox' checked={st?.st5} onChange={handleStatements} /> */}
              <GoDotFill/>
            <p>
            I may be unable to receive messages for up to 3 business days after the port completes.
            </p>
            </li>
            </ul>
            <div className='py-4 flex items-center gap-x-2'>
              <input name='st1' type='checkbox' checked={st?.st1} onChange={handleStatements} onKeyDown={handleKeyDown}/>
            <p>
            I understand and agree to the above terms and conditions.
            </p>
            </div>
          </div>
          {/* <div className='flex flex-col gap-y-8 '> */}
            <div className='flex flex-col gap-y-2 col-span-4'>
                <b className='text-lg pb-2 border-b border-black'>2. Basic Information</b>
                <div  className='capitalize'>Port Request Name</div>
                <TxtField 
                variant='outlined'
                name='PortRequestName'
                value={formData?.PortRequestName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                />
            </div>
            {/* <div className='flex items-center gap-x-16 col-span-4'> */}
                <div className='capitalize '>Residential or Business</div>
                <div className='flex items-center gap-x-2'>
                    <input name='ResidentialOrBusiness' type='radio' checked={formData?.ResidentialOrBusiness==='Residential'} value='Residential' onChange={handleCheckBoxChange} onKeyDown={handleKeyDown}/>
                    <label className='capitalize'>Residential</label>
                </div>
                <div className='flex items-center gap-x-2'>
                    <input name='ResidentialOrBusiness' type='radio' checked={formData?.ResidentialOrBusiness==='Business'} value='Business' onChange={handleCheckBoxChange} onKeyDown={handleKeyDown}/>
                    <label className='capitalize'>Business</label>
                </div>
            {/* </div> */}
            <div></div>
            <div className='flex flex-col gap-y-2 col-span-4'>
                <div className='capitalize'>Name <b className='text-red-500'>*</b></div>
                <TxtField 
                variant='outlined'
                name='FirstName'
                value={formData?.FirstName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                />
            </div>
            {err.FirstName && <p className='text-xs text-red-500'>{err.FirstName}</p>}
            <div className='flex flex-col gap-y-2 col-span-4'>
                <div className='capitalize'>Address<b className='text-red-500'>*</b></div>
                <TxtField 
                variant='outlined'
                name='Address'
                value={formData?.Address}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                />
            </div>
            {err.Address && <p className='text-xs text-red-500'>{err.Address}</p>}
            <div className='col-span-4 grid grid-cols-3 gap-4'>
            <div className='flex flex-col gap-y-2'>
                <div className='capitalize'>City<b className='text-red-500'>*</b></div>
                <TxtField 
                variant='outlined'
                name='City'
                value={formData?.City}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                />
                {err.City && <p className='text-xs text-red-500'>{err.City}</p>}
            </div>
            
            <div className='flex flex-col gap-y-2'>
                <div className='capitalize'>State<b className='text-red-500'>*</b></div>
                <MuiDropDown
                fullWidth={true}
              autoComplete='autcomplete-off-stateName_chr'
                name="stateName_chr"
                // label={constant_label?.lbl_txt_state}
                options={stateList}
                getOptionLabel={(option) => option.stateName_chr}
                value={selectedState}
                onChange={(event, value) => {
                  // console.log('value',value)
                  setSelectedState(value)
                  if(value!==null){
                    setFormData((state)=>({
                      ...state,
                      State:value?.stateName_chr
                    }))
                    setErr((state)=>({
                      ...state,
                      State:''
                    }))
                  }else{
                    setErr((state)=>({
                      ...state,
                      State:'State is required field.'
                    }))
                  }
                  
                }}
                onKeyDown={handleKeyDown}
              />
              {err.State && <p className='text-xs text-red-500'>{err.State}</p>}
            </div>
            <div className='flex flex-col gap-y-2'>
                <div className='capitalize'>Postal Code<b className='text-red-500'>*</b></div>
                <TxtField 
                variant='outlined'
                name='PostalCode'
                value={formData?.PostalCode}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                />
                {err.PostalCode && <p className='text-xs text-red-500'>{err.PostalCode}</p>}
            </div>

            </div>
            <div className=' col-span-4 flex flex-col gap-y-2'>
                <div className='capitalize'>Landline & Toll Free Phone Numbers</div>
                <textarea 
                className='border p-2'
                name='PhoneNumbers'
                value={formData?.PhoneNumbers}
                onChange={handleChange}
                />
            </div>
            
            <div className='col-span-4 grid grid-cols-3 gap-4'>
            <div className='col-span-3 capitalize font-bold'>Wireless numbers</div>
            <div className='col-span-4'>Please provide complete information of each wireless number you wish to port</div>
            {/* {
              wireLessNumbers.map(({id},index)=> <div  key={index} className='col-span-4'><CustomComponent key={index} indexValue={index} id={id} /></div>)
            } */}
            {wireLessNumbers.map((item,index)=>(
              <div key={index} className='col-span-4 grid grid-cols-3 gap-4'>
              <div className=''>
                        <div className='capitalize'>Phone Number<b className='text-red-500'>*</b></div>
                        <TxtField 
                        variant='outlined'
                        name='phoneNumber_chr'
                        value={item?.phoneNumber_chr}
                        onChange={(e)=>handlePortNumbers(e,index)}
                        inputProps={{ maxLength: 10 }}
                        onKeyDown={handleKeyDown}
                        />
                       {numberErr[index].phoneNumber_chr && <p className="text-xs text-red-500">{numberErr[index].phoneNumber_chr}</p>}
                    </div>
                    <div className=''>
                        <div className='capitalize'>Wireless Account Number<b className='text-red-500'>*</b></div>
                        <TxtField 
                        variant='outlined'
                        name='wirelessAccountNumber_chr'
                        value={item?.wirelessAccountNumber_chr}
                        onChange={(e)=>handlePortNumbers(e,index)}
                        onKeyDown={handleKeyDown}
                        />
                        {numberErr[index].wirelessAccountNumber_chr && <p className="text-xs text-red-500">{numberErr[index].wirelessAccountNumber_chr}</p>}
                    </div>
                    <div className=''>
                        <div className='capitalize'>Pin or Last 4 of SSN<b className='text-red-500'>*</b></div>
                        <TxtField 
                        variant='outlined'
                        name='pinOrLast4Ssn_chr'
                        inputProps={{ maxLength: 4 }}
                        value={item?.pinOrLast4Ssn_chr}
                        onChange={(e)=>handlePortNumbers(e,index)}
                        onKeyDown={handleKeyDown}
                        />
                        {numberErr[index].pinOrLast4Ssn_chr && <p className="text-xs text-red-500">{numberErr[index].pinOrLast4Ssn_chr}</p>}
                    </div>
                    </div>
          
            ))}
           
           </div>
          { wireLessNumbers.length>1 && <div className={`${wireLessNumbers.length<5 ? 'col-start-3':'col-start-4'} border  border-[#263003] hover:bg-[#263003] hover:text-white p-2 text-center cursor-pointer`} onClick={handleRemoveWirelessNumber}>Remove</div>
            }
           {wireLessNumbers.length<5 &&  <div className={`col-start-4 border  border-[#263003] hover:bg-[#263003] hover:text-white p-2 text-center cursor-pointer`} onClick={handleAddAnotherWirelessNumber}>Add Another Wireless Number</div>
            }
            <div className='col-span-4 grid grid-cols-4 gap-4 items-center'>
                <b className='text-lg col-span-4 pb-2 border-b border-black'>3. Documents</b>
                <p className='col-span-4'>
                Please download the
                  <Link 
                  className='px-1 text-blue-800 hover:underline'
                  target='_blank'
                  to={'/Letter_of_Authorization.pdf'}
                  download
                  >Letter of Authorization form</Link>, complete it including all numbers entered above and upload.
                </p>
                <p className='col-span-4 flex items-center gap-x-2 italic'><HiOutlineLightBulb size={20} color='#FF0000'/>Letter of Authorization and Billing Statement(s) must be PDF files and cannot exceed 4MB.</p>
                 <div className=' col-span-2 capitalize flex items-center gap-x-2'>
                  Letter of authorization<b className='text-red-500'>*</b> <div className='capitalize text-blue-800  cursor-pointer' onClick={handleLOAClick} >
                  <MdOutlineInfo size={20}/>
                  </div>
                  </div>
                  <div className='col-span-2 capitalize flex items-center gap-x-2 '>
                  Billing statements
                  <b className='text-red-500'>*</b>
                  <div className='text-blue-800  cursor-pointer' onClick={handleBSClick}>
                 <MdOutlineInfo size={20}/>
                  </div>

                  </div>
                  {/* <div className='col-span-2'>
                    <TxtField
                    name='letterOfAuthorization' 
                     variant='outlined'
                     type='file'
                     onChange={UploadLOA}
                    />
                    </div>
                    <div className='col-span-2'>
                    <TxtField
                       name='billingDocument' 
                       variant='outlined'
                       type='file'
                       onChange={UploadBillingDoc}
                     />
                   </div> */}
                  
                  {/* {wireLessNumbers.map(({id},index)=> <div className='col-span-4' key={index}><CustomComponent2 key={index} pos={index}/> </div>) } */}
                  {
                    docs.map((data,index)=>(
                      <div className='col-span-4 grid grid-cols-4 gap-4' key={index}>
                   {index<1 ?<div key={index} className='col-span-2 flex items-center gap-x-2'>
                    <button key={index} className='border border-black px-2 py-1 rounded bg-gray-200 text-md' onClick={ChooseLOA}>Choose File</button>
                    <input
                    ref={myInputRef1}
                    name='letterOfAuthorization' 
                     variant='outlined'
                     type='file'
                     hidden={true}
                    //  accept='.pdf'
                     onChange={(e)=>UploadLOA(e,index)}
                    />
                     <p>
                      {(docs[index][`letterOfAuthorization`]?.name)?(docs[index][`letterOfAuthorization`].name):'No file chosen'}  
                     </p>
                    </div>:<div key={index} className='col-span-2'></div>}
                    <div className='col-span-2 flex items-center gap-x-2' key={index}>
                    <button key={index} className='border border-black px-2 py-1 rounded bg-gray-200 text-md' onClick={(e)=>ChooseBS(e,index)}>Choose File</button>
                    <input
                       name='billingDocument'
                       ref={(el) => myInputRef2.current[index] = el} 
                       id={data?.id}
                       variant='outlined'
                       type='file'
                       hidden={true}
                      //  accept='.pdf'
                       onChange={(e)=>UploadBillingDoc(e,index)}
                     />
                    <p>
                      {(docs[index][`billingDocument`]?.name)?(docs[index][`billingDocument`].name):'No file chosen'}  
                     </p>
                   </div>
    </div>
                    ))
                  }


                  {/* {docs.map(({id},index)=> <div className='col-span-4' key={index}><CustomComponent2 key={index} pos={index}/> </div>) } */}
                  
                 
                 {/* { docs.length >1 && <div className='col-start-3 border  border-[#263003] hover:bg-[#263003] hover:text-white p-2 text-center cursor-pointer' onClick={handleRemoveDocs}>Remove</div>} */}
                  
                  {/* <div className='col-start-4 border  border-[#263003] hover:bg-[#263003] hover:text-white p-2 text-center cursor-pointer' onClick={handleAddAnotherDocs}>Add Another Billing Document</div> */}
           
            </div>
          <button type='submit' className='col-start-4 p-2 text-center bg-[#263003] text-white '>Submit</button>
          </div>
          
      {/* </div> */}
      <LOAInfo open={open} setOpen={setOpen}/>
      <BSInfoModal isOpen={isOpen} setIsOpen={setIsOpen}/>
      </form>
    </>
  );
}
