import React, { useEffect, useState } from 'react';

import { MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaCheckCircle } from "react-icons/fa";
import { MdAdd, MdDelete, MdInfo } from 'react-icons/md';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import MuiDropDown from '../../components/InputFields/DropDown/autoComplete';
import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import { getCampaignUseCase, postSMSCampaign } from '../../services/smsCampaign/smsCampaignService';

const useStyles = makeStyles((theme) => ({
    root: {
      border: 0,
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
  
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontSize: '14px',
        fontFamily: "'Rubik', sans-serif",
        color: '#333',
      },
      '& .MuiDataGrid-columnsContainer': {
        border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
  
      '&  .MuiDataGrid-window ': {
        //   [theme.breakpoints.up('1450')]: {
        //     overflowX: (props) => {
        //       if (!props) return 'hidden';
        //       return props.open ? 'auto' : 'hidden';
        //     },
        //   },
      },
      '& .MuiDataGrid-dataContainer': {
        borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Rubik', sans-serif",
        borderBottom: '0',
      },
      '& .MuiDataGrid-row': {
        color: '#444',
        backgroundColor: '#ffffff',
        outline: 'none',
      },
  
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f7f7',
        color: '#aeacb9',
      },
    },
  
    paper2: {
      width: '100%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
      '& .makeStyles-paper-32': {
        marginTop: '0px',
      },
      overflow: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingY: '10px',
      margin: '10px',
    },
    tableContainer: {
      overflow: 'auto',
    },
    selected: {
      '&:hover': {
        backgroundColor: 'rgba(17, 122, 139, 0.15) !important',
      },
    },
  
    clickable: {
      cursor: 'pointer',
    },
    icons: {
      padding: '0px 3px',
      '&:focus': {
        outline: 'none',
      },
    },
    table: {
      minWidth: 750,
    },
    radio: {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
  
      '&$checked': {
        color: '#263003',
      },
  
      '& .MuiSvgIcon-root': {
        height: '15px',
        width: '15px',
      },
    },
  
    table: {
      minWidth: 340,
    },
    tableCell: {
      paddingRight: 4,
      paddingLeft: 5,
  
      a: {
        color: '#9BA3B9',
      },
      paddingY: '0px',
    },
    onHover: {
      '& .MuiButtonBase-root': {
        '&:hover': {
          color: '#000000DE',
        },
      },
    },
  
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  
    grid: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    heading: {
      display: 'flex',
      justifyItems: 'center',
      columnGap: '4px',
      fontWeight: 'bold',
      borderBottom:'1px solid black',
    //   marginTop: 30,
      padding: 10,
      background:'#263003',
      color:'white',
    },
  }));
  // Custom component for the sample message
function SampleMessage({ id, value, onChange }) {
  console.log('174',id)
  return (
    <div className='flex flex-col gap-y-1'>
      <label className='text-lg font-bold'>Sample message #{id} {id<3?<b className='text-red-500'>*</b>:null}</label>
      <TxtField
        name='sampleMsg1'
        placeholder='Your one-time passcode is 123456'
        variant='outlined'
        value={value}
        multiline
        maxRows={4}
        onChange={(e) => onChange(id, e.target.value)}
      />
      <div className='text-xs text-gray-400'>
        Provide an example of a message that you will be sending with this campaign. This message sample will be used by carriers to help identify your traffic.
      </div>
    </div>
  );
}
export default function SMSCampaignPage() {
    const classes = useStyles()
  const dispatch = useDispatch();
  const [selectedUseCase,setSelectedUseCase]=useState({})
  const [messages, setMessages] = useState([{id:1,value:''},{id:2,value:''}]);
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const userId = useSelector((state) => state?.login?.loginData?.userId)
  const initialData={
    messagingServiceName:'',
    campaignUseCase:'',
    usAppToPersonUsecase:'',
    // serviceType:'',
    description:'',
    // sampleMsg1:'',
    // sampleMsg2:'',
    hasEmbeddedLinks:false,
    hasEmbeddedPhone:false,
    messageFlow:'',
    optInMessage:'',
    optInKeywords:'',
  }
 const [campaignData,setCampaignData]=useState(initialData)
 const [useCase,setUseCase]=useState([])
 const initialErrData={
  messagingServiceName:'',
  campaignUseCase:'',
  usAppToPersonUsecase:'',
  description:'',
  messageFlow:'',
  optInMessage:'',
  optInKeywords:'',
}
const [err,setErr]=useState(initialErrData)

const formValidation=(name,value)=>{
  if(name==='messagingServiceName' )
  {
    if(value.length<1)
   { setErr((state)=>({
      ...state,
      messagingServiceName:'Messaging Service Name is required'
    }))
  }
    else{
      setErr((state)=>({
        ...state,
        messagingServiceName:''
      }))
    }
  }else if(name==='description')
  if(value.length<40){
    setErr((state)=>({
      ...state,
      description:'The campaign description is required and should contain a minimum of 40 characters.'
    }))
  }else{
    setErr((state)=>({
      ...state,
      description:''
    }))
  }
  else if(name==='messageFlow')
  if(value.length<40){
    setErr((state)=>({
      ...state,
      messageFlow:'This field is required and should contain a minimum of 40 characters'
    }))
  }else{
    setErr((state)=>({
      ...state,
      messageFlow:''
    }))
  }
  else if(name==='optInMessage')
  if(value.length<20){
    setErr((state)=>({
      ...state,
      optInMessage:'This field is required and should contain a minimum of 20 characters'
    }))
  }else{
    setErr((state)=>({
      ...state,
      optInMessage:''
    }))
  }
}
console.log('240',err)
 const handleChange=(event)=>{
   const {name,value}=event.target
   formValidation(name,value)
   setCampaignData((state)=>({
    ...state,
    [name]:value
   }))
 }
 const handleMessageContent = (event) => {
  const {name,checked}=event.target
  console.log('207',name,checked)
  setCampaignData((state)=>({
    ...state,
    [name]:checked
  }))
};
useEffect(()=>{
  dispatch(getCampaignUseCase({},(data)=>{
    console.log('getCampaignUseCase',data)
    setUseCase(data)
  }))
},[])

const handleSampleMsgChange = (id, value) => {
  const updatedMessages = messages.map(message => {
    if (message.id === id) {
      return { ...message, value };
    }
    return message;
  });
  setMessages(updatedMessages);
};

const handleAddMessage = (e) => {
  e.preventDefault()
  const newId = messages.length + 1;
  if(messages.length<5)
  {setMessages([...messages, { id: newId, value: '' }]);}
};
const handleDeleteMessage=(e)=>{
  e.preventDefault()
  if(messages.length>2)
  {
    const cloneArray=[...messages]
     cloneArray.pop()
    console.log('259',cloneArray)
    setMessages(cloneArray)
    // setMessages([...messages, { id: newId, value: '' }]);
  }
}

 console.log('campaignData',
//  campaignData,
 messages)
 
 function checkLengthGreaterThan20(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length <= 20) {
      return false;
    }
  }
  return true;
}
 const handleCreateCampaign=(e)=>{
  e.preventDefault()
  const totalSampleMsg=messages.map((item)=> item.value)
  const finalSamples=totalSampleMsg.filter((item)=>item.length>1)
  const opt_in_key=(campaignData.optInKeywords)?.split(',')
  console.log('msgSample',totalSampleMsg,finalSamples)
  const payload={...campaignData,messageSamples:finalSamples,optInKeywords:opt_in_key }
  console.log('266',payload)

  console.log(payload?.messagingServiceName,payload?.description,payload?.messageFlow,payload?.optInMessage,payload?.usAppToPersonUsecase, messages.length)
  const smpleMsg=checkLengthGreaterThan20(payload?.messageSamples)
  if
  (payload?.messagingServiceName && 
    (payload.description.length>=40) 
    && (payload.messageFlow.length>=40)
    // && (payload.optInMessage.length>=20)
    && (payload?.usAppToPersonUsecase)
    && smpleMsg
    )
  {
    dispatch(postSMSCampaign(payload , (data)=>{
    console.log('268',data)
     setCampaignData(initialData)
     setSelectedUseCase(null)
     setMessages([{id:1,value:''},{id:2,value:''}])
     setErr(initialErrData)
    NotificationManager.success('Campaign Created Succesfully !')
  },(err)=>{
    NotificationManager.error('Please fill all the mandatory field marked as(*)')
  }))
  }
    else{
      NotificationManager.error('Please fill all the mandatory field marked as(*)')
  }
  
 }

  return (
    <>
      {spinner ? <Loader /> : null}
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleCreateCampaign}>

      <div className="bg-white border border-black p-2 mt-2 grid grid-cols-4 gap-4">
       <div className='col-span-4'>
       <Typography variant="h5" gutterBottom className={classes.heading}>
            {/* <FaUsers size={28} className="" /> */}
            Create New A2P Campaign
          </Typography>
       </div>
          {/* <div className='flex flex-col gap-y-8'> */}
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Available A2P Campaign use cases <b className='text-red-500'>*</b></label>
            <div className='col-span-4'>
            <MuiDropDown 
            fullWidth={true}             
              size="small"              
              placeholder='Select Campaign use case'
                options={useCase}
                getOptionLabel={(option)=>option.name}  
                value={selectedUseCase}             
                onChange={(event, value) => {
                  console.log('select sender',value)
                  setSelectedUseCase(value)
                  if (value!==null) {
                  setCampaignData((state)=>({
                    ...state,
                    campaignUseCase:value.name,
                    usAppToPersonUsecase:value.code
                  }))
                    
                  }else{
                    
                  }
                }}
               renderOption={(option)=>(<div><div>{option.name}</div><p className='text-xs'>{option.description}</p></div>)}
               style={{
                // width:'250px',
                background:'white'
              }}
              
              />
            </div>
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Messaging Service Name <b className='text-red-500'>*</b></label>
            <div className='col-span-4'>
            <TxtField
            name='messagingServiceName'
            placeholder='Messaging Service Name'
            variant='outlined'
            value={campaignData?.messagingServiceName}
            onChange={handleChange}
            />
            </div>
            {err?.messagingServiceName && <p className='col-span-4 text-xs text-red-500'>{err?.messagingServiceName}</p>}
            {/* </div> */}
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Campaign Description <b className='text-red-500'>*</b></label>
            <div className='col-span-4'>
            <TxtField
            name='description'
            placeholder={`Example: This campaign sends one-time passcodes to the end users when they try to log into our company's website.`}
            variant='outlined'
            value={campaignData?.description}
            multiline
            maxRows={4}
            onChange={handleChange}
            />
            </div>
             {err?.description && <p className='col-span-4 text-xs text-red-500'>{err?.description}</p>}
            {/* </div> */}
                {messages.map((message, index) => (
                <div  key={message.id} className='col-span-4'>
                   <SampleMessage id={message.id} value={message.value} onChange={handleSampleMsgChange} />
                    {((message.value.length<20 && message.value!=='')) && <p className='text-xs text-red-500'>Sample Meassage is required and should contain a minimum of 20 characters</p>}
                </div>
              ))}
              {/* <div className='w-full flex items-center gap-x-4'> */}
              <button className='col-start-3 flex items-center justify-center gap-x-2 border border-[#263003] hover:bg-[#263003] hover:text-white p-2' onClick={handleDeleteMessage}>
              <MdDelete size={20}/>
                Remove
                </button>
              <button className='flex items-center justify-center gap-x-2 border border-[#263003] hover:bg-[#263003] hover:text-white p-2' onClick={handleAddMessage}>
                <MdAdd size={24}/>
                Add Another Sample Message
                </button>
              {/* </div> */}
            {/* <div className='col-span-4 flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Message Content</label>
            <div className='col-span-4 flex items-center gap-x-2'>
                <input name='hasEmbeddedLinks' type='checkbox' checked={campaignData?.hasEmbeddedLinks} onChange={handleMessageContent}/>Message will include embedded links.
            </div>
            <div className='col-span-4 flex items-center gap-x-2'>
                <input name='hasEmbeddedPhone' type='checkbox'checked={campaignData?.hasEmbeddedPhone} onChange={handleMessageContent}/>Message will include phone numbers.
            </div>
            
            {/* </div> */}
            <div className='col-span-4 bg-blue-200 p-2 rounded' >
                <div className='flex items-center gap-x-2 font-bold'>
                    <MdInfo size={24}/>
                    Did you know
                    </div>
                    <p className='p-2'>
                        Most Campaign that fail or are rejected due to incorrect information being submitted in the 
                        "How do end-users consent to receive messages?" section below.
                    </p>
            </div>
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>How do end-users consent to receive messages? (40-2048 characters)<b className='text-red-500'>*</b></label>
            <div className='col-span-4'>
            <TxtField
            name='messageFlow'
            placeholder='Example: End users opt-in by visiting www.examplewebsite.com and adding their phone number. They then check a box agreeing to receive text messages from Example Brand. Opt-in occurs after end users create an account; see screenshot of opt-in (www.example.com/screenshot-of-the-form-field). Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt in.'
            variant='outlined'
            value={campaignData?.messageFlow}
            multiline
            maxRows={4}
            onChange={handleChange}
            />
            </div>
             {err?.messageFlow && <p className='col-span-4 text-xs text-red-500'>{err?.messageFlow}</p>}

            <div className='col-span-4 text-xs text-gray-400'>This field should describe how end users opt-in to the campaign, therefore giving consent to the sender to receive their messages. If multiple opt-in methods can be used for the same campaign, they must all be listed.</div>
            {/* </div> */}
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Opt-in Keywords (max 255 characters)</label>
            <div className='col-span-4'>
            <TxtField
            name='optInKeywords'
            placeholder='Example: Subscribe, Start'
            variant='outlined'
            value={campaignData?.optInKeywords}
            multiline
            maxRows={4}
            onChange={handleChange}
            />
            </div>
            <div className='col-span-4 text-xs text-gray-400'>
            If end users can text in a keyword (for example, "Subscribe") to start receiving messages from your campaign, those keywords must be provided. If you do not support opt-in via text, please leave this blank
            </div>
            {/* </div> */}
            {/* <div className='flex flex-col gap-y-1'> */}
             <label className='col-span-4 text-lg font-bold'>Opt-in Message (20-320 characters)
             <b className='text-red-500'>*</b>
             </label>
            <div className='col-span-4'>
            <TxtField
            name='optInMessage'
            placeholder='Example: Acme Corporation: You are now opted-in. For help, reply HELP. To opt-out, reply STOP'
            variant='outlined'
            multiline
            maxRows={4}
            value={campaignData?.optInMessage}
            onChange={handleChange}
            />
             {err?.optInMessage && <p className='text-xs text-red-500'>{err?.optInMessage}</p>}
             </div>
            <div className='col-span-4 text-xs text-gray-400'>
            If end users can text in a keyword to start receiving messages from your campaign, the auto-reply messages sent to the end users must be provided. The opt-in response should include the Brand name, confirmation of opt-in enrollment to a recurring message campaign, how to get help, and clear description of how to opt-out. If you do not support opt-in via text, please leave this blank.
            </div>
            {/* </div> */}
          <button type='submit' className='col-start-4 p-2 bg-[#263003] text-white flex items-center justify-center gap-x-2' >
            <FaCheckCircle size={24}/>
            Create
            </button>
          </div>
      {/* </div> */}
      </form>

    </>
  );
}
