import ActionConstants from '../config/AppConstants';
const innitialData = {
  userDir:[],
  userEmail:'',
  personateEmail:'',
};
export default function directories(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.ALL_DIR: {
      return {
        userDir:action.userDir,
        userEmail:action.userEmail,
        personateEmail:action.personateEmail
      };
    }
    default:
      return { ...state };
  }
}
