import React from 'react';

import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import constant_label from '../../../constants';
import DefaultButton from '../../Buttons/defaultButton';
import NoButton from '../../Buttons/noButton';
import YesButton from '../../Buttons/yesButton';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  title:{
    display:'flex'
  }
});

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {};

  handleClose = () => {
    this.props.onHandleModel();
  };

  onAccept = () => {
    this.props.action()
    this.props.onHandleModel();
  };

  render() {
    // const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.isOpenDialog}
          // onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          // maxWidth={"md"}
        >
          
          <DialogContent style={{padding:'8px',minWidth:'313px',maxWidth:'330px'}}>
            <Paper elevation={3}>
            <div className='flex items-center gap-x-2 bg-[#263003] text-white p-1 rounded'> 
            <img 
            src="/connect_logo_1.svg" 
            alt=''
            width="130px"
            // height="20px"
            />
          </div>
            </Paper>
              <Paper elevation={3}>
              <div className='flex flex-col gap-y-6 bg-[#D3D3D3] p-2 rounded'>
              {this.props.content}
              
              <div className='flex items-center justify-end gap-x-2'>
            <NoButton onClick={this.handleClose} />
            <YesButton onClick={this.onAccept}/>
              </div>
              </div>
              </Paper>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);
