import {serviceCallWithToken, serviceCallWithToken2} from '../serviceCall'
export const getAvailableNumbers = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getAvailableNumbers',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const buyTwilioNumber = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/buyTwilioNumber',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getActiveNumbers = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getActiveNumbers',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getUserPhoneNumber = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/User/GetUserPhoneNumber',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const portNumberRequest = (data,headers, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/portNumberRequest',
        method: 'post',
        data: data,
        headers:headers
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const putTwilioNumber = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken2({
        url: '/PutTwilioNumber',
        method: 'put',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getRingGroups = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getRingGroups',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postRingGroup = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken2({
        url: '/PostRingGroup',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteRingGroup = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      serviceCallWithToken({
        url: '/DeleteRingGroups',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          // dispatch(toggleLoader(false));
          callback && callback();
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const assignRingGroupNumber = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken2({
        url: '/AssignRingGroupNumber',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const unassignRingGroupNumber = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      serviceCallWithToken({
        url: '/UnassignRingGroupNumber',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          // dispatch(toggleLoader(false));
          callback && callback();
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getDialPlans = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getDialPlan',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          // dispatch(toggleLoader(false));
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postDialPlan = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
        url: '/PostDialPlan',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteDialPlan = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      serviceCallWithToken({
        url: '/DeleteDialPlan',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          // dispatch(toggleLoader(false));
          callback && callback();
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };

  export const getDialPlanOption = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getDialPlanOption',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          // dispatch(toggleLoader(false));
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postDialPlanOption = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
        url: '/PostDialPlanOption',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteDialPlanOption = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      serviceCallWithToken({
        url: '/DeleteDialPlanOption',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          // dispatch(toggleLoader(false));
          callback && callback();
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getDialPlanAction = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      // dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getDialPlanAction',
        method: 'get',
        data: data,
      })
        .then((response) => {
          // dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          // dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
 
  export const postDialPlanNode = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
        url: '/PostDialPlanNode',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getDialPlanNode = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getDialPlanNode',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteDialPlanNode = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/DeleteDialPlanNode',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          // dispatch(toggleLoader(false));
          callback && callback();
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error?.response?.data);
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
        });
    };
  };
  export const getAutoAttendantAction = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/getAutoAttendantAction',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postAutoAttendantAction = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
        url: '/PostAutoAttendantAction',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteAutoAttendantAction = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/DeleteAutoAttendantAction',
        method: 'delete',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback();
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };