const innitialData = {
    dialPlanActions:[],
    
   };
   export default function dialPlanData(state = innitialData, action) {
     switch (action.type) {
       case 'DIAL_PLAN_ACTION': {
         return {
           ...state,
           dialPlanActions:action.dialPlanActions,
         };
       }
       default:
         return { ...state };
     }
   }