import axios from "axios";
import { NotificationManager } from "react-notifications";

import { userIdToken } from "../../userIdToken";
import { serviceCallAuth } from "../serviceCallAuth";



export const getPermission = (params, callback, errorCallBack) => {
    return async (dispatch) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallAuth({
        url: '/api/Account/GetPermission',
        method: 'get',
        data:params
      })
        .then((response) => {
          callback && callback(response.data.aspNetPermission);
          console.log(response.data, 'data');
            dispatch({ type: 'SPINNER_STATE', isLoading: false });

        })
        .catch((error) => {
          console.log(error, 'error');
          errorCallBack && errorCallBack(error?.response?.data);
            dispatch({ type: 'SPINNER_STATE', isLoading: false });

        });
    };
  };
  export const getPermissionByRoleGroup = (params, callback, errorCallBack) => {
    console.log(params)
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallAuth({
        url: '/api/Account/GetPermissionByRoleGroup',
        method: 'get',
        data:params
      })
        .then((response) => {
          callback && callback(response.data.aspNetRoles);
          console.log(response.data, '37');
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
        })
        .catch((error) => {
          console.log(error, 'error');
          errorCallBack && errorCallBack(error?.response?.data);
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
        });
    };
  };
export const getPermissionArea = (params, callback, errorCallBack) => {
    return async (dispatch) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallAuth({
        url: '/api/Account/GetPermissionArea',
        method: 'get',
        data:params
      })
        .then((response) => {
          callback && callback(response.data.aspNetPermissionArea);
          console.log(response.data, 'data');
            dispatch({ type: 'SPINNER_STATE', isLoading: false });

        })
        .catch((error) => {
          console.log(error, 'error');
          errorCallBack && errorCallBack(error?.response?.data);
            dispatch({ type: 'SPINNER_STATE', isLoading: false });

        });
    };
  };
  export const addEditPermission = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      serviceCallAuth({
        url: '/api/Account/PostPutPermission',
        method: 'post',
        data: data,
      })
        .then((response) => {
          NotificationManager.success(response?.message || 'Added Permission Successfully')
          callback && callback(response);
          // dispatch(add_permission(data));
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error.response);
          NotificationManager.error(error.response || 'Something went wrong');
        });
    };
  };

  export const deletePermission = (data, callback, errorCallBack) => {
    return async (dispatch) => {
      serviceCallAuth({
        url: '/DeletePermission',
        method: 'delete',
        data
      })
        .then((response) => {
          NotificationManager.success(response?.message || 'Deleted Permission successfully');
  
          callback && callback(response.data);
        })
        .catch((error) => {
          console.log(error, 'error');
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  }
//   export const assignPermissionToRole = (data, callback, errorCallBack) => {
//     return async (dispatch) => {
//       // dispatch(spinner_state(true));
//       serviceCallAuth({
//         url: '/api/Account/AssignPermissionToRole',
//         method: 'post',
//         data
//       })
//         .then((response) => {
//           callback && callback();
//           console.log(response.data, 'data');
//         })
//         .catch((error) => {
//           console.log(error, 'error');
//           errorCallBack && errorCallBack();
//         });
//     };

// }
export const assignPermissionToRole = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    const getKeys=userIdToken()
    const domain_Name=getKeys?.DOMAIN_NAME;
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    axios.post(
      `${window.config.AUTH_API_URL}/api/Account/AssignPermissionToRole`,
      data,
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
          "x-domain-name": domain_Name,
        },
      }
    ).then((response) => {
        callback && callback();
        console.log(response.data, 'data');
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      })
      .catch((error) => {
        console.log(error, 'error');
        errorCallBack && errorCallBack();
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };

}