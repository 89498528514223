import React from 'react';

import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import './index.css';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';

import App from './App';
import ReduxStore from './ReduxStore';
import reportWebVitals from './reportWebVitals';
import 'react-notifications/lib/notifications.css';
import './scss/index.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import indigo from '@material-ui/core/colors/indigo';

const font = '\'Rubik\', sans-serif';
const theme = createTheme({
  palette: {
    primary: {
      main: '#263003',
    },
    secondary: {
      // main: red[500],
      blue: blue[500],
      main:'#95B632',
    },

    // inherit:{
    //   main:red
    // }
  },
  typography: {
    fontFamily: font,
  },
  // Button: {
  //   backgroundColor: '#263003',
  //   color: 'white',
  // },
});

ReactDOM.render(
  <Provider store={ReduxStore.getDefaultStore().store}>
    <MuiThemeProvider theme={theme}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
      <NotificationContainer />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
