import React from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function LoadingScreen() {
  return (
    <div className="h-screen w-full bg-inherit">
      <div className="w-full h-full p-10 flex justify-center items-center ">
        <span className="animate-bounce text-3xl ">
          <AiOutlineLoading3Quarters className="h-28 w-20" />
        </span>
      </div>
    </div>
  );
}

export default LoadingScreen;
