// import { get } from "lodash";
import ActionConstants from '../config/AppConstants';
const innitialData = {
  customerEmailPhone: [],
};
export default function customer(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.CUSTOMERS_EMAIL_PHONE: {
      return {
        // ...state,
        customerEmailPhone: action.customerEmailPhone,
      };
    }
    default:
      return { ...state };
  }
}
