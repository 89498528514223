import { useEffect, useRef, useState } from 'react';

import { TextField, IconButton, Container, Grid, Paper, Typography,Avatar ,Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MdCameraAlt } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import { getTenantDetailByTenantKey,getTenantDetails, saveTenantDetails } from '../../services/tenant';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',    
        // color: theme.palette.text.secondary,
      },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      background: '#6082B6',
      
    },
    avatar2: {
      width: theme.spacing(10),
      height: theme.spacing(10),
     },
     avatar3:{
        background: 'green',
     }
  }));
function OrgSettings(){
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const dispatch=useDispatch()
  const classes = useStyles();
  const {tenant,firstName,lastName,email}=useSelector((state)=>state.login.loginData)
  const [tenantDetails,setTenantDetails]=useState({
    tenantName_chr:'Organization Name',
    adminFirstName_chr:'First Name',
    adminLastName_chr:'Last Name',

  })
  const [initials,setInitials]=useState({orgInitial:'',firstInitial:'',LastInitial:''})
  const imgInputRef=useRef(null)
  const [selectedImage, setSelectedImage] = useState(null);

  const getInitials = (name) => {
    const words = name?.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase();
    } else {
      return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
    }
  };
  const getOrganizationDetails=()=>{
    dispatch(getTenantDetails({DomainName:tenant},(tenant)=>{
    
      dispatch(getTenantDetailByTenantKey({TenantKey_chr:tenant[0]?.TenantKey_chr},res=>{
        // console.log(res,"res57")
          setTenantDetails(res?.tblTenantDetail[0])
          const {tenantName_chr,adminFirstName_chr,adminLastName_chr}=res.tblTenantDetail[0]
          setInitials(
            {
              orgInitial:getInitials(tenantName_chr),
            firstInitial:getInitials(adminFirstName_chr),
            LastInitial:getInitials(adminLastName_chr),
          }
          )
      }))
    }))
  }
useEffect(()=>{
  getOrganizationDetails()
  },[])

// console.log('tenantDetail55',tenantDetails)
const handleImageChange = (e) => {
  const file = e.target.files[0];

  // Check if a file is selected
  if (file) {
    // Check if the file type is an image
    if (file.type.startsWith('image/')) {
      // If it's an image, set the selected image
      // setSelectedImage(file);
      console.log('inside if')
      const formData=new FormData();
      formData.append('TenantDetailID_ids',tenantDetails.tenantDetailID_ids)
      formData.append('CompanyLogo_fl',file)
      formData.append('CompanyName_chr',(tenantDetails.companyLegalName_chr)?.trim())
      formData.append('CompanyDescription_chr',(tenantDetails.CompanyDescription_chr)?.trim())
      formData.append('CompanyAddress_chr',(tenantDetails.companyAddress_chr)?.trim())
      formData.append('CompanyAddress2_chr',(tenantDetails.companyAddress2_chr)?.trim())
      formData.append('CompanyWebsiteUrl_chr',(tenantDetails.CompanyWebsiteUrl_chr)?.trim())
      formData.append('CompanyID_chr',(tenantDetails.CompanyID_chr)?.trim())
      formData.append('PhoneNumber_chr',(tenantDetails.phoneNumber_chr)?.trim())
      formData.append('EmailAddress_chr',(tenantDetails.emailAddress_chr)?.trim())
      formData.append('Country_lng',tenantDetails.Country_lng)
      formData.append('State_lng',tenantDetails.State_lng)
      formData.append('City_chr',(tenantDetails.City_chr)?.trim())
      formData.append('ZipCode_chr',(tenantDetails.ZipCode_chr)?.trim())
      // formData.append('ZipCode_chr','111111')
      formData.append('adminDetails_chr.FirstName_chr',(tenantDetails.adminFirstName_chr)?.trim())
      formData.append('adminDetails_chr.LastName_chr',(tenantDetails.adminLastName_chr)?.trim())
      formData.append('adminDetails_chr.Email_chr',(tenantDetails.adminEmail_chr)?.trim())
      formData.append('adminDetails_chr.PhoneNumber_chr',(tenantDetails.adminPhoneNumber_chr)?.trim())
      formData.append('adminDetails_chr.Password_chr',(tenantDetails.adminPassword_chr)?.trim())
      dispatch(saveTenantDetails(formData, {
        'content-type': 'multipart/form-data',
      },(res)=>{
        console.log('117 res',res)
        
        if(res){
          console.log('117 res')
          getOrganizationDetails()
        }
      } ))
      
    } else {
      // If it's not an image, show an error message or perform another action
      alert('Please select an image file.');
    }
  }
};
const onClickCamera=()=>{
  imgInputRef.current.click()
}
return (<div className="p-4 bg-white">
  {spinner? <>
      <div className="fixed z-40 min-h-full min-w-full bg-black opacity-25 top-0 left-0"></div>
      <div className="fixed inset-x-1/2 top-2/4	z-50 text-center ">
        {/* <ImSpinner8 className="dark:text-white-600  inline h-12 w-12 animate-spin fill-[#263003] text-gray-200 " /> */}
        <div className='animate-bounce'>
        <img src='/connect_fav_icon.svg' className="h-12 w-12 max-w-fit animate-spin " />
        </div>
      </div>
    </>:null}
    <Grid container spacing={2}>
    <Grid item xs={12}>
   <Typography variant='h4'>
        Organization Overview
    </Typography>
   </Grid>
   
  <Grid item xs={12} md={6} container spacing={2}>
  
    <Grid item xs={12}>
    <Typography variant='h6'>Name</Typography>
    <TxtField variant='outlined' value={tenantDetails?.tenantName_chr}/>
    </Grid>
    <Grid item xs={12}>
    <Typography variant='h6'>Description</Typography>
    <TxtField
    fullWidth
    variant='outlined'
    // label="Description"
    name="companyDescription"
    value={tenantDetails?.CompanyDescription_chr}
    minRows={4}
    multiline={true}
    />
    </Grid>
     
    <Grid item xs={12}>
    <Typography variant='h6'>Address Line 1</Typography>
    <TxtField
    fullWidth
    variant='outlined'
    // label="Description"
    name="orgAddress"
    value={tenantDetails?.companyAddress_chr}
    />
    </Grid>
    <Grid item xs={12}>
    <Typography variant='h6'>Address Line 2</Typography>
    <TxtField
    fullWidth
    variant='outlined'
    // label="Description"
    name="orgAddress"
    value={tenantDetails?.companyAddress2_chr}
    />
    </Grid>
    <Grid item xs={12}>
    <Typography variant='h6'>Country</Typography>
    <TxtField variant='outlined' 
    value={tenantDetails?.CountryName_chr}
    
    />
    </Grid>
    <Grid item xs={12}>
    <Typography variant='h6'>State</Typography>
    <TxtField
    fullWidth
    variant='outlined'
    // label="Description"
    name="orgAddress"
    value={tenantDetails?.StateName_chr}
    />
    </Grid>
    <Grid item xs={12}>
    <Typography variant='h6'>City</Typography>
    <TxtField
    fullWidth
    variant='outlined'
    // label="Description"
    name="orgAddress"
    value={tenantDetails?.City_chr}
    />
    </Grid>
  </Grid>
  <Grid item xs={6} md={6}>
            <Container maxWidth='xs'>
            <Paper elevation={1} className={classes.paper}>
                <div className='pb-2 flex flex-col items-center justify-center'>
                 <div className='w-full flex justify-end'>
                 <input 
                  ref={imgInputRef}
                  type='file' accept="image/*"
                  onChange={handleImageChange}
                  className='hidden'
                  />
                  <IconButton onClick={onClickCamera}><MdCameraAlt/></IconButton>
                 </div>
                    {tenantDetails.companyLogoUrl_chr?
                    <Avatar className={classes.avatar2} 
                    src={tenantDetails?.companyLogoUrl_chr}
                    />:
                    <Avatar className={classes.avatar}>
                        {initials?.orgInitial}
                    </Avatar>
                    }<Typography variant='h6'>{tenantDetails?.tenantName_chr}</Typography>

                </div>
                <Divider light/>
                <div className='p-4 flex flex-col gap-4'>
                    <Typography variant='body1'>Organization owner</Typography>
                    <div className='flex items-center justify-center gap-x-2'>
                        <Avatar className={classes.avatar3}>
                            {`${initials?.firstInitial}${initials?.LastInitial}`}
                        </Avatar>
                        <div className='flex flex-col text-left'>
                            <Typography variant='body2'>{`${tenantDetails?.adminFirstName_chr} ${tenantDetails?.adminLastName_chr}`}</Typography>
                            <Typography variant='caption'>{tenantDetails?.adminEmail_chr}</Typography>
                        </div>
                    </div>
                </div>
        </Paper>
            </Container>

  </Grid>
    </Grid>
   
   
    
</div>)
}
export default OrgSettings