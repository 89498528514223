// import { useDispatch } from 'react-redux';

import { NotificationManager } from 'react-notifications';

import AppConstants from '../../config/AppConstants';
import { userIdToken } from '../../userIdToken';
import { serviceCallWithToken } from '../serviceCall';
export const addEditTemplate = (data, callback, errorCallBack, templateType = 1) => {
  let url = '/api/Email/PutEmailTemplate';
  if (templateType === 2) url = '/api/SMS/PostPutSMSTemplate';
  else if (templateType === 3) url = '/api/Script/PostPutScriptTemplate';
  const apiMethod=templateType === 2?'post':'put'
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url,
      method: apiMethod,
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        callback && callback(response && response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const GetEmailTemplatesOfUser = (data, callback, errorCallBack) => {
  const userId=userIdToken().userId
  // console.log('data',data)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: `/api/Email/GetEmailTemplatesOfUser/${userId}`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        console.log(response);
        // dispatch(toggleLoader(false));
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          // Array.isArray(response?.data?.emailTemplates) &&
          //   response.data.emailTemplates.forEach((element, index) => {
          //     response.data.emailTemplates[index]['id'] = index + 1;
          //   });
          callback && callback(response.data);

          // dispatch({
          //   data: response.data.emailTemplates,
          //   type: AppConstants.GET_ALL_TEMPLATES,
          // });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getEmailTemplate = (data, callback, errorCallBack) => {
  // console.log(data, 'get email template');
  const userId=window.config?.CALLING_USER_ID?window.config?.CALLING_USER_ID:userIdToken().userId
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: `/api/Email/GetEmailTemplate/${userId}/${data?.EmailTemplateID_ids}`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        console.log(response);
        if (response && response.data) {
          Array.isArray(response.data.emailTemplate) &&
            response.data.emailTemplate.forEach((element, index) => {
              response.data.emailTemplate[index]['id'] = index + 1;
            });
          callback && callback(response.data.emailTemplate);

          dispatch({
            data: response.data.emailTemplate,
            type: AppConstants.GET_ALL_TEMPLATES,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getSMSTemplate = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/GetSMSTemplate',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          Array.isArray(response.data.tblSMSTemplate) &&
            response.data.tblSMSTemplate.forEach((element, index) => {
              response.data.tblSMSTemplate[index]['id'] = index + 1;
            });
          callback && callback(response.data.tblSMSTemplate);

          // dispatch({
          //   data: response.data.tblTemplate,
          //   type: AppConstants.GET_ALL_TEMPLATES,
          // });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getScriptTemplate = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Script/GetScriptTemplate',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          Array.isArray(response.data.tblScriptTemplate) &&
            response.data.tblScriptTemplate.forEach((element, index) => {
              response.data.tblScriptTemplate[index]['id'] = index + 1;
            });
          callback && callback(response.data.tblScriptTemplate);

          // dispatch({
          //   data: response.data.tblTemplate,
          //   type: AppConstants.GET_ALL_TEMPLATES,
          // });
        }
      })
      .catch((error) => {
        console.log(error);
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getAllTemplatesList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailTemplatesOfUser',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // console.log('template res',response)
        // dispatch(toggleLoader(false));
        // dispatch({ type: 'SPINNER_STATE', isLoading: true });

        if (response && response.data) {
          // Array.isArray(response.data.emailTemplates) &&
          //   response.data.emailTemplates.forEach((element, index) => {
          //     response.data.emailTemplates[index]['id'] = index + 1;
          //   });
          callback && callback(response.data);

          dispatch({
            data: response.data.emailTemplates,
            type: AppConstants.GET_ALL_TEMPLATES,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const deleteTemplate = (data, callback, errorCallBack, templateType) => {
  let url = `/api/Email/DeleteEmailTemplate/${data?.Calling_UserID_chr}/${data?.EmailTemplateID_ids}`;
  if (templateType === 2) url = '/api/SMS/DeleteSMSTemplate';
  else if (templateType === 3) url = '/api/Script/DeleteScriptTemplate';

  // "/api/Email/DeleteTemplate" +
  // "?Calling_UserID_chr=" +
  // data.calling_UserID_chr +
  // "&TemplateID_lng=" +
  // data.templateID_ids +
  // "&is_Hard_Delete_ysn=false&Include_Deleted_Clients_byt=0",

  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url,
      method: 'delete',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response) {
          callback && callback(response?.data);
          NotificationManager.success('Deleted Successfully');
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};
