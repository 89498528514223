import Axios from 'axios';

import ReduxStore from '../../ReduxStore';
const configData = window.config;

const reduxStoreInstance = ReduxStore.getDefaultStore();
const store = reduxStoreInstance.store; // Access the Redux store
const state = store.getState();

let isRefreshing = false;
let failedQueue = [];



const onRefreshed = (token) => {
  failedQueue.forEach((cb) => {
    cb(token);
  });
  failedQueue = [];
};

const subscribeTokenRefresh = (cb) => {
  failedQueue.push(cb);
};


const onRequest = (config) => {
  const token = JSON.parse(localStorage.getItem('keys'));
  config.headers['Authorization'] = `Bearer ${token?.access || configData.TOKEN} `;

  return config;
};


const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};


const refreshToken = async () => {
  return new Promise( (resolve, reject) => {
    (async()=>{
      try {
        const storedToken = JSON.parse(localStorage.getItem('keys'));
        const domainName = window.config.DOMAIN_NAME;
        const rs = await Axios.post(
          `${window.config?.AUTH_API_URL}/api/Account/RefreshToken`,
          {
            refreshToken: storedToken?.refresh,
            token: storedToken?.access,
          },
          {
            headers: {
              'x-domain-name': window.config.DOMAIN_NAME,
            },
          }
        );
  
        const { accessToken, user, refreshToken } = rs.data;
  
        const data = { token: accessToken };
  
        localStorage.setItem('keys', JSON.stringify({ access: accessToken, refresh: refreshToken }));
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        //   store.dispatch(setReduxToken(data));
        store.dispatch({ type: 'GET_TOKENS', token: data, refreshToken: refreshToken });
        resolve(accessToken);
      } catch (error) {
        reject(error);
      }
    })()
    
  });
};


  

const onResponseError = async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const newToken = await refreshToken();
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        onRefreshed(newToken);
        isRefreshing = false;
        return Axios(originalRequest);
      } catch (err) {
        isRefreshing = false;
        failedQueue = [];  // Clear the queue on failure
        store.dispatch({ type: 'USER_LOGOUT' });
        return Promise.reject(err);
      }
    } else {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          resolve(Axios(originalRequest));
        });
      });
    }
  }

  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export const axios = Axios.create({});

export const loggedInAxios = setupInterceptorsTo(Axios.create({}));