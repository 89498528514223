import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";

import { assignPermissionToRole, getPermissionByRoleGroup } from "../../services/permissions/permissions";
// import { ImSpinner2 } from "react-icons/im";



// import { toast } from "react-toastify";

export default function AdminSettings() {
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);
 const callingUserId=useSelector((state)=>state.login?.loginData?.userId)
  // const spinner = useSelector((state) => state.spinner.isLoading);

  const showToast = (message) => {
    NotificationManager.success(message);
  };

  const savePermissions = () => {
    dispatch(
      assignPermissionToRole(
        {
          // roleId: selectedRole.id,
          permissionIds: Array.from(checkedPermissions).map(
            ([name, value]) => name
          ),
        },
        () => {
          showToast("Roles' permissions updated successfully");
        }
      )
    );
  };

  const setTableRows = (permissions) => {
    const updatedCheckedPermissions = permissions.map(({ permission }) => {
      if (!permission) return Array(permissions.length).fill(false);
      return permission.map(({ hasPermission }) => hasPermission);
    });
  
    setPermissions(permissions);
    setCheckedPermissions(updatedCheckedPermissions);
  };

  const getPermissionForAllRole = () => {
    dispatch(
      getPermissionByRoleGroup({Calling_UserID_chr:callingUserId}, (data) => {
        console.log(data, "61");
        setTableRows(data);
      })
    );
  };

  useEffect(() => {
    getPermissionForAllRole();
  }, []);

  const handleCheckboxChange = (dataAreaIndex, checkboxIndex) => {
    console.log(checkboxIndex)
    console.log(dataAreaIndex , '65')
    setCheckedPermissions((prevCheckedPermissions) => {
      const updatedCheckedPermissions = prevCheckedPermissions.map(
        (areaMap, dataIndex) => {
          if (dataIndex === dataAreaIndex) {
            const newAreaMap = areaMap.map((checked, checkboxIdx) => {
              return checkboxIdx === checkboxIndex ? !checked : checked;
            });
            return newAreaMap;
          }
          return areaMap;
        }
      );
      return updatedCheckedPermissions;
    });
  };
  
  

  const selectAllCheckBox = () => {
    setCheckedPermissions((prevCheckedPermissions) => {
      const allSelected = !prevCheckedPermissions.flat().includes(false);
      const updatedCheckedPermissions = prevCheckedPermissions.map(
        (areaMap) => {
          const newAreaMap = areaMap.map(() => !allSelected);
          return newAreaMap;
        }
      );
      return updatedCheckedPermissions;
    });
  };

  const isChecked = useCallback(
    (dataAreaIndex, checkboxIndex) => {
      const areaMap = checkedPermissions[dataAreaIndex];
      return areaMap && areaMap[checkboxIndex];
    },
    [checkedPermissions]
  );

  console.log(checkedPermissions, "134");

  return (
    <>
      {/* {spinner ? (
        <>
          <div className="fixed  top-0 left-0 z-40 min-h-full min-w-full bg-black opacity-50"></div>
          <div className="fixed inset-x-1/2 top-2/4	z-50 text-center">
            <ImSpinner2 className="dark:text-white-600 mr-2 inline h-12 w-12 animate-spin fill-red-900 text-gray-200 " />
          </div>
        </>
      ) : null} */}
      <div className=" w-full px-4 "></div>

      <div className="w-full overflow-hidden px-4 ">
        <main className="overflow-auto bg-white p-4">
          <div
            className="flex justify-between border-b-2"
            style={{ textAlign: "left" }}
          >
            <lable className=" text-lg font-bold">Assigned Permissions</lable>
            <div className="mb-2 flex gap-x-2">
              <Button
                // variant="outlined"
                color="primary"
                sx={{ mr: 4 }}
                onClick={selectAllCheckBox}
              >
                Select All
              </Button>
              <Button variant="contained" color="primary" onClick={savePermissions}>
                Save
              </Button>
            </div>
          </div>
          <table className="border-collapse">
          <thead>
  <tr>
    <th className="border-3 w-1/4 py-4 text-center">Permission Area</th>
    {permissions.map((role) => (
      <th
        className="border-b-3 border-gray bg-white px-8 py-4 text-center"
        key={role.id}
      >
        {role.name}
      </th>
    ))}
  </tr>
</thead>

<tbody>
  {permissions.map((role) => (
    <React.Fragment key={role.id}>
      {role.permissions.map((permissionArea) => (
        <React.Fragment key={permissionArea.area}>
          <tr>
            <td
              colSpan={permissions.length + 1}
              className="bg-[#263003] p-2 text-left text-2xl font-bold text-white"
            >
              {permissionArea.area}
            </td>
          </tr>
          {permissionArea.permission.map((permission) => (
            <tr key={permission.id}>
              <td className="flex w-full flex-wrap border-b-2 p-2 text-left">
                {permission.description}
              </td>
              {permissions.map((role) => {
                const rolePermission = role.permissions.find(
                  (perm) => perm.id === permission.id
                );
                return (
                  <td className="border-b-2 p-2 text-center" key={role.id}>
                    <input
                      type="checkbox"
                      checked={isChecked(permissions.indexOf(role), permissionArea.permission.indexOf(permission))}


                      onChange={() =>
                        handleCheckboxChange(
                          permissions.indexOf(role),
                          permissionArea.permission.indexOf(permission)
                        )
                      }
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  ))}
</tbody>


          </table>
        </main>
      </div>
    </>
  );
}
