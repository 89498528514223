import ActionConstants from '../config/AppConstants';
const innitialData = {
  isLoading: false,
};
export default function spinnerState(state = innitialData, action) {
  // console.log(action, 'data');
  switch (action.type) {
    case ActionConstants.SPINNER_STATE: {
      return {
        isLoading: action.isLoading,
      };
    }
    default:
      return { ...state };
  }
}
