import React, { useEffect, useState } from 'react';

import { Chip, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FaListCheck } from "react-icons/fa6";
import { IoFlagOutline, IoHandLeftOutline } from "react-icons/io5";
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/loader';
import { GetMessagingCampaign } from '../../services/smsCampaign/smsCampaignService';

const styles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  heading: {
    display: 'flex',
    justifyItems: 'center',
    columnGap: '4px',
    fontWeight: 'bold',
    borderBottom:'1px solid black',
  //   marginTop: 30,
    padding: 10,
    // background:'#263003',
    // color:'white',
  },
}));

export default function SMSCampaignDetail() {
    const classes = styles()
    const dispatch=useDispatch()
    const history=useHistory()
  const {tenant}=useSelector((state)=>state.login.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {messagingServiceSid,sid}=useSelector((state)=>state?.smsCampaign)
  const [campaignDetail,setCampaignDetail]=useState({})
  useEffect(()=>{
    dispatch(GetMessagingCampaign({messagingServiceSid:messagingServiceSid,sid:sid},(res)=>{
        console.log('35',res)
        setCampaignDetail(res)
        dispatch({
          type:'SMS_CAMPAIGN_IDS',
          messagingServiceSid:res?.messaging_service_sid,
          sid:res?.sid,
          useCase:res?.us_app_to_person_usecase,
          description:res?.description
        })
    }))
  },[])
  const handleLinkedNumbersClick=()=>{
    history.push(`/${tenant}/linked-campaign-numbers`)
  }
  const handleLinkToCampaignClick=()=>{
    history.push(`/${tenant}/link-campaign`)
  }
  return (
    <>
      {spinner ? <Loader/> : null}

      <div className="bg-white border border-black p-2 mt-2 grid grid-cols-4 gap-4">
       <div className='col-span-4 grid grid-cols-4 gap-2 border-b-2 border-black font-xl'>
       <Typography variant="h5" gutterBottom className={` col-span-2 `}>
            {/* <FaUsers size={28} className="" /> */}
            Campaign Details
          </Typography>
          <button className='col-start-3 border border-[#263003] mb-2 flex items-center justify-center gap-x-2 hover:bg-[#263003] hover:text-white' onClick={handleLinkToCampaignClick}><MdAdd size={20}/> Add Numbers</button>
       <button className='col-start-4 border border-[#263003] mb-2  flex items-center justify-center gap-x-2 hover:bg-[#263003] hover:text-white' onClick={handleLinkedNumbersClick}><FaListCheck size={20}/>  Linked Numbers</button>
       </div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
       <div className='font-bold text-lg'>
         Campaign SID
       </div>
       <div>{(campaignDetail?.sid)?campaignDetail?.sid:'-'}</div>
       <div className='font-bold text-lg'>
         Linked Messaging Services
       </div>
       <div>{(campaignDetail?.messaging_service_sid)?campaignDetail?.messaging_service_sid:'-'}</div>
       <div className='font-bold text-lg'>
        Brand Registration SID
       </div>
       <div>{(campaignDetail?.brand_registration_sid)?campaignDetail?.brand_registration_sid:'-'}</div>
       </div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
       <div className='font-bold text-lg'>
         Campaign use case
       </div>
       <div>{(campaignDetail?.us_app_to_person_usecase)?campaignDetail?.us_app_to_person_usecase:'-'}</div>
       <div className='font-bold text-lg'>
         Campaign Status
       </div>
       <div>{(campaignDetail?.campaign_status)?campaignDetail?.campaign_status:'-'}</div>
       <div className='font-bold text-lg'>
        External Campaign ID
       </div>
       <div>{(campaignDetail?.campaign_id)?campaignDetail?.campaign_id:'-'}</div>
       </div>
         

       <div className='col-span-4'>
       <Typography variant="h5" gutterBottom className={classes.heading}>
       <div className='bg-purple-400 p-1 w-[35px] h-[35px]flex items-center justify-center rounded-full'>
            <IoFlagOutline size={24} className="" />
            </div>
            Campaign Informations
          </Typography>
       </div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
       <div className='font-bold text-lg'>
         Description
       </div>
       <div>{(campaignDetail?.description)?campaignDetail?.description:'-'}</div>
       <div className='font-bold text-lg'>
         Sending messages with embedded links
       </div>
       <div>{(campaignDetail?.has_embedded_links)?'Yes':'No'}</div>
       <div className='font-bold text-lg'>
       Sending messages with embedded phone numbers
       </div>
       <div>{(campaignDetail?.has_embedded_phone)?'Yes':'No'}</div>
       </div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
      { (campaignDetail?.message_samples)?.map((data,index)=><div key={index} className='grid grid-cols-1 gap-2'>
        <div className='font-bold text-lg'>
         Message Sample #{index+1}
       </div>
       <div className=''>
       {data}
       </div>
       </div>)}
       
       </div>

       <div className='col-span-4'>
       <Typography variant="h5" gutterBottom className={classes.heading}>
           <div className='bg-purple-400 p-1 w-[35px] h-[35px]flex items-center justify-center rounded-full'>
           <IoHandLeftOutline size={24} className="" />
           </div>
            End User Consent
          </Typography>
       </div>
       <div className='col-span-4 font-bold text-lg'>How do end-users consent to recieve messages?</div>
       <div className='col-span-4'>End users opt-in by creating a new user account, consenting to receive marketing messages via text and providing a valid mobile phone number.</div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
       <div className='font-bold text-lg'>
         Opt-in Message
       </div>
       <div>{(campaignDetail?.opt_in_message)?campaignDetail?.opt_in_message:'-'}</div>
       <div className='font-bold text-lg'>
       Opt-out Message
       </div>
       <div>{(campaignDetail?.opt_out_message)?campaignDetail?.opt_out_message:'-'}</div>
       <div className='font-bold text-lg'>
       Help Message
       </div>
       <div>{(campaignDetail?.help_message)?campaignDetail?.help_message:'-'}</div>
       </div>
       <div className='col-span-4 sm:col-span-2 grid grid-cols-1 gap-4'>
       <div className='font-bold text-lg'>
         Opt-in Keywords?
       </div>
       <div>{(campaignDetail?.opt_in_keywords)?campaignDetail?.opt_in_keywords:'-'}</div>
       <div className='font-bold text-lg'>
         Opt-out Keywords
       </div>
      <div className='flex items-center gap-x-2 text-wrap'>
      {(campaignDetail.opt_out_keywords)?.map((data,index)=><Chip label={data} key={index}/>)}
      </div>
       <div className='font-bold text-lg'>
        Help Keywords
       </div>
       <div className='flex items-center gap-x-2 text-wrap'>
      {(campaignDetail.help_keywords)?.map((data,index)=><Chip label={data} key={index}/>)}
      </div>
       </div>
           
          </div>
      

    </>
  );
}
