import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { BrowserRouter, Switch, useHistory } from 'react-router-dom';
import './App.css';

import CacheBuster from './CacheBuster';
import CustomerDoc from './components/CustomersDoc/component';
import LoadingScreen from './components/LoadingScreen';
import { onMessageListener } from './firebase/firebaseInit';
import useNotifications from './hooks/useNotifications';
import useUpdateEffect from './hooks/useUpdateEffect';
import importRetry from './importRetry';
import Account from './pages/Account/component';
import AddEditCustomePage from './pages/AddEditCustomer/component';
import AdminSettings from './pages/AdminSetting/component';
import Billing from './pages/BillingPage/billing';
import BounceListPage from './pages/BounceList/component';
import Dashboard from './pages/DashboardPage/dashboard';
import CallDialer from './pages/DialerPage/component';
import OutBoundCallPage from './pages/DialerPage/outBoundCallPage';
import DisplayDialPlan from './pages/DialPlans/component';
import DialNodeDiagram from './pages/DialPlans/dialNodeDiagram';
import DialPlans from './pages/DialPlans/dialPlansList';
import AddEditDialPlan from './pages/DialPlans/newComponent';
// import DialPlanFlow from './pages/DialPlansNew/component';
import DirectExtensions from './pages/DirectExtension/component';
import EnterPassword from './pages/LoginPage/EnterPassword/component';
import GetTenant from './pages/LoginPage/GetTenant/component';
import Tenants from './pages/LoginPage/Tenants/component';
import NotPermitted from './pages/NotAllow/component';
import DeleteUser from './pages/NotAuthDeleteUser/component';
import NotFound from './pages/NotFound/component';
import Notifications from './pages/Notifications/component';
import OneToOneMessage from './pages/OneToOneMessages/component';
import OrgSettings from './pages/Organization/component';
import PortPhoneNumber from './pages/PortNumber/component';
import PrivacyPolicy from './pages/PrivacyPolicy/component';
import SetPassword from './pages/ResetPasswordPage/SetPassword';
import RingGroups from './pages/RingGroups/component';
import SMSCampaignPage from './pages/SMSCampaign/component';
import LinkedCampaignNumbers from './pages/SMSCampaign/linkedCampaignNumbers';
import LinkNumberToCampaign from './pages/SMSCampaign/linkNumberToCampaign';
import SMSCampaignDetail from './pages/SMSCampaign/smsCampaignDetail';
import SMSCampaignList from './pages/SMSCampaign/smsCampaignList';
import Subscription from './pages/Subscriptions/component';
import EmailPlans from './pages/Subscriptions/emailPlan';
import RegistrationForm from './pages/TenantRegistration/registration';
import Unsubscribe from './pages/Unsubscribe/component';
import Resubscribe from './pages/Unsubscribe/Resubscribe/component';
import ResubscribeSuccess from './pages/Unsubscribe/Resubscribe/resubscribeSuccess';
import UploadDocuments from './pages/UploadCustomerDocuments/component';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import { getFCMBroadcastMessage} from './services/firebase/firebase';
import { getConversationsByPhoneNumber } from './services/message/messagingService';
import { getUserPhoneNumber } from './services/twilio/twilioPhoneService';
import { getAllActiveNumbers } from './services/userService';
import { formatedNumber } from './utils';

const AccountInfo = lazy(() => importRetry(() => import('./pages/AccountInfo')));
const AccountInfoEdit = lazy(() => importRetry(() => import('./pages/AccountInfoEdit')));
const BillingPage = lazy(() => importRetry(() => import('./pages/BillingPage')));
const BulkEmail = lazy(() => importRetry(() => import('./components/Bulk/BulkEmail')));
const BulkMessage = lazy(() => importRetry(() => import('./components/Bulk/BulkMessage')));
const CalendarPage = lazy(() => importRetry(() => import('./pages/CalendarPage')));
const ClientsPage = lazy(() => importRetry(() => import('./pages/ClientsPage')));
// const Dashboard = lazy(() => importRetry(() => import('./pages/DashboardPage')));
const Details = lazy(() => importRetry(() => import('./components/Details')));
const CustomerDetail = lazy(() => importRetry(() => import('./components/Engage/CustomerDetail')));
const CustomerPage = lazy(() => importRetry(() => import('./pages/CustomerPage')));
const ForgotPassword = lazy(() => importRetry(() => import('./pages/ForgotPassword')));
const AppLayout = lazy(() => importRetry(() => import('./components/layout/AppLayout')));
const LoginPage = lazy(() => importRetry(() => import('./pages/LoginPage')));
const Register = lazy(() => importRetry(() => import('./pages/Register')));
const CadenceLogsPage = lazy(() => importRetry(() => import('./components/Reports/CadenceLogs')));
const MessageLogsPage = lazy(() => importRetry(() => import('./components/Reports/MessageLogs')));
const ReportingPage = lazy(() => importRetry(() => import('./components/Reports/EmailLogs')));
const VoiceCallLogsPage = lazy(() => importRetry(() => import('./components/Reports/VoiceCallLogs')));
const RolesPage = lazy(() => importRetry(() => import('./pages/RolesPage')));
const CadenceTemplatePage = lazy(() => importRetry(() => import('./pages/CadenceTemplatePage')));
const EmailTemplatePage = lazy(() => importRetry(() => import('./pages/EmailTemplatePage')));
const ScriptTemplatePage = lazy(() => importRetry(() => import('./pages/ScriptTemplatePage')));
const SMSTemplatePage = lazy(() => importRetry(() => import('./pages/SMSTemplatePage')));
const ChangePassword = lazy(() => importRetry(() => import('./components/User/ChangePassword')));
const UserList = lazy(() => importRetry(() => import('./components/User/UserList')));
const VideoCallPage = lazy(() => importRetry(() => import('./pages/VideoCalls/VideoCallPage')));
// const VoiceCalls = lazy(() => importRetry(() => import('./components/VoiceCalls/VoiceCalls')));
const PermissionsPage = lazy(() => importRetry(() => import('./pages/PermissionsPage')));
const PermissionsAreaPage = lazy(() => importRetry(() => import('./pages/PermissionsAreaPage')));
const DNDListPage = lazy(() => importRetry(() => import('./pages/DNDListPage/component')));
const BuyPhoneNumber = lazy(() => importRetry(() => import('./pages/BuyPhoneNumber/component')));
const AgencyPage = lazy(() => importRetry(() => import('./pages/Agency/component')));
const AddEditAgency = lazy(() => importRetry(() => import('./pages/AddEditAgency/component')));
const AgencyProfile = lazy(() => importRetry(() => import('./pages/Agency/agencyProfile')));
const AllLeads = lazy(() => importRetry(() => import('./components/CRM/AllLeads/component')));
const NewLead = lazy(() => importRetry(() => import('./components/CRM/LeadStages/newLead')));
const SiteVisit = lazy(() => importRetry(() => import('./components/CRM/DealStages/siteVisit')));
const Negotiation = lazy(() => importRetry(() => import('./components/CRM/DealStages/negotiation')));
const Quotation = lazy(() => importRetry(() => import('./components/CRM/DealStages/quotation')));
const ReNegotiation = lazy(() => importRetry(() => import('./components/CRM/DealStages/reNegotiation')));
const DealWon = lazy(() => importRetry(() => import('./components/CRM/DealStages/dealWon')));
const DealDocumentation = lazy(() => importRetry(() => import('./components/CRM/DealStages/dealDocumentation')));
const CRMDealLose = lazy(() => importRetry(() => import('./components/CRM/DealStages/dealLose')));
const CRMleadUnassigned = lazy(() => importRetry(() => import('./components/CRM/LeadStages/leadUnassigned')));
const CRMleadInProgress = lazy(() => importRetry(() => import('./components/CRM/LeadStages/leadInProgress')));
const CRMleadProcessed = lazy(() => importRetry(() => import('./components/CRM/LeadStages/leadProcessed')));
const CRMGoodLead = lazy(() => importRetry(() => import('./components/CRM/LeadStages/goodLead')));
const CRMJunkLead = lazy(() => importRetry(() => import('./components/CRM/LeadStages/junkLead')));

function App(props) {
  const [baseName, setBaseName] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [callingDeviceIdentity, setCallingDeviceIdentity] = useState(null);
  const [token, setToken] = useState('');
  const [device, setDevice] = useState();
  const reduxToken = useSelector((state) => state.login?.loginData?.token);
  const grantedPermissions = useSelector((state) => state?.grantedPermissions?.permissions);
  const [conn, setConn] = useState();
  const { authorized } = useSelector((state) => state.login);
  const {tenant,twilioNumber,userId,isImpersonated}=useSelector((state) => state.login?.loginData)
  const loginData=useSelector((state)=>state.login?.loginData)
  const { isCallConnected } = useSelector((state) => state.twilio);
  const {unreadSms,conversationNumbers,savedContact}=useSelector((state)=>state.smsConversations)
  const {isMessagePing,isMissedCallPing,notificationsCount}=useSelector((state)=>state?.firebase)


  useNotifications();

  useEffect(() => {
    history.listen((location) => {
      dispatch({ type: 'MAIL_MODAL_CLOSE' });
    });
  }, [history, dispatch]);

  const channel = new BroadcastChannel('sw-messages');
  channel.addEventListener('message', (event) => {
    // console.log('check ping',event)
    const { NotificationType } = event.data;
   if(NotificationType==='SMS'){
    // console.log('sms ping')
    // dispatch({type:'IS_MSG_PING',isMessagePing:true}) 
   }
  });
  const getMicrophonePermission = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    permissions
      .then((stream) => {
        dispatch({ type: 'ADD_PERMISSION', payload: { data: 'microphone' } }); // If user gives permission to microphone then add permission to redux
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
      });
  };
  const params = { role: twilioNumber };
  // const params = { role: userId };
  const getTwilioToken = () => {
    console.log('inside api call',window.config?.API_URL)
    axios({
      url: `${window.config?.API_URL}/api/Token/Generate`,
      method: 'get',
      headers: {
        Authorization: 'Bearer  ' + reduxToken,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
      },
      params,
    }).then(async (res) => {
      console.log('twilio token', res.data);
      setCallingDeviceIdentity(res.data?.identity);
      setToken(res.data?.token);
      // dispatch({ type: 'ADD_TOKEN', payload: { token: res.data?.token,callDeviceIdentity: res.data?.identity } });
    });
  };
  useEffect(() => {
    if (authorized) {
      console.log('inside authblock',twilioNumber)
      if (twilioNumber) {
        getTwilioToken();
        if (!grantedPermissions || !grantedPermissions.includes('microphone')) getMicrophonePermission();
      }
    }else{
      setToken('')
    }
  }, [twilioNumber, authorized]);
  useUpdateEffect(() => {
    const accessToken = String(token);
    const device = new Device(accessToken, { enableRingingState: true, debug: true });
    setDevice(device);
    // device.setup(token, {enableRingingState: true,debug: true });
    device.register();
    device.on('registered', function () {
      console.log('Twilio.Device Ready to make and receive calls!',device);
      // dispatch({ type: 'ADD_DEVICE', payload: { device: device } });
    });
    device.on('ready', () => {
      console.log('device 1 ready');
      setDevice(device);
      // dispatch({ type: 'ADD_TOKEN', payload: { token: token,callDeviceIdentity: callingDeviceIdentity } });
      
      //setState(states.READY);
    });

    // device.on('connect', (connection) => {
    //   console.log('Connect event');
    //   setConn(connection);
    //   //setState(states.ON_CALL);
    // });
    // device.on('disconnect', () => {
    //   //setState(states.READY);
    //   console.log('disconnected event')
    //   // setConn(null);
    // });
    device.on('incoming', (call) => {   
      console.log('call recieve', call,device,call.parameters.From);
      let toNumber=call.parameters.To
      if(toNumber.includes('client:')){
        toNumber=toNumber.replace('client:', '');
      }
      if (call && call.parameters && call.parameters.Params) {
        const resultFrom = call.parameters.Params.split('=')[1];
        console.log(resultFrom); // Or whatever you want to do with resultFrom
      dispatch({ type: 'CALL_RECEIVE', payload: { isReceiving: true, From: resultFrom, call: call,device:device } });

    } else {
      dispatch({ type: 'CALL_RECEIVE', payload: { isReceiving: true, From: call.parameters.From, call: call,device:device } });

    }
      call.on('cancel', (call) => {
        console.log('cancel call');
        dispatch({ type: 'CALL_RECEIVE', payload: { isReceiving: false, From: '', call: call } });
      });
       call.on('disconnect', (call) => {
        console.log('disconnect call');
        dispatch({ type: 'CALL_RECEIVE', payload: { isReceiving: false, From: '', call: call } });
      });
      call.on('reject', (call) => {
        console.log('reject call');
        dispatch({ type: 'CALL_RECEIVE', payload: { isReceiving: false, From: '', call: call } });
      });
    
  });
   
    device.on('cancel', () => {
      //setState(states.READY);
      console.log('cancelled event');

      setConn(null);
    });

    // device.on('')

    device.on('reject', () => {
      //setState(states.READY);
      console.log('rejected event');

      setConn(null);
    });

    return () => {
      device.destroy();
      setDevice(null);
      // closeCallModal()
      // setState(states.OFFLINE);
    };
  }, [token]);
  useEffect(() => {
    setBaseName(tenant);
  }, [tenant]);
  console.log('check base name', tenant, baseName);
  // useEffect(()=>{
  //   onMessageListener()
  // },[])
  
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isTokenExpired, setIsTokenExpired] = useState(false);


  useEffect(()=>{
    if(authorized && isTokenExpired)
    {dispatch(getUserPhoneNumber({},(data)=>{
      const phoneNumber=data?.tblPhoneNumber[0]?.phoneNumber
       dispatch({type:'TWILIO_NUMBER',payload:{twilioNumber:phoneNumber}})
       dispatch({type:'UPDATE_NUMBER',data:{...loginData,twilioNumber:phoneNumber}})      
    }))
}
  },[authorized,isTokenExpired])
  useEffect(() => {
    // Function to update the current date and time
    const updateDateTime = () => {
      setCurrentDateTime(new Date());
    };

    // Set up an interval to call the updateDateTime function every minute
    const intervalId = setInterval(updateDateTime, 60 * 1000); // 60 seconds * 1000 milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [])
  console.log('currentDateTime now',currentDateTime)
  
  const checkTokenExpiration = (token) => {
    try {
      const decodedToken = jwt_decode(token);
      console.log('check 252',token)
      if (decodedToken && decodedToken.exp) {
        // Compare the expiration time with the current time
        const isExpired = decodedToken.exp * 1000 < (Date.now()- 30000); // Convert seconds to milliseconds
        console.log(decodedToken.exp * 1000 < (Date.now()- 30000),'300',isExpired,'check 252',decodedToken.exp * 1000 , Date.now(),decodedToken.exp)
        setIsTokenExpired(isExpired);
        return isExpired
      }
      else{
        return false
      }
    } catch (error) {
      console.error('Error decoding or checking token expiration:', error);
    }
  };
  
  useEffect(()=>{
    // console.log('isTokenExpired',checkTokenExpiration(token))
    // console.log('isTokenExpired',token)
    if(authorized && token)
    {
     if(checkTokenExpiration(token)){
      console.log('token expire, call refresh token 266')
       getTwilioToken()
     }else{
      console.log('twilio token is valid')
     }
    }
  },[ authorized,currentDateTime,token])
  useEffect(() => {
    // import.meta.env.MODE === "production" && globalDebug(false);

    const channel = new BroadcastChannel("sw-messages");

    const messageHandler = (event) => {
      console.log(event.data, "see e data");
      const {NotificationType,Body,fromNumber,Title}=event.data
      if (NotificationType === "SMS" && Title === 'New message') {
        console.log('missed call ping',NotificationType,Title)
        // const  number= Body.split(':')[0].trim();
        dispatch({type:'IS_MSG_PING',isMessagePing:true,smsPingFrom:fromNumber})
      }else if(NotificationType === 'Call'&& Title === 'Missed Call' ){
        console.log('missed call ping',NotificationType,Title)
            dispatch({ type: 'IS_MISSED_CALL_PING', isMissedCallPing: true });
      }

     
      
    };
    channel.addEventListener("message", messageHandler);

    const timeoutId = setTimeout(() => {
      // dispatch(set_realtime_notifications_ping(false));

    }, 1000); // 1000 milliseconds = 1 second

   

    
    return () => {
      clearTimeout(timeoutId);
      channel.removeEventListener("message", messageHandler);
    };
  }, []);
  const getAllActiveConversations=()=>{
    dispatch((getConversationsByPhoneNumber({phoneNumber:twilioNumber},(res)=>{
      console.log('Appjs 164 res',res)
      const filteredData = res.map(item => ({
        convSid: item?.conversationSid,
        convPh: item?.messagingBinding.address,
        latestMessage:item?.latestMessage,
        unreadCount:item?.unreadCount
      }));
      console.log('Appjs 167',filteredData,savedContact)
      const addContactNameArr = filteredData.map((data) => {
        const contact = savedContact.find((item) => item.phNumber === data.convPh);
        console.log('Appjs 167',contact)
        if (contact) {
            return {
                ...data,
                contactName: contact.name
            };
        } else {
            return data; // Return original data if no match found
        }
    });
    
      console.log('addContactName',addContactNameArr)
      dispatch({type:'CONV_LIST',conversationNumbers:addContactNameArr})
    },(err)=>{console.log(err)})))
  }

  const getMessagePingData=()=>{
    console.log('check response')
    const payload={
      Page_Index_int: +1,
      Page_Size_int: 5,
      Where_GeneralCriteria_Delim_mem: '',
      Sort_Expression_Delim_mem: '',
      UserId_chr:userId,
      Paginate_ysn:false,
    }
    dispatch(getFCMBroadcastMessage(payload,(res)=>{
      console.log('check response',res)
      const totalUnreadNotificationCount=(res?.tblFCMBroadcastMessage.length)?res?.tblFCMBroadcastMessage[0].message_Count_Excluding_Criteria_int:0
      const unreadMsg=res.tblFCMBroadcastMessage.filter((data)=>data.isMessageRead_ysn===false)
      const unreadSMS = res.tblFCMBroadcastMessage.filter(data => data.notificationType_chr === 'SMS' && data.title_chr==='New message' && data.isMessageRead_ysn===false);
      console.log('urm',unreadMsg.length,unreadSMS)
      // Function to extract the number before the colon and add it as a new key in each object
      const updatedUnreadSMS = unreadSMS.map(message => {
        // Extract the number before the colon
        const fromNumber = message.messageBody_chr.split(':')[0].trim();
  
        // Return a new object with all the original properties and the new key
        return { ...message, from_number: fromNumber };
      });
      console.log('urm',updatedUnreadSMS)
      const calculateUnreadCounts = (data) => {
        const unreadCounts = {};
      
        data.forEach((item) => {
          const type = item.notificationType_chr;
          if (!unreadCounts[type]) {
            unreadCounts[type] = 0;
          }
          unreadCounts[type] += item.totalUnreadCount;
        });
      
        return unreadCounts;
      };
      
      const unreadCountTable=[...res.table1]
      console.log('dataaa Appjs',unreadCountTable,res.table1)
      const data=  calculateUnreadCounts(unreadCountTable)
      console.log('dataaa',data)
      dispatch({type:'ADD_NOTIFICATIONS',allNotifications:res.tblFCMBroadcastMessage})
      dispatch({type:'FIREBASE_PING',notificationsCount:totalUnreadNotificationCount})
      dispatch({type:'UPDATE_SMS_COUNT',smsCount:data.SMS,unreadSms:updatedUnreadSMS})
      dispatch({type:'IS_MSG_PING',isMessagePing:false,smsPingFrom:''})
  }))
  }
  useEffect(()=>{
if((authorized ))
  {
    console.log('message ping')
    getMessagePingData()
    getAllActiveConversations()
}
  },[authorized,isImpersonated])
  useEffect(()=>{
    if((authorized && isMessagePing))
      {
        getMessagePingData()
     getAllActiveConversations()
    }
      },[isMessagePing])
      console.log('isMissedCallPing',isMissedCallPing)
      useEffect(()=>{
        console.log('isMissedCallPing in useEffect',isMissedCallPing,authorized && isMissedCallPing)
        const payload={
          Page_Index_int: +1,
          Page_Size_int: 5,
          Where_GeneralCriteria_Delim_mem: '',
          Sort_Expression_Delim_mem: '',
          UserId_chr:userId,
          Paginate_ysn:false,
        }
        if(authorized && isMissedCallPing ){
          dispatch(getFCMBroadcastMessage(payload,(res)=>{
            console.log('check response',res)
            const totalUnreadNotificationCount=(res?.tblFCMBroadcastMessage.length)?res?.tblFCMBroadcastMessage[0].message_Count_Excluding_Criteria_int:0
            dispatch({type:'ADD_NOTIFICATIONS',allNotifications:res.tblFCMBroadcastMessage})
            dispatch({type:'FIREBASE_PING',notificationsCount:totalUnreadNotificationCount})
            dispatch({type:'IS_MISSED_CALL_PING',isMissedCallPing:false})
        }))
        }
      },
      [isMissedCallPing])
  const currentPath = window.location.pathname;
  useEffect(()=>{
    console.log('check currentPath',currentPath,currentPath.includes('voice/callhistory'))
  if(authorized){
    if(((!currentPath.includes('voice/callhistory'))  && (isCallConnected))){
      console.log('voice check currentPath',true,isCallConnected)
      dispatch({type:'CALL_PROGRESS_POP_UP',payload:{isCallProgressPopUp:true}})
  }
}
  },[currentPath])
  useEffect(()=>{
    const payload={
    Page_Index_int: +1,
    Page_Size_int: 20,
    Where_GeneralCriteria_Delim_mem: '',
    Sort_Expression_Delim_mem: 'lastUpdatedDateTime_dtm desc',
    Paginate_ysn:false,
  }
  if(authorized)
  {
    dispatch(getAllActiveNumbers(payload,(res)=>{
    console.log('check numbers',res?.tblActiveNumbers)
    const list=res.tblActiveNumbers.map((data)=>({name:data.assignedUsers,phNumber:data.twilioNumber}))
    dispatch({type:'SAVE_CONTACT',savedContact:list})
  }))
}

  },[authorized,isImpersonated])
  useEffect(() => {
    if(authorized && notificationsCount>0)
    {document.title = `(${notificationsCount}) DialConnect`;}
    else{
      document.title = 'DialConnect'
    }
  }, [authorized,notificationsCount]);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <AppLayout>
              <Switch>
                <PublicRoute exact path="/delete-user" component={DeleteUser} />
                <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
                <PublicRoute exact path="/register" component={Register} />
                {/* <PublicRoute exact path="/unsubscribe" component={Unsubscribe} /> */}
                <PublicRoute exact path="/org/registration" component={RegistrationForm} />
                <PublicRoute exact path={`/${baseName}/authentication`} component={EnterPassword} />
                <PublicRoute exact path={`/${baseName}/forgot-password`} component={ForgotPassword} />
                {/* <PublicRoute exact path={`/${baseName}/login`} component={GetTenant} /> */}
                <PublicRoute exact path="/workspaces/select-workspace" component={Tenants} />
                <PublicRoute exact path="/:tenantName/set-password" component={SetPassword} />
                <PublicRoute exact path="/:tenantName/re-subscribe" component={Resubscribe} />
                <PublicRoute exact path="/:tenantName/re-subscribe/success" component={ResubscribeSuccess} />
                <PublicRoute exact path="/:tenantName/unsubscribe" component={Unsubscribe} />
                <PublicRoute exact path={['/', `/${baseName}`,'/login',]} component={GetTenant} />
                 {/* <PublicRoute exact path="/subscriptions" component={Subscription} /> */}



                {!authorized && <PublicRoute component={GetTenant} />}
                
                <ProtectedRoute exact path={`/${baseName}/bulk/email`} component={BulkEmail} />
                <ProtectedRoute exact path={`/${baseName}/bulk/message`} component={BulkMessage} />
                <ProtectedRoute exact path="/details" component={Details} />
                <ProtectedRoute exact path={`/${baseName}/dashboard`} component={Dashboard} />
                <ProtectedRoute exact path={`/${baseName}/customers`} component={CustomerPage} />
                <ProtectedRoute 
                exact 
                path={
                  [
                    `/${baseName}/customer/:customerId`,
                  `/${baseName}/leads/new-lead/:customerId`,
                  `/${baseName}/deal/site-visit/:customerId`,
                  `/${baseName}/deal/quotation/:customerId`,
                  `/${baseName}/deal/negotiation/:customerId`,
                  `/${baseName}/deal/re-negotiation/:customerId`,
                  `/${baseName}/deal/win/:customerId`,
                  `/${baseName}/deal/lose/:customerId`,
                  `/${baseName}/deal/documentation/:customerId`,
                  `/${baseName}/leads/unassigned/:customerId`,
                  `/${baseName}/leads/in-progress/:customerId`,
                  `/${baseName}/leads/processed/:customerId`,
                  `/${baseName}/leads/good-leads/:customerId`,
                  `/${baseName}/leads/junk-leads/:customerId`,
                ]} 
                component={AddEditCustomePage}
                 />
                <ProtectedRoute 
                exact
                 path={
                  [
                    `/${baseName}/customer/:customerId/upload-doc`,
                  `/${baseName}/leads/new-lead/:customerId/upload-doc`,
                  `/${baseName}/deal/site-visit/:customerId/upload-doc`,
                  `/${baseName}/deal/quotation/:customerId/upload-doc`,
                  `/${baseName}/deal/negotiation/:customerId/upload-doc`,
                  `/${baseName}/deal/re-negotiation/:customerId/upload-doc`,
                  `/${baseName}/deal/win/:customerId/upload-doc`,
                  `/${baseName}/deal/lose/:customerId/upload-doc`,
                  `/${baseName}/deal/documentation/:customerId/upload-doc`,
                  `/${baseName}/leads/unassigned/:customerId/upload-doc`,
                  `/${baseName}/leads/in-progress/:customerId/upload-doc`,
                  `/${baseName}/leads/processed/:customerId/upload-doc`,
                  `/${baseName}/leads/good-leads/:customerId/upload-doc`,
                  `/${baseName}/leads/junk-leads/:customerId/upload-doc`,
                  ]} 
                  component={UploadDocuments} 
                  />
                <ProtectedRoute exact path={`/${baseName}/softphone`} component={CallDialer} />
                <ProtectedRoute exact path={`/${baseName}/voice/callhistory`} component={OutBoundCallPage} />
                <ProtectedRoute exact path={`/${baseName}/messaging/new-text-conversation`} component={OneToOneMessage} />
                <ProtectedRoute exact path={`/${baseName}/email-template`} component={EmailTemplatePage} />
                <ProtectedRoute exact path={`/${baseName}/sms-template`} component={SMSTemplatePage} />
                <ProtectedRoute exact path="/script-template" component={ScriptTemplatePage} />
                <ProtectedRoute exact path="/cadence-template" component={CadenceTemplatePage} />
                <ProtectedRoute exact path="/calendar" component={CalendarPage} />
                <ProtectedRoute exact path="/reporting" component={ReportingPage} />
                <ProtectedRoute exact path="/billing" component={BillingPage} />
                <ProtectedRoute exact path={`/${baseName}/org/billing`} component={Billing} />
                <ProtectedRoute exact path={`/${baseName}/user`} component={UserList} />
                <ProtectedRoute exact path={`/${baseName}/roles`} component={RolesPage} />
                <ProtectedRoute exact path="/clients" component={ClientsPage} />
                <ProtectedRoute exact path={`/${baseName}/change-password`} component={ChangePassword} />
                <ProtectedRoute path={`/${baseName}/account`} exact component={AccountInfo} />
                <ProtectedRoute path="/account/edit" exact component={AccountInfoEdit} />
                <ProtectedRoute path="/customer/details/:id" component={CustomerDetail} />
                <ProtectedRoute path="/customer/Video-call" component={VideoCallPage} />
                <ProtectedRoute path={`/${baseName}/permissions`} component={PermissionsPage} />
                <ProtectedRoute path={`/${baseName}/permissionsArea`} component={PermissionsAreaPage} />
                <ProtectedRoute path="/settings" component={AdminSettings} />
                <ProtectedRoute path={`/${baseName}/email-logs`} component={ReportingPage} />
                <ProtectedRoute path={`/${baseName}/sms-logs`} component={MessageLogsPage} />
                <ProtectedRoute path={`/${baseName}/voicecall-logs`} component={VoiceCallLogsPage} />
                <ProtectedRoute path="/cadence-logs" component={CadenceLogsPage} />
                <ProtectedRoute path="/videocall-logs" component={ReportingPage} />
                <ProtectedRoute path={`/${baseName}/DND-list`} component={DNDListPage} />
                
                <ProtectedRoute path={`/${baseName}/buy-phone-number`} component={BuyPhoneNumber} />
                <ProtectedRoute path={`/${baseName}/direct-extensions`} component={DirectExtensions} />
                <ProtectedRoute  path={`/${baseName}/ring-groups`} component={RingGroups} />
                {/* <ProtectedRoute  path={`/${baseName}/dial-plans/1`} component={DialNodeDiagram} /> */}
                {/* <ProtectedRoute  path={`/${baseName}/dial-plans/1`} component={DisplayDialPlan} /> */}
                <ProtectedRoute  path={`/${baseName}/dial-plans/:dialPlanId`} component={AddEditDialPlan} />
                {/* <ProtectedRoute  path={`/${baseName}/dial-flow`} component={DialPlanFlow} /> */}
                <ProtectedRoute  path={`/${baseName}/dial-plans`} component={DialPlans} />
                <ProtectedRoute path={`/${baseName}/agencies`} component={AgencyPage} />
                <ProtectedRoute path={`/${baseName}/agency/:id`} component={AddEditAgency} />
                <ProtectedRoute path={`/${baseName}/agency/add`} component={AddEditAgency} />
                <ProtectedRoute path={`/${baseName}/agency-detail/:id/docs`} component={AgencyProfile} />
                <ProtectedRoute path={`/${baseName}/not-permitted`} component={NotPermitted} />
                <ProtectedRoute path={`/${baseName}/customer-docs/:id`} component={CustomerDoc} />
                <ProtectedRoute path={`/${baseName}/create-sms-campaign`} component={SMSCampaignPage} />
                <ProtectedRoute path={`/${baseName}/sms-campaign`} component={SMSCampaignList} />
                <ProtectedRoute path={`/${baseName}/sms-campaign-detail`} component={SMSCampaignDetail} />
                <ProtectedRoute path={`/${baseName}/link-campaign`} component={LinkNumberToCampaign} />
                <ProtectedRoute path={`/${baseName}/linked-campaign-numbers`} component={LinkedCampaignNumbers} />
                <ProtectedRoute path={`/${baseName}/bounce-list`} component={BounceListPage} />
                <ProtectedRoute path={`/${baseName}/port-number`} component={PortPhoneNumber} />
                {/* CRM  pages*/}
                 <ProtectedRoute exact path={`/${baseName}/all-leads`} component={AllLeads} />
                 <ProtectedRoute exact path={`/${baseName}/leads/new-lead`} component={NewLead} />
                <ProtectedRoute exact path={`/${baseName}/deal/site-visit`} component={SiteVisit} />
                <ProtectedRoute exact path={`/${baseName}/deal/quotation`} component={Quotation} />
                <ProtectedRoute exact path={`/${baseName}/deal/negotiation`} component={Negotiation} />
                <ProtectedRoute exact path={`/${baseName}/deal/re-negotiation`} component={ReNegotiation} />
                <ProtectedRoute exact path={`/${baseName}/deal/win`} component={DealWon} />
                <ProtectedRoute exact path={`/${baseName}/deal/documentation`} component={DealDocumentation} />
                <ProtectedRoute exact path={`/${baseName}/deal/lose`} component={CRMDealLose} />
                <ProtectedRoute exact path={`/${baseName}/leads/unassigned`} component={CRMleadUnassigned} />
                <ProtectedRoute exact path={`/${baseName}/leads/in-progress`} component={CRMleadInProgress} />
                <ProtectedRoute exact path={`/${baseName}/leads/processed`} component={CRMleadProcessed} />
                <ProtectedRoute exact path={`/${baseName}/leads/good-leads`} component={CRMGoodLead} />
                <ProtectedRoute exact path={`/${baseName}/leads/junk-leads`} component={CRMJunkLead} />
                <ProtectedRoute exact path={`/${baseName}/account-details`} component={Account} />
                <ProtectedRoute exact path={`/${baseName}/org/overview`} component={OrgSettings} />
                <ProtectedRoute exact path={`/${baseName}/notifications`} component={Notifications} />
                <ProtectedRoute exact path={`/${baseName}/org/subscriptions`} component={Subscription} />
                <ProtectedRoute exact path={`/${baseName}/plans/email`} component={EmailPlans} />
                {authorized && <ProtectedRoute component={NotFound} />}
                
                {/* <ProtectedRoute path={`/${baseName}/not-found`} component={NotFound} /> */}
              </Switch>
            </AppLayout>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

function AppWrapper(props) {
  const isMailModalOpen = useSelector((state) => state.mailModalState.open);
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <BrowserRouter>
          <App {...props} />
          <Prompt when={isMailModalOpen} message="Are you sure you want to leave? You may lose your progress" />
        </BrowserRouter>
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.loader,
    data: state.login,
    error: state.error,
  };
};

export default connect(mapStateToProps, null)(AppWrapper);
