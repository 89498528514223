// import { get } from "lodash";
import ActionConstants from '../config/AppConstants';
const innitialData = {
  loginData: {},
  showLogoutPopUp: false,
  authorized:false,
};
export default function login(state = innitialData, action) {
  switch (action.type) {
  case ActionConstants.SAVE_LOGIN_DATA: {
    return {
      ...state,
      loginData: action.data,
      authorized:true
    };
  }
  case ActionConstants.GET_TENANTS: {
    return {
      ...state,
      loginData:action.data,
    };
  }
  case ActionConstants.SAVE_TENANT: {
    return {
      ...state,
      loginData: action.data,
    };
  }
  case ActionConstants.UPDATE_NUMBER: {
    return {
      ...state,
      loginData: action.data
    };
  }
  case ActionConstants.UNAUTH_USER: {
    // console.log()
    return {
      ...state,
      loginData: {},
      authorized:false
    };
  }
  default:
    return { ...state };
  }
}