const innitialData = {
    conversationClientInstance:{},  
  };
  export default function smsPushNotification(state = innitialData, action) {
    switch (action.type) {
      case 'CLIENT_INSTANCE': {
        return {
          ...state,
          conversationClientInstance: action.conversationClientInstance,
          };
      }
      default:
        return { ...state };
    }
  }
  