import React, { useEffect, useState } from "react";

import { Checkbox, IconButton, Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { BiDetail } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";

import Loader from "../../components/loader";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import Table from "../../components/Table";
import constant_label from '../../constants';
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { getUnassignedPhoneNumbers, postCampaignPhoneNumbers } from "../../services/smsCampaign/smsCampaignService";
import { formatPhoneNumber } from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
      border: 0,
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
  
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
  
      [theme.breakpoints.up('lg')]: {
        '&  .MuiDataGrid-window ': {
          overflowX: 'hidden',
          // overflowX:props=>{if(props.open)
          //   {return 'scroll'}
          // else{return 'hidden'}
          // }
        },
      },
  
      '& .MuiDataGrid-colCellTitle': {
        fontSize: '14px',
        fontFamily: "'Rubik', sans-serif",
        color: '#333',
      },
      '& .MuiDataGrid-columnsContainer': {
        border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-dataContainer': {
        borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Rubik', sans-serif",
        borderBottom: '0',
      },
      '& .MuiDataGrid-row': {
        color: '#444',
        backgroundColor: '#ffffff',
        outline: 'none',
      },
  
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f7f7',
      },
    },
  
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    radio: {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
      '&$checked': {
        color: '#263003',
      },
  
      '& .MuiSvgIcon-root': {
        height: '15px',
        width: '15px',
      },
    },
  
    add_btn: {
      float: 'right',
    },
  
    flex: {
      flexGrow: 1,
      display: 'flex',
      height: '20px',
    },
  
    heading: {
      display: 'flex',
      justifyItems: 'center',
      columnGap: '4px',
      fontWeight: 'bold',
      marginTop: 30,
    },
    icons: {
      padding: '0px 3px',
      '&:focus': {
        outline: 'none',
      },
    },
    moreVertIcon: {
      [theme.breakpoints.up('768')]: {
        display: 'none',
      },
    },
  }));
function LinkNumberToCampaign(){
    const classes = useStyles();
    const dispatch=useDispatch();
    const history=useHistory()
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {tenant}=useSelector((state)=>state.login.loginData)
  const [totalRows, setTotalRows] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const fetchIdRef = React.useRef(0);
    const [selectedNumberSID,setSelectedNumberSID]=useState([])
    const {messagingServiceSid,sid,useCase,description}=useSelector((state)=>state?.smsCampaign)
    const [tableState, setTableState] = useState({
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: '',
      });
      const onClickAdd=(row)=>{
        handlePrompt()
        console.log(row)
        setSelectedNumberSID(row?.sid)
          
      }
      const tableColumns = [
        {
          accessor: 'acction',
          Header: constant_label?.lbl_txt_actions,
          disableSortBy:true,
          sortable: false,
          filterable: false,
          headerAlign: 'right',
          align: 'right',
          width: 110,
          Cell: ({ row: { original }, value, column }) => {
            return (
              <div className={`${classes.flex} `}>
                {/* {smsPermissions?.editSmsTemplate ? ( */}
                  {/* <Tooltip title="Select">
                    <IconButton aria-label="edit" className={classes.icons} onClick={(e) => onCheck(original)}>
                      <Checkbox color="primary" />
                    </IconButton>
                  </Tooltip> 
                {/* ) : null} */}
    
                {/* {smsPermissions?.deleteSmsTemplate ? ( */}
                  <Tooltip title="Link Number">
                    <IconButton aria-label="delete" className={classes.icons}
                     onClick={(e) => onClickAdd(original)}
                     >
                      <MdAddCircle color="#263003" className="text-theme-green" />
                    </IconButton>
                  </Tooltip>
               {/* ) : null} */}
              </div>
            );
          },
          flex: 1,
        },
        {
            accessor: 'phoneNumber',
            Header: 'Phone Number',
            width: 350,
            // sortDirection: "asc",
            sortable: true,
            Cell:({value})=>{
              return formatPhoneNumber(value)
            }
          },
        {
          accessor: 'assignedUser',
          Header: 'Assigned To',
          width: 350,
          // sortDirection: "asc",
          sortable: true,
        },
        {
            accessor: 'email',
            Header: 'Email',
            width: 350,
            // sortDirection: "asc",
            sortable: true,
          },
         
        // {
        //   accessor: 'lastUpdatedDateTime_dtm',
        //   Header: constant_label?.lbl_txt_last_updated_dtm,
        //   sortable: true,
        //   // width: 250,
        //   Cell: ({ value }) => {
        //    return <span>{convertTimeZone(value)}</span>;
        //   },
        // },
      ];
      const calculatePageCount = (rowsData, pageSize) => {
        // const totalRows = parseInt(rowsData[0]['total_Count']);
        console.log(rowsData, 'totalRows', pageSize);
        // console.log(totalRows, 'totalRows', pageSize);
        setPageCount(Math.ceil(rowsData / pageSize));
      };
      const fetchTableData = (callback) => {
  
        dispatch(getUnassignedPhoneNumbers(tableState, (data) => {
          console.log('247',data)
            callback && callback(data?.phoneNumbers);
            setRows(data?.phoneNumbers);
            calculatePageCount(data?.count, tableState?.Page_Size_int);
       
          }))
         
        };
      useUpdateEffect(() => {
        fetchTableData();
      }, [tableState]);
      const onTableStateChange = React.useCallback(
        ({ pageIndex: Page_Index_int, pageSize: Page_Size_int, globalFilter: Where_GeneralCriteria_Delim_mem, sortBy }) => {
          const fetchId = ++fetchIdRef.current;
          const sort = sortBy?.[0];
          let sortingField = 'usecase_chr';
          let sortingOrder = 'asc';
    
          if (sort) {
            sortingField = sort['id'];
            sortingOrder = sort['desc'] ? 'desc' : 'asc';
          }
          const Sort_Expression_Delim_mem = `${sortingField} ${sortingOrder}`;
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          setTableState((state) => ({
            // Calling_UserID_chr: userId,
            ...state,
            Page_Index_int: Page_Index_int + 1,
            Page_Size_int: Page_Size_int || 20,
            Where_GeneralCriteria_Delim_mem,
            Sort_Expression_Delim_mem: Sort_Expression_Delim_mem || '',
          }));
        },
        []
      );
      console.log('251',rows)
      const handlePrompt = () => {
        setIsPromptOpen((state) => !state);
      };
      const handleLinkNumber=()=>{
        const payload={messagingServiceSid:messagingServiceSid,phoneSid:selectedNumberSID}
        dispatch(postCampaignPhoneNumbers(payload,(res)=>{
          console.log('270',res)
          NotificationManager.success('Successfuly link number to this campaign.')
          history.push(`/${tenant}/linked-campaign-numbers`)
        }))
      }
    return <>
      {spinner ? <Loader/> : null}
    
    <div className="mt-4 p-4 bg-white grid grid-cols-4 gap-4">
        <Typography variant="h4" className="col-span-4 border-b-2 border-black">Link Numbers To Campaign</Typography>
        <div className="col-span-4 text-lg font-bold">Message Service SID</div>
        <div className="col-span-4 ">{messagingServiceSid}</div>
        <div className="col-span-4 text-lg font-bold">Use Case</div>
        <div className="col-span-4 ">{useCase}</div>
        <div className="col-span-4 text-lg font-bold">Description</div>
        <div className="col-span-4 ">{description}</div>
        <div className="col-span-4 ">
        <Table
            // hiddenColumns={hiddenColumns}
            pageCount={pageCount}
            totalRows={totalRows}
            columns={tableColumns}
            data={rows}
            // fetchData={fetchTableData}
            onTableStateChange={onTableStateChange}
            // globalSearchQuery={globalSearchQuery}
          />
        </div>
       {isPromptOpen && <ConfirmationDialog
         onHandleModel={() => setIsPromptOpen((state) => !state)}
         isOpenDialog={isPromptOpen}
         action={handleLinkNumber}
         title={'Confirm'}
         content={'Are you sure, you wanna to link this number to campaign ?'}
         />}
    </div>
    </>
}
export default LinkNumberToCampaign