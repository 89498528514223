import ActionConstants from '../config/AppConstants';

const innitialData = {
  open:false,
};

export default function drawerState(state = innitialData, action) {
  switch (action.type) {
  case ActionConstants.DIALER_OPEN: {
    return {
      open:true
    };
  }
  case ActionConstants.DIALER_CLOSE:{
    return {
      open:false
    }
  }

  default:
    return { ...state };
  }
}
  