import { NotificationManager } from 'react-notifications';

import AppConstants from '../../config/AppConstants';
import { userIdToken } from '../../userIdToken';
import { serviceCallWithToken } from '../serviceCall';
import { serviceCallAuth } from '../serviceCallAuth';

export const postBulkCustomers = (data, headers, callback, errorCallBack, method) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/PutBulkCustomers',
      method: method || 'put',
      data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        callback && callback(response && response.data);
        response?.data?.bulkPutCustomerResults[0]?.updated > 1
          ? NotificationManager.success('File updated succesfully !')
          : NotificationManager.success('File uploaded succesfully !');
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback(error?.response?.data);
        errorCallBack && errorCallBack(error?.response?.data);
        // error?.response?.data?.message==="File contains Incorrect Data. Please correct it and try again."?
        // NotificationManager.error(error?.response?.data?.message):
        // NotificationManager.error('File upload failed');
      });
  };
};
export const putBulkDNDEmails = (data, headers, callback, errorCallBack, method) => {
  // console.log('dnd data',data)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/PutBulkDNDEmails',
      method: method || 'put',
      data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        console.log('dnd data',response.status)
        callback && callback(response && response.data);
        // response.status===200? NotificationManager.success('File uploaded succesfully !'): NotificationManager.error('File upload failed !');
      })
      .catch((error) => {
        console.log('dnd err',error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback(error?.response?.data);
        errorCallBack && errorCallBack(error?.response?.data);
        // error?.response?.data?.message==="File contains Incorrect Data. Please correct it and try again."?
        // NotificationManager.error(error?.response?.data?.message):
        // NotificationManager.error('File upload failed');
      });
  };
};

export const addBulkCustomers = (data, callback, errorCallBack, method) => {
  return async (dispatch) => {
    serviceCallWithToken({
      // url: '/api/Customer/ImportLoadFromFile',
      url: '/api/Customer/PostBulkCustomers',

      method: method || 'post',
      data: data,
    })
      .then((response) => {
        callback && callback(response && response.data);
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const addCustomer = (data, callback, errorCallBack, method) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/PutCustomer',
      method: method || 'put',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

        // NotificationManager.success('Customer Added Succesfully !');

        // dispatch(toggleLoader(false));
        callback && callback(response);
      })
      .catch((error) => {
        console.log('99',error?.response?.data)
        errorCallBack && errorCallBack(error?.response?.data);
        if (!error) {
        } else {
          const errorString = error?.response?.data?.message;
          const pattern = /already exists a Customer/i;

          if (pattern.test(errorString)) {
            NotificationManager?.error('Customer already exist !');
          }
          else if((error.response.data).includes('Invalid Email')){
            NotificationManager?.error('Invalid email,Please check email and try again.');

          }

          
        }
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };
};

export const deleteCustomer = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Customer/DeleteCustomer',
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        callback && callback();
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getCustomersList = (data, callback, errorCallBack) => {
  console.log('c d',data)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/GetCustomer',
      method: 'get',
      data: data,
    })
      .then((response) => {
        console.log('customer response',response)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          Array.isArray(response.data.customersResponseTable) &&
            response.data.customersResponseTable.forEach((element, index) => {
              response.data.customersResponseTable[index]['id'] = element.customerID_ids;
              // response.data.tblCustomer.forEach((element, index) => {
              //   response.data.tblCustomer[index]['id'] = element.customerID_ids;
              // response.data.tblCustomer[index]["id"] = index + 1;
            });
          callback && callback(response?.data);
          // callback && callback(response.data.table1);
          // callback && callback(response.data.tblCustomer);

          dispatch({
            data: response.data.customersResponseTable,
            type: AppConstants.GET_ALL_CUSTOMERS,
          });
        }
      })

      .catch((error) => {
        // console.log('error11',error.response?.status)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const deleteCustomerDocs = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/DeleteCustomerDocument',
      method: 'delete',
      data
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
       callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        console.log(error, 'error');
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
}
export const getCustomerComments = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/getCustomerComments',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getCustomerEmailPhone = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/GetCustomerEmailPhone',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          const { tblCustomer } = response.data;
          tblCustomer.forEach((element, index) => {
            tblCustomer[index]['id'] = element['customerID_ids'];
            // response.data.tblCustomer[index]["id"] = element.customerID_ids;
            // tblCustomer[index]["id"] = tblCustomer[index]['email_chr'];
          });

          // callback && callback(response.data.tblCustomer);
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getCustomerDetail = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    // dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/GetCustomerDetail',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getCustomerRef = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Customer/GetCustomerRef',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          if (Array.isArray(response.data.refCountry)) {
            // const refCountry = ['Select',...response.data.refCountry]
            response.data.refCountry.unshift({ countryName_chr: 'Select Country' });
            response.data.refCountry.forEach((element, index) => {
              // response.data.tblCustomer[index]["id"] = element.customerID_ids;
              response.data.refCountry[index]['id'] = index + 1;
            });
            // response.data.refCountry.unshift({'Select Country')
          }

          if (Array.isArray(response.data.refIndustryType)) {
            // response.data.refIndustryType.unshift({ industryName_chr: 'Select Industry' });

            // response.data.refIndustryType.unshift('Select Organization')
            response.data.refIndustryType.forEach((element, index) => {
              // response.data.tblCustomer[index]["id"] = element.customerID_ids;
              response.data.refIndustryType[index]['id'] = index + 1;
            });
          }

          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getStateByCountryRef = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Customer/GetStateByCountryRef',
      method: 'get',
      data,
    })
      .then((response) => {
        if (response && response.data) {
          if (Array.isArray(response.data.refState)) {
            // const refCountry = ['Select',...response.data.refCountry]
            response.data.refState.unshift({ stateName_chr: 'Select State' });
            response.data.refState.forEach((element, index) => {
              // response.data.tblCustomer[index]["id"] = element.customerID_ids;
              response.data.refState[index]['id'] = index + 1;
            });
            // response.data.refCountry.unshift({'Select Country')
          }

          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getListOfUsers = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    // dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: `/api/Account/GetUserByAdmin`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getListOfImportedFiles = (data, callback, errorCallBack) => {
  const userId=data?.Calling_UserID_chr?data?.Calling_UserID_chr:userIdToken().userId
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });

    serviceCallWithToken({
      url: `/api/Customer/GetListOfImportedFiles/${userId}`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const deleteImportFile = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Customer/DeleteImportFile',
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        callback && callback();
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getDashboardData = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Dashboard/GetDashboardData',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getCustomerDoc = (data, callback, errorCallBack, finallyCB) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true })
    serviceCallWithToken({
      url: "/api/Customer/GetCustomerDocument",
      method: "get",
      data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false })
        console.log(response,"getAgrncyDocResponse")
        callback && callback(response.data?.tblCustomerDocument);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false })
        errorCallBack && errorCallBack(error.response);
      })
      .finally(() => {
        finallyCB && finallyCB();
      });
  };
};