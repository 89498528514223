import React from 'react'

// import DeleteUserImage from "./deleteuserss.png";

export default function DeleteUser() {
    return (
        <>
            <div>
                <title>How to delete user</title>
                <meta name="description" content="privacy policy"></meta>
                <meta name="keywords" content="policies"></meta>
            </div>
            {/* -=====================================  Main  HEading  ========================================================*/}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50px',
                    marginBottom: '50px',
                }}
            >
                <h1 class="heading">Delete User</h1>
                <span id="scope"></span>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '20px',
                }}
            >

                {/* =========================================   Main Content =========================================================== */}
                <div className="mainContent">
                    <span style={{ fontWeight: '700' }}>
                        To Delete a particular user you need to follow these following steps.
                    </span>
                    <div style={{ marginTop: '10px' }}>
                        <label
                            style={{ fontSize: '24px', color: '#b00000', fontWeight: '500' }}
                        >
                            How to Delete a User
                        </label>
                        <p>
                            To delete a user you need to first login by super admin account only super admin user can delete a user.
                            <br />
                            Here is the screenshot of delete user page, It is not a public page it is a private page only admin can see it.
                        </p>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <br />
                        <img src='/deleteuserss.png'></img>
                    </div>
                </div>
            </div>
        </>
    )
}