// import { get } from "lodash";
// import ActionConstants from "../config/AppConstants";
const innitialData = {
  twilioNumber:'',
  token: '',
  device: '',
  callEvent: '',
  callDeviceIdentity:'',
  callInstance:{},
  isCallConnected:false,
  isOutgoingConnected:false,
  isDialpadOpen:false,
  isMuted:false,
  callTimer:0,
  dialedNumber:'',
  isCallProgressPopUp:false,

};
export default function twilio(state = innitialData, action) {
  switch (action.type) {
    case 'ADD_TOKEN': {
      return {
        ...state,
        token: action.payload.token,
        callDeviceIdentity: action.payload.callDeviceIdentity,
      };
    }
    case 'ADD_DEVICE': {
      return {
        ...state,
        device: action.payload.device,
      };
    }
    case 'CALL_DEVICE_IDENTITY': {
      return {
        ...state,
        callDeviceIdentity: action.payload.device,
      };
    }
    case 'CALL_INSTANCE': {
      return {
        ...state,
        callInstance: action.payload.callInstance,
      };
    }
    case 'CHANGE_TWILIO_CALL_EVENT': {
      return {
        ...state,
        callEvent: action.payload.callEvent,
      };
    }
    case 'TWILIO_NUMBER': {
      return {
        ...state,
        twilioNumber: action.payload.twilioNumber,
      };
    }
    case 'IS_CALL_CONNECTED':{
      return {
        ...state,
      isCallConnected:action.payload.isCallConnected
    }
    }
    case 'IS_DIALPAD_OPEN':{
      return{
        ...state,
        isDialpadOpen:action.payload.isDialpadOpen,
      }
    }
    case 'IS_OUTGOING_CONNECTED':{
      return{
        ...state,
        isOutgoingConnected:action.payload.isOutgoingConnected,
      }
    }
    case 'DIALED_NUMBER':{
      return{
        ...state,
        dialedNumber:action.payload.dialedNumber,
      }
    }
    case 'IS_MUTED':{
      return{
      ...state,
      isMuted:action.payload.isMuted,
      }       
    }
    case 'CALLING_TIME':{
      return{
        ...state,
        callTimer:action.payload.callTimer,
      }
    }
    case 'CALL_PROGRESS_POP_UP':{
      return{
        ...state,
        isCallProgressPopUp:action.payload.isCallProgressPopUp,
      }
    }
    case 'CLEAR_TWILIO_INFO':{
      return {}
    }
    default:
      return { ...state };
  }
}
