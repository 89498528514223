import React,{ lazy,Suspense,useEffect, useState } from 'react';

import { Button, Grid, IconButton,Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CiViewList } from 'react-icons/ci';
import { FaClipboardList, FaFileImport,FaTrash } from 'react-icons/fa';
import { MdAdd, MdModeEdit } from 'react-icons/md';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
// import { Tooltip } from 'react-tippy';

import BootstrapTooltip from '../../components/BootstrapTooltip/component';
import CommonButton from '../../components/Buttons/commonButton';
import MuiSearch from '../../components/InputFields/SearchFields/muiSearch';
import MuiSearch2 from '../../components/InputFields/SearchFields/muiSearch2';
import Loader from '../../components/loader';
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import Table from '../../components/Table';
import constant_label from '../../constants';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import importRetry from '../../importRetry';
import { addToBounceList, getEmailBounceList, putBulkBounceEmails, removeEmailFromBounce } from '../../services/report/reportService';
import { convertTimeZone } from '../../utils';
import DNDListDialog from '../CustomerPage/DNDListModal';
// import MassImportCustomerModal from '../CustomerPage/MassImportCustomerModal';
const MassImportCustomerModal = lazy(() =>
  importRetry(() => import(/* webpackChunkName: 'mass import modal' */ '../CustomerPage/MassImportCustomerModal'))
);

const useStyles = makeStyles((theme) => ({
    root: {
      border: 0,
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
  
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontSize: '14px',
        fontFamily: "'Rubik', sans-serif",
        color: '#333',
      },
      '& .MuiDataGrid-columnsContainer': {
        border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
  
      '&  .MuiDataGrid-window ': {
        //   [theme.breakpoints.up('1450')]: {
        //     overflowX: (props) => {
        //       if (!props) return 'hidden';
        //       return props.open ? 'auto' : 'hidden';
        //     },
        //   },
      },
      '& .MuiDataGrid-dataContainer': {
        borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Rubik', sans-serif",
        borderBottom: '0',
      },
      '& .MuiDataGrid-row': {
        color: '#444',
        backgroundColor: '#ffffff',
        outline: 'none',
      },
  
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f7f7',
        color: '#aeacb9',
      },
    },
  
    paper2: {
      width: '100%',
      marginTop: '0px',
      marginBottom: theme.spacing(2),
      '& .makeStyles-paper-32': {
        marginTop: '0px',
      },
      overflow: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingY: '10px',
      margin: '10px',
    },
    tableContainer: {
      overflow: 'auto',
    },
    selected: {
      '&:hover': {
        backgroundColor: 'rgba(17, 122, 139, 0.15) !important',
      },
    },
  
    clickable: {
      cursor: 'pointer',
    },
    icons: {
      padding: '0px 3px',
      '&:focus': {
        outline: 'none',
      },
    },
    table: {
      minWidth: 750,
    },
    radio: {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
  
      '&$checked': {
        color: '#263003',
      },
  
      '& .MuiSvgIcon-root': {
        height: '15px',
        width: '15px',
      },
    },
  
    table: {
      minWidth: 340,
    },
    tableCell: {
      paddingRight: 4,
      paddingLeft: 5,
  
      a: {
        color: '#9BA3B9',
      },
      paddingY: '0px',
    },
    onHover: {
      '& .MuiButtonBase-root': {
        '&:hover': {
          color: '#000000DE',
        },
      },
    },
  
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    ttitle: {
      fontWeight: 'bold',
    },
    pagination: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      width: '100%',
      backgroundColor: '#fff',
    },
  
    inputBase: {
      [theme.breakpoints.up('xs')]: {
        width: '200px',
        height: '25px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '200px',
        height: '30px',
      },
    },
  
    moreVertIcon: {
      [theme.breakpoints.up('768')]: {
        display: 'none',
      },
    },
    grid2: {
      [theme.breakpoints.up('0', '499')]: {
        display: 'none',
      },
      [theme.breakpoints.up('500')]: {
        display: 'inline',
      },
    },
  
    grid: {
      flexGrow: 1,
    },
  
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    add_btn: {
      float: 'right',
    },
    flex: {
      flexGrow: 1,
    },
    heading: {
      display:'flex',
      justifyItems:'center',
      columnGap:'4px',
      fontWeight: 'bold',
      marginTop: 30,
      // paddingBottom: 25,
    },
    menuItem: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }));
function BounceListPage(){
  const classes = useStyles();
  const dispatch=useDispatch()
  const {userId,permissions}=useSelector((state)=>state?.login?.loginData);
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const [globalSearchQuery,setglobalSearchQuery]=useState('')
  const [sortModel, setSortModel] = useState([{ field: 'email_chr', sort: 'asc' }]);
  const [searchedVal, setSearchedVal] = useState('');
  const fetchIdRef = React.useRef(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [isOpenDNDListDialog,setIsOpenDNDListDialog]=useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [tableState, setTableState] = useState({
    // Calling_UserID_chr: userId,
    Page_Index_int: +1,
    Page_Size_int: 20,
    Where_GeneralCriteria_Delim_mem: '',
    Sort_Expression_Delim_mem: '',
  });
  const [isUploaded, setIsUploaded] = useState(false);
  const [isConfirmationOpen,setIsConfirmationOpen]=useState(false)
  const [selectedRow,setSelectedRow]=useState({})
  const [file,setFile]=useState(null)
  const onClickDelete=()=>{
    const row=selectedRow
    const payload={
      // Calling_UserID_chr:userId,
      EmailsBounceListID_ids:row?.emailsBounceListID_ids,
    }
    dispatch(removeEmailFromBounce(payload,(res)=>{
      // console.log(res)
        NotificationManager.success('Email Removed Succesfully !'); 
        reloadList(page)
      }))
  }
  const tableColumns = React.useMemo(
    () => [
      {
        Header: constant_label?.lbl_txt_actions,
        accessor: 'acction',
        disableSortBy: true,
        Cell: ({ row: { original } }) =>(
            <div className={`${classes.flex} `}>
              
                {/* <Tooltip title="Edit" position="top" arrow size='small'> 
                  <IconButton aria-label="edit" className={`${classes.icons}`} 
                  // onClick={() => onClickEdit(original)}
                  >
                    <MdModeEdit color="#263003" className="text-theme-green h-20px" fontSize="small" size={20} />
                  </IconButton>
                </Tooltip> */}
              {permissions.Engage?.removeFromDnd ? <BootstrapTooltip title="Delete">
                  <IconButton
                    className={`${classes.icons} `}
                    aria-label="delete"
                    // onClick={() => onClickDelete(original)}
                    onClick={()=>onClickDeleteIcon(original)}

                  >
                    <FaTrash color="#263003" className="text-theme-green h-16px " />
                  </IconButton>
                </BootstrapTooltip>:null}
            </div>
          ),
        width: 80,
        sticky: 'left',
      },
      {
        accessor: 'email_chr',
        Header: constant_label?.lbl_txt_email,
        // width: 260,
        sortable: true,
        Cell:({value})=>{
          return <BootstrapTooltip title={value} >
            <span className='w-full cursor-pointer'>
            {value}
            </span>
          </BootstrapTooltip>
        }
      },
      {
        accessor: row => `${row.firstName} ${row.lastName}`,
        Header: constant_label?.lbl_txt_added_by,
        // width: 260,
        sortable: true,
      },
      {
        accessor: 'creationDate_dtm',
        Header: constant_label?.lbl_txt_added_date_time,
        // width: 260,
        sortable: true,
        Cell:({value})=>{
          return convertTimeZone(value)
        }
      },
      {
        accessor: 'comment_chr',
        Header: constant_label?.lbl_txt_comment,
        // width: 260,
        sortable: true,
        Cell:({value})=>{
          return <BootstrapTooltip title={value}>
            <span className='w-full cursor-pointer'>
            {value}
            </span>
          </BootstrapTooltip>
        }
      },
     
    ],
    []
  );
  useEffect(() => {
    let hiddenColumns = ['acction'];
    console.log('check permission',permissions,permissions.Engage?.removeFromDnd,
      )
    if (
      permissions.Engage?.removeFromDnd
    ) {
      hiddenColumns = [];
    }

    setHiddenColumns(hiddenColumns);
  }, [permissions]);
  useEffect(()=>{
    const updatedValue = JSON.parse(
      sessionStorage.getItem("BounceListPage-global-search-query")
    )
    setSearchedVal(updatedValue);
    setglobalSearchQuery(updatedValue)
    const newTableState={...tableState}
    newTableState['Where_GeneralCriteria_Delim_mem']=updatedValue
    // newTableState["Page_Index_int"]=updatedPageIndex
    setTableState(newTableState)
  },[])
  useEffect(()=>{
    if(searchedVal===""){
      // setSearchedVal('')
      setglobalSearchQuery('')
    }
  },[searchedVal])
  const requestSearch = (e ) => {
    const { name, value } = e.target;
  //   if (name === "Where_GeneralCriteria_Delim_mem") {
  //     sessionStorage.setItem(
  //       "BounceListPage-global-search-query",
  //       JSON.stringify(value)
  //     );
  // };
  setSearchedVal(value);

}
  const calculatePageCount = (rowsData, pageSize) => {
    const totalRows = parseInt(rowsData?.[0]?.['client_Count_Including_Criteria_int']);
    // const totalRows = parseInt(rowsData);

    setPageCount(Math.ceil(totalRows / pageSize));
  };
  const onTableStateChange = React.useCallback(
    ({ pageIndex: Page_Index_int, pageSize: Page_Size_int, globalFilter: Where_GeneralCriteria_Delim_mem, sortBy }) => {
        // console.log('first call',Page_Index_int)
       
      const fetchId = ++fetchIdRef.current;
      if (fetchId !== fetchIdRef.current) {
        return;
      }
      setTableState((state) => {
        const sort = sortBy?.[0];
        let sortingField = "email_chr";
        let sortingOrder = 'asc';
 
        if (sort) {
          sortingField = sort['id'];
          sortingOrder = sort['desc'] ? 'desc' : 'asc';
          sortingField=(sortingField==='Added By')?"firstName":sortingField
        }
        const Sort_Expression_Delim_mem = `${sortingField} ${sortingOrder}`; 
          return {
        ...state,
        Page_Index_int: Page_Index_int + 1,
        Page_Size_int: Page_Size_int||20,
        Where_GeneralCriteria_Delim_mem,
        Sort_Expression_Delim_mem: Sort_Expression_Delim_mem || '',
          }
        });
       
    },
    []
  );
  const fetchTableData = (callback) => {
  // dispatch({type:'TOKEN_API_CALL',isTokenApiCall:true})
    dispatch(getEmailBounceList(tableState, (data) => {
      // console.log(data)
      callback && callback(data?.usp_EmailBounceList_GET);
      setRows(data?.usp_EmailBounceList_GET);
      calculatePageCount(data?.usp_EmailBounceList_GET, tableState?.Page_Size_int);
  // dispatch({type:'TOKEN_API_CALL',isTokenApiCall:false})
    }))
  };
  // console.log('rows',rows)
  useUpdateEffect(() => {
    fetchTableData();
    // console.log('check tableState',tableState) 
  }, [tableState,isUploaded === true]);
  const reloadList = async (query = {}, paginate = false) => {
    // console.log('second call',query)
    setRows([]);
    const { searchTerm = searchedVal, pageNumber = page, pageSize } = query;
    // console.log(query)
    const sortingQuery = `${sortModel[0]['field']} ${sortModel[0]['sort']}`;

    const data = {
      Calling_UserID_chr: userId,
      Where_GeneralCriteria_Delim_mem: searchTerm,
      Sort_Expression_Delim_mem: sortingQuery,

      Return_All_Rows_ysn: true,
      Page_Index_int: pageNumber,

      Page_Size_int: pageSize || rowsPerPage,
    };
    // console.log(data)
    setTableState((state)=>({
      ...state,
      Where_GeneralCriteria_Delim_mem:searchTerm
    }))
    // setPage(1)
  };
  
  const onClickAdd=(row)=>{
    setIsOpenDNDListDialog(true)
    // console.log(row)
  }
  const onClickMassImport = () => {
    setIsImportModalOpen(true);
  };
  const handleDialogClose = () => {
    setIsImportModalOpen(false);
  };
  const onClickDeleteIcon=(row)=>{
    setIsConfirmationOpen((state) => !state)
    setSelectedRow(row)
  }
  const handleSearch=()=>{
    const searchQuery=searchedVal.trim()
    setglobalSearchQuery(searchQuery)
    sessionStorage.setItem(
            "BounceListPage-global-search-query",
            JSON.stringify(searchQuery)
          );
  }
  const handleCloseIcon=()=>{
    setSearchedVal('')
    setglobalSearchQuery('')
    sessionStorage.setItem(
      "BounceListPage-global-search-query",
      JSON.stringify('')
    );
  } 
  const handleKeyPress=(event)=>{
    if (event.key === "Enter") {
      event.preventDefault(); 
    const searchQuery=searchedVal.trim()
      setglobalSearchQuery(searchQuery)
    sessionStorage.setItem(
      "BounceListPage-global-search-query",
      JSON.stringify(searchQuery)
    );
    }
  }
  console.log('513',file)
  const uploadExcelFile=()=>{
    if (!file) {
      NotificationManager.error('Please upload file first');
    } else {
    const formData = new FormData();
      formData?.append('excelFile', file);
        dispatch(
            putBulkBounceEmails(
          formData,
          {
            'content-type': 'multipart/form-data',
          },
          (res) => {
            console.log('import data', res,res.ErrorCode);
            if(res.ErrorCode===500){
              NotificationManager.error('File contains Incorrect Data. Please correct it and try again.');
            }else{
              setIsImportModalOpen(false);
            setIsUploaded(true);
            setFile(null)
            NotificationManager.success('File uploaded succesfully !')
            }
           
          },(err)=>{
            // console.log('532',err,err.ErrorCode)
           
          }
        )
        )
  }
}
const handleSubmitData=(payload)=>{
    dispatch(addToBounceList(payload,
        (res)=>{
          setIsOpenDNDListDialog(false);
          // console.log('check dnd res',res)
          NotificationManager.success('Email Added Succesfully !')
          reloadList();
          // console.log('finally',isOpenDNDListDialog)  
        }),(err)=>{
          NotificationManager.error('please try again !')
        })
}
return <> {spinner ? <Loader/> : null}
{isConfirmationOpen && <ConfirmationDialog 
onHandleModel={(e) => setIsConfirmationOpen((state) => !state)}
isOpenDialog={isConfirmationOpen}
action={onClickDelete}
title={'Remove'}
content={'Are you sure you want to remove this email ?'}/>}
<div className={classes.root} component="main">
           <Grid container spacing={3}>

           <Grid item xs={12}>
          <Typography variant="h5" className='custom_heading'>
            <CiViewList size={24} className="" />
            {constant_label?.lbl_hd_bounce_list}
          </Typography>
          </Grid>
           
           
                <Grid item xs={12} sm={4} md={3} lg={3}>
                 
                     <MuiSearch2
                      name="Where_GeneralCriteria_Delim_mem"
                      value={searchedVal}
                      onChange={requestSearch}
                      handleSearch={handleSearch}
                      handleCloseIcon={handleCloseIcon}
                      onKeyDown={handleKeyPress}
                      />
                      </Grid>
                      {permissions?.Engage?.addToDnd?<Grid item xs={6} sm={3} md={2} lg={2}><CommonButton onClick={onClickAdd} >
                      <MdAdd size={20} className=" mr-1" />
                        <span className="hidden md:inline"> {constant_label?.btn_add_another}</span>
                      </CommonButton>
                      </Grid>:null}
                     {permissions?.Engage?.massImportDndList ?<Grid item xs={6} sm={3} md={2} lg={2}><CommonButton onClick={onClickMassImport}>
                      <FaFileImport size="15px" className="mr-1" />
                      <span className="w-full hidden md:inline">{constant_label?.btn_mass_import}</span>
                  </CommonButton></Grid>:null}
                   
                
                  <Grid item xs={12}>
              <Table
              // key={rows}
                hiddenColumns={hiddenColumns}
                pageCount={pageCount}
                totalRows={totalRows}
                columns={tableColumns}
                data={rows}
                // fetchData={fetchTableData}
                onTableStateChange={onTableStateChange}
                globalSearchQuery={globalSearchQuery}
              />
              </Grid>
           </Grid>
       <Suspense fallback={<div>Loading Component</div>}>
       <DNDListDialog
           label='Add to Bounce list'
           isDialogOpen={isOpenDNDListDialog}
           setIsOpenDNDListDialog={setIsOpenDNDListDialog}
           reloadList={() => reloadList(page)}
           selectedEmail=''
           handleSubmitData={handleSubmitData}
           />
           {isImportModalOpen && (
            <MassImportCustomerModal
              onSuccess={reloadList}
              handleDialogClose={handleDialogClose}
              isImportModalOpen={isImportModalOpen}
              setIsImportModalOpen={setIsImportModalOpen}
              setIsUploaded={setIsUploaded}
              setFile={setFile}
              handleFileUpload={uploadExcelFile}
              sampleFile='/Sample_Bounce_List.xlsx'
            />
          )}
       </Suspense>

    </div>
</>
}
export default BounceListPage;