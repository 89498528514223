import React from 'react';

import Button from '@material-ui/core/Button';

import '../../../src/DialSightStyles.css'

const LoginButton = ({ children,onClick }) => {
  return (
    <button fullWidth type="submit" variant="contained" color="primary" onClick={onClick} className='login_button'>
     {children}
    </button>
  );
};

export default LoginButton;
