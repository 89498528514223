import React, { useCallback, useEffect, useState } from 'react';

import { Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import debounce from "lodash/debounce";
import { FcBusinessman } from "react-icons/fc";
import { ImSpinner2 } from 'react-icons/im';
import { NotificationManager } from 'react-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import CommonButton from '../../../components/Buttons/commonButton';
import TxtField from '../../../components/InputFields/TextFields/TextField';
import ActionConstants from '../../../config/AppConstants';
import constant_label from '../../../constants';
import CustomHistoryHook from '../../../hooks/useHistory';
import { checkLogin } from '../../../services/userService';
import FormValidator from '../../../validations/FormValidator';
import '../styles.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:'5vw 3vh 8vw 3vh',
    gap:'5vh'
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: alpha('#263003',0.5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display:'flex',
    flexDirection:'column',
    gap:'5vh'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const loginFormValidator = new FormValidator();

function GetTenant(props) {
  const {tenant,domainName}=useSelector((state)=>state?.login?.loginData)
 const {tenantName}=useParams()
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const history = useHistory();
  const {customHistory}=CustomHistoryHook()
  const classes = useStyles();
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [value, setValue] = useState(0);
  // const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedDomainName, setSelectedDomainName] = useState(null);
  const dispatch = useDispatch();
  const [hostName, setHostName] = useState('');
  const [doDomainNamesIncludesHostName, setDoDomainNamesIncludesHostName] = useState();
  const [status, setStatus] = useState('loading');
  const [values, setValues] =useState({
    password: '',
    showPassword: false,
  });
  const [currentTenant,setCurrentTenant]=useState('')
  const onChange = (e) => {
    const { name, value } = e.target;
    setLoginData((data) => ({ ...data, [name]: value }));
  };
  const path=window.location.pathname
  useEffect(()=>{
    console.log('your path',path)
    const routeParts=path.split('/')
    console.log('routeParts',routeParts)
    if(routeParts[1].length)
   { 
    if(routeParts[1].toLocaleLowerCase()==='login')
    {
      setCurrentTenant('')
    }
    else
   { 
    // history.push(`:/${routeParts[1]}`)
    setCurrentTenant(routeParts[1])
  }
  }
  },[path])
  const debouncedForNavigate = useCallback(
    debounce(() =>  customHistory(localStorage.getItem("tenantName"),'/authentication'),1000),
    []
  );
  useEffect(() => {
    const isIncluded = tenants?.map(({ DomainName }) => DomainName)?.includes(hostName);
    setDoDomainNamesIncludesHostName(isIncluded);
    console.log('checck tennts',tenants)
    if (tenants?.length > 1) {
      dispatch({
        type: ActionConstants.GET_TENANTS,
        data: {
          tenants: tenants,
          user:loginData?.email,
          tenantArray:tenants
        },
      });
      history.push('/workspaces/select-workspace');
    
    }else if(tenants?.length ===1){
      const tenantName=tenants[0]?.TenantName_chr
      // const domainName=tenants[0]?.DomainName
      const domainName=tenants[0]?.TenantName_chr
      dispatch({
        type: ActionConstants.SAVE_TENANT,
        data: {
          tenant: tenantName,
          user:loginData?.email,
          tenantArray:tenants,
          domainName:domainName,
          domain:tenantName
        },
      });
      debouncedForNavigate()
      // localStorage.setItem('domain',domainName)
      localStorage.setItem('domain',tenantName)
      localStorage.setItem('tenantName',tenantName)
      
    }
  }, [tenants, hostName]);

  useEffect(() => {
    console.log('location',window.location.hostname==='localhost')
    if( window.location.hostname==='localhost')
   {
     setHostName('connect-dev.dialsight.com')
    //  setHostName('connect.dialsight.com')
    }else{
    setHostName(window.location.hostname)
   }
   dispatch({ type: 'SPINNER_STATE', isLoading: false });
  }, []);

  console.log('window',window.config)
  const getTenants = (event) => {
    
   localStorage.setItem('hostName',hostName)
   event.preventDefault();
       if (loginFormValidator.allValid()) 
    {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      axios({
      url: window.config.COMMON_API_URL + '/api/Tenant/GetUserTenants',
      method: 'get',
      params: { UserEmailPhone: loginData?.email ,DomainName:currentTenant},

    })
      .then((res) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        const { data = [] } = res;
        setTenants(data);
       
        if (res.data && res.data?.length === 0) {
          swal({
            title: 'User not found !',
            text: 'Invalid email. Please check your email and try again.',
            icon: "error",
            dangerMode: true,
          });
        }
        
      })
      .catch(() => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        setTenants([]);
      })
      .finally(() => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        setStatus('completed');
      });}
      else{
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        loginFormValidator.showMessages();
        setValue(value + 1);
      }
  };

  const onSelectTenant = (domainName) => {
    setSelectedDomainName(domainName);
  };
  
  console.log('check final tenant',currentTenant)
  const navigateReg=(e)=>{
    // history.push('/org/registration')
    history.push('register')
  }

  return (
    <>
      {/* {spinner ? (
        <>
          <Loader />
        </>
      ) : null} */}
      <div  component="main" className=''>
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}></Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Paper elevation={3}>
            <div className={classes.paper}>
              <Typography 
              variant='h5'
              className='flex flex-col gap-y-6 items-center justify-center'
              > 
              <img src="/connect_fav_icon.svg" alt="favIcon" width="32px"/>
              <img src='/connect_logo_2.svg' width='180px'/>
              Sign in
              </Typography>
              <form className={`${classes.form} mt-4`} 
              noValidate 
            //   onSubmit={onSubmitForm}
              >
                {/* <label for="email">{constant_label?.lbl_txt_email}</label> */}
                <TxtField
                 required
                 name="email"
                 variant="outlined"
                //  label={constant_label?.lbl_txt_email}
                 label='Enter Email'
                 value={loginData.email}
                 onChange={onChange}
                 disabled={spinner}
                //  onBlur={getTenants}
                 />
                {loginFormValidator.message('Email', loginData.email, 'required|email', 'text-danger')}

                {loginFormValidator.message(
                  'Tenant',
                  selectedDomainName,
                  !doDomainNamesIncludesHostName && tenants?.length > 0 ? 'required|tenant' : '',
                  'text-danger'
                )}
                {tenants?.length > 1 && !doDomainNamesIncludesHostName && (
                  <select
                    className="w-full border-2 rounded-sm my-2 p-2 border-collapse  h-14 border-gray-300"
                    onChange={(e) => onSelectTenant(e.target.value)}
                  >
                    <option value={''}>Select Tenant</option>
                    {tenants?.map(({ TenantName_chr, DomainName, TenantId_lng },index) => (
                      
                        <option key={index} value={DomainName} className="capitalize" id={TenantId_lng} >
                          {TenantName_chr}
                        </option>
                      
                    ))}
                  </select>
                )}
                <CommonButton onClick={getTenants}>
                {spinner ? 
                  <div className='flex gap-x-1'>
                  <ImSpinner2 size={20} className='animate-spin'/>
                  Continue
                  </div>
                  : 
                  'Continue'
                  }
               </CommonButton>
              </form>
              <Grid item xs={12}>
                    <span className="text-base">Don't have an account?</span>
                    <Link to='/register'>
                    <span className="cursor-pointer text-base ml-2 hover:text-blue-600"
                    //  onClick={navigateReg}
                     >Register here</span>{' '}
                    </Link>
                   
                  </Grid>

            </div>
            </Paper>
          </Grid>
          <Grid item  xs={12} sm={3} md={3} lg={4}></Grid>
        </Grid>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  checkLogin,
};
const mapStateToProps = (state) => {
  return {
    data: state.login,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetTenant);
