import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getCredentials } from './credentials';

function PublicRoute({ component: Component, ...rest }) {
  const {tenant}=useSelector((state)=>state?.login?.loginData)
  const isAuth = useSelector((state) => state?.login?.authorized);

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuth) {
          return <Redirect to={{ pathname: `/${tenant}/dashboard` }} />;
        } else {
          return <Component />;
        }
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.login && state.login.loginData,
  };
};

export default connect(mapStateToProps, null)(PublicRoute);
