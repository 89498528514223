import React from 'react';

import Button from '@material-ui/core/Button';

import constant_label from '../../constants';
import '../../../src/DialSightStyles.css'
const NoButton = ({style, onClick }) => {
  return (
    <button fullWidth type="button" onClick={onClick} className='no_button' style={style}>
     {constant_label?.btn_no}
    </button>
  );
};

export default NoButton;
