import React from 'react'

// import './privacyPolicy.css'
import * as AiIcons from 'react-icons/ai'
import {HiOutlineMail,HiOutlinePhone} from 'react-icons/hi'
import * as ImIcons from 'react-icons/im'
// import { Helmet } from 'react-helmet'

export default function PrivacyPolicy(props) {
  return (
    <>
      <div>
        <title>Privacy Policy</title>
        <meta name="description" content="privacy policy"></meta>
        <meta name="keywords" content="policies"></meta>
      </div>
      {/* -=====================================  Main  HEading  ========================================================*/}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        <h1 class="heading">Privacy Policy</h1>
        <span id="scope"></span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        {/* =====================================      Key Points Section  ' Start'   ============================================ */}
        <div className="keyPoints">
          <div>
            <div>
              <span style={{ fontSize: '20px', fontWeight: '500' }}>
                Key Points :-
              </span>
            </div>

            <div style={{ marginLeft: '5px' }}>
              <div>
                <a href="#scope">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  <label
                    style={{
                      marginLeft: '10px',
                      color: 'black',
                      cursor: 'pointer',
                      fontSize: '13px',
                    }}
                  >
                    Scope
                  </label>
                </a>
              </div>

              <div>
                {' '}
                <a href="#pdc">
                  {' '}
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  <label
                    style={{
                      marginLeft: '10px',
                      color: 'black',
                      cursor: 'pointer',
                      fontSize: '13px',
                    }}
                  >
                    Personal Data Collection and Use
                  </label>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* =========================================   Main Content =========================================================== */}
        <div className="mainContent">
          <span style={{ fontWeight: '700' }}>
            PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY
            BEFORE USING THIS WEBSITE.
          </span>
          <div style={{ marginTop: '10px' }}>
            <label
              style={{ fontSize: '24px', color: '#b00000', fontWeight: '500' }}
            >
              Introduction to the Privacy Policy
            </label>
            <p>
              DialSight, Inc is dedicated to ensuring the accuracy,
              confidentiality, and security of any personal data it collects
              from or about you, whether through this Internet website , a
              software program or application , or other means.
              <br />
              This Privacy Policy explains what personal information the Company
              obtains from or about you, and how it is used. The Policy is
              separated into sections based on the type of information acquired
              or the method used to gather that information. The purpose of this
              Policy is to I provide a consistent policy statement and
              guidelines for the collection, processing, storage, transfer, and
              use of personal data collected from or about you at this Website
              by the Company, and inform you of the principles by which the
              Company processes personal data collected at this Website
            </p>
          </div>

          <div style={{ marginTop: '20px' }}>
            <label className="label">Scope</label>
            <br />

           
            <p>
              Personal data about an identified or identifiable natural person
              who is an employee of the Company or who seeks to be or was
              employed by the Company and obtained in the context of a person's
              employment with the Company or in the context of a person's
              application to be employed by the Company is governed by the
              Company's Employee Personal Data Privacy Policy. If there is a
              disagreement or inconsistency between this Policy and the
              Company's Employee Personal Data Privacy Policy, the Company's
              Employee Personal Data Privacy Policy will take precedence.
              <br />
              <br />
              If you have any questions about which of the Company's privacy
              rules apply to you, please contact the Company at:
              <br />
              <br />
              <span id="pdc"></span>
              DialSight, Inc.
              <br />
              <ImIcons.ImLocation
                className="imIcons"
                style={{
                  fontSize: '17px',
                  verticalAlign: 'sub',
                  color: '#b00000',
                }}
              />{' '}
              3753 Howard Hughes Pkwy,Suite 200-803,
              <br />
              &nbsp; &nbsp;&nbsp; Las Vegas, Nevada,89169
              <br />
              <HiOutlineMail className="hiIcons" /> info@dialsight.com
              <br />
              <HiOutlinePhone className="hiIcons" />
              {'  '}909-235-9978
              <br />
            </p>
          </div>

          {/* Observance of local laws */}

          {/* Personal Data Collection and Use*/}
          <div style={{ marginTop: '20px', marginBottom: '40px' }}>
            <label className="label">Personal Data Collection and Use</label>
            <br />
            <label
              style={{ fontSize: '15px', color: '#b00000', fontWeight: '400' }}
            >
              Applicant Information
            </label>
            <p>
              By installing the following application or software on your
              computer or mobile device, you agree to the Company collecting,
              using, and disclosing certain information that may be deemed
              personal data in some jurisdictions.
              <br />
              <br />
              Onsite DialSight App: This app is intended for use and
              installation on cell phones and other cellular-connected mobile
              devices. This app's purpose is to collect and communicate the
              location of the mobile device on which it is installed to Company.
              When the app is operating and the user is regarded to be in the
              process of conveying products for a Customer, location data is
              collected via the device's GPS system and transferred to Company.
              <br />
              <br />
              <span id="sw"></span>
              The device's latitude, longitude, altitude, and direction, as well
              as a measure of the accuracy of that data, make up the location
              data. The Onsite App collects some information about the phone in
              addition to location data for verification and diagnostic
              purposes. The phone's number, the phone's type, including make,
              model, and operating system, the phone's condition and battery,
              and language and region codes are all possible pieces of
              information. The app does not gather any personally identifiable
              information, such as a user's name or email address.
              <br />
              <br />
              Company uses the location data to give tracking information on the
              current location of products being delivered by Customers. The
              location information isn't used for anything else. You may delete
              the app at any time or block its access to your mobile device's
              GPS but doing so will prohibit Customer from tracking the movement
              of products you may be conveying on their behalf correctly.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

