import React, { useEffect, useState } from 'react';

import { TextField, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';

import CommonButton from '../../components/Buttons/commonButton';
import DefaultButton from '../../components/Buttons/defaultButton';
import PasswordField from '../../components/InputFields/TextFields/passwordField';
import TxtField from '../../components/InputFields/TextFields/TextField';
import { saveTenantDetails } from '../../services/tenant';
import OrganizationDetail from './organizationDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    width:'100%',
    textTransform:'capitalize'
  },
  
}));
function AdminDetails({isAdminForm,setIsAdminForm,adminDetails,setAdminDetails,organizationDetails,setOrganizationDetails}) {
  const classes = useStyles();
   const dispatch=useDispatch()
   const [showPswd,setShowPswd]=useState(false)
   const [showConfPswd,setShowConfPswd]=useState(false)
   const [errorMessage, setErrorMessage] = useState({isErr:false,msg:''});
  const [adminData, setAdminData] = useState({
    firstName: '',
    lastName: '',
    adminPhoneNumber: '',
    adminEmail: '',
    password: '',
    confirmPassword: '',
  });

useEffect(()=>{
  setAdminData(adminDetails)
},[adminDetails])
  const handleAdminChange = (event) => {
    const { name, value } = event.target;
    if (name == "adminPhoneNumber") {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setAdminData((prevState) =>( {
        ...prevState,
       [name] : onlyNums
     
     }))
   
  }else{
    setAdminData({
      ...adminData,
      [name]: value,
    });
  }
  }
  const handleAdminSubmit = (event) => {
    event.preventDefault();
    console.log('Regs Details:',adminDetails, organizationDetails);
    const formData=new FormData();
    formData.append('CompanyName_chr',(organizationDetails.name)?.trim())
    formData.append('CompanyDescription_chr',(organizationDetails.companyDescription)?.trim())
    formData.append('CompanyAddress_chr',(organizationDetails.addressLine1)?.trim())
    formData.append('CompanyAddress2_chr',(organizationDetails.addressLine2)?.trim())
    formData.append('CompanyWebsiteUrl_chr',(organizationDetails.website)?.trim())
    formData.append('CompanyID_chr',(organizationDetails.companyID)?.trim())
    formData.append('PhoneNumber_chr',(organizationDetails.companyPhone)?.trim())
    formData.append('EmailAddress_chr',(organizationDetails.companyEmail)?.trim())
    formData.append('Country_lng',organizationDetails.country_lng)
    formData.append('State_lng',organizationDetails.state_lng)
    formData.append('City_chr',(organizationDetails.city)?.trim())
    formData.append('ZipCode_chr',(organizationDetails.zipCode)?.trim())
    formData.append('adminDetails_chr.FirstName_chr',(adminData.firstName)?.trim())
    formData.append('adminDetails_chr.LastName_chr',(adminData.lastName)?.trim())
    formData.append('adminDetails_chr.Email_chr',(adminData.adminEmail)?.trim())
    formData.append('adminDetails_chr.PhoneNumber_chr',(adminData.adminPhoneNumber)?.trim())
    formData.append('adminDetails_chr.Password_chr',(adminData.password)?.trim())
    console.log('frm',formData)
    for (var pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
    if(adminData?.password === adminData?.confirmPassword){
      dispatch(saveTenantDetails(formData,
      {
        'content-type': 'multipart/form-data',
      },
      (res) => {
        console.log('res data', res);
    swal({
      title: "Registration Successful !",
      text: 'You will receive an email within 24 hours for setup.',
      icon: "success",
      dangerMode: false,
    });
    setOrganizationDetails({})
    setIsAdminForm({})
    setIsAdminForm(false)
        
      }))

    }else{
      NotificationManager.error('Password and confirm password do not match.')
    }
    
    
  };
  function validatePassword(password) {
    const isPasswordValid = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/;
    return isPasswordValid.test(password);
  }
  const handleClickShowPassword = () => {
    setShowPswd(!showPswd);
  };
  const handleClickShowConfPassword = () => {
    setShowConfPswd(!showConfPswd);
  };
  function handleBlur(event) {
    const allowedSpecialCharacters = '(! @ # $ % ^ & * ~ - )'
    // console.log('in blur block 72',pswdVal?.newPassword)
    const newPassword = event.target.value;
    if (!adminData?.password) {
      // setErrorMessage("New Password is required.");
    } else if (!validatePassword(newPassword)) {
      setErrorMessage({isErr:true,msg:`Password must be at least 8 characters long and contain at least one special character ${allowedSpecialCharacters}`})
    } else {
      setErrorMessage({isErr:false,msg:''})

    }
  }
  console.log('error message', errorMessage)
const handleBack=()=>{
    setIsAdminForm(!isAdminForm)
    setAdminDetails(adminData)
}
  return (
          <div className='h-[75vh]'>
            <form onSubmit={handleAdminSubmit}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="body1" className='text-[#95B632]'>
              Admin Details
            </Typography>
              </Grid>
                <Grid item xs={12}>
                  <TxtField
                    required={true}
                    fullWidth
                    variant='outlined'
                    label="First Name"
                    name="firstName"
                    value={adminData.firstName}
                    onChange={handleAdminChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Last Name"
                    name="lastName"
                    value={adminData.lastName}
                    onChange={handleAdminChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Phone Number"
                    name="adminPhoneNumber"
                    value={adminData.adminPhoneNumber}
                    onChange={handleAdminChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TxtField
                  required={true}
                    fullWidth
                    variant='outlined'
                    label="Email"
                    name="adminEmail"
                    value={adminData.adminEmail}
                    onChange={handleAdminChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <PasswordField
                  required={true}
                  fullWidth
                  variant='outlined'
                  label="Password"
                  name="password"
                  type={showPswd?"text":"password"}
                  value={adminData.password}
                  onChange={handleAdminChange}
                  onClick={handleClickShowPassword}
                  onBlur={handleBlur}
                  error={errorMessage?.isErr}
                  helperText={errorMessage?.msg}
                 />
                </Grid>
                <Grid item xs={12}>
                  <PasswordField
                  required={true}
                  fullWidth
                  variant='outlined'
                  label="Confirm Password"
                    name="confirmPassword"
                    type={showConfPswd?"text":"password"}
                    value={adminData.confirmPassword}
                    onChange={handleAdminChange}
                    onClick={handleClickShowConfPassword}
                 />
                </Grid>
                <Grid item xs={12} >
                 <div className='flex items-center gap-x-2 justify-end'>
                 <Button type='sumit' onClick={handleBack} className={classes.button}>Back</Button>
                  <Button variant="contained" color="primary" type="submit" className={classes.button}>
                    Register
                  </Button>
                 </div>
                </Grid>
              </Grid>
            </form>
          </div>    
  );
};

export default AdminDetails;
