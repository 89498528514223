import React from 'react';

import Button from '@material-ui/core/Button';

import constant_label from '../../constants';
import '../../../src/DialSightStyles.css'
const SaveButton = ({ onClick }) => {
  return (
    <button type="submit" onClick={onClick} className='save_button'>
     {constant_label?.btn_save}
    </button>
  );
};

export default SaveButton;
