const initialState = {
  open: true,
  notificationInfo:''
};

export default function notificationPopupState(state = initialState, action) {
  switch (action.type) {
  case 'SHOW_NOTIFICATION_POPUP': {
    return {
      open: true,
      notificationInfo:action?.payload?.notification || ''
    };
  }
  case 'HIDE_NOTIFICATION_POPUP': {
    return {
      open: false,
    };
  }

  default:
    return { ...state };
  }
}