import axios from 'axios';

import { getCredentials } from '../credentials';
import { userIdToken } from '../userIdToken';
import { loggedInAxios } from './lib/axios';
const configData = window.config;
const userId=configData?.CALLING_USER_ID;
const domainName=configData?.DOMAIN_NAME;
export async function serviceCallWithToken(requestData) {
  console.log('userIdToken',userIdToken())
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('service call auth final domain',userId,domain_Name)
  const newToken= await getCredentials()
  return new Promise( (resolve, reject) => {
    const url = configData.API_URL + requestData.url;  
    // const url = 'https://479d-103-99-202-86.ngrok-free.app/api/SMS/SendSMS' 
// console.log('newtoken',newToken.access)
    const request = {
      ...requestData,
      method: requestData.method || 'get',
      headers: {
        Authorization: 'Bearer  ' + newToken.access,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
        ...requestData.headers,
        "x-domain-name": domain_Name,
      },
      url: url,
    };
    if (requestData.method === 'post' || requestData.method === 'put') {
      const request={...requestData?.data}
      request['data'] = {request,Calling_UserID_chr:user_Id} || {};
    } else {
      request['params'] = requestData.data || {};
    }
    request['params'] = {
      ...(request['params'] || {}),
      Calling_UserID_chr: user_Id,
    };
    console.log('req',request)
    loggedInAxios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
        //   // history.push('/login')
        //   // localStorage.removeItem('demo_data')
        //   // resetStore()
        //   localStorage.removeItem('token');
        //   // store.store.dispatch({type:'RESET_STORE'})
        //   // window.location.replace("/login");
        // localStorage.clear();
        //    logout();
        //   // dispatch({type:'UNAUTH_USER'})
        }  
        reject(err);
      });
    
  
    });
  
}
export async function serviceCallWithToken2(requestData) {
  console.log('userIdToken',userIdToken())
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('service call auth final domain',userId,domain_Name)
  const newToken= await getCredentials()
  return new Promise( (resolve, reject) => {
    const url = configData.API_URL + requestData.url;   
// console.log('newtoken',newToken.access)
    const request = {
      ...requestData,
      // method: 'post'||'get'||'put',
      headers: {
        Authorization: 'Bearer  ' + newToken.access,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
        ...requestData.headers,
        "x-domain-name": domain_Name,
      },
      url: url,
    };
      request['params'] = requestData.data || {};
   

    console.log('req',request)
    loggedInAxios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
        }  
        reject(err);
      });
    
  
    });
  
}
export async function serviceCallWithToken3(requestData) {
  console.log('userIdToken 106',userIdToken())
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('service call auth final domain 106',userId,domain_Name)
  const newToken= await getCredentials()
  return new Promise( (resolve, reject) => {
    const url = configData.API_URL + requestData.url;
    const request = {
      ...requestData,
      method: requestData.method || 'get',
      headers: {
        Authorization: 'Bearer  ' + newToken.access,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
        ...requestData.headers,
        "x-domain-name": domain_Name,
      },
      url: url,
    };
    console.log('check req 11',requestData)
    const q=requestData?.query
      
      // request['data'] = requestData.data.body || {};
       request['params'] = {
        ...(request['params']),
        Calling_UserID_chr: user_Id,
        subject:requestData.subject,
        prompt:encodeURIComponent(q)
      };
    console.log('check req 11',requestData)
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
        }  
        reject(err);
      });
    
  
    });
  
}
export function serviceCall(requestData) {
  // const user_Id=userIdToken().userId;
  // const domain_Name=domainName?domainName:userIdToken().DOMAIN_NAME;
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  return new Promise((resolve, reject) => {
    const url = configData.API_URL + requestData.url;
    const request = {
      method: requestData.method || 'get',
      headers: requestData.headers || {},
      url: url,
    };
    if (requestData.method === 'post'|| requestData.method === 'put') {
      request['data'] ={...requestData.data,Calling_UserID_chr: user_Id } || {};
    } else {
      request['params'] ={...requestData.data,Calling_UserID_chr: user_Id } || {};
    }
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function elasticSearchServiceCall(requestData) {
  // const user_Id=userIdToken().userId;
  // const domain_Name=domainName?domainName:userIdToken().DOMAIN_NAME;
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  return new Promise((resolve, reject) => {
    const url = configData.EMAIL_LOGS_API_URL + requestData.url;
    const request = {
      method: requestData.method || 'get',
      headers: {
        ...requestData.headers,
                domainName: domain_Name,
      },
      url: url,
    };
    if (requestData.method === 'post'|| requestData.method === 'put') {
      request['params'] ={...requestData.data,SenderEmail:'',Calling_UserID_chr: user_Id } || {};
      request['data'] =requestData.data?.SenderEmail;
    } else {
      request['params'] ={...requestData.data,Calling_UserID_chr: user_Id } || {};
    }
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
