// import { get } from "lodash";
import ActionConstants from '../config/AppConstants';
const innitialData = {
  usersListData: {},
  getAllUsers:[],
  allAgencies:[],
};
export default function user(state = innitialData, action) {
  switch (action.type) {
  case ActionConstants.GET_USERS_LIST: {
    return {
      ...state,
      usersListData: action.data,
    };
  }
  case ActionConstants.GET_ALL_AGENCIES: {
    return {
      ...state,
      allAgencies: action.data,
    };
  }
  case ActionConstants.GET_ALL_USERS: {
    return {
      ...state,
      allUsers: action.data,
    };
  }
  default:
    return { ...state };
  }
}
