import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function MuiDropDown(props) {
    const {
      fullWidth,
      required,
      autoComplete,
      margin,
      size,
      id,
      name,
      label,
      placeholder,
      groupBy,
      options,
      getOptionLabel,
      value,
      onChange,
      onKeyDown,
      onInputChange,
      renderOption,
      InputLabelProps,
      helperText,
      error,
      style}=props
    // console.log('drop down',value)
    console.log('options',id ,error)
    // console.log('getOptionLabel',getOptionLabel)
  return (
    <Autocomplete
    fullWidth={fullWidth}
      margin={margin}
      id={id}
      size={size?size:'small'}
      options={options}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={(params) => <TextField 
        {...params}
        fullWidth={fullWidth}
        required={required}
        margin={margin} 
        name={name} 
        label={label} 
        placeholder={placeholder}
        variant="outlined"
        InputLabelProps={InputLabelProps}
        inputProps={{
          ...params.inputProps,
          autoComplete: autoComplete,  // Set this prop to "off" to disable autofill
        }}
        onKeyDown={onKeyDown}
        helperText={helperText}
        error={error} 
        />}
      style={style}
      renderOption={renderOption?renderOption:null}
      className='bg-white'
    />
  );
}