import { useEffect, useState } from "react"

import { Button, Container, Grid, Typography } from "@material-ui/core"
import { IoMdNotifications } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"

import CommonButton from "../../components/Buttons/commonButton"
import MuiSearch2 from "../../components/InputFields/SearchFields/muiSearch2"
import Loader from "../../components/loader"
import { getFCMBroadcastMessage, markAsReadUnread } from "../../services/firebase/firebase"
import { getConversationsByPhoneNumber } from "../../services/message/messagingService"
import { convertTimeZone } from "../../utils"

function Notifications(){
  const notificationsCount=useSelector((state)=>state.firebase.notificationsCount)
  const {allNotifications}=useSelector((state)=>state.notifications)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const {userId,twilioNumber}=useSelector(state=>state.login.loginData)
  const {conversationNumbers,savedContact}=useSelector((state)=>state.smsConversations)
    const dispatch=useDispatch();
    const [notifications,setNotifications]=useState([])
    const [searchedVal,setSearchedVal]=useState('')
    const [tableState, setTableState] = useState({
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: '',
        UserId_chr:userId,
        Include_Read_Message_byt:1,
        Paginate_ysn:false,
      });
      const fetchTableData=()=>{
        dispatch(getFCMBroadcastMessage(tableState,(res)=>{
          console.log(res)
          // setNotifications(res.tblFCMBroadcastMessage)
          dispatch({type:'ADD_NOTIFICATIONS',allNotifications:res.tblFCMBroadcastMessage})

      }))
      }
    useEffect(()=>{
        fetchTableData()
    },[tableState])
    const requestSearch=(e)=>{
        const {value}=e.target;
        setSearchedVal(value)
    }
    const handleSearch=()=>{
      const searchQuery = searchedVal.trim();
      setTableState({...tableState,Where_GeneralCriteria_Delim_mem:searchQuery})
    }
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const searchQuery = searchedVal.trim();
        setTableState({...tableState,Where_GeneralCriteria_Delim_mem:searchQuery})
      }
    };
    const handleCloseIcon = () => {
      setSearchedVal('');
      setTableState({...tableState,Where_GeneralCriteria_Delim_mem:''})

    };
    const onNotificationClicked=(e,data)=>{
      console.log(e.currentTarget)
      
      dispatch({type:'IS_NOTIFICATION_OPEN',isNotificationOpen:true,data:data})
      
      const {id,value}=e.currentTarget
      console.log(id,value,value===false)
     
     const payload= {
        calling_UserID_chr: userId,
        messageID_ids: [id]
      }
      if(value===0)
      {dispatch(markAsReadUnread(payload,(res)=>{
        dispatch({type:'FIREBASE_PING',notificationsCount:notificationsCount-1})
        // fetchTableData()
        getMessagePingData()
    
      }))}
    }
    const getAllActiveConversations=()=>{
      dispatch((getConversationsByPhoneNumber({phoneNumber:twilioNumber},(res)=>{
        console.log('Appjs 164 res',res)
        const filteredData = res.map(item => ({
          convSid: item?.conversationSid,
          convPh: item?.messagingBinding.address,
          latestMessage:item?.latestMessage,
          unreadCount:item?.unreadCount
        }));
        const addContactNameArr = filteredData.map((data) => {
          const contact = savedContact.find((item) => item.phNumber === data.convPh);
          if (contact) {
              return {
                  ...data,
                  contactName: contact.name
              };
          } else {
              return data; // Return original data if no match found
          }
      });
        console.log('addContactName',addContactNameArr)
      dispatch({type:'CONV_LIST',conversationNumbers:addContactNameArr})
      },(err)=>{console.log(err)})))
    }

    const handleMarkAllRead=()=>{
      // Filter messages where isMessageRead_ysn is false and get their message IDs
  const msgIds = allNotifications
  .filter(message => message.isMessageRead_ysn === false)
  .map(message => message.messageID_ids);
    const payload= {
      calling_UserID_chr: userId,
      messageID_ids: msgIds
    }
    // console.log('74',msgIds)
  if(msgIds.length)
    {dispatch(markAsReadUnread(payload,(res)=>{
      getAllActiveConversations()
      dispatch({type:'FIREBASE_PING',notificationsCount:0})
      dispatch({type:'UPDATE_SMS_COUNT',smsCount:0,unreadSms:[]})
      fetchTableData()
    }))}
  }
  const getMessagePingData=()=>{
    const payload={
      Page_Index_int: +1,
      Page_Size_int: 5,
      Where_GeneralCriteria_Delim_mem: '',
      Sort_Expression_Delim_mem: '',
      UserId_chr:userId,
      Paginate_ysn:false,
    }
    dispatch(getFCMBroadcastMessage(payload,(res)=>{
      console.log('check response',res)
      const totalUnreadNotificationCount=(res?.tblFCMBroadcastMessage.length)?res?.tblFCMBroadcastMessage[0].message_Count_Excluding_Criteria_int:0
      const unreadMsg=res.tblFCMBroadcastMessage.filter((data)=>data.isMessageRead_ysn===false)
      const unreadSMS = res.tblFCMBroadcastMessage.filter(data => data.notificationType_chr === 'SMS' && data.title_chr==='New message' && data.isMessageRead_ysn===false);
      console.log('urm',unreadMsg.length,unreadSMS)
      // Function to extract the number before the colon and add it as a new key in each object
      const updatedUnreadSMS = unreadSMS.map(message => {
        // Extract the number before the colon
        const fromNumber = message.messageBody_chr.split(':')[0].trim();
  
        // Return a new object with all the original properties and the new key
        return { ...message, from_number: fromNumber };
      });
      console.log('urm',updatedUnreadSMS)
      const calculateUnreadCounts = (data) => {
        const unreadCounts = {};
      
        data.forEach((item) => {
          const type = item.notificationType_chr;
          if (!unreadCounts[type]) {
            unreadCounts[type] = 0;
          }
          unreadCounts[type] += item.totalUnreadCount;
        });
      
        return unreadCounts;
      };
      
      const unreadCountTable=res.table1
      const data=  calculateUnreadCounts(unreadCountTable)
      console.log('dataaa',data)
      dispatch({type:'ADD_NOTIFICATIONS',allNotifications:res.tblFCMBroadcastMessage})
      dispatch({type:'FIREBASE_PING',notificationsCount:totalUnreadNotificationCount})
      dispatch({type:'UPDATE_SMS_COUNT',smsCount:data.SMS,unreadSms:updatedUnreadSMS})
      getAllActiveConversations()
  }))
  }
    return <>
      {spinner ? <Loader /> : null}
    <div className="h-[100vh] bg-white">
        <Grid container spacing={3}>
       <Grid item xs={12}>
        <Typography variant="h4">
           <div className="flex items-center gap-x-2">
           <IoMdNotifications size={32} color="#95B632"/>
            Notifications
           </div>
        </Typography>
       </Grid>
       <Grid item xs={4} >
              <MuiSearch2
                name="Where_GeneralCriteria_Delim_mem"
                value={searchedVal}
                onChange={requestSearch}
                handleSearch={handleSearch}
                handleCloseIcon={handleCloseIcon}
                onKeyDown={handleKeyPress}
              />
          </Grid>
          <Grid item xs={8}>
          <Button onClick={handleMarkAllRead}>Mark all as read</Button>
          </Grid>
       <Grid item xs={12}>
        <ul className="px-2 pb-2 rounded-lg bg-white drop-shadow-lg  ">
         {allNotifications?.map((notification, index) => (
            <li
            key={index}
            id={notification.messageID_ids}
            value={notification.isMessageRead_ysn?1:0}
            onClick={(e)=>onNotificationClicked(e,notification)}
              className={`sm:p-4 md:px-8 md:py-6 hover:cursor-pointer  ${!notification.isMessageRead_ysn
                  ? "rounded-t-lg border-b border-b-white bg-[#d5ed8e]"
                  : "border-b-gray border-b"
                } flex flex-col items-center justify-between text-xs  md:flex-row md:text-base`}
            >
              <div className="w-full flex flex-col">
                <div className={`break-all text-left ${!notification.isMessageRead_ysn ? 'text-black font-semibold text-lg' : 'text-black'}`}>{notification.title_chr}</div>
                <div className={`text-md text-left text-gray-600 ${!notification.isMessageRead_ysn ? 'text-sm' : ''}text-black truncate`}>
                  {notification.messageBody_chr}
                </div>
                <div className="text-xs text-right"> {convertTimeZone(notification.lastUpdatedDateTime_dtm)}</div>
              </div>
              {/* <div className="flex items-center">
                <span className={`mr-4 text-xs ${!notification.isMessageRead_ysn ? 'text-black' : 'text-black'}`}>
                  {convertTimeZone(
                    notification.lastUpdatedDateTime_dtm
                  )}
                </span>
              </div> */}
            </li>
          ))}
        </ul>
       </Grid>
    </Grid>
    </div>
    </>

}
export default Notifications