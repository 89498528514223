import React, { useState } from 'react';

import {TextField,MenuItem,Grid,Paper,Container,Typography,Chip,Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaCheck } from 'react-icons/fa';
import { MdClose, MdMail } from 'react-icons/md';

import CommonButton from '../../components/Buttons/commonButton';
import { formatedNumber } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      width:'100%',
      height:'300px',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      border:'1px solid #95B632',
      borderRadius:'10px',
      gap:'20px',
      textWrap:'wrap',
      '&:hover': {
        transform: 'scale(1.05)', /* Adjust the scale value as needed */
        transition: 'transform 0.3s ease', /* Adjust the transition duration and timing function as needed */
      }
    },
    
  }));
function EmailPlans(){
  const classes = useStyles();
const totalContacts=[500,1500,2500,5000,10000,15000,20000,25000,30000,40000,50000,75000,100000,130000,150000,200000,250000]
  const [selectedCount, setSelectedCount] = useState(""); // State to store the selected count
  const [planPriceBasic,setPlanPriceBasic]=useState({ firstValue: 9.19})
  const [planPriceStandard,setPlanPriceStandard]=useState({ firstValue: 13.73})
  const [planPricePremimum,setPlanPricePremimum]=useState({ firstValue: 274.61 })

  function divideAndRound(num) {
    let result = num / 2;
    // Round up to two decimal places if there is a fraction
    if (result - Math.floor(result) > 0) {
        result = Math.round(result * 100) / 100; // rounding to 2 decimal places
    }
    return result;
}
  // Function to handle changes in the select element
  const handleCountChange = (event) => {
    const { value } = event.target;
    console.log('500', value);
    setSelectedCount(value); // Update the selectedCount state with the new value
    if (value === "500") {
        setPlanPricePremimum({ firstValue: 274.61 });
        setPlanPriceStandard({ firstValue: 13.73 });
        setPlanPriceBasic({ firstValue: 9.19 });
    } else if (value === "1500") {
        setPlanPricePremimum({ firstValue: 274.61 });
        setPlanPriceStandard({ firstValue: 31.04});
        setPlanPriceBasic({ firstValue: 17.91 });
    } else if (value === "2500") {
        setPlanPricePremimum({ firstValue: 274.61 });
        setPlanPriceStandard({ firstValue: 45.97});
        setPlanPriceBasic({ firstValue: 27.46});
    } else if (value === "5000") {
        setPlanPricePremimum({ firstValue: 274.61});
        setPlanPriceStandard({ firstValue: 69.25 });
        setPlanPriceBasic({ firstValue:45.97 });
    } else if (value === "10000") {
        setPlanPricePremimum({ firstValue: 274.61 });
        setPlanPriceStandard({ firstValue: 90.74 });
        setPlanPriceBasic({ firstValue: 69.25});
    } else if (value === "15000") {
        setPlanPricePremimum({ firstValue: 322.36 });
        setPlanPriceStandard({ firstValue: 145.66 });
        setPlanPriceBasic({ firstValue: 118.20});
    } else if (value === "20000") {
        setPlanPricePremimum({ firstValue: 370.12 });
        setPlanPriceStandard({ firstValue: 173.12 });
        setPlanPriceBasic({ firstValue: 145.66 });
    } else if (value === "25000") {
        setPlanPricePremimum({ firstValue: 411.91 });
        setPlanPriceStandard({ firstValue: 205.96});
        setPlanPriceBasic({ firstValue: 173.12 });
    } else if (value === "30000") {
        setPlanPricePremimum({ firstValue: 459.67 });
        setPlanPriceStandard({ firstValue: 232.82 });
        setPlanPriceBasic({ firstValue: 202.97 });
    } else if (value === "40000") {
        setPlanPricePremimum({ firstValue: 507.42 });
        setPlanPriceStandard({ firstValue: 250.73 });
        setPlanPriceBasic({ firstValue: 232.82 });
    } else if (value === "50000") {
        setPlanPricePremimum({ firstValue: 549.21 });
        setPlanPriceStandard({ firstValue: 274.61 });
        setPlanPriceBasic({ firstValue: 238.79 });
    } else if (value === "75000") {
        setPlanPricePremimum({ firstValue: 596.97});
        setPlanPriceStandard({ firstValue: 370.12 });
        setPlanPriceBasic({ firstValue: 0});
    } else if (value === "100000") {
        setPlanPricePremimum({ firstValue: 644.73 });
        setPlanPriceStandard({ firstValue: 459.67 });
        setPlanPriceBasic({ firstValue: 0});
    } else if (value === "130000") {
        setPlanPricePremimum({ firstValue: 274.61 });
        setPlanPriceStandard({ firstValue: 13.73 });
        setPlanPriceBasic({ firstValue: 0});
    } else if (value === "150000") {
        setPlanPricePremimum({ firstValue: 823.82 });
        setPlanPriceStandard({ firstValue: 0 });
        setPlanPriceBasic({ firstValue: 0 });
    } else if (value === "200000") {
        setPlanPricePremimum({ firstValue: 1008.88 });
        setPlanPriceStandard({ firstValue: 0 });
        setPlanPriceBasic({ firstValue: 0 });
    } else if (value === "250000") {
        setPlanPricePremimum({ firstValue: 0 });
        setPlanPriceStandard({ firstValue: 0});
        setPlanPriceBasic({ firstValue: 0 });
    }
    // Add more else if conditions as needed
};

  console.log('511',selectedCount)
    return (<div className='w-full pt-8'>
        
        <Container>
        <Grid container spacing={8}>
            <Grid item xs={12}>
           <div>
            <div className='flex item-center justify-center'>
                <img src='/connect_fav_icon.svg' className='h-16 w-16'/>
            </div>
           <Typography variant='h3' className='text-center'>Welcome to our email service!</Typography>
            <Typography variant='h6' className='text-center'>Select the plan that best fits your needs.</Typography>
            
            </div>
            </Grid>
            <Grid item xs={12}>
            <div className='flex items-center justify-center gap-x-4'>
            <div className='border py-2 px-4 rounded bg-[#263003]'>
                <label for="count" className='text-white'>How many contacts do you have?</label>

                <select name="count"
                 id="cars"
                 className='bg-[#95B632] py-2 w-[250px] text-center rounded outline-none'
                 value={selectedCount} 
                onChange={handleCountChange}>
                    {
                        totalContacts.map((count, index) => <option key={index} value={count} className='bg-white'>{formatedNumber(count)}</option>)
                    }
                </select>
            </div>
            
        </div>
            </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} className={classes.paper}>
                     <Typography variant='h4'>Premium</Typography>
                     {planPricePremimum?.firstValue!==0?
                     <>
                     <div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>${planPricePremimum.firstValue}</div>
                        <div className='text-4xl font-bold'>${divideAndRound(planPricePremimum.firstValue)}</div>
                        <div>/month for 12 months*</div>
                     </div>
                     <CommonButton>Buy</CommonButton> 
                     </>
                     :
                     <>
                     <div className='border p-2 rounded flex flex-col gap-y-2 bg-gray-200'>
                     <div>
                     Talk to Sales at 
                     </div>
                     <b>
                     +1 (999) 999-9999
                     </b>
                     <div>
                        <Divider/>
                    <div className='py-2 text-center'>
                    <Chip 
                     label="Contact Sales"
                    //   variant="outlined"
                        deleteIcon={<MdMail/>}
                        color='primary'
                        
                     />
                    </div>
                     </div>
                     </div>
                     {/* <Chip label="Contact limit exceeded" variant="outlined"/> */}
                     </> 
                     }
                     
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} className={classes.paper}>
                     <Typography variant='h4'>Standard</Typography>
                     {planPriceStandard.firstValue!==0?<>
                     < div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>${planPriceStandard.firstValue}</div>
                        <div className='text-4xl font-bold'>${divideAndRound(planPriceStandard.firstValue)}</div>
                        <div>/month for 12 months*</div>
                        </div>
                        <CommonButton>Buy</CommonButton>
                     </>:
                     <>
                     <div>
                        You've selected more contacts than this plan allows
                     </div>
                     <Chip label="Contact limit exceeded" variant="outlined"/>
                     </>
                     }
                     
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} className={classes.paper}>
                     <Typography variant='h4'>Basic</Typography>
                     {planPriceBasic.firstValue!==0?<>
                     <div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>${planPriceBasic.firstValue}</div>
                        <div className='text-4xl font-bold'>${divideAndRound(planPriceBasic.firstValue)}</div>
                        <div>/month for 12 months*</div>
                     </div>
                      <CommonButton>Buy</CommonButton>
                      </>
                     :
                    <>
                    <div>
                       You've selected more contacts than this plan allows
                    </div>
                    <Chip label="Contact limit exceeded" variant="outlined"/>
                    </> }
                    
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <div class="w-full overflow-x-auto">
                        <table class="min-w-full bg-white border border-gray-200">
                            <caption class="text-lg font-bold text-gray-800 mb-4">Key Plan Features</caption>
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">''</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Premimum</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Standard</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Basic</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Monthly Email Sends</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{formatedNumber(150000)}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{formatedNumber(6000)}</td>
                                    <td class="px-6 py-4 whitespace-nowrap">{formatedNumber(5000)}</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Custom Email Templates</td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </Grid>
                <Grid item xs={12}>
                    <div class="w-full overflow-x-auto">
                        <table class="min-w-full bg-white border border-gray-200">
                            <caption class="text-lg font-bold text-gray-800 mb-4">Admin</caption>
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">''</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Premimum</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Standard</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Basic</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Users</td>
                                    <td class="px-6 py-4 whitespace-nowrap">Unlimited</td>
                                    <td class="px-6 py-4 whitespace-nowrap">5 seats</td>
                                    <td class="px-6 py-4 whitespace-nowrap">3 seats</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Role-based Access</td>
                                    <td class="px-6 py-4 whitespace-nowrap">5 Roles</td>
                                    <td class="px-6 py-4 whitespace-nowrap">3 Roles</td>
                                    <td class="px-6 py-4 whitespace-nowrap">2 Roles</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </Grid>
                <Grid item xs={12}>
                    <div class="w-full overflow-x-auto">
                        <table class="min-w-full bg-white border border-gray-200">
                            <caption class="text-lg font-bold text-gray-800 mb-4">Reporting & Analytics</caption>
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">''</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Premimum</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Standard</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Basic</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Reporting & Analytics</td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                    <td class="px-6 py-4 whitespace-nowrap">Limited</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Download Reports</td>
                                    <td class="px-6 py-4 whitespace-nowrap"><FaCheck/></td>
                                    <td class="px-6 py-4 whitespace-nowrap">Limited</td>
                                    <td class="px-6 py-4 whitespace-nowrap"><MdClose/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </Grid>
                <Grid item xs={12}>
                    <div class="w-full overflow-x-auto">
                        <table class="min-w-full bg-white border border-gray-200">
                            <caption class="text-lg font-bold text-gray-800 mb-4">Services & Support</caption>
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">''</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Premimum</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Standard</th>
                                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Basic</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Custmer Support</td>
                                    <td class="px-6 py-4 whitespace-nowrap">24/7 Email & Chat Support</td>
                                    <td class="px-6 py-4 whitespace-nowrap">24/7 Email & Chat Support</td>
                                    <td class="px-6 py-4 whitespace-nowrap">24/7 Email & Chat Support</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap">Personalized Onboarding</td>
                                    <td class="px-6 py-4 whitespace-nowrap">4 Sessions</td>
                                    <td class="px-6 py-4 whitespace-nowrap">1 Session</td>
                                    <td class="px-6 py-4 whitespace-nowrap"><MdClose/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </Grid>
        </Grid>
        </Container>
    </div>)
}
export default EmailPlans;