import React, { useEffect, useState } from "react";

// import { spinner_state } from "../../redux/features/spinner-slice";
import { Avatar, Button, CssBaseline, Grid, IconButton, Paper, TextField, Typography, alpha, makeStyles } from "@material-ui/core";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { FcUnlock } from "react-icons/fc";
import { ImSpinner2 } from "react-icons/im";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { useParams } from 'react-router';
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";

import SaveButton from "../../components/Buttons/saveButton";
import PasswordField from "../../components/InputFields/TextFields/passwordField";
import TxtField from "../../components/InputFields/TextFields/TextField";
import Loader from "../../components/loader";
import constant_label from "../../constants";
import { ResetPasswordApiCall } from "../../services/userService";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3vh'
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: alpha('#263003', 0.5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function SetPassword() {
  const { tenantName } = useParams();
  const classes = useStyles();
  const [params, setParams] = useState(null);
  const [resetPassword, setResetPassword] = useState("");
  const [resetConfirmPassword, setresetConfirmPassword] = useState("");
  // const [spinner, setSpinner] = useState(false);
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  //   console.log("spinner State", spinner);
  const [domain, setDoamin] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  function validatePassword(password) {
    const isPasswordValid = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/;
    return isPasswordValid.test(password);
  }

  function handleBlur(event) {
    const allowedSpecialCharacters = '(! @ # $ % ^ & * ~ - )'
    // console.log('in blur block 72',pswdVal?.newPassword)
    const newPassword = event.target.value;
    if (!pswdVal?.newPassword) {
      // setErrorMessage("New Password is required.");
    } else if (!validatePassword(pswdVal?.newPassword)) {
      setErrorMessage(`Password must be at least 8 characters long and contain at least one special character ${allowedSpecialCharacters}`);
    } else {
      setErrorMessage("");
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const code = queryParams.get("code");
    setParams(code);
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const resetEmail = location.search.slice(12, location.search.indexOf("&"));
  console.log('check tenant name from url', tenantName)
  useEffect(() => {
    localStorage.setItem('domain', tenantName)
    dispatch({
      type: 'SAVE_TENANT',
      data: {
        domain:tenantName
      },
    });
  }, [])
  const handleSubmit = (e) => {
    console.log('check again',pswdVal)
    e.preventDefault();
    console.log(window.location.hostname)
    try {
      if (!pswdVal?.newPassword || !pswdVal?.ConfirmNewPassword) {
        // console.log('please fill password')
        NotificationManager.error('Please fill all mandatory fields marked as(*)');
      }
      else {
        if ((pswdVal?.newPassword.trim()) === (pswdVal?.ConfirmNewPassword.trim())) {
          const resetPassword=pswdVal?.newPassword.trim()
          const resetConfirmPassword=pswdVal?.ConfirmNewPassword.trim()
          dispatch(
            ResetPasswordApiCall(
              {
                resetEmail: resetEmail,
                resetPassword: resetPassword,
                resetConfirmPassword:resetConfirmPassword,
                code: params,
              },
              (data) => {
                console.log(data)
                if (data?.status === 200 || data === 'Successfully reset password.') {

                  swal({
                    title: "Great",
                    text: "Your password has been changed successfully",
                    icon: "success",
                    dangerMode: false,
                  });
                  history.push("/login");
                } else {

                  swal({
                    title: "Operation Failed",
                    text: "Please try again",
                    icon: "error",
                    dangerMode: true,
                  });
                  console.log('Please try again')

                }
              },(err)=>{
                console.log('err 151',err)
                if(err==='One-time link expired')
                  {
                    swal({
                      title: "link Expired",
                      // text: "Please try again",
                      icon: "error",
                      dangerMode: true,
                    });
                  }
              }
            )
          );
        }
        else {
          // console.log('password not match')
          NotificationManager.error('New Password and Confirm New Password does not match')
      
        }
      }
    } catch (err) {
      // setSpinner(false);
      //   dispatch(spinner_state(false));
      console.log(err);
    }
  };
  const [pswdVal, setPswddVal] = useState({
    newPassword: '',
    ConfirmNewPassword: '',
    newPswd: false,
    cnfNewPswd: false,
  })
  console.log('check value',pswdVal)
  const handleChange = (prop) => (event) => {
    setPswddVal({ ...pswdVal, [prop]: event.target.value });
  };
  const handleShowPassword = (prop) => () => {
    setPswddVal({ ...pswdVal, [prop]: !pswdVal[prop] });
  };
  console.log('check final tenant', localStorage.getItem('domain'))
  return (
    <>
      {spinner ? <Loader /> : null}
      <div className="container-fluid" component="main">
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper elevation={3}>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <FcUnlock size={30} />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {constant_label?.lbl_txt_reset_password}
                </Typography>
                <form className={`${classes.form} mt-4`} noValidate onSubmit={handleSubmit}>
                  <TxtField
                    margin='normal'
                    variant='outlined'
                    label="Email"
                    type="text"
                    name="email"
                    disabled={true}
                    value={resetEmail}
                  />
                  {/* <TxtField
                    margin='normal'
                    variant='outlined'
                    label="New Password"
                    type="password"
                    name="resetPassword"
                    onChange={handlePasswordChange}
                    onBlur={handleBlur}
                  /> */}
                  <PasswordField
                    required={true}
                    margin='normal'
                    label="New Password"
                    type={pswdVal.newPswd ? "text" : "password"}
                    name="newPassword"
                    onChange={handleChange('newPassword')}
                    onClick={handleShowPassword('newPswd')}
                    onBlur={handleBlur}
                    onMouseDown={handleMouseDownPassword}
                  />
                  {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
                  <PasswordField
                    required={true}
                    margin='normal'
                    name="ConfirmPassword"
                    label="Confirm New Password"
                    type={pswdVal.cnfNewPswd ? "text" : "password"}
                    onChange={handleChange('ConfirmNewPassword')}
                    onClick={handleShowPassword('cnfNewPswd')}
                    onMouseDown={handleMouseDownPassword}
                  />
                  <SaveButton
                    onClick={handleSubmit}
                  />
                </form>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        </Grid>
      </div>
    </>
  );
}

export default SetPassword;
