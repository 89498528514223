// import { get } from "lodash";
// import ActionConstants from "../config/AppConstants";
const innitialData = {
  isMessagePing:false,
  token: '',
  notificationsCount:0,
  smsPingFrom:'',
  isMissedCallPing:false,
};
export default function template(state = innitialData, action) {
  switch (action.type) {
    case 'ADD_FIREBASE_TOKEN': {
      return {
        ...state,
        token: action.token,
      };
    }
    case 'FIREBASE_PING':{
      return{
        ...state,
        notificationsCount:action.notificationsCount,
      }
    }
    case 'IS_MSG_PING':{
      return{
        ...state,
        isMessagePing:action.isMessagePing,
        smsPingFrom:action.smsPingFrom
      }
    }
    case 'IS_MISSED_CALL_PING':{
      return{
        ...state,
        isMissedCallPing:action.isMissedCallPing,
    }
  }
    default:
      return { ...state };
  }
}
