// import axios from 'axios';

import { NotificationManager } from 'react-notifications';

import ActionConstants from '../config/AppConstants';
import { serviceCallAuth, serviceCallAuthWithToken } from './serviceCallAuth';

export const checkLogin = ({ data, headers }, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Account/Login',
      method: 'post',
      data: data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error['response']['data']);
        // NotificationManager.error('Invalid Credentials !');
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };
};

export const registerUser = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Account/Register',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const ResetPasswordApiCall = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Account/ResetPassword',
      method: 'post',
      data: data,
      headers: {
        // Authorization: "",
        'Content-Type': 'application/json-patch+json',
      },
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data.message);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getUserList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Account/GetUser',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
          dispatch({
            data: response.data.aspNetUsers,
            type: ActionConstants.GET_USERS_LIST,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const getRoles = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallAuth({
      url: '/api/Account/GetRole',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};
export const getAgency = (data,callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: "/api/Agency/GetAgency",
      method: "get",
      data: data,
    })
      .then((response) => {
        console.log(response,"agency11")
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
    if(response.status!==204)
       {
         callback && callback(response?.data);
        }
      }
      )
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // console.log(error, "error");
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const addEditAgency = (data, callback, errorCallBack, method) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Agency/PostPutAgency',
      method: 'post',
      data: data,
    })
      .then((response) => {
       dispatch({ type: 'SPINNER_STATE', isLoading: false });    
        callback && callback(response);
        // dispatch(add_agency(data));
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
        // toast.error(error.response || 'Something went wrong');
      });
  };
};
export const getAgencyDocs = (data, callback, errorCallBack, finallyCB) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: "/api/Agency/GetAgencyDocument",
      method: "get",
      data,
    })
      .then((response) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        console.log(response,"getAgrncyDocResponse")
        callback && callback(response.data?.tblAgencyDocument);
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
      })
      .finally(() => {
        finallyCB && finallyCB();
      });
  };
};
export const deleteAgencyDocs = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Agency/DeleteAgencyDocument',
      method: 'delete',
      data
    })
      .then((response) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });

        // toast.success(response?.message || 'Deleted Agency successfully');

        callback && callback(response.data);
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });

        console.log(error, 'error');
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
}
export const deleteAgency = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Agency/DeleteAgency',
      method: 'delete',
      data
    })
      .then((response) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // NotificationManager.success('Deleted Agency successfully')
        callback && callback(response.data);
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        console.log(error, 'error');
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
}
export const logout = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({
      type: ActionConstants.UNAUTH_USER,
    });
  };
};

export const updateUser = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuthWithToken({
      url: '/api/Account/UpdateUser',
      method: 'put',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};

export const deleteUser = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuthWithToken({
      url: '/api/Account/DeleteUser',
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};

export const getLoggedInUserDetail = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallAuthWithToken({
      url: '/api/UserInfo/GetLoggedInUserDetail',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        if (response) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};

export const changePassword = ({ data, headers }, callback, errorCallBack) => {

  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuthWithToken({
      url: '/api/Account/ChangePassword',
      method: 'post',
      data: data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response) {
          callback && callback(response.data.message);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};
export const forgetPasswordApiCall = (data, callback, errorCallback) => {
  return async (dispatch) => {
  dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: "/api/Account/ForgotPassword",
      method: "post",
      data,
    }).then((response) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
      // console.log("response", response);
      callback && callback(response);
    }).catch((err)=>{
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
      // console.log('err',err)
      errorCallback && errorCallback(err.response);
    })
  };
};
export const activateUser = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    // console.log(data)
    serviceCallAuth({
      url: "/api/Account/ActivateUser",
      method: "put",
      data: data,
    })
      .then((response) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // console.log(response?.data)
        if(data?.isDeleted_ysn){
          // NotificationManager.success(response?.data || "User is Active now");
        }else{
          // NotificationManager.success(response?.data || "User is InActive now");
          // console.log('User is InActive now')

        }        
        callback && callback(response);
    
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // console.log(error)
        errorCallBack && errorCallBack(error.response);
        NotificationManager.error(error.response || "Something went wrong");
      });
  };
};
export const switchUser = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: "/api/Account/SwitchUser ",
      method: "post",
      data: data,
    })
      .then((response) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response);
      })
      .catch((error) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
      });
  };
};
export const getAllActiveNumbers = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Account/getAllActiveNumbers',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};