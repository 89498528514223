import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MdClose } from 'react-icons/md';

const DocumentPreview = ({ open, onClose, url,fileName}) => {
  const [imageStyle, setImageStyle] = useState({ width: '100%', height: '100vh', border: 'none' });
  const handleImageLoad = (event) => {
    const { naturalWidth } = event.target;
    if (naturalWidth > 100) {
      setImageStyle({ width: '25px' });
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className={`flex items-center ${fileName?'justify-between':'justify-end'}`}>
      {fileName}
        <MdClose onClick={onClose}/>
      </DialogTitle>
      <DialogContent>
        {(fileName!==null && fileName.includes('.pdf'))?<iframe src={url} title="Document Preview"
         style={imageStyle}
          />
          :
          <img src={url} style={imageStyle}/>
          }
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default DocumentPreview;
