import { combineReducers } from 'redux';

// import { resetStore } from '../config/userActions';
// import variableListModalState from './variableListModalState';
// import {RESET_STORE} from '../config/AppConstants';
import constants from '../config/AppConstants';
import bulkSmsEditor from './bulkSmsEditorState';
import callModal from './callModal';
import clients from './clients';
import customer from './customer';
import customerEmailPhone from './customerEmailPhone';
import dialerState from './dialerState';
import dialPlanData from './dialPlanTable';
import drawerState from './drawerState';
import exitPrompt from './exitPrompt';
import firebase from './firebase';
import login from './login';
import mailModalState from './mailModalState';
import mailState from './mailState';
import notificationModal from './notificationDetailModalState';
import notificationPopupState from './notificationPopupState';
import notifications from './notifications';
import getTokens from './refreshTokenApiCall'
import messageLogs from './report';
import roles from './roles';
import signatureModal from './signatureModalState';
import signature from './signatureState';
import smsCampaign from './smsCampaignState';
import smsConversations from './smsConversations';
import smsModalState from './smsModalState';
import smsPushNotification from './smsPushNotificaton';
import smsState from './smsState';
import spinnerState from './spinnerState';
import template from './template';
import templateModalState from './templateModalState';
import editorContent from './textEditorState';
import twilio from './twilio';
import user from './user';
import userDir from './userDirectory'
import voiceCallLogs from './voiceCallLog';
const appReducer = combineReducers({
  bulkSmsEditor,
  callModal,
  clients,
  customer,
  customerEmailPhone,
  dialPlanData,
  dialerState,
  drawerState,
  exitPrompt,
  firebase,
  getTokens,
  login,
  mailModalState,
  mailState,
  messageLogs,
  notificationPopupState,
  notifications,
  notificationModal,
  roles,
  smsCampaign,
  signature,
  signatureModal,
  smsConversations,
  smsModalState,
  smsState,
  spinnerState,
  smsPushNotification,
  template,
  editorContent,
  templateModalState,
  twilio,
  user,
  userDir,
  voiceCallLogs,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
// export default combineReducers({
//   login,
//   user,
//   roles,
//   clients,
//   template,
//   customer,
//   messageLogs,
//   voiceCallLogs,
//   drawerState,
//   mailState,
//   mailModalState,
//   templateModalState,
//   dialerState,
//   smsModalState,
//   smsState,
//   exitPrompt,
//   // variableListModalState
// })
