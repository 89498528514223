import { useEffect, useRef, useState } from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import axios from "axios";
import { FaFilePdf, FaUpload, FaUser, FaUserEdit } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { MdClose, MdOutlineExitToApp } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";

import CancelButton from "../../components/Buttons/cancelButton";
import CommonButton from "../../components/Buttons/commonButton";
import DefaultButton from "../../components/Buttons/defaultButton";
import SaveButton from "../../components/Buttons/saveButton";
import TxtField from "../../components/InputFields/TextFields/TextField";
import Loader from "../../components/loader";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import constant_label from "../../constants";
import { addCustomer, getCustomerComments, getCustomerDoc, getCustomerRef, getCustomersList, getListOfUsers, getStateByCountryRef } from "../../services/customer/customerService";
import FormValidator from "../../validations/FormValidator";

const useStyles = (theme) => ({
  //   formControl: {
  //     margin: theme.spacing(1),
  //     minWidth: 120,
  //     width: '100%',
  //   },
});
function UploadDocuments(props) {
  const classes = useStyles();
  const { customerId } = useParams()
  const history = useHistory()
  const initFormData = {
    customerID_ids: "",
    customer_chr: "",
    documentName: "",
  };
  const [formData, setFormData] = useState(initFormData);
  const [formDataError, setFormDataError] = useState({
    documentName: false,
  });
  const inputFile = useRef(null);
  const { domain, userId, token, tenant } = useSelector((state) => state?.login?.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const { onClickAdd, selectedCustomer, reloadList } = props;
  const initData = selectedCustomer;
  const [customerData, setCustomerData] = useState(initData)
  const [isPromptOpen, setIsPromptOpen] = useState(false)
  const [ownerList, setOwnerList] = useState([])
  const [SelectedAccountOwner, setSelectedAccountOwner] = useState([])
  const [commentList, setCommentList] = useState([])
  const [selectedFilesArray, setSelectedFilesArray] = useState([]);
  const [customerDoc,setCustomerDoc]=useState([])
  const pathname = window.location.pathname
  const dispatch = useDispatch()

  console.log('check res', customerData)
  const handlePrompt = () => {
    console.log('cancel called')
    setIsPromptOpen(!isPromptOpen)
  }

  useEffect(() => {
    console.log('check action', customerId)
    if(customerId)
    {dispatch(
      getCustomerDoc({CustomerID_ids:customerId }, (res) => {
        console.log('docs11',res)
        setCustomerDoc(res)
      })
    )};
  }, [])

  const handleClose = () => {
    onClickAdd();
    // onClose();
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const onClickFile = () => {
    if (
      formData?.documentName === null ||
      formData?.documentName === undefined ||
      formData?.documentName === ""
    ) {
      setFormDataError((state) => ({
        ...state,
        documentName: true,
      }));
      return false;
    } else {
      setFormDataError((state) => ({
        ...state,
        documentName: false,
      }));
      return true;
    }
  };
  const handlePdfSelection = () => {
    console.log('pdf upload')
    const isDocumentName_Exist = onClickFile();

    if (isDocumentName_Exist) {
      setFormDataError((state) => ({
        ...state,
        documentName: false,
      }));
      inputFile.current.click();
    } else {
      setFormDataError((state) => ({
        ...state,
        documentName: true,
      }));
    }
  }
  const onChangeFile = (e) => {
    const fileInput = e.target;
    const file = fileInput?.files?.[0];
    console.log('check file', file)
    if (!file) {
    } else if (file && file?.type === "application/pdf") {
      const newSelectedFilesArray = [
        ...selectedFilesArray,
        {
          customerDocumentName_chr: `${formData?.documentName}.pdf`,
          file: file,
        },
      ];
      setSelectedFilesArray(newSelectedFilesArray);
      // dispatch(set_agency_documents(newSelectedFilesArray));
      setFormData((state) => ({
        ...state,
        documentName: "",
      }));
    } else {
      // toast.error("you can only select PDF file");
      NotificationManager.error("you can only select PDF file")
    }
    fileInput.value=''
  };
  console.log('setSelectedFilesArray', selectedFilesArray)
  const onChange = (e) => {
    setFormData((state) => ({
      ...state,
      documentName: e.target.value
    }))
  }

  const deleteFile = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...selectedFilesArray];

    // Remove the file at the specified index
    updatedFiles.splice(index, 1);

    // Update the state with the new array
    setSelectedFilesArray(updatedFiles);
  };
  const handleBack = () => {

    console.log('check pathname', pathname.includes('/new-lead/add'))
    if (pathname.includes('/customer')) {
      history.push(`/${tenant}/customer/${customerId}`)
    }
    else if (pathname.includes('leads/new-lead')) {
      history.push(`/${tenant}/leads/new-lead/${customerId}`)
    } else if (pathname.includes('/leads/unassigned')) {
      history.push(`/${tenant}/leads/unassigned/${customerId}`)
    } else if (pathname.includes('/leads/in-progress')) {
      history.push(`/${tenant}/leads/in-progress/${customerId}`)
    }
    else if (pathname.includes('/leads/processed')) {
      history.push(`/${tenant}/leads/processed/${customerId}`)
    } else if (pathname.includes('/leads/good-leads')) {
      history.push(`/${tenant}/leads/good-leads/${customerId}`)
    }
    else if (pathname.includes('/leads/junk-leads')) {
      history.push(`/${tenant}/leads/junk-leads/${customerId}`)
    }
    else if (pathname.includes('/deal/site-visit')) {
      history.push(`/${tenant}/deal/site-visit/${customerId}`)
    }
    else if (pathname.includes('/deal/quotation')) {
      history.push(`/${tenant}/deal/quotation/${customerId}`)
    } else if (pathname.includes('/deal/negotiation')) {
      history.push(`/${tenant}/deal/negotiation/${customerId}`)
    }
    else if (pathname.includes('/deal/re-negotiation')) {
      history.push(`/${tenant}/deal/re-negotiation/${customerId}`)
    }
    else if (pathname.includes('/deal/win')) {
      history.push(`/${tenant}/deal/win/${customerId}`)
    }
    else if (pathname.includes('/deal/lose')) {
      history.push(`/${tenant}/deal/lose/${customerId}`)
    }
    else if (pathname.includes('/deal/documentation')) {
      history.push(`/${tenant}/deal/documentation/${customerId}`)
    }
  }
  const onClickDoneExit = () => {
    console.log('check pathname', pathname)
    if (pathname.includes('/customer')) {
      history.push(`/${tenant}/customers`)
    }
    else if (pathname.includes('leads/new-lead')) {
      history.push(`/${tenant}/leads/new-lead`)
    } else if (pathname.includes('/leads/unassigned')) {
      history.push(`/${tenant}/leads/unassigned`)
    } else if (pathname.includes('/leads/in-progress')) {
      history.push(`/${tenant}/leads/in-progress`)
    }
    else if (pathname.includes('/leads/processed')) {
      history.push(`/${tenant}/leads/processed`)
    } else if (pathname.includes('/leads/good-leads')) {
      history.push(`/${tenant}/leads/good-leads`)
    }
    else if (pathname.includes('/leads/junk-leads')) {
      history.push(`/${tenant}/leads/junk-leads`)
    }
    else if (pathname.includes('/deal/site-visit')) {
      history.push(`/${tenant}/deal/site-visit`)
    }
    else if (pathname.includes('/deal/quotation')) {
      history.push(`/${tenant}/deal/quotation`)
    } else if (pathname.includes('/deal/negotiation')) {
      history.push(`/${tenant}/deal/negotiation`)
    }
    else if (pathname.includes('/deal/re-negotiation')) {
      history.push(`/${tenant}/deal/re-negotiation`)
    }
    else if (pathname.includes('/deal/win')) {
      history.push(`/${tenant}/deal/win`)
    }
    else if (pathname.includes('/deal/documentation')) {
      history.push(`/${tenant}/deal/documentation`)
    }
  }
  const handleDocUpload = (e) => {
    e.preventDefault();
    if (selectedFilesArray.length) {
      try {
        const postCustomerDocuments_URL = `${window.config?.API_URL}/api/Customer/PostPutCustomerDocument`;
        const config = {
          headers: {
            Authorization: "Bearer" + " " + token,
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "x-domain-name": domain,
          },
        };
        const CustomerDocumentsFormData = new FormData();
        CustomerDocumentsFormData.append("Calling_UserID_chr", userId)
        CustomerDocumentsFormData.append("CustomerID_ids", customerId)

        // for (const x in agency_documents_list) {
        for (const x in selectedFilesArray) {
          CustomerDocumentsFormData.append(
            `CustomerDocuments[${x}].customerDocumentName_chr`,
            // agency_documents_list[x]?.customerDocumentName_chr
            selectedFilesArray[x]?.customerDocumentName_chr
          );
          CustomerDocumentsFormData.append(
            `CustomerDocuments[${x}].file`,
            // agency_documents_list[x]?.file
            selectedFilesArray[x]?.file
          );
        }
        CustomerDocumentsFormData.append("Calling_UserID_chr", userId);
        dispatch({ type: 'SPINNER_STATE', isLoading: true });
        axios
          .post(postCustomerDocuments_URL, CustomerDocumentsFormData, config)
          .then((data) => {
            if (data?.status === 200) {
              dispatch({ type: 'SPINNER_STATE', isLoading: false });
              NotificationManager.success(customerId ? "document has been successfully uploaded !" : null)
              setSelectedFilesArray([])
              onClickDoneExit()
            }
          })
          .catch((error) => {
            console.log(error)
            dispatch({ type: 'SPINNER_STATE', isLoading: false });
          });

      } catch (err) { }
    } else {
      NotificationManager.error(constant_label?.err_msg_no_file)
    }
  }
  console.log('fdata', formData)
  return <>
    <div>
      {spinner ? (

        <Loader />

      ) : null}
      <div className="m-2 px-2 bg-white h-[100vh]">
        {(isPromptOpen && <ConfirmationDialog
          onHandleModel={handlePrompt}
          isOpenDialog={isPromptOpen}
          action={() => onClickAdd()}
          title={'Discard'}
          content={'Do you want to discard ?'}
        />)}

        <form noValidate
        // onSubmit={onSubmitForm}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-8 gap-4 py-2" >
            <div className="lg:col-span-3">
            <Typography variant='h6' className="flex items-center text-lg" >
              {customerId === 'add' ? <div className="flex items-center gap-x-1">
                <FaUser size={18} />{constant_label?.lbl_txt_add_customer}
              </div> :
                <div className="flex items-center gap-x-">
                  <FaUserEdit size={21} />Upload Customer Documents/{customerId}
                </div>
              }
            </Typography>
            </div>
            {/* <div className="flex items-center gap-x-2"> */}
            <div className="lg:col-start-5">
            <DefaultButton onClick={handleBack}>Back</DefaultButton>
            </div>
              <div className="lg:col-span-2">
              <CommonButton onClick={handleDocUpload}>
                Upload and Close
                <IoCheckmarkDoneCircleSharp size={20} />
              </CommonButton>
              </div>
              <CommonButton onClick={onClickDoneExit}>
                Exit
                <MdOutlineExitToApp size={20}/>
                </CommonButton>
            {/* </div> */}
          </div>
        </form>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                 <div className='mt-8'>
                <p className='text-red-500 text-xs mt-1 flex items-center gap-x-2'>
                  <HiOutlineLightBulb size={18} />
                  You can also upload Multiple files, just click again
                  <FaFilePdf color='#263003' />
                  icon
                </p>
                <p className='text-red-500 text-xs mt-1 flex items-center gap-x-2'>
                  <HiOutlineLightBulb size={18} />
                  You can upload PDF files only
                </p>
                {customerDoc.length ? <div className="flex flex-col gap-y-2 m-4 rounded p-1 bg-gray-100">
                  <p className="border-b border-black">Uploaded Documents</p>
                  <div className="flex items-center gap-x-4 py-2">
                  {customerDoc.map((doc,index)=><p>{`${index+1}. ${doc?.customerDocumentName_chr}`}</p>)}

                  </div>
                  </div>:null}
                <div className='border-dotted border-2 border-[#263003] rounded-lg h-[200px] mx-4 my-8 flex items-center justify-center'>
                  <div className='flex flex-col gap-y-4'>
                    <div className='flex flex-col items-center justify-center p-1'>
                      <p className="mb-4 text-xs text-[#263003]">
                        Click on file icon to Add Document
                      </p>
                      <Paper elevation={2} className='hover:bg-gray-200 cursor-pointer' onClick={handlePdfSelection}>
                        <FaFilePdf size={36} color='#263003' className='m-1' />
                      </Paper>
                    </div>
                    <TxtField
                      name="documentName"
                      label='Document Name'
                      variant='outlined'
                      value={formData?.documentName}
                      onChange={onChange}
                      error={formDataError?.documentName}
                      helperText={
                        formDataError?.documentName
                          ? "Document Name Required, Please fill this field first"
                          : null
                      }
                    />
                  </div>
                </div>
                <div className='flex items-center gap-x-2 p-1'>
                  {selectedFilesArray?.map((res, index) => (
                    <p key={index} className="flex items-center gap-x-2 text-base text-[#263003] px-2 py-0.5 rounded-2xl bg-[#F0F0F0]">
                      {index + 1 + "." + " " + res?.customerDocumentName_chr}  <MdClose size={18} color='white' className="cursor-pointer bg-[#263003] rounded" onClick={() => deleteFile(index)} />
                    </p>
                  ))}

                </div>
                  <input
                  onChange={onChangeFile}
                  type="file"
                  accept=".pdf"
                  name="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  multiple={false}
                />
              </div>
              {/* </Paper> */}

            </Grid>

          </Grid>
        </div>
      </div>
    </div>
  </>
}
export default UploadDocuments