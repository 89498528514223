import { NotificationManager } from 'react-notifications';

import AppConstants from '../../config/AppConstants';
import { userIdToken } from '../../userIdToken';
import { serviceCallWithToken } from '../serviceCall';
import { serviceCallAuth } from '../tenantService';


export const saveTenantDetails = (data, headers, callback, errorCallBack, method) => {
  // console.log('dnd data',data)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth({
      url: '/api/Tenant/PostPutTenantDetail',
      method: method || 'post',
      data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        console.log('dnd data',response.status)
        callback && callback(response && response.data);
        // response.status===200? NotificationManager.success('File uploaded succesfully !'): NotificationManager.error('File upload failed !');
      })
      .catch((error) => {
        console.log('dnd err',error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback(error?.response?.data);
        errorCallBack && errorCallBack(error?.response?.data);
        // error?.response?.data?.message==="File contains Incorrect Data. Please correct it and try again."?
        // NotificationManager.error(error?.response?.data?.message):
        // NotificationManager.error('File upload failed');
      });
  };
};
export const getTenantDetails = (data, callback, errorCallBack) => {
  return async (dispatch) => {
  dispatch({ type: 'SPINNER_STATE', isLoading: true });
  serviceCallAuth({
      url: '/api/Tenant/GetTenantDetails',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getTenantDetailByTenantKey = (data, callback, errorCallBack) => {
  return async (dispatch) => {
  dispatch({ type: 'SPINNER_STATE', isLoading: true });
  serviceCallAuth({
      url: '/api/Tenant/GetTenantDetailByTenantKey',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getCountryRef = (data, callback, errorCallBack) => {
  return async (dispatch) => {
  dispatch({ type: 'SPINNER_STATE', isLoading: true });
  serviceCallAuth({
      url: '/api/Tenant/GetCountryRef',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getStateByCountryRef = (data, callback, errorCallBack) => {
  return async (dispatch) => {
  dispatch({ type: 'SPINNER_STATE', isLoading: true });
  serviceCallAuth({
      url: '/api/Tenant/GetStateByCountryRef',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};





