import { useEffect } from 'react';

import { Button, Typography, Container, makeStyles } from '@material-ui/core';
import { FaExclamationCircle } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { useSelector } from 'react-redux';

import CommonButton from '../../components/Buttons/commonButton';
import useHistoryWithBase from '../../hooks/useHistory';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    textAlign: 'center',
  },
  icon: {
    fontSize: '4rem',
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
}));
function NotFound(){
    const classes = useStyles();
    const {tenant}=useSelector((state)=>state.login?.loginData)
    const {customHistory}=useHistoryWithBase()
    console.log('hey')
    useEffect(()=>{
        customHistory(tenant,'/not-found')
    },[])
  const handleClick=()=>{
    customHistory(tenant,'/dashboard')
  }
  return (
    <Container className={classes.root}>
      <FaExclamationCircle className={classes.icon} />
      <Typography variant="h4" gutterBottom>
        Oops! Page not found
      </Typography>
      <Typography variant="body1" paragraph>
        The page you are looking for might be in another castle.
      </Typography>
      <CommonButton to="/" variant="contained" color="primary" onClick={handleClick}>
      <MdHome size={24}/>
        Go to dashboard
      </CommonButton>
    </Container>
  );
}
export default NotFound