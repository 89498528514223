import { useEffect, useState } from "react"

import { Chip } from "@material-ui/core";
import { GoArrowRight } from "react-icons/go";
import { MdAdd, MdArrowCircleLeft, MdClose } from "react-icons/md"
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import SaveButton from "../../components/Buttons/saveButton";
import TxtField from "../../components/InputFields/TextFields/TextField"
import Loader from "../../components/loader";
import { getDialPlans,
     getRingGroups,
     getDialPlanOption,
     postDialPlanOption,
     deleteDialPlanOption,
     postDialPlanNode,
     getDialPlanNode,
     deleteDialPlanNode,
     getAutoAttendantAction,
     postAutoAttendantAction,
     deleteAutoAttendantAction
    } from "../../services/twilio/twilioPhoneService";
import { getAllActiveNumbers } from "../../services/userService";
import { formatPhoneNumber } from "../../utils";


const NodeComponent=(props)=>{
    const dispatch=useDispatch()
    const {
        indexVal,
        selectedOpt,
        nodesToDisplay,
        setNodesToDisplay,
        node,
        ringGrp,
        userNmb,
        cfLength,
        updateNode,
        parentNodeId,
        selectedDialPlanDataFromApi,
    }=props
  console.log('node 155',node)
  const actionParameters =node.actionParameters_chr!==null && node.actionParameters_chr!==undefined && node.actionParameters_chr!=="" && JSON.parse(node.actionParameters_chr);
    const idVal = actionParameters.Id;
    console.log('idVal',idVal)
    const [idValue,setidValue]=useState(idVal)

    const updateActionParameters = (parentId, childId, newActionParameters) => {
        setNodesToDisplay(prevData => 
            prevData.map(item => {
                if (item.nodeId_lng === parentId) {
                    return {
                        ...item,
                        childNodes: item.childNodes.map(child => {
                            if (child.nodeID_ids === childId) {
                                return {
                                    ...child,
                                    actionParameters_chr: newActionParameters
                                };
                            }
                            return child;
                        })
                    };
                }
                return item;
            })
        );
    };
  const handleChangeRingGrpOrPhn=(e,i)=>{
    const {value}=e.target
    console.log(value,'currentnode',node)
    const actParm={Id:value}
    const payload={
        dialPlanID:node.dialPlanID_lng,
        nodeID:node.nodeID_ids,
        parentNodeID:node.parentNodeID_lng,
        actionTypeID:node.actionTypeID_lng,
        description_chr:'',
        actionParameters: JSON.stringify(actParm)

    }
    updateNode(payload)
    const cloneOptNodes=[...nodesToDisplay]
    const indexVal=selectedOpt.optIndex
    console.log('check ids',parentNodeId,node.nodeID_ids)
    updateActionParameters(parentNodeId,node.nodeID_ids,JSON.stringify(actParm))
    // cloneOptNodes[indexVal]={...cloneOptNodes[indexVal],childNodes:[...cloneOptNodes[indexVal].childNodes,cloneOptNodes[indexVal].childNodes[]]}
  console.log('cloneOptNodes',selectedOpt,cloneOptNodes)
    //    setNodesToDisplay(cloneOptNodes) 

  }


  const deleteChildNode = (parentId, childId) => {
    setNodesToDisplay(prevData => 
        prevData.map(item => {
            if (item.nodeId_lng === parentId) {
                return {
                    ...item,
                    childNodes: item.childNodes.filter(child => child.nodeID_ids !== childId)
                };
            }
            return item;
        })
    );
};
  const handleRemove=(parentId, childId)=>{
    deleteChildNode(parentId, childId)
    dispatch(deleteDialPlanNode({NodeID_ids:childId},(res)=>{
        console.log(res)
        selectedDialPlanDataFromApi()
    }))
  }
    return <div className="p-2 flex flex-col gap-2">
        <div>{node.actionType_chr}</div>
        {node.actionType_chr==='RingGroup'?
    <select 
    name="ringGroupId_lng"
     value={idVal} 
     onChange={(e)=>handleChangeRingGrpOrPhn(e,indexVal)} 
     className="w-full border rounded p-2"
     >
        <option value=''>Select</option>
   {ringGrp.map((data)=> <option value={data.id}>{data.groupName_chr} {data.extension}</option>)}
    
  </select>
  :node.actionType_chr==='SimpleDial'?
  <select 
  name="forwardNumber"
   value={idVal} 
   onChange={(e)=>handleChangeRingGrpOrPhn(e,indexVal)} 
   className="w-full border rounded p-2"
   >
    <option value=''>Select</option>
   {userNmb.map((data)=> <option value={data.id}>{data.assignedUsers} {data.extension}</option>)}
    
  </select>:node.actionType_chr==='Voicemail'?<div className="text-xs border rounded p-2">leave a message after beep</div>:null
 
  }
  {
    indexVal==cfLength-1?
    <div className="flex items-center justify-end">
    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-2 py-1 rounded"
    onClick={()=>handleRemove(parentNodeId,node.nodeID_ids)}
    >Delete</button>
  </div>:null}
    </div>

}
function AddEditDialPlan(){
    const {userId,tenant}=useSelector((state)=>state.login.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {dialPlanActions}=useSelector((state)=>state.dialPlanData)

    const [dialPlanDetail,setDialPlanDetail]=useState({})
    const [dialOptions,setDialOptions]=useState([{
        option:"",
        callFlow:[{callForwardTo:'Ring Group',id:'',optionNumber_lng:'',dialPlanId_lng:'',ringGroupId_lng:'',forwardNumber:''}]
    }])
    const [selectedOpt,setSelectedOpt]=useState({})
    const [ringGrp,setRingGrp]=useState([])
    const [userNmb,setUserNmb]=useState([])
    const [autoattendentMsg,setAutoattendentMsg]=useState('')
    const [autoAttendantAction,setAutoAttendantAction]=useState([])
    const [allDialPlanNodes,setAllDialPlanNodes]=useState([])
    const [nodesToDisplay,setNodesToDisplay]=useState([])
    const dispatch=useDispatch()
    const history=useHistory()
    const { dialPlanId } = useParams();
    console.log('')
    const addOption=()=>{
        const cloneOptions=[...dialOptions]
        setDialOptions((state)=>[...state,{option:"",callFlow:[{callForwardTo:'Ring Group',id:'',optionNumber_lng:'',dialPlanId_lng:dialPlanId,ringGroupId_lng:'',forwardNumber:'',}]}])
        const cloneNodesOpt=[...nodesToDisplay]
        setNodesToDisplay((state)=>[...state,{digit_chr:'',childNodes:[]}])
             const payload={
                    nodeId_lng:nodesToDisplay[0].nodeId_lng,
                    digit_chr:'',
                    description_chr:"",

                }
                     dispatch(postAutoAttendantAction(payload,(res)=>{
                    console.log(res)
                    selectedDialPlanDataFromApi()
                }))
    }
    console.log('dialOptions',dialOptions,selectedOpt,selectedOpt?.optValue)
    const removeOption=(i,id)=>{
       console.log(i)
       const cloneNodeOptions=[...nodesToDisplay]
       cloneNodeOptions.splice(i,1)
          setNodesToDisplay(cloneNodeOptions)
          setSelectedOpt({})
          console.log('opt id',id,id!==undefined , id!=="")
          if(id!==undefined && id!=="")
       {   
           dispatch(deleteAutoAttendantAction({AutoAttendantID:id},(res)=>{
           console.log(res)
           selectedDialPlanDataFromApi()
          }))
       }

    }
    const handleOptChange = (e, i) => {
        const optVal = e.target.value.replace(/[^0-9]/g, '');
        const cloneNodeOptions = [...nodesToDisplay];
    
        const opts = cloneNodeOptions.map((data) => data.digit_chr);
        console.log('cloneopt', opts);
    
        if (optVal === '') {
            // Handle the case where the user cleared the input
            cloneNodeOptions[i].digit_chr = optVal;
            setNodesToDisplay(cloneNodeOptions);
        } else if (opts.includes(optVal.toString())) {
            NotificationManager.error('Option already exists.');
        } else {
            cloneNodeOptions[i].digit_chr = optVal;
            setNodesToDisplay(cloneNodeOptions);
        }
    }
    
    const handleOptClick=(i,val,node)=>{
        console.log(i,val,node)
        setSelectedOpt({optIndex:i,optValue:val,callFlow:node,node:node})
    }
    console.log(selectedOpt)
    const selectedDialPlanDataFromApi=()=>{
        dispatch(getDialPlanNode({DialPlanID:dialPlanId},(res)=>{
            console.log('getDialPlanNode',res)
            const allNodes=res.tblDialPlanNode
            const parentNode=allNodes.filter((node)=>node.parentNodeID_lng===null)
            const parentNodeId=parentNode[0].nodeID_ids
            console.log(allNodes,parentNode)
            setAllDialPlanNodes(allNodes)
            const payloadForAutoAttendant={
                NodeId_lng:parentNodeId
            }
            dispatch(getAutoAttendantAction(payloadForAutoAttendant,(res)=>{
                console.log('getDialPlanNode',res)
                const tblAutoAttendantAction=res.tblAutoAttendantAction
                setAutoAttendantAction(tblAutoAttendantAction)
            }))
        }))
    }
    useEffect(()=>{
        const payload={
            Page_Index_int:1,
            Page_Size_int:20,
            // Return_All_Rows_ysn:true,
            Sort_Expression_Delim_mem:'groupName_chr asc'

        }
        dispatch(getRingGroups(payload,(res)=>{
            console.log(res)
            setRingGrp(res?.tblRingGroups)
        }))
        dispatch(getAllActiveNumbers({},(res)=>{
            console.log(res)
            setUserNmb(res?.tblActiveNumbers)
        }))
        selectedDialPlanDataFromApi()    
    },[])
    useEffect(()=>{
            if(dialPlanId){
                dispatch(getDialPlans({DialplanId:dialPlanId},(res)=>{
                    setDialPlanDetail(res?.tblDialPlan[0])
                }))            }
    },[dialPlanId])
    const handleSavePlan = async (e) => {
        e.preventDefault();
        console.log('final dialOptions', nodesToDisplay);
    
        for (const node of nodesToDisplay) {
            if (node.parentNodeID_lng !== null && node.id_ids !== undefined && node.childNodes[0].actionType_chr === 'RingGroup') {
                let actionParameters = null;
    
                // Try to parse actionParameters_chr
                try {
                    actionParameters = node.actionParameters_chr 
                        ? JSON.parse(node.actionParameters_chr) 
                        : null;
                } catch (error) {
                    console.error('Invalid JSON in actionParameters_chr', error);
                    continue; // Skip this iteration if JSON parsing fails
                }
    
                const idVal = actionParameters ? parseInt(actionParameters.Id) : null;
                const indexVal = ringGrp.findIndex((data) => data.id === idVal);
    
                if (indexVal === -1 || idVal === null) {
                    console.error('Invalid index or idVal', { idVal, indexVal });
                    continue; // Skip this iteration if the index or idVal is invalid
                }
    
                const payload = {
                    id_ids: node.id_ids,
                    nodeId_lng: node.nodeId_lng,
                    digit_chr: node.digit_chr,
                    description_chr: ringGrp[indexVal].groupName_chr,
                    groupOrPhoneId_lng: ringGrp[indexVal].id,
                    toNodeId_lng: node.toNodeId_lng
                };
    
                console.log('onsave', payload);
    
                try {
                    await dispatch(postAutoAttendantAction(payload, (res) => {
                        console.log('postAutoAttendantAction', res);
                    }));
                } catch (error) {
                    console.error('Error in dispatching action', error);
                    // Decide how to handle the error here. Maybe break, continue, or throw.
                }
            } else if (node.parentNodeID_lng !== null && node.id_ids !== undefined && node.childNodes[0].actionType_chr === "SimpleDial") {
                let actionParameters = null;
    
                // Try to parse actionParameters_chr
                try {
                    actionParameters = node.actionParameters_chr 
                        ? JSON.parse(node.actionParameters_chr) 
                        : null;
                } catch (error) {
                    console.error('Invalid JSON in actionParameters_chr', error);
                    continue; // Skip this iteration if JSON parsing fails
                }
    
                const idVal = actionParameters ? parseInt(actionParameters.Id) : null;
                const indexVal = userNmb.findIndex((data) => data.id === idVal);
    
                if (indexVal === -1 || idVal === null) {
                    console.error('Invalid index or idVal', { idVal, indexVal });
                    continue; // Skip this iteration if the index or idVal is invalid
                }
    
                const payload = {
                    id_ids: node.id_ids,
                    nodeId_lng: node.nodeId_lng,
                    digit_chr: node.digit_chr,
                    description_chr: userNmb[indexVal].assignedUsers,
                    groupOrPhoneId_lng: userNmb[indexVal].id,
                    toNodeId_lng: node.toNodeId_lng
                };
    
                console.log('onsave', payload);
    
                try {
                    await dispatch(postAutoAttendantAction(payload, (res) => {
                        console.log('postAutoAttendantAction', res);
                    }));
                } catch (error) {
                    console.error('Error in dispatching action', error);
                    // Decide how to handle the error here. Maybe break, continue, or throw.
                }
            }
            else {
                console.log('Node skipped due to unmet conditions', node);
            }
        }
        history.push(`/${tenant}/dial-plans`)
    };
    
    const changeDescription=(e)=>{
        const {value}=e.target
        setAutoattendentMsg(value)
    }
    const handleOutSideClick=()=>{
        const payload={
            id_ids:nodesToDisplay[0].id_ids,
            nodeId_lng:nodesToDisplay[0].nodeId_lng,
            digit_chr:nodesToDisplay[0].digit_chr,
            description_chr:autoattendentMsg,
            groupOrPhoneId_lng:nodesToDisplay[0].groupOrPhoneId_lng,
            toNodeId_lng:nodesToDisplay[0].toNodeId_lng,

        }
        console.log('auto',payload,nodesToDisplay[0].description_chr)
        if(autoattendentMsg.trim())
        {dispatch(postAutoAttendantAction(payload,(res)=>{
            console.log(res)
            selectedDialPlanDataFromApi()
        }))}
    }
    const handleAddNode=(e,data)=>{
       console.log('selectedOpt',selectedOpt,data)
       const cloneOptNodes=[...nodesToDisplay]
       console.log(cloneOptNodes,'selectedOpt',selectedOpt,data)

       const indexVal=selectedOpt.optIndex
       cloneOptNodes[indexVal]={...cloneOptNodes[indexVal],childNodes:[...cloneOptNodes[indexVal].childNodes,{actionTypeID_lng:data.actionID_ids,actionType_chr:data.actionType_chr}]}
     console.log('cloneOptNodes',cloneOptNodes,selectedOpt.node,selectedOpt.node.length)
          setNodesToDisplay(cloneOptNodes) 
         const selectedOptChildNodes=cloneOptNodes[selectedOpt.optIndex].childNodes
         console.log('cloneOptNodes',selectedOptChildNodes,selectedOptChildNodes.length>0,selectedOptChildNodes.length)
         console.log('cloneOptNodes111',selectedOptChildNodes.length-1,selectedOptChildNodes[selectedOptChildNodes.length-1],selectedOptChildNodes[selectedOptChildNodes.length-1].nodeID_ids)
       const payload={
            dialPlanID:parseInt(dialPlanId),
            parentNodeID: selectedOptChildNodes.length===1?cloneOptNodes[0].nodeId_lng: selectedOptChildNodes[selectedOptChildNodes.length-2].nodeID_ids,
            actionTypeID:data.actionID_ids,
            actionParameters:''

        }
        console.log('cloneOptNodes',payload,cloneOptNodes[selectedOpt.optIndex],cloneOptNodes)
        dispatch(postDialPlanNode(payload,(res)=>{
            console.log(res)
            // selectedDialPlanDataFromApi()
            console.log('check data before node add',selectedOpt.node,selectedOpt.node.length)
            if(selectedOptChildNodes.length===1){
            
               console.log('check data before node add 222')

                const id=(res.split(':')[1]).trim()
                const payload={
                    id_ids:nodesToDisplay[selectedOpt.optIndex].id_ids,
                    nodeId_lng:nodesToDisplay[0].nodeId_lng,
                    digit_chr:selectedOpt.optValue,
                    description_chr:data.actionDescription_chr,
                    // groupOrPhoneId_lng:'',
                    toNodeId_lng:parseInt(id),

                }
                console.log('auto',payload,)
                dispatch(postAutoAttendantAction(payload,(res)=>{
                    console.log(res)
                    selectedDialPlanDataFromApi()
                }))
            }else{
                selectedDialPlanDataFromApi()
            }
        }))

    }
  

    


    const findPaths = (data, startNode) => {
        const paths = [];
    
        const buildPaths = (currentPath) => {
            const currentNode = currentPath[currentPath.length - 1];
            const children = data.filter(node => node.parentNodeID_lng === currentNode.nodeID_ids);
    
            if (children.length === 0) {
                paths.push(currentPath);
                return;
            }
    
            children.forEach(child => {
                buildPaths([...currentPath, child]);
            });
        };
    
        buildPaths([startNode]);
    
        return paths;
    };
    useEffect(() => {
        // Filter out nodes that are starting nodes but should not be included in the paths
        const startNodes = allDialPlanNodes.filter(node => node.parentNodeID_lng === null && node.actionTypeID_lng !== 1);
        const startNodesChildren = allDialPlanNodes.filter(node => node.parentNodeID_lng === null && node.actionTypeID_lng === 1)
            .flatMap(node => allDialPlanNodes.filter(child => child.parentNodeID_lng === node.nodeID_ids));

        let allPaths = [];

        // Process the valid starting nodes
        startNodes.forEach(startNode => {
            allPaths = [...allPaths, ...findPaths(allDialPlanNodes, startNode)];
        });

        // Process the children of the filtered-out nodes
        startNodesChildren.forEach(startNode => {
            allPaths = [...allPaths, ...findPaths(allDialPlanNodes, startNode)];
        });
        console.log('allPaths',allPaths)
        // setPaths(allPaths);
        const autoAttendent=[...autoAttendantAction]
        autoAttendent.forEach(attendant => {
            attendant.childNodes = allPaths.find(path => path[0].nodeID_ids === attendant.toNodeId_lng) || [];
          });
          
          console.log('autoAttendent',autoAttendent);
          setNodesToDisplay(autoAttendent)
    }, [autoAttendantAction]);
    console.log('nodesToDisplay',nodesToDisplay);
    const updateNode=(payload)=>{
        console.log('updateNode',payload)
        dispatch(postDialPlanNode(payload,(res)=>{
            console.log(res)
            selectedDialPlanDataFromApi()
        }))
    }
    useEffect(()=>{
        if(nodesToDisplay.length)
       { const msg=nodesToDisplay[0].description_chr
       setAutoattendentMsg(msg)}
    },[nodesToDisplay])
    return <>
    {spinner?<Loader/>:null}
    <div className="w-full h-[100dvh] min-h-screen bg-white p-4 flex flex-col gap-4">
        <div className="text-lg flex items-center justify-between">
            Dial Plan : {dialPlanDetail?.dialplanName_chr}
           <div className="w-[10vw]">
           <SaveButton onClick={handleSavePlan}/>
           </div>
        </div>
        <div className="grid grid-cols-12 gap-4 ">
        <div className="col-span-3 p-4  flex flex-col gap-4 border rounded">
            <TxtField  size='small' variant='outlined' label='Phone Number' value={formatPhoneNumber(dialPlanDetail?.phoneNumber)} InputLabelProps={{shrink:true}}/>


{nodesToDisplay.length ? <div className="p-2 border rounded-lg flex flex-col gap-2">
               { nodesToDisplay.map((node,index)=>node.digit_chr!==null ?<div key={index} className="flex items-center justify-between">
                <button onClick={()=>removeOption(index,node.id_ids)}><MdClose size={24}/></button>
                <div className="w-16">
                <input value={node.digit_chr} className="w-[50px] p-1 border text-center rounded" maxLength={1} onChange={(e)=>handleOptChange(e,index)}/>
                 
                </div>
                </div>:null)}
                
               
            </div>: null}

            {nodesToDisplay.length<10?<div className="p-2 flex items-center justify-center gap-2 cursor-pointer hover:bg-gray-100" onClick={addOption}>
                    <MdAdd size={18}/>
                    Add Option
                    </div>:null}
        </div>
        <div className="col-span-9  p-4 flex flex-col gap-4 border  rounded border h-[88vh] overflow-y-auto">
            <div className="">
            <TxtField variant='outlined' size='small' label='Autoattendent Message' onChange={changeDescription} value={autoattendentMsg} onBlur={handleOutSideClick}/>
            </div>
            
           

           {
            nodesToDisplay.length?<div className="flex items-center gap-4">
              {
            nodesToDisplay.map((node,index)=>(node.digit_chr!=="" && node.digit_chr!==null)?
            <div
            key={index} 
            className={`w-16  flex items-center justify-center border p-2 rounded-lg cursor-pointer hover:bg-gray-200 ${(selectedOpt?.optIndex==index)?"bg-black text-white hover:bg-black hover:text-white border-black":null}`} 
            onClick={()=>handleOptClick(index,node.digit_chr,node.childNodes)}        
            >
            { node.digit_chr}
            </div>
            :null)

              }
            </div>:null
           }
          {(selectedOpt?.optValue!==null && selectedOpt?.optValue!=="" && selectedOpt?.optValue!==undefined)? <div className="text-lg">Call Flow for Option : {selectedOpt?.optValue}</div>:null}

          {
           (selectedOpt?.optValue!==null && selectedOpt?.optValue!=="" && selectedOpt?.optValue!==undefined) &&
           nodesToDisplay.length ?<>
           <div className="flex items-center gap-2">
                {
                dialPlanActions.map((data,index)=>data.actionType_chr!=='AutoAttendant' ? <Chip  key={index} label={data.actionType_chr}  onClick={(e)=>handleAddNode(e,data)}/>:null)
                    }
            </div>
           </>
           :null
           } 

           {
           (selectedOpt.optIndex!==undefined && selectedOpt.digit_chr!==" " && selectedOpt.digit_chr!==null)?<div className="py-2 grid grid-cols-4 gap-4">
           
            {
                 
                 nodesToDisplay?.filter((data,index)=>parseInt(data.digit_chr)===parseInt(selectedOpt.optValue)).map((data)=>data.childNodes?.map((node,index)=>
                    <>
               {(index!=undefined && index!=0)?<div className="flex items-center justify-center"><GoArrowRight size={52}/></div>:null}
               <div  key={index} className="border min-h-[100px] rounded ">
               <NodeComponent  key={index} indexVal={index} selectedOpt={selectedOpt} node={node} ringGrp={ringGrp} userNmb={userNmb} updateNode={updateNode} nodesToDisplay={nodesToDisplay} setNodesToDisplay={setNodesToDisplay} cfLength={data.childNodes.length} parentNodeId={data.nodeId_lng} selectedDialPlanDataFromApi={selectedDialPlanDataFromApi}/>
               </div>
              </>
                ))    
            }
           </div>:null
           } 
        </div>
        </div>
        
            
    </div>
    </>
}
export default AddEditDialPlan