import React, { useState } from 'react';

import {TextField,MenuItem,Grid,Paper,Container,Typography,Chip,Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaCheck } from 'react-icons/fa';
import { MdCall, MdClose, MdEmail, MdMail, MdSms } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CommonButton from '../../components/Buttons/commonButton';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      width:'100%',
      // height:'300px',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
    //   border:'1px solid #95B632',
      borderRadius:'10px',
      gap:'20px',
      textWrap:'wrap',
      '&:hover': {
        transform: 'scale(1.05)', /* Adjust the scale value as needed */
        transition: 'transform 0.3s ease', /* Adjust the transition duration and timing function as needed */
      }
    },
    
  }));
function Subscription(){
  const classes = useStyles(); 
  const {tenant}=useSelector((state)=>state.login.loginData)
  const history=useHistory()
 const OnClickEmailPlans=()=>{
  history.push(`/${tenant}/plans/email`)
 }
    return (<div className='w-full pt-8'>
        
        <Container>
        <Grid container spacing={8}>
            <Grid item xs={12}>
           <div>
            <div className='flex item-center justify-center'>
                <img src='/connect_fav_icon.svg' className='h-16 w-16'/>
            </div>
           <Typography variant='h3' className='text-center'>Our Services!</Typography>
            <Typography variant='h6' className='text-center'>Select the plan that best fits your needs.</Typography>
            
            </div>
            </Grid>
            <Grid item xs={12}>
            <div className='w-full bg-white rounded shadow-lg rounded-t'>
             <div className='font-bold text-lg text-center bg-black text-white p-4 rounded-t'>Current Plan</div>
             <div className='p-2 grid grid-cols-2 gap-4'>
             <div>Emailing</div>
             <div className='bg-gray-100 p-2'>
                <div>Basic</div>
                <div>Amount : $ 100</div>
                <div>Jun 1, 2024  -  Jun 1, 2025</div>
             </div>
             <div>Calling</div>
             <div className='bg-gray-100 p-2'>
                <div>Basic</div>
                <div>Amount : $ 100</div>
                <div>Jun 1, 2024  -  Jun 1, 2025</div>
             </div>
             <div>SMS</div>
             <div className='bg-gray-100 p-2'>
                <div>Basic</div>
                <div>Amount : $ 100</div>
                <div>Jun 1, 2024  -  Jun 1, 2025</div>
             </div>
             </div>
            </div>
            </Grid>
                <Grid item xs={4}>
                <Paper elevation={3} className={classes.paper}>
                     <div>
                     <MdEmail size={56} />
                     </div>
                     <Typography variant='h4'>Emailing</Typography>
                     
                     <div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>$200</div>
                        <div className='text-4xl font-bold'>$100</div>
                        <div>/month for 12 months*</div>
                     </div>
                      <CommonButton>Buy</CommonButton>
                      <div className='hover:undeline hover:text-blue-500 cursor-pointer' onClick={OnClickEmailPlans}>See all plans</div>             
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                <Paper elevation={3} className={classes.paper}>
                    <div>
                     <MdCall size={56} />
                     </div>
                     <Typography variant='h4'>Calling</Typography>
                   
                     <div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>$200</div>
                        <div className='text-4xl font-bold'>$100</div>
                        <div>/month for 12 months*</div>
                     </div>
                      <CommonButton>Buy</CommonButton>
                      <div className='hover:undeline hover:text-blue-500 cursor-pointer'>See all plans</div>                               
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} className={classes.paper}>
                    <div>
                     <MdSms size={56} />
                     </div>
                     <Typography variant='h4'>SMS</Typography>
                   
                     <div className='flex flex-col justify-center gap-y-2'>
                        <p>Starts at</p>
                        <div className='line-through text-lg'>$200</div>
                        <div className='text-4xl font-bold'>$100</div>
                        <div>/month for 12 months*</div>
                     </div>
                      <CommonButton>Buy</CommonButton>
                      <div className='hover:undeline hover:text-blue-500 cursor-pointer'>See all plans</div>             
                    </Paper>
                </Grid>
               
        </Grid>
        </Container>
    </div>)
}
export default Subscription;