import React from 'react';

import Button from '@material-ui/core/Button';

import constant_label from '../../constants';
import '../../../src/DialSightStyles.css'
const YesButton = ({style, onClick }) => {
  return (
    <button fullWidth type="submit" onClick={onClick} className='yes_button' style={style}>
     {constant_label?.btn_yes}
    </button>
  );
};

export default YesButton;
