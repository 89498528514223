import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function PasswordField(props){
    const {required,disabled,margin,size,variant,name,label,type,placeholder,onChange,onBlur,autoComplete,value,onClick,onMouseDown,error,helperText}=props
return <TextField
        fullWidth
        disabled={disabled ? disabled : false}
        size={size?size:'small'}
        required={required}
        margin={margin?margin:'none'} 
        name={name}
        label={label}
        type={type}
        variant={variant?variant:"outlined"}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        helperText={helperText}
        InputProps={
            {endAdornment: <InputAdornment position="end">
               <IconButton
                 aria-label="toggle password visibility"
                 onClick={onClick}
                 onMouseDown={onMouseDown}
                 edge="end"
               >
                 {type!=='password' ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
               </IconButton>
             </InputAdornment>}
           }/>
        
}
export default PasswordField;