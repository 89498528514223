import React, { useCallback } from 'react';

import debounce from 'lodash/debounce';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import {
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useExportData } from 'react-table-plugins';
import { useSticky } from 'react-table-sticky';
import { utils, writeFile } from 'xlsx';

import './styles.css';
import { SortDownIcon, SortIcon, SortUpIcon } from '../../assets/icons';
import '../../../src/DialSightStyles.css'
// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || '');
  }, 200);

  return (
    <span className="font-barlow ">
      <span className="hidden md:inline-block">Search: </span>
      <input
        value={value || ''}
        className="ml-2 rounded-lg px-4 py-2 border-2 bg-gray-200 text-black border-gray-200 focus:outline-none focus:border-gray-400"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : 'unknown';

  return (
    <span
      className={`
            px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm
            ${status.startsWith('active') ? 'bg-green-100 text-green-800' : null}
            ${status.startsWith('inactive') ? 'bg-yellow-100 text-yellow-800' : null}
            ${status.startsWith('offline') ? 'bg-red-100 text-red-800' : null}            
            `}
    >
      {status}
    </span>
  );
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      name={id}
      id={id}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="px-4 py-3 border-2 border-gray-400"
    >
      <option value="">All</option>
      {options?.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  const header = columns?.map((c) => c.exportValue);
  const compatibleData = data?.map((row) => {
    const obj = {};
    header?.forEach((col, index) => {
      obj[col] = row[index];
    });
    return obj;
  });

  const wb = utils.book_new();
  const ws1 = utils.json_to_sheet(compatibleData, {
    header,
  });
  utils.book_append_sheet(wb, ws1, 'React Table Data');
  writeFile(wb, `${fileName}.xlsx`);

  return false;
}

function Table(props) {

  const defaultColumn = React.useMemo(
    () => ({
      width: 200,
      // height:300
    }),
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    exportData,

    state, // new
    preGlobalFilteredRows, // new
    setGlobalFilter, // new

    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      getExportFileBlob,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        hiddenColumns: props?.hiddenColumns?.length || [],
        pageSize: 20

        // filters: [
        //   {
        //     id: "status",
        //     value: "Active",
        //   },
        // ],
      },
      manualPagination: true,
      manualSortBy: true,

      pageCount: props.pageCount,
    },
    useFlexLayout,
    useSticky,

    useFilters,
    useGlobalFilter, // new
    useSortBy,
    // useExportData,
    usePagination
    /*
        First, we're passing the useFilter as an additional argument to the useTable hook. 
        Note that the order of useFilter and useGlobalFilter matters. 
        In our case, useFilter is applied first, so the globalFilter text search is only applied on whatever rows are
        selected by useFilter. This performs better, especially for larger amounts of data, because it reduces the number of
        rows that have to be included in the text search
        */
  );
  // React.useEffect(() => {
  //   gotoPage(props.page)
  // }, [props.Where_GeneralCriteria_Delim_mem])
  const { globalSearchQuery, SenderEmail, range, Status, isTickClicked, direction, sender, stage, durationTo, durationFrom ,tableName} = props
  React.useEffect(() => {
    // debouncedFunction(props.globalSearchQuery)
    setGlobalFilter(globalSearchQuery)
    gotoPage(0)

  }, [globalSearchQuery, SenderEmail, range, Status, isTickClicked === true, direction, sender, stage, durationTo, durationFrom])
  React.useEffect(() => {
    props.onTableStateChange({ pageIndex, pageSize, globalFilter, sortBy });
  }, [props.onTableStateChange, pageIndex, pageSize, sortBy]);
  // const debouncedFunction=useCallback( debounce((value)=>{
  //   setGlobalFilter(value)
  //   gotoPage(0)
  // },1),[])
  React.useEffect(() => {
    props.onTableStateChange({ pageIndex, pageSize, globalFilter, sortBy })
  }, [globalFilter])
  React.useEffect(() => {
    if (props.hiddenColumns) {
      setHiddenColumns(props.hiddenColumns);
    }
  }, [props.hiddenColumns, setHiddenColumns, props.columns]);
  // Use the state and functions returned from useTable to build your UI

  // Render the UI for your table
  // console.log('check page rows', page, props)
  return (
    <>
    {tableName!=='activeNumberList' &&
    <>
      <div className="pagination flex items-center  w-full font-barlow ">
          <div className="flex items-center  w-full gap-x-2 justify-end mx-4">
          <span className="hidden md:flex">Rows per page:</span>
          <select
            className="px-2 py-2 flex justify-center"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50].map((pageSize) => (
              <option className="" key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span>
            {pageIndex + 1}-{pageOptions.length? pageOptions.length:1} of {pageOptions.length?pageOptions.length:1}
          </span>{' '}
        </div>
        <div className="buttons flex bg-white ">
          {/* <button
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <HiChevronDoubleLeft />
            </button>{" "} */}
          <button
            className="relative inline-flex items-center px-2 py-2 border-2  border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <HiChevronLeft />
          </button>{' '}
          <button
            className="relative inline-flex items-center px-2 py-2 border-2 border-l-0 border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <HiChevronRight />
          </button>{' '}
        </div>
      </div>
      <div className="flex items-center gap-x-2 max-w-fit overflow ">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">{props.children}</div>
        </div>

        {headerGroups?.map((headerGroup) =>
          headerGroup?.headers?.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                <label htmlFor={column.id}>{column.render('Header')}: </label>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      </>}

      <div className={` ${tableName==='activeNumberList' ?null:'mt-3'} flex flex-col justify-center items-center w-full  drop-shadow-lg`}>
        <div className=" overflow-x-auto w-full ">
          <table
            {...getTableProps()}
            className="rounded-none  w-full  bg-gray-100 "
          >
            <thead className="w-full ">
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="">
                  {headerGroup?.headers?.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="w-full group tracking-wider  px-3 py-2 text-center text-xs font-medium font-barlow tableHeaderTheme"
                    >
                      <div className="flex justify-between items-center capitalize text-[14px]  py-2 ">
                        {column.render('Header')}
                        {!column.disableSortBy && (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDownIcon className="text-gray-400" />
                              ) : (
                                <SortUpIcon />
                              )
                            ) : (
                              <SortIcon className="opacity-0 group-hover:opacity-100 " />
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white border-b border-gray-200 shadow ">
              {page?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-100">
                    {row?.cells?.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="tableCellTheme px-3 py-2 text-left text-black text-sm font-barlow-semi-condensed whitespace-nowrap  truncate items-center border border-white rounded rounded-md "
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {tableName==='activeNumberList' &&
    <>
      <div className="pagination flex items-center  w-full font-barlow ">
          <div className="flex items-center  w-full gap-x-2 justify-end mx-4">
          <span className="hidden md:flex">Rows per page:</span>
          <select
            className="px-2 py-2 flex justify-center"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50].map((pageSize) => (
              <option className="" key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span>
            {pageIndex + 1}-{pageOptions.length} of {pageOptions.length}
          </span>{' '}
        </div>
        <div className="buttons flex bg-white ">
          {/* <button
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <HiChevronDoubleLeft />
            </button>{" "} */}
          <button
            className="relative inline-flex items-center px-2 py-2 border-2  border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <HiChevronLeft />
          </button>{' '}
          <button
            className="relative inline-flex items-center px-2 py-2 border-2 border-l-0 border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <HiChevronRight />
          </button>{' '}
        </div>
      </div>
      <div className="flex items-center gap-x-2 max-w-fit overflow ">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">{props.children}</div>
        </div>

        {headerGroups?.map((headerGroup) =>
          headerGroup?.headers?.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                <label htmlFor={column.id}>{column.render('Header')}: </label>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      </>}
    </>
  );
}

Table.defaultProps = {
  data: [],
};

export default Table;
