import React, { useEffect, useState } from 'react';

import { IconButton, InputAdornment, LinearProgress, Menu, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import { BsFillMicMuteFill, BsMicFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa"
import { IoMdBackspace } from 'react-icons/io';
import { IoArrowBackOutline } from "react-icons/io5";
import { MdAddCall, MdCall, MdCallEnd, MdDialpad, MdOutlineKeyboardDoubleArrowRight, MdPhonePaused } from 'react-icons/md';
import { SlCallOut } from 'react-icons/sl';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import MuiDropDown from '../../components/InputFields/DropDown/autoComplete';
import TxtField from '../../components/InputFields/TextFields/TextField';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { holdCall, unHoldCall } from '../../services/call/callServices';
import { getCustomerRef } from '../../services/customer/customerService';
import { callTime, formatPhoneNumber } from '../../utils';
// import './index.css'; 
const CallDialer = (props) => {
  const {token,device,callDeviceIdentity,callInstance,isCallConnected,isDialpadOpen,dialedNumber,isOutgoingConnected,callTimer,isMuted}=useSelector((state)=>state.twilio)
  const {phNumber,toggleDialer2,setToggleDialer2,setPhNumber}=props
    const dispatch=useDispatch()
    const [countryList, setCountryList] = useState([
      {
        countryID_ids: 1,
        countryName_chr: "United States",
        countryCode_chr: "US",
        countryPhoneCode_chr: "1",
        sortOrder_lng: 1,
        isActive_ysn: true,
        lastUpdatedByUserID_chr: "Vivek",
        lastUpdatedDateTime_dtm: "2021-08-28T02:01:32.927",
        row_Version_Binary_bin: "AAAAAAAAB9Y=",
        isDeleted_ysn: false
    }
    ]);
    const [countryCode,setCountryCode]=useState('+1')
    const [selectedValue,setSelectedValue]=useState({
      countryID_ids: 1,
      countryName_chr: "United States",
      countryCode_chr: "US",
      countryPhoneCode_chr: "1",
      sortOrder_lng: 1,
      isActive_ysn: true,
      lastUpdatedByUserID_chr: "Vivek",
      lastUpdatedDateTime_dtm: "2021-08-28T02:01:32.927",
      row_Version_Binary_bin: "AAAAAAAAB9Y=",
      isDeleted_ysn: false
  })
    const {userId,twilioNumber,authorized}=useSelector((state)=>state.login.loginData)
  const grantedPermissions = useSelector((state) => state?.grantedPermissions?.permissions);
  const reduxToken=useSelector((state)=>state.login?.loginData?.token)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callExt,setCallExt]=useState('')
  // const [device, setDevice] = useState();
  // const [token, setToken] = useState('');
  // const [callingDeviceIdentity,setCallingDeviceIdentity]=useState(null)
  const [conn, setConn] = useState()
  // const [callInstance,setCallInstance]=useState(null)
  // const [isCallConnected,setIsCallConnected]=useState(false)
  // const [isDialpadOpen,setIsDialpad]=useState(false)
  // const [isMuted,setIsMuted]=useState(false)
  const [callingTime,setCallingTime]=useState(parseInt(callTimer))
  // const [isOutgoingConnected,setIsOutGoingConnected]=useState(false)
  const [isCallOnHold,setIsCallOnHold]=useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const transferTypesList = [
    { typeID: 1, type: 'Warm Transfer', icon: <MdOutlineKeyboardDoubleArrowRight /> },
    { typeID: 2, type: 'Cold Transfer', icon: <SlCallOut /> },
]

  const handleKeyPress = (key) => {
    if(isCallConnected)
   { 
    callInstance.sendDigits(`w${key}`)
    setCallExt(prevNumber => prevNumber + key)
    
  }else{
    
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber + key);
  }
  };
// useEffect(()=>{
//   if(isCallConnected){
//     callInstance.sendDigits(`w${callExt}`)
//   }
  
// },[callExt])
  const handleBackspace = () => {
    if(isCallConnected){
    setCallExt(prevPhoneNumber => prevPhoneNumber.slice(0, -1))
    }
    else
   { 
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber.slice(0, -1));
  }
  };

  const handleClear = () => {
    setPhoneNumber('');
  };

  const handleCall = async() => {
    // Implement your call functionality here
    console.log('Calling:', countryCode,phoneNumber);
    
    if (phoneNumber.length < 10) {
      NotificationManager.error('Please enter a valid number');
    }else
  { 
    // setIsOutGoingConnected(true)
    dispatch({ type: 'IS_OUTGOING_CONNECTED', payload: { isOutgoingConnected: true } })

     const call = await device.connect({
      params: {
        To:(countryCode + phoneNumber),
           From:twilioNumber,
           Record:'record',
           callDeviceIdentity,

      },
    });
    // setCallInstance(call);
    dispatch({ type: 'CALL_INSTANCE', payload: { callInstance: call } })
    dispatch({type:'DIALED_NUMBER',payload:{dialedNumber:(countryCode + phoneNumber)}})

   
      // setIsCallConnected(!isCallConnected)
  }
  };
//   useEffect(()=>{
//     if(dialedNumber?.length)
//     {
//       dispatch({ type: 'IS_CALL_CONNECTED', payload: { isCallConnected: true } })
// }else{
//   dispatch({ type: 'IS_CALL_CONNECTED', payload: { isCallConnected: false } })

// }
//   },[])
  const handleCallMute=()=>{
    // setIsMuted(!isMuted)
  dispatch({type:'IS_MUTED',payload:{isMuted:!isMuted}})
  }
  useEffect(()=>{
    console.log('testing 78',callInstance)
    if(isCallConnected)
{
   if(isMuted){
  callInstance?.mute(true)
  // dispatch({type:'IS_MUTED',payload:{isMuted:true}})
 }else{
  callInstance?.mute(false)
  // dispatch({type:'IS_MUTED',payload:{isMuted:false}})

 }
}
  },[isMuted])
  useEffect(() => {
    console.log('testing 78', callInstance);
    if (isCallConnected && callInstance) { // Ensure callInstance exists
        if (isMuted && typeof callInstance.mute === 'function') { // Check if callInstance.mute is a function
            callInstance.mute(true);
            // dispatch({type:'IS_MUTED',payload:{isMuted:true}})
        } else if (!isMuted && typeof callInstance.mute === 'function') { // Check if callInstance.mute is a function
            callInstance.mute(false);
            // dispatch({type:'IS_MUTED',payload:{isMuted:false}})
        } else {
            console.error('callInstance.mute is not a function');
        }
    }
}, [isMuted, isCallConnected, callInstance]);
  const handleCallDisconnect=()=>{
    // const map = new Map();
    if (callInstance) {
      if(typeof callInstance.disconnect==='function')
      {
        callInstance.disconnect();
      // setIsCallConnected(!isCallConnected)
      setPhNumber('')
      setToggleDialer2(false)
      }
      setToggleDialer2(false)
      dispatch({type:'IS_DIALPAD_OPEN',payload:{isDialpadOpen:false}})
      // setCallInstance(null);
  }
  dispatch({type:'IS_CALL_CONNECTED',payload:{isCallConnected:false}})
      dispatch({type:'DIALED_NUMBER',payload:{dialedNumber:''}})
      dispatch({type:'IS_MUTED',payload:{isMuted:false}})
      dispatch({ type: 'CALLING_TIME', payload: { callTimer: 0 } })
}
const handleChange=(e)=>{
    console.log('chch',e.currentTarget.value)
    const onlyNums = e.target.value.replace(/[^0-9*#]/g, '');
    setPhoneNumber(onlyNums.slice(0,10));
}
const handleChangeExt=(e)=>{
  console.log('chch',e.currentTarget.value)
    const onlyNums = e.target.value.replace(/[^0-9*#]/g, '');
    setCallExt(onlyNums);
    callInstance.sendDigits(`w${onlyNums}`)
}
useEffect(() => {
    const data = {
      Calling_UserID_chr: userId,
    };
    dispatch(
      getCustomerRef(
        data,
        async (
          { refCountry } //this is a callback
        ) => {
          console.log('refCountry',refCountry)
           const newCountrylist=refCountry.slice(1)
          setCountryList(newCountrylist)
          if(countryCode==='+1'){
            setSelectedValue(newCountrylist[0])
          }   
        }
      )
    );
  }, []);
  const getTwilioToken = () => {
    const params={role:twilioNumber}
    axios({
      url: `${window.config?.API_URL}/api/Token/Generate`,
      method: 'get',
      headers: {
        Authorization: 'Bearer  ' + reduxToken,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
      },
      params,
    }).then(async (res) => {
      console.log('twilio token 106', res.data);
      dispatch({ type: 'ADD_TOKEN', payload: { token: res.data?.token,callDeviceIdentity: res.data?.identity } });
    });
  };
  const getMicrophonePermission = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    permissions
      .then((stream) => {
        dispatch({ type: 'ADD_PERMISSION', payload: { data: 'microphone' } }); // If user gives permission to microphone then add permission to redux
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
      });
  };
  useEffect(() => {
   
      getTwilioToken();
    if (!grantedPermissions || !grantedPermissions.includes('microphone')) getMicrophonePermission();
 
  }, [twilioNumber]);
  useUpdateEffect(() => {
    const device = new Device(token,{enableRingingState: true,debug: true });
    // setDevice(device);
    device.register();
    device.on("registered", function () {
      console.log("Twilio.Device Ready to make and receive calls!");
      dispatch({ type: 'ADD_DEVICE', payload: { device: device } });
        });
    device.on('ready', () => {
      console.log('device 1 ready')
      // setDevice(device);
            //setState(states.READY);
    });
    device.on('incoming', (call) => {
      console.log('incoming event');
    })
    device.on('cancel', () => {
      console.log('cancelled event');
    });
    device.on('reject', () => {
      console.log('rejected event');

      // setConn(null);
    });

    return () => {
      // device.destroy();
      // dispatch({ type: 'ADD_DEVICE', payload: { device: null } });
      // setDevice(null);
    };
  }, [token]);
  useUpdateEffect(() => {
    callInstance?.on('ready',(device)=>{
      console.log('device new 1 ready')
    })
    callInstance?.on('disconnect', (call) => {
      setPhoneNumber('')
      // setIsCallConnected(false)
      setCallingTime(0)
      // setIsOutGoingConnected(false)
    dispatch({ type: 'IS_OUTGOING_CONNECTED', payload: { isOutgoingConnected: false } })
      console.log('The call has been disconnected.');
      dispatch({type:'IS_CALL_CONNECTED',payload:{isCallConnected:false}})
      dispatch({type:'IS_MUTED',payload:{isMuted:false}})
      dispatch({ type: 'CALLING_TIME', payload: { callTimer: 0 } })
      // dispatch({type:'CALL_PROGRESS_POP_UP',payload:{isCallProgressPopUp:false}})
    });

    callInstance?.on('cancel', (call) => {
      console.log('cancel event');
      // dispatch({type:'CALL_MODAL_CLOSE'})
    });

    callInstance?.on('reject', (call) => {
      console.log('reject event');

      // dispatch({type:'CALL_MODAL_CLOSE'})
    });

    callInstance?.on('accept', (call) => {
      console.log("The incoming call was accepted or the outgoing call's media session has finished setting up.");
      // setIsOutGoingConnected(false)
    dispatch({ type: 'IS_OUTGOING_CONNECTED', payload: { isOutgoingConnected: false } })
      // setIsCallConnected(!isCallConnected)
      dispatch({type:'IS_CALL_CONNECTED',payload:{isCallConnected:true}})
      setCallExt('');
      // setIsDialpad(false)
      dispatch({type:'IS_DIALPAD_OPEN',payload:{isDialpadOpen:false}})
    });
    // callInstance?.on('incoming',(call)=>{
    //   console.log('ringing',call)

    // })
    callInstance.on('error', (error) => {
      console.log('An error has occurred: ', error);
  });
    if(callInstance)
    {console.log('check call status',callInstance.status())}
  }, [callInstance]);
  const toggleDialpad=()=>{
    // setIsDialpad(!isDialpadOpen)
    dispatch({type:'IS_DIALPAD_OPEN',payload:{isDialpadOpen:!isDialpadOpen}})

  }
  const handlePaste = (event) => {
    console.log('paste fun',event.target.value)
    event.preventDefault(); // Prevent default paste behavior
    const paste = event.clipboardData.getData('text/plain');
    const onlyNums = paste.replace(/\D/g, ''); // Remove non-numeric characters
    console.log('paste fun1',onlyNums)
    setPhoneNumber(onlyNums.slice(0,10));
    
  };
  useEffect(() => {
    let timerInterval;
  if (isCallConnected) {
    timerInterval = setInterval(() => {
      setCallingTime((prevTimer) => prevTimer + 1);
      // dispatch({ type: 'CALLING_TIME', payload: { callTimer: callTimer + 1} })
    }, 1000); // Update the timer every second
  }

  return () => {
    clearInterval(timerInterval);
  };
}, [isCallConnected]);
useEffect(()=>{
  if(isCallConnected){
    const isNotEmpty = Object.entries(callInstance).length > 0;
    console.log('call status',isNotEmpty)
    // dispatch({type:'IS_CALL_CONNECTED',payload:{dialedNumber:callInstance?.customParameters.get('To')}})

  }
},[callInstance])
useEffect(()=>{
  console.log('hold',isCallOnHold)
  if(isCallConnected){
    if (isCallOnHold) {
      dispatch(holdCall({ sid: callInstance?.parameters?.CallSid }, (res) => {
          // console.log('check resp',res)
      }))
  } else {
      dispatch(unHoldCall({ sid: callInstance?.parameters?.CallSid }, (res) => {
          // console.log('check resp',res)
      }))
  }
  }
},[isCallOnHold])
const handleHoldCall=()=>{
  console.log('hold')
  setIsCallOnHold(!isCallOnHold)
}
useEffect(async()=>{
  console.log('22',toggleDialer2,twilioNumber,phNumber)
  if(toggleDialer2){
    // setIsOutGoingConnected(true)
    // dispatch({ type: 'IS_OUTGOING_CONNECTED', payload: { isOutgoingConnected: true } })
    console.log('22',toggleDialer2,twilioNumber,phNumber)
     const call = await device.connect({
      params: {
        To:phNumber,
           From:twilioNumber,
           Record:'record',
           callDeviceIdentity,

      },
    });
    // setCallInstance(call);
    dispatch({type:'DIALED_NUMBER',payload:{dialedNumber:phNumber}})
    dispatch({ type: 'CALL_INSTANCE', payload: { callInstance: call } })
  }
},[toggleDialer2])
useEffect(()=>{
  if(isCallConnected){
    dispatch({ type: 'CALLING_TIME', payload: { callTimer: callingTime } })
  }
},[callingTime])

const handleAddCall=(event)=>{
  setAnchorEl(event.currentTarget);
}
const handleClose = () => {
  setAnchorEl(null);
};
const handleMenuItemClick = (event) => {
  // setSelectedOption('AgentList')
  // const value = event.currentTarget.getAttribute('type-id');
  // setShowAgent(true)
  // setTransferTypeId(value)
  // handleClose()
  // console.log('transfer type ', value)
  // if (value === '1') {
  //     setIsHold(true)

  // }
}

  return (
    <>
    {!isCallConnected ?
     
      <div className='mx-auto'>
        <div className="flex flex-col gap-4 h-[87vh] bg-white p-2 rounded rounded-4">
        <div className='font-bold col-span-3 text-lg'>Dial</div>
        {isOutgoingConnected?<LinearProgress />:null}
        <div className="">
        <Autocomplete
        fullWidth
             id="size-small-standard"
            size="small"
            options={countryList}
            getOptionLabel={(option) => `${option.countryName_chr} `}
            renderInput={(params) => <TextField {...params} label="Country Code" variant="outlined" />}
            value={selectedValue}
            onChange={(event,value)=>{
              console.log('check code',value)
              setSelectedValue(value)
              setCountryCode(`+${value.countryPhoneCode_chr}`);
            }}
            />
         </div>
         <div className="col-span-3 flex items-center gap-x-2">
        <TxtField
        variant='outlined'
        type="text"
        placeholder="+1"
        value={countryCode}
        style={{width:'90px'}}
        />
        <TextField 
        size='small'
        variant='outlined'
        type="text"
        value={phoneNumber}
        placeholder="Enter phone number"
        className="p-2 text-lg border border-gray-300 rounded w-64"
        onChange={handleChange}
        onPaste={handlePaste}
        InputProps={
          {endAdornment: <InputAdornment position="end">
             <IconButton
               aria-label="toggle password visibility"
               onClick={handleBackspace}
              //  onMouseDown={onMouseDown}
               edge="end"
             >
                <IoMdBackspace size={18} />
             </IconButton>
           </InputAdornment>}
         }/>
      </div>
      <div className='text-xs text-center'>Calling from {formatPhoneNumber(twilioNumber)}</div>
      <div className="grid grid-cols-3 gap-1 justify-items-center"> 
      
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('1')}>
            <p>1</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('2')}>
            <p>2</p>
            <p className='text-xs'>ABC</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('3')}>
            <p>3</p> 
            <p className='text-xs'>DEF</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('4')}>
           <p> 4</p>
             <p className='text-xs'>GHI</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('5')}>
           <p>5</p>
             <p className='text-xs'>JKL</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('6')}>
            <p>6</p>
             <p className='text-xs'>MNO</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('7')}>
            <p>7 </p>
            <p className='text-xs'>PQRS</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('8')}>
            <p>8</p> 
            <p className='text-xs'>TUV</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('9')}>
            <p>9</p>
             <p className='text-xs'>WXYZ</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 'onClick={() => handleKeyPress('*')}>*</div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('0')}>
            <p>0</p>
            <p className='text-xs'>+</p>
            </div>
          <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('#')}>#</div>
        <div className="col-span-3 flex items-center justify-center pt-4">
          <button onClick={handleCall} className="w-16 h-16 border rounded-full bg-green-600 hover:bg-green-700 flex items-center justify-center"><MdCall size={24} color='white'/></button>

        </div>
      </div>
    </div>
    </div>
     :
     <div className='mx-auto'>
       <div className="flex flex-col gap-2 h-[87vh] bg-white p-2 rounded rounded-4">
        {isDialpadOpen?
        <div>
          <IoArrowBackOutline size={24} onClick={toggleDialpad}/>
        </div>
        :
        <div className='h-[24px]'></div>
        }
     <div className='grid grid-cols-3 gap-1 justify-items-center '>
     <div className='col-span-3'><FaUserCircle size={52}/></div>
     <div className='col-span-3'>Unknown</div>
     <div className='col-span-3'>
      {formatPhoneNumber(dialedNumber)}
      </div>
     <div className='col-span-3'>{callTime(parseInt(callTimer))}</div>
     </div>
     {isDialpadOpen?<>
      <div className="grid grid-cols-3 gap-1 justify-items-center">
       
       <div className="col-span-3 ">
          <TxtField
          name='callExt'
         variant='outlined'
           type="text"
           value={callExt}
           placeholder="Enter phone number"
           className="p-2 text-lg border border-gray-300 rounded w-64"
           onChange={handleChangeExt}
           
         />
       </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('1')}>
             <p>1</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('2')}>
             <p>2</p>
             <p className='text-xs'>ABC</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('3')}>
             <p>3</p> 
             <p className='text-xs'>DEF</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('4')}>
            <p> 4</p>
              <p className='text-xs'>GHI</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('5')}>
            <p>5</p>
              <p className='text-xs'>JKL</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('6')}>
             <p>6</p>
              <p className='text-xs'>MNO</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('7')}>
             <p>7 </p>
             <p className='text-xs'>PQRS</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('8')}>
             <p>8</p> 
             <p className='text-xs'>TUV</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('9')}>
             <p>9</p>
              <p className='text-xs'>WXYZ</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('#')}>#</div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('0')}>
             <p>0</p>
             <p className='text-xs'>+</p>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={() => handleKeyPress('*')}>*</div>
         
       </div>
       

       </>:<>
      <div className="grid grid-cols-3 gap-4 justify-items-center">
        <div className='col-span-3 h-[72px]'></div>
        <div className='col-span-3 h-16'></div>
        <div className='col-span-3 h-16'></div>
        {/* <div className='col-span-3 h-16'></div> */}
        {/* <div className='col-span-3 h-16'></div> */}
                  <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={handleCallMute}>
                    {
                      isMuted ?
                        <BsFillMicMuteFill size={24} />
                        :
                        <BsMicFill size={24} />
                    }
                  </div>
           {/* <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={handleHoldCall}>
             <MdPhonePaused size={24}/>
             </div> */}
             <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={handleAddCall}>
             <MdAddCall size={24}/>
             </div>
           <div className='w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer hover:border-none hover:bg-gray-200 ' onClick={toggleDialpad}>
            <MdDialpad size={24}/>
             </div>
       </div>
       </>}
       <div className="flex items-center justify-center">
           <button onClick={handleCallDisconnect} className="w-16 h-16 border rounded-full bg-red-600 hover:bg-red-700 flex items-center justify-center"><MdCallEnd size={24} color='white'/></button>
 
         </div>  
     </div> 
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >{transferTypesList?.map((type) =>
            <MenuItem onClick={handleMenuItemClick} type-id={type?.typeID} className='flex items-centers gap-x-2'>
              {type?.icon}
              {type?.type}
            </MenuItem>
          )
            }
          </Menu> 
</div>
}
</>
  );
};

export default CallDialer;
