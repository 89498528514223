import { useEffect,useState} from 'react';

import { Avatar, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TxtField from '../../components/InputFields/TextFields/TextField';
import { formatPhoneNumber } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'none',
    boxShadow: 'none',

    // color: theme.palette.text.secondary,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    background: 'green',
    
  },
  avatar2: {
    width: theme.spacing(10),
    height: theme.spacing(10),
   },
}));
function Profile() {
  
  const loginDetails=useSelector((state)=>state.login?.loginData)
  const intialData={
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    dialConnectNumber:'',
  }
  const [userData,setUserData]=useState(intialData)
  const classes = useStyles();
  const getInitials = (name) => {
    const words = name.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase();
    } else {
      return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
    }
  };
 
const firstInitial=getInitials(loginDetails?.firstName)
const LastInitial=getInitials(loginDetails?.lastName)
useEffect(()=>{
  setUserData({
    firstName:loginDetails?.firstName,
    lastName:loginDetails?.lastName,
    email:loginDetails?.email,
    phone:loginDetails?.phone,
    dialConnectNumber:loginDetails?.twilioNumber,
  })
},[loginDetails])
  return (
    <div className='w-[500px]'>
     <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className='flex items-center justify-center'>
            <Avatar className={classes.avatar}>
               {`${firstInitial}${LastInitial}`}
            </Avatar>
            {/* <Avatar className={classes.avatar2} src='/Display_Picture.png' /> */}
        </div>
      </Grid>
        <Grid item xs={12}>
          <TxtField
          variant='outlined'
          label='First Name'
          value={userData?.firstName}
          />
        </Grid>
       <Grid item xs={12}>
          <TxtField
          variant='outlined'
          label='Last Name'
          value={userData?.lastName}/>
        </Grid>
       <Grid item xs={12}>
          <TxtField
          variant='outlined'
          label='Email'
          value={userData?.email}/>
        </Grid>
        <Grid item xs={12}>
          <TxtField
          variant='outlined'
          label='Phone Number'
          value={userData?.phone}/>
        </Grid>
        {loginDetails?.twilioNumber? <Grid item xs={12}>
          <TxtField
          variant='outlined'
          label='DialConnect Number'
          value={formatPhoneNumber(userData?.dialConnectNumber)}/>
        </Grid>:null}
     </Grid>
    </div>
  );
}


export default Profile;
