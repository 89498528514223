const constants = {
  ALL_DIR:'ALL_DIR',
  ADD_CUSTOMER_MODAL_CLOSE: 'ADD_CUSTOMER_MODAL_CLOSE',
  ADD_CUSTOMER_MODAL_OPEN: 'ADD_CUSTOMER_MODAL_OPEN',
  ADD_EDIT_TEMP:'ADD_EDIT_TEMP',
  BULK_SMS_EDITOR:'BULK_SMS_EDITOR',
  CUSTOMERS_EMAIL_PHONE: 'CUSTOMERS_EMAIL_PHONE',
  DIALER_CLOSE: 'DIALER_CLOSE',
  DIALER_OPEN: 'DIALER_OPEN',
  DRAWER_CLOSE: 'DRAWER_CLOSE',
  DRAWER_OPEN: 'DRAWER_OPEN',
  GET_ALL_AGENCIES:'GET_ALL_AGENCIES',
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  GET_ALL_CUSTOMERS: 'GET_ALL_CUSTOMERS',
  GET_ALL_EMAIL_LOG: 'GET_ALL_EMAIL_LOG',
  GET_ALL_ROLES: 'GET_ALL_ROLES',
  GET_ALL_SMS_LOG: 'GET_ALL_SMS_LOG',
  GET_ALL_TEMPLATES: 'GET_ALL_TEMPLATES',
  GET_TENANTS:'GET_TENANTS',
  GET_TOKENS:'GET_TOKENS',
  GET_ALL_USERS:'GET_ALL_USERS',
  GET_USERS_LIST: 'GET_USERS_LIST',
  SIGNATURE: 'SIGNATURE',
  SIGNATURE_LIST: 'SIGNATURE_LIST',
  SIGNATURE_MODAL_OPEN: 'SIGNATURE_MODAL_OPEN',
  SPINNER_STATE: 'SPINNER_STATE',
  GET_VOICE_CALL_LOG: 'GET_VOICE_CALL_LOG',
  MAIL_MODAL_CLOSE: 'MAIL_MODAL_CLOSE',
  MAIL_MODAL_OPEN: 'MAIL_MODAL_OPEN',
  MAIL_TEMPLATE_MODAL_CLOSE: 'MAIL_TEMPLATE_MODAL_CLOSE',
  MAIL_TEMPLATE_MODAL_OPEN: 'MAIL_TEMPLATE_MODAL_OPEN',
  MASS_MAIL_SUBJECT:'MASS_MAIL_SUBJECT',
  RESET_STORE: 'RESET_STORE',
  SAVE_LOGIN_DATA: 'SAVE_LOGIN_DATA',
  SAVE_TENANT:'SAVE_TENANT',
  SMS_MODAL_CLOSE: 'SMS_MODAL_CLOSE',
  SMS_MODAL_OPEN: 'SMS_MODAL_OPEN',
  SMS_TEMPLATE_MODAL_CLOSE: 'SMS_TEMPLATE_MODAL_CLOSE',
  SMS_TEMPLATE_MODAL_OPEN: 'SMS_TEMPLATE_MODAL_OPEN',
  TOKEN_API_CALL:'TOKEN_API_CALL',
  TEXT_AREA: 'TEXT_AREA',
  UNAUTH_USER: 'UNAUTH_USER',
  UPDATE_NUMBER:'UPDATE_NUMBER',
};
export default constants;
