import { MdCancel } from 'react-icons/md';

import constant_label from '../../constants';
import '../../../src/DialSightStyles.css'
 const CancelButton=({onClick})=> {
  return  <button  type="button" className='cancel_button' onClick={onClick}>
        {constant_label?.btn_cancel} 
        <MdCancel size={18}/>
      </button>
 
}
export default CancelButton;
