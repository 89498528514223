import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatPhoneNumber } from '../../utils';
import Profile from '../Profile/component';
import Subscription from '../Subscriptions/emailPlan';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'none',
    boxShadow: 'none',

    // color: theme.palette.text.secondary,
  },
}));
function Account() {
  
  const {firstName, lastName,tenant,twilioNumber}=useSelector((state)=>state.login?.loginData)
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
const handleProfieClick=(e)=>{
    const {label}=e.target
  console.log(e.target,'tbb')
}
console.log(value,'tbb')
  return (
    <>
      <div className="p-2 md:p-16 w-full accountInfoEdit-mainArea flex flex-col justify-center items-center text-sm md:text-base whitespace-nowrap">
        <Grid container spacing={1} className="bg-white py-4 rounded-lg">
                 <Grid item xs={12}>
                 <Paper className={classes.root} elevation={0}>
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                      >
                          <Tab label="Profile" index={0} onClick={handleProfieClick}/>
                          <Tab label="Your Plans" index={1} onClick={handleProfieClick}/>
                          <Tab label="Subscriptions" index={2} onClick={handleProfieClick}/>
                      </Tabs>
                  </Paper>
                 </Grid>
                 <Grid item xs={12}>
                   <div className='flex justify-center'>
                   {value==0?<Profile/>:<Subscription/>}
                   </div>
                 </Grid>
                  
        </Grid>
      </div>
    </>
  );
}


export default Account;
