import ReduxStore from './ReduxStore';

const reduxStoreInstance = ReduxStore.getDefaultStore();
const store = reduxStoreInstance.store; // Access the Redux store
export const userIdToken = () => {
  console.log('get store',store.getState())
  const {userId,token,tenant,domain}=store.getState({type:'SAVE_LOGIN_DATA'}).login.loginData
  const localDomain=localStorage.getItem('domain')
  const finalDomain=domain?domain:localDomain
  const config = {
    userId:userId,
    token:token,
    DOMAIN_NAME:finalDomain,
    tenant:tenant
  };
console.log('check functional config',config)
  return config; 
};


