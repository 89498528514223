import React, { useCallback, useEffect, useState } from "react";

import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { IoMdEye } from "react-icons/io";
import { MdAdd, MdDelete, MdModeEdit } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CommonButton from "../../components/Buttons/commonButton";
import MuiSearch2 from "../../components/InputFields/SearchFields/muiSearch2";
import Loader from "../../components/loader";
import AddEDitDialPlanModal from "../../components/Modals/AddEditDialPlanModal/component";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import Table from "../../components/Table";
import constant_label from "../../constants";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { getDialPlans ,deleteDialPlan,getDialPlanAction} from "../../services/twilio/twilioPhoneService";
import { getAllActiveNumbers } from "../../services/userService";
import { convertTimeZone, formatPhoneNumber } from "../../utils";

function DialPlans(){
    const dispatch=useDispatch();
    const history=useHistory()
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {tenant}=useSelector((state)=>state.login.loginData)
    const [pageCount, setPageCount] = useState(1);
    const [totalRows, setTotalRows] = useState(0)
    const [rows, setRows] = useState([]);
    const fetchIdRef = React.useRef(0);
    const [tableState, setTableState] = useState({
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: '',
      });
      const [open,setOpen]=useState(false)
      const [action,setAction]=useState('Add')
      const [activeNumbers,setActiveNumbers]=useState([])
      const [selectedRow,setSelectedRow]=useState({})
      const [searchedVal, setSearchedVal] = useState('');
      const [globalSearchQuery,setglobalSearchQuery]=useState('')
      const [ringGroupNumbers,setRingGroupNumbers]=useState([])
  const [isConfirmationOpen,setIsConfirmationOpen]=useState(false)

      const getAllActions=()=>{
        dispatch(getDialPlanAction({},(res)=>{
          console.log('res',res)
          const actionTable=res?.tblDialPlanAction
          dispatch({type:'DIAL_PLAN_ACTION',dialPlanActions:actionTable})
        }))
      }
      const onClickAdd=()=>{
        setAction('Add')
        setOpen(true)
        getAllActions()  
            
      }
      const onClickView=(row)=>{
        console.log(row)
        getAllActions()
        history.push(`/${tenant}/dial-plans/${parseInt(row.dialplanID_ids)}`)
      }
      const onClickEdit=(row)=>{
        console.log(row)
        setOpen(true)
        setAction('Edit')
        setSelectedRow(row)
        getAllActions()
      }
      const handleDeleteDialPlan=()=>{
        dispatch(deleteDialPlan({DialPlanID:selectedRow.dialplanID_ids},(res)=>{
          NotificationManager.success('Deleted successfuly.')
          fetchTableData()

        }))
      }
      const onClickDeleteIcon = (row) => {
        console.log('row', row)
        setIsConfirmationOpen((state) => !state)
        setSelectedRow(row)
      }
      const tableColumns = [
        {
            accessor: 'acction',
            Header: constant_label?.lbl_txt_actions,
            disableSortBy:true,
            sortable: false,
            filterable: false,
            headerAlign: 'right',
            align: 'right',
            width: 70,
            Cell: ({ row: { original }, value, column }) => {
              return (
                <div style={{ width: '100%' }}>
                  <Tooltip title="View" arrow size='small'>
                      <IconButton
                        color="primary"
                        // className={classes.icons}
                        aria-label="edit"
                        onClick={(e) => onClickView(original)}
                      >
                        <IoMdEye className="h-20px" fontSize="small" size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" arrow size='small'>
                      <IconButton
                        color="primary"
                        // className={classes.icons}
                        aria-label="edit"
                        onClick={(e) => onClickEdit(original)}
                      >
                        <MdModeEdit className="h-20px" fontSize="small" size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow size='small'>
                      <IconButton
                        color="primary"
                        // className={classes.icons}
                        aria-label="delete"
                        onClick={(e) => onClickDeleteIcon(original)}
                      >
                        <MdDelete className="h-20px" fontSize="small" size={20} />
                      </IconButton>
                    </Tooltip>
                  
                 
                </div>
              );
            },
            flex: 1,
          },
          
        {
          accessor: 'dialplanName_chr',
          Header: 'Name',
          minWidth: 140,
          width: 140,
          sticky: 'left',
          sortable: true,
        },
        {
          accessor: 'phoneNumber',
          Header: 'Phone Number',
          width: 100,
          sortable: true,
          Cell:(({value})=>formatPhoneNumber(value))
        },
        
          {
            accessor: 'lastUpdatedDateTime_dtm',
            Header: 'Last updated date/time',
            width: 100,
            sortable: true,
            Cell: ({ value }) => {
              return <span>{convertTimeZone(value)}</span>;
            },
          },
        
        ]
      const calculatePageCount = (rowsData, pageSize) => {
        const totalRows = parseInt(rowsData?.[0]?.['user_Count_Including_Criteria_int']);
        // const totalRows = parseInt(rowsData);
    
        setPageCount(Math.ceil(totalRows / pageSize));
      };
    const onTableStateChange = useCallback(
        ({ pageIndex: Page_Index_int, pageSize: Page_Size_int, globalFilter: Where_GeneralCriteria_Delim_mem, sortBy }) => {
          const fetchId = ++fetchIdRef.current;
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          setTableState((state) => {
            const sort = sortBy?.[0];
            let sortingField = 'createdDate';
            let sortingOrder = 'asc';
    
            if (sort) {
              sortingField = sort['id'];
              sortingOrder = sort['desc'] ? 'desc' : 'asc';
            }
            const Sort_Expression_Delim_mem = `${sortingField} ${sortingOrder}`;
            return {
              ...state,
              Page_Index_int: Page_Index_int + 1,
              Page_Size_int: Page_Size_int || 20,
              Where_GeneralCriteria_Delim_mem,
              Sort_Expression_Delim_mem: Sort_Expression_Delim_mem || '',
            };
          });
        },
        []
      );
      const fetchTableData = (callback) => {
        dispatch(getDialPlans(tableState, (data) => {
          console.log('check ress here',data)
          // setActiveNumberList(res?.tblActiveNumbers)
          callback && callback(data?.tblDialPlan);
          setRows(data?.tblDialPlan);
          calculatePageCount(data?.tblDialPlan,tableState?.Page_Size_int)
        }))
        };
      useUpdateEffect(() => {
        fetchTableData();
        // sessionStorage.setItem('newLead-Page_Index_int', tableState?.Page_Index_int);
      }, [tableState]);
      
    const updatedValue = sessionStorage.getItem('ringGroupPage-global-search-query');
  React.useEffect(() => {setSearchedVal(updatedValue);
    setglobalSearchQuery(updatedValue);
    const newTableState = { ...tableState };
    newTableState['Where_GeneralCriteria_Delim_mem'] = updatedValue;
    setTableState(newTableState);
  }, [updatedValue]);
  useEffect(()=>{
    if(searchedVal===""){
      setglobalSearchQuery('')  
    }
  },[searchedVal])
  const requestSearch = (e ) => {
    const { name, value } = e.target;
  setSearchedVal(value);

}
    const handleSearch=()=>{
      const searchQuery=searchedVal.trim()
        setglobalSearchQuery(searchQuery)
        sessionStorage.setItem(
          "ringGroupPage-global-search-query",searchQuery
        );
      }
      const handleCloseIcon=()=>{
        setSearchedVal('')
        setglobalSearchQuery('')
        sessionStorage.setItem(
          "ringGroupPage-global-search-query",''
        );
      }
      const handleKeyPress=(event)=>{
        if (event.key === "Enter") {
          event.preventDefault();
      const searchQuery=searchedVal.trim()
          setglobalSearchQuery(searchQuery)
        sessionStorage.setItem(
          "ringGroupPage-global-search-query",searchQuery
        );
        }
      }
    return <>
    {spinner?<Loader/>:null}
    {isConfirmationOpen && <ConfirmationDialog
      onHandleModel={(e) => setIsConfirmationOpen((state) => !state)}
      isOpenDialog={isConfirmationOpen}
      action={handleDeleteDialPlan}
      title={'Remove'}
      content={'Are you sure you want to delete this Dial Plan ?'} />}
    <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h5" component="h5">
            Dial Plans
        </Typography>

        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
        <MuiSearch2 
            name="Where_GeneralCriteria_Delim_mem"
            onChange={requestSearch}
            value={searchedVal}
            handleSearch={handleSearch}
            handleCloseIcon={handleCloseIcon}
            onKeyDown={handleKeyPress}/>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2}>
          <CommonButton 
          onClick={onClickAdd}
          >
                      <MdAdd size={24}  />
                      <span className="">Add Dial Plan</span>
                    </CommonButton>
          </Grid>
        
        <Grid item xs={12}>
        <Table
            pageCount={pageCount}
            totalRows={totalRows}
            columns={tableColumns}
            data={rows}
            onTableStateChange={onTableStateChange}
            globalSearchQuery={globalSearchQuery}
            />
        </Grid>
    </Grid>
    <AddEDitDialPlanModal open={open} setOpen={setOpen} action={action} selectedRow={selectedRow} reloadList={fetchTableData} ringGroupNumbers={ringGroupNumbers}/>
    </>
}
export default DialPlans;