import { useEffect } from 'react';

import DOMPurify from 'dompurify';
import QuillBetterTable from 'quill-better-table';
import BlotFormatter from 'quill-blot-formatter';
import Delta from 'quill-delta';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import 'quill-better-table/dist/quill-better-table.css';
import './editorStyles.css';
import { useDispatch, useSelector } from 'react-redux';

import { uploadImageOnCloudinary } from '../../utils';

const TextEditor = (props) => {
  const {isSMSModal,setBodyInput, isAddEditTemp,isVar,selectedVariable, fromMailCom, action,fromMailModal ,sendFlag,setMessageBody,setTemplateData}=props
  const dispatch = useDispatch();
  let editorValue = undefined;
  editorValue = useSelector((state) => state?.editorContent?.TextArea);
  if (editorValue === undefined) {
    editorValue = '';
  }
  useEffect(()=>{
    editorValue = ''
  },[sendFlag])
  let mailEditor=useSelector((state) => state?.mailState?.Body);
  if (mailEditor === undefined) {
    mailEditor = '';
  }
  let templateBodyChr = useSelector((state) => state?.template?.templateBody);
  if (templateBodyChr === undefined) {
    templateBodyChr = '';
  }
  let smsModalBodyChr=useSelector((state) => state.smsState.Body)
  if (smsModalBodyChr === undefined) {
    smsModalBodyChr = '';
  }
  const templateModalState = useSelector((state) => state?.templateModalState?.templateModalOpen);
  const isSignatureModalOpen = useSelector((state) => state?.signatureModal?.isOpen);
  const signature = useSelector((state) => state?.signature?.userSignature);
  const subject = useSelector((state) => state?.editorContent?.Subject);
  const {isSigned,userSignature} = useSelector((state) => state?.signature);
  var toolbar = [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: '' }, { align: 'right' }, { align: 'center' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [
      'link',
      'image',
      //  'video'
    ],
    // ['clean'],    
  ];
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {}, toolbar ,
    
  },
  });
 
  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };
  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  // const saveToServer = async (file) => {
  //   const url = await uploadImageOnCloudinary(file);
  //   insertToEditor(url);
  // };
  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file, maxWidth = 140, maxHeight = 100) => {
    // create a new image object
    const image = new Image();
    image.src = URL.createObjectURL(file);

    // wait for the image to load
    await new Promise((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = (error) => reject(error);
    });

    // calculate the new image dimensions
    let width = image.width;
    let height = image.height;
    if (width > maxWidth) {
      height = (height * maxWidth) / width;
      width = maxWidth;
    }
    if (height > maxHeight) {
      width = (width * maxHeight) / height;
      height = maxHeight;
    }

    // create a canvas element and resize the image
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);

    const binaryData = canvas.toDataURL('image/jpeg');

    // upload the binary data to the server
    const res = await uploadImageOnCloudinary(binaryData);
    insertToEditor(res);
  };
  
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldContents) => {
        // editorValue && quill.clipboard.dangerouslyPasteHTML(editorValue); //set initial value here
        quill.focus();
        const currrentContents = quill.getContents();
        const actualContent = quill.root.innerHTML;
        if(fromMailCom){
        setMessageBody(actualContent)
        }
        else if(fromMailModal){
        setMessageBody(actualContent)
        }else if(isAddEditTemp===true)
        {
          // dispatch({type:'ADD_EDIT_TEMP',data:actualContent})
          setTemplateData((state)=>({
            ...state,
            body_chr:actualContent
          }))
        }else if(isSMSModal){
          setBodyInput(actualContent)
        }
        else{
        dispatch({ type: 'SIGNATURE_MODAL_OPEN', isOpen: true,name:'',body:actualContent })
        }
      });
      // editorValue && quill.clipboard.dangerouslyPasteHTML(editorValue); //set initial value here

      // Add custom handler for Image Upload
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
    }
  }, [quill, Quill]);
  useEffect(() => {
    if (quill) {
      quill.focus();
      const cursorPosition = quill.getSelection();
      selectedVariable && quill.insertText(cursorPosition.index, selectedVariable);
    }
  }, [quill, Quill,isVar, selectedVariable]);
  useEffect(() => {
    if (quill) {
      quill.focus();
      let html;
      if(fromMailCom)
      { 
        html=editorValue
        setMessageBody(html)

      }
      else if(fromMailModal){
        html=mailEditor
        setMessageBody(html)
      }
      else if(isAddEditTemp)
      {
        html=templateBodyChr
      }else if(isSMSModal)
      {
        html=smsModalBodyChr
      }
      else{
        html=userSignature===undefined?"":userSignature
      }

      const cursorPosition = quill.getSelection();
      const div = document.createElement('div');
      div.innerHTML = html;
      // quill.insertEmbed(cursorPosition, html);
      const sanitizedHtml = DOMPurify.sanitize(div.innerHTML);
      // const modifiedContent = sanitizedHtml.replace(/<div>/g, '<div style="line-height: 0.5;">');
      quill.clipboard.dangerouslyPasteHTML(sanitizedHtml);
      // editorValue && quill.clipboard.dangerouslyPasteHTML(cursorPosition.index, sanitizedHtml);
    }
  }, [quill, Quill, templateModalState,editorValue,mailEditor,templateBodyChr.length>0,isSigned,sendFlag]);
  useEffect(() => {
    if (quill) {
      quill.focus();

      if (isSigned === true) {
        const html = signature;

        const cursorPosition = quill.getSelection();
        const div = document.createElement('div');
        div.innerHTML = DOMPurify.sanitize(html);
        // quill.insertEmbed(cursorPosition, html);
        if (fromMailCom === true) {
        } else {
          const sanitizedHtml = DOMPurify.sanitize(div.innerHTML);
          if (action === 'Add') {
            // quill.clipboard.dangerouslyPasteHTML(cursorPosition.index, sanitizedHtml);
          } else {
            // quill.clipboard.dangerouslyPasteHTML(sanitizedHtml);
          }
        }
      }
    }
  }, [quill, Quill, signature, isSigned,fromMailCom,action]);
  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default TextEditor;
