import ActionConstants from '../config/AppConstants';
const innitialData = {
  isOpen: false,
  isSigned: false,
  userSignature: '',
};
export default function signature(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.SIGNATURE: {
      return {
        isSigned: action.isSigned,
        userSignature: action.userSignature,
      };
    }
    case ActionConstants.SIGNATURE_LIST: {
      return {
        isOpen: action.isOpen,
      };
    }
    default:
      return { ...state };
  }
}
