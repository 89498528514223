import ActionConstants from '../config/AppConstants';
const innitialData = {
    isTokenApiCall:false,
    token:'',
    refreshToken:'',
};
export default function getTokens(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.TOKEN_API_CALL: {
      return {
        isTokenApiCall: action.isTokenApiCall,
        
      };
    }
    case ActionConstants.GET_TOKENS: {
        return {
            token: action.token,
            refreshToken:action.refreshToken,
          
        };
      }
    default:
      return { ...state };
  }
}
