import { TextField } from "@material-ui/core"

function TxtField(props) {
    const { autoComplete,width, required, disabled, type, id, size, margin, multiline, name, label, placeholder, variant, minRows, maxRows, value, onChange, onBlur,onKeyDown,onPaste, inputProps, InputLabelProps, helperText, error,style } = props
    return <TextField
        autoComplete={autoComplete}
        width={width}
        required={required}
        disabled={disabled ? disabled : false}
        fullWidth
        id={id}
        margin={margin}
        type={type}
        size={size ? size : 'small'}
        multiline={multiline}
        name={name}
        label={label}
        placeholder={placeholder}
        variant={variant}
        minRows={minRows}
        maxRows={maxRows}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        InputLabelProps={InputLabelProps}
        inputProps={inputProps}
        helperText={helperText}
        error={error}
        style={style}
    />
}
export default TxtField