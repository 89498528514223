import { initializeApp } from 'firebase/app';
// import firebase from 'firebase/app';
import { getFirestore ,collection, addDoc} from 'firebase/firestore'
import { getMessaging, getToken, onMessage,} from 'firebase/messaging';

const config = {
  apiKey: "AIzaSyC-E1pweWVIcoehDodZh1lOzHvGWWER5OQ",
  authDomain: "dialsight-communication.firebaseapp.com",
  projectId: "dialsight-communication",
  storageBucket: "dialsight-communication.appspot.com",
  messagingSenderId: "349662703976",
  appId: "1:349662703976:web:a78f958e2ca136659126dd",
  measurementId: "G-ZXSSJJ0SYS"
};

const app = initializeApp(config);

const messaging = getMessaging(app);

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey: 'BBr6nNxPml0isKeEpXFuXw_yxzz3eJ7C4IjIamt1rmsozf0c2yKvc4wX56pr0EKmlFlAwi27zAiaRf7rRZZa9z8',
    })
      .then((firebaseToken) => {
        console.log('new firebaseToken',firebaseToken)
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = (cb) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('check alert',payload)
      cb(payload)
      resolve(payload);
    });
  });

//----
// Function to send a message from server to client
export const sendFirebaseMessage = (recipientToken, isPermissionsChanges) =>
  new Promise((resolve, reject) => {
    const db = getFirestore(app);
    const messagesRef = collection(db, 'permission_alert'); // Replace 'messages' with your Firestore collection name

    const sanitizedMessageData = {
      to: recipientToken,
      isPermissionsChanges: isPermissionsChanges,  // Set the boolean value directly
    };

    addDoc(messagesRef, sanitizedMessageData)
      .then(() => {
        console.log('Message added to Firestore successfully');
        resolve();
      })
      .catch((err) => {
        console.error('Error adding message to Firestore:', err);
        reject(err);
      });
  });