import { useEffect, useState } from "react"

import { Grid, Typography } from "@material-ui/core"
import { FaUserCircle } from "react-icons/fa"
import { MdCall, MdCallMade, MdCallMissed, MdCallReceived, MdDialpad, MdOutlineCall, MdOutlineSms, MdSms } from "react-icons/md"
import { SlCallIn, SlCallOut } from "react-icons/sl"
import { useDispatch, useSelector } from "react-redux"

import CommonButton from "../../components/Buttons/commonButton"
import Loader from "../../components/loader"
import { GetCallLogs } from "../../services/report/reportService"
import { convertTimeZone, formatPhoneNumber } from "../../utils"
import CallDialer from "./component"


function OutBoundCallPage(){
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {isMissedCallPing}=useSelector((state)=>state?.firebase)
    const dispatch=useDispatch()
    const initialState={
        Page_Index_int: +1,
        Page_Size_int: 100,
        // Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: 'lastUpdatedDateTime_dtm desc',
        // Direction_chr:'',
        // Status:'no-answer',
        // Paginate_ysn:false,
        // Return_All_Rows_ysn:true,
      }
    const [tableState,setTableState]=useState(initialState)
    const [historyList,setHistoryList]=useState([])
    const [selectedData,setSelectedData]=useState({})
    const [toggleDialer,setToggleDialer]=useState(true)
    const [toggleDialer2,setToggleDialer2]=useState(false)
    const [phNumber,setPhNumber]=useState('')
    const getCallLogs=()=>{
      const today = new Date();
    const endDate = new Date(today); // Copy the current date to the end date
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - 6)
      dispatch(GetCallLogs({...tableState,
          TimeStamp_from: startDate.toISOString().slice(0, 10) + `T00:00:00`,
        TimeStamp_to: endDate.toISOString().slice(0, 10) + `T23:59:59`,
      },(res)=>{
          setHistoryList(res?.callLogsTable)
      }))
    }
    useEffect(()=>{
      getCallLogs()
    },[])
    useEffect(()=>{
      if(isMissedCallPing)
     { getCallLogs()}
},[isMissedCallPing])
    const handleRowClick=(row)=>{
      console.log('selected row',row)
      setToggleDialer(false)
      setSelectedData(row)
      if(row?.direction_chr==='outbound-dial'){
        setPhNumber(row?.callTo_chr)
      }else{
        setPhNumber(row?.callFrom_chr)
      }
      
    }
    const handleCallClick=()=>{
      console.log('click')
      setToggleDialer(!toggleDialer)
      setToggleDialer2(!toggleDialer2)
      // dispatch({type:'IS_CALL_CONNECTED',payload:{isCallConnected:true}})
    }
    const handleSmsClick=()=>{
      console.log('sms click')
      dispatch({ type: 'SMS_MODAL_OPEN',payload: { customers: [{
        customerName_chr:'',
        phone_chr:phNumber
      }] } });
    }
    useEffect(()=>{
      if(toggleDialer2){
        dispatch({type:'IS_CALL_CONNECTED',payload:{isCallConnected:true}})
      }else{
        getCallLogs()
      }
    },[toggleDialer2])
    return <>
    {spinner?<Loader/>:null}
    <div className="grid grid-cols-12 gap-2 pt-2">
       
           <div className="h-[87vh] col-span-9 overflow-y-auto bg-white relative">
          <div className="sticky top-0 p-2 bg-white">
          <Typography variant="h5">
                Calls
            </Typography>
          </div>
            {historyList.length? historyList?.map((data,index)=><div
             key={index}
             className={`w-full p-4 flex items-center justify-between hover:bg-gray-100 cursor-pointer ${data?.siD_ids===selectedData?.siD_ids?'bg-gray-200':''}`}
             onClick={()=>handleRowClick(data)}
             >
                <div>
                    <b className="flex items-center gap-x-2">
                     
                      {data?.direction_chr==='outbound-dial'?
                      <>
                       <SlCallOut size={18} color="#FF0000"/>
                     { formatPhoneNumber(data.callTo_chr)}
                      </>
                      :
                      <>
                      <SlCallIn size={18} color="#228B22"/>
                      {formatPhoneNumber(data.callFrom_chr)}
                      </>
                      }</b>
                    <p className="flex items-center gap-x-2 text-xs pl-8">
                       { data?.direction_chr==='outbound-dial'? <>
                      
                        Outgoing from : {formatPhoneNumber(data.callFrom_chr)}
                        </>: 
                       <>
                       
                        Incoming for : {formatPhoneNumber(data.callTo_chr)}
                        </>
            }
                        </p>
                </div>
                <div>{convertTimeZone(data.timeStamp_dtm)}</div>
                </div>):<div className="h-[79vh] flex items-center justify-center text-2xl font-bold">
                  No data
                  </div>}
           </div>
         
           <div className="col-span-3">
           { 
           toggleDialer?<CallDialer toggleDialer2={toggleDialer2} setToggleDialer2={setToggleDialer2} phNumber={phNumber} setPhNumber={setPhNumber} />
           :
           <div className="h-[87vh] p-4 bg-white">
           <div className='grid grid-cols-3 gap-4 justify-items-center'>
     <div className='col-span-3'><FaUserCircle size={52}/></div>
     <div className='col-span-3'>{selectedData?.direction_chr==='outbound-dial'?formatPhoneNumber(selectedData?.callTo_chr):formatPhoneNumber(selectedData?.callFrom_chr)}</div>
     <div className="col-span-3 flex items-center justify-center gap-x-4"> 
    <div 
    className="p-2 border rounded-full flex items-center justify-center border-gray-300 cursor-pointer hover:bg-gray-300"
    onClick={handleCallClick}
    >
    <MdOutlineCall size={24}/>
    </div>
     <div 
     className="p-2 border rounded-full flex items-center justify-center border-gray-300 cursor-pointer hover:bg-gray-300"
     onClick={handleSmsClick}
     >
     <MdOutlineSms size={24}/>
     </div>
     </div>
     {selectedData?.direction_chr==='outbound-dial'?<div className="w-full col-span-3">
      <div className="w-full text-xs flex justify-end">{convertTimeZone(selectedData.timeStamp_dtm)}</div>
      <div className="rounded bg-gray-100 p-2">
        {/* <div className="py-2 font-bold text-lg">Call</div> */}
        <div className="flex items-center gap-x-4">
          <div 
          className="p-1 bg-red-500 text-white rounded"
          >
          <MdCallMade size={18}/>
          </div>
         <div className="text-red-500">
         Outgoing
         </div>
        </div>
        <div className="flex flex-col gap-y-4 p-4 text-xs">
          <p>To : {formatPhoneNumber(selectedData?.callTo_chr)}</p>
          <p>From : {formatPhoneNumber(selectedData?.callFrom_chr)}</p>
        </div>
      </div>
     </div>
     :
     <div className="w-full col-span-3">
     <div className="w-full text-xs flex justify-end">{convertTimeZone(selectedData.timeStamp_dtm)}</div>
     <div className="rounded bg-gray-100 p-2">
       {/* <div className="py-2 font-bold text-lg">Call</div> */}
       <div className="flex items-center gap-x-4">
         <div 
         className="p-1 bg-green-500 text-white rounded"
         >
         <MdCallReceived size={18}/>
         </div>
        <div className="text-green-500">
        Incoming
        </div>
       </div>
       <div className="flex flex-col gap-y-4 p-4 text-xs">
         <p>From : {formatPhoneNumber(selectedData?.callFrom_chr)}</p>
         <p>To : {formatPhoneNumber(selectedData?.callTo_chr)}</p>
       </div>
     </div>
    </div>}
     <div className="w-full col-span-3 pt-[100px]">
     <CommonButton onClick={()=>{setToggleDialer(!toggleDialer)}}>
      <MdDialpad size={18}/>
      Dial
      </CommonButton>
     </div>
     </div>
     
           </div>
           }
           </div>
    </div>
    </>
}
export default OutBoundCallPage