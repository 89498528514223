import React, { useEffect, useState } from 'react';

import { FaSmile } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/loader';

const ResubscribeSuccess = () => {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
   
    return (
      <>
      {spinner?<Loader/>:null}
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-[50vw] flex flex-col gap-6 bg-white rounded-lg shadow-lg p-6  text-center border-t-4 border-[#95B632]">
                <div className='flex items-center justify-center'><FaSmile size={48} color='FFBF00'/></div>
                <h1 className="text-2xl font-bold text-gray-800">You have been successfully resubscribed</h1>
                <div className="text-gray-600 text-lg">Thank you.</div>            
            </div>
        </div>
        </>
    );
};

export default ResubscribeSuccess;
