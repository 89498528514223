import React, { useState } from 'react';

import {TextField,MenuItem,Grid,Paper,Container,Typography,Chip,Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaCheck } from 'react-icons/fa';
import { MdCall, MdClose, MdEdit, MdEmail, MdMail, MdSms } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CommonButton from '../../components/Buttons/commonButton';
import BillingInfo from '../../components/Modals/BillingInfoModal/component';
import InvoiceAddress from '../../components/Modals/InvoiceAddressModal/component';
import InvoiceTable from './invoiceTable';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      width:'100%',
      // height:'300px',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
    //   border:'1px solid #95B632',
      borderRadius:'10px',
      gap:'20px',
      textWrap:'wrap',
      '&:hover': {
        transform: 'scale(1.05)', /* Adjust the scale value as needed */
        transition: 'transform 0.3s ease', /* Adjust the transition duration and timing function as needed */
      }
    },
    
  }));
function Billing(){
  const classes = useStyles(); 
  const {tenant}=useSelector((state)=>state.login.loginData)
  const history=useHistory()
  const [isInVoiceAdressModalOpen,setIsInVoiceAdressModalOpen]=useState(false)
  const [isBillingInfoModalOpen,setIsBillingInfoModalOpen]=useState(false)
 const OnClickEmailPlans=()=>{
  history.push(`/${tenant}/plans/email`)
 }
 const handleInvoiceAddressAddEdit=()=>{
  setIsInVoiceAdressModalOpen(true)
 }
 const handleBillingInfoAddEdit=()=>{
  setIsBillingInfoModalOpen(true)
 }
    return (
    <>
    {isInVoiceAdressModalOpen && <InvoiceAddress open={isInVoiceAdressModalOpen} setOpen={setIsInVoiceAdressModalOpen}/>}
    {isBillingInfoModalOpen && <BillingInfo open={isBillingInfoModalOpen} setOpen={setIsBillingInfoModalOpen}/>}
    <div className='w-full pt-8'>
        
        <Container>
        <Grid container spacing={4}>
            <Grid item xs={12}>
             <div className='p-2 grid grid-cols-2 gap-4 w-full bg-white rounded shadow-lg rounded-t'>
                <div className='col-span-2 p-4 bg-black text-white rounded-t'>
                    <Typography>Billing</Typography>
                </div>
             <div>Billing Address</div>
             <div className='bg-gray-100 p-2'>
                <div className='flex items-center justify-between'>Vivek Sharma
                 <button className='hover:bg-gray-200 p-1 rounded' onClick={handleBillingInfoAddEdit}> <MdEdit size={18} /></button>
                </div>
                <div>3753 Howard Hughes PKWY</div>
                <div>SUITE 200 - 803</div>
                <div>Las Vegas, NV 89169</div>
                <div>US</div>
             </div>
             <div>Invoice Address</div>
             <div className='bg-gray-100 p-2'>
             <div className='flex items-center justify-between'>Vivek Sharma
                 <button
                 className='hover:bg-gray-200 p-1 rounded'
                 onClick={handleInvoiceAddressAddEdit}
                 > <MdEdit size={18} /></button>
                </div>
             <div>info@loadkarma.com</div>
             <div>Load Karma</div>
                <div>3753 Howard Hughes PKWY</div>
                <div>SUITE 200 - 803</div>
                <div>Las Vegas, NV 89169</div>
                <div>US</div>
             </div>
             <div>Payment Method</div>
             <div className='bg-gray-100 p-2'>
             <div className='flex items-center justify-between'>Add Payment Method
                 <button className='hover:bg-gray-200 p-1 rounded' onClick={handleBillingInfoAddEdit}> <MdEdit size={18} /></button>
                </div>
                
             </div>
             </div>
            </Grid>
            <Grid item xs={12}>
             <div className='p-2  w-full bg-white rounded shadow-lg rounded-t grid grid-col-1 gap-4'>
                <div className='p-4 bg-black text-white rounded-t'>
                    <Typography>Invoices</Typography>
                </div>
                <div>
                    <InvoiceTable/>
                </div>
             
             </div>
            </Grid>
                
               
        </Grid>
        </Container>
    </div>
    </>)
}
export default Billing;