import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getCredentials } from './credentials';

 function ProtectedRoute({ component: Component, loginData, ...rest }) {
  const {tenant}=useSelector((state)=>state?.login?.loginData)
  
  const isAuth = useSelector((state) => state?.login?.authorized);
 
  console.log('isAuth protected20',isAuth)
  return (
    <Route
      {...rest}
      render={() => {
        if (isAuth) {
          return <Component />;
        } else {
          return <Redirect to={{ pathname: '/' }} />;
          // return <Redirect to={{ pathname: `/${tenant}` }} />;

        }
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.login,
  };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
