import React, { Component, useEffect, useState } from 'react';

import { InputAdornment } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
// import Chip from '@material-ui/core/Chip';
// import { indigo } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { BsClipboard2Fill } from 'react-icons/bs';
import { FaTowerCell } from 'react-icons/fa6';
import { ImSearch, ImStatsDots } from 'react-icons/im';
// import { IoMdCall } from 'react-icons/io';
// import { MdMail } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import MuiSearch from '../../components/InputFields/SearchFields/muiSearch';
import Loader from '../../components/loader';
import { getCustomersList, getDashboardData,getStateByCountryRef } from '../../services/customer/customerService';
import { GetEmailDNDList } from '../../services/report/reportService';
const useStyles = makeStyles((theme) => ({
    cardHeader: {
      '& MuiChip-root': {
        backgroundColor: '#3f51b5',
      },
    },
    chip: {
      backgroundColor: '#0288d1',
      color: '#fff',
    },
  
    outerTitleIcons: {
      width: 20,
      height: 20,
      marginBottom: 3,
      marginLeft: 15,
      color:'#263003'
    },
    root: {
      flexGrow: 1,
      padding: 15,
    },
    innerroot: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: theme.spacing(0, 3),
    },
    innerpaper: {
      minWidth: 275,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      backgroundColor: '#F4F2FF',
    },
    search: {
      position: 'relative',
      // borderRadius: theme.shape.borderRadius,
      // backgroundColor: alpha(theme.palette.common.white, 1),
      '&:hover': {
        // backgroundColor: alpha(theme.palette.common.white, 1),
      },
      // marginRight: theme.spacing(2),
      marginLeft: 0,
      // width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    heading: {
      fontWeight: 'bold',
      marginTop: 15,
    },
    statsNumbers: {
      height: '26px',
      width: '27px',
      color: '#9BA3B9',
      fontSize: '22px',
      lineHeight: '26px',
    },
    cardContent: {
      padding: '4px 10px',
    },
    cardHeader: {
      padding: '8px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  })
);
  const handleSearch=()=>{}
function Dashboard(){
  const classes = useStyles();
  const dispatch=useDispatch()
    const [searchVal,setSearchVal]=useState('')
    const [customerCreated,setCustomerCreated]=useState([])
    const [card,setCard]=useState({
        customerName_chr:'',
        phone_chr:''
    })
    const [weekStats,setEeekStats]=useState({
        callsMade:'',
        callsReached:'',
        callsWon:'',
        emailsSent:'',
        emailsRead:'',
        emailReplies:'',
        textsSent:'',
        textRead:'',
        textReplies:''

    })
    const [liveFeed,setLiveFeed]=useState([])
    return <>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={9}>
              <Typography variant="h5" component="h5" className={classes.heading}>
                Dashboard Overview
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <div className='relative'>
                <MuiSearch onChange={handleSearch} />
                {
              searchVal?.length?
             <div className='ml-4 w-[250px] absolute top-[3rem] left-0 p-2 font-bold bg-white drop-shadow-lg rounded'>0 Match Found</div>:null
            }
              </div>            
              </Grid>
           
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              {/* <Grid item lg={12} sm={12} xl={12} xs={12}> */}
              <Paper className={classes.paper}>
                <Typography component="div">
                  <Box fontWeight="fontWeightBold" m={1} textAlign="left" color="black" className="flex items-center">
                    Customer Created Today <BsClipboard2Fill className={classes.outerTitleIcons} />
                     {/* <img src="Task.png" alt="Task" className={classes.outerTitleIcons} /> */}
                  </Box>
                </Typography>

                {customerCreated?.map((card, i) => (
                  <Card className={classes.innerpaper}>
                    {/* <CardHeader
                    className={`text-left ${classes.cardHeader}`}
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                      </Avatar>
                    }
                    title={card.customerName}
                    action={
                      <Chip
                        size="small"
                        label={card.actionForToday}
                        className={classes.chip}
                      />
                    }
                    subheader={card.actionTime}
                  /> */}
                    <div className="flex items-center  justify-between">
                      <div>Name: {card.customerName_chr}</div>
                      {card?.phone_chr ? (
                        <div className="">Phone: {card?.phone_chr}</div>
                      ) : (
                        <div className="pr-[1.5vw]">Phone: Not Provided</div>
                      )}
                    </div>
                  </Card>
                ))}

                {/* --------- */}
              </Paper>
            </Grid>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <Paper className={classes.paper}>
                {' '}
                <Typography component="div">
                  <Box fontWeight="fontWeightBold" m={1} textAlign="left" color="black" className="flex items-center">
                    This week stats <ImStatsDots className={classes.outerTitleIcons}/>
                    {/* <img src="Vector.png" alt="Vector" className={classes.outerTitleIcons} /> */}
                  </Box>
                </Typography>
                {/* -----------------Inner Content Cell 2 --------------- */}
                <Card className={classes.innerpaper}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Calls Made
                      </Typography>
                      <Typography
                        align="left"
                        // variant="subtitle2"
                        color="textSecondary"
                        className={classes.statsNumbers}
                      >
                        {weekStats?.callsMade}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Reached
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.callsReached}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Won
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.callsWon}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <Card className={classes.innerpaper}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Emails Sent
                      </Typography>
                      <Typography
                        align="left"
                        // variant="subtitle2"
                        className={classes.statsNumbers}
                        color="textSecondary"
                      >
                        {weekStats?.emailsSent}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Read
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.emailsRead}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Replies
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.emailReplies}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <Card className={classes.innerpaper}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Text Sent
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.textsSent}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Read
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.textRead}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                      <Typography align="left" variant="subtitle2">
                        Replies
                      </Typography>
                      <Typography
                        align="left"
                        className={classes.statsNumbers}
                        // variant="subtitle2"
                        color="textSecondary"
                      >
                        {weekStats?.textReplies}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                {/* ------------------------------------------ */}
              </Paper>
            </Grid>
            <Grid item lg={4} sm={12} xl={4} xs={12}>
              <Paper className={classes.paper}>
                <Typography component="div">
                  <Box fontWeight="fontWeightBold" m={1} textAlign="left" color="black" className="flex items-center">
                    Live Feed <FaTowerCell className={classes.outerTitleIcons}/>
                    {/* <img src="LiveFeed.png" alt="live" className={classes.outerTitleIcons} /> */}
                  </Box>
                </Typography>
                {/* -----------------Inner Content Cell 3 --------------- */}
                {liveFeed?.map((card) => (
                  <Card className={classes.innerpaper}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          JD
                        </Avatar>
                      }
                      title={card.title}
                      subheader={card.subTitle}
                      className={`text-left ${classes.cardHeader}`}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {card.bodyText}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}

                {/* ------------------------------------------ */}
              </Paper>
            </Grid>
          </Grid>
        </div>
    </>
}
export default Dashboard