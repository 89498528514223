import ActionConstants from '../config/AppConstants';
const innitialData = {
  isOpen: false,
  name:'',
  body:'',
};
export default function signatureModal(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.SIGNATURE_MODAL_OPEN: {
      return {
        isOpen: action.isOpen,
        name:action.name,
        body:action.body,
      };
    }
    default:
      return { ...state };
  }
}
