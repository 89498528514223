import { serviceCallWithToken, serviceCallWithToken2 } from "../serviceCall";

export const sendFirebaseTokenToServer = (
    data,
    callback,
    errorCallback,
    // finallyCb
  ) => {
    console.log(
      '🚀 ~ file:firebase.js ~ line 56 ~ sendFirebaseTokenToServer ~ data',
      data
    );
    serviceCallWithToken({
      url: '/api/FCM/SaveFirebaseToken',
      method: 'post',
      data,
    })
      .then((response) => {
        console.log(
          '🚀 ~ file:firebase.js ~ line 63 ~ sendFirebaseTokenToServer ~ response',
          response
        );
  
        callback && callback(response.data);
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file:firebase.js ~ line 68 ~ sendFirebaseTokenToServer ~ error',
          error
        );
  
        errorCallback && errorCallback(error.response?.data);
      })
    //   .finally(() => {
    //     finallyCb && finallyCb();
    //   });
  };
  export const sendPushNotification = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/FCM/SendPushNotification',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getFCMBroadcastMessage = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/FCM/GetFCMBroadcastMessage',
        method: 'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const markAsReadUnread = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/FCM/MarkAsReadUnread',
        method: 'put',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postPutFCMBroadcastMessage = (data, callback, errorCallBack) => {
    return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken2({
        url: '/api/FCM/PostPutFCMBroadcastMessage',
        method: 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          if (response && response.data) {
            callback && callback(response.data);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };