import React, { useEffect, useState } from 'react';

import { Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DefaultButton from '../../../components/Buttons/defaultButton';
import SaveButton from '../../../components/Buttons/saveButton';
import TxtField from '../../../components/InputFields/TextFields/TextField';
import Loader from '../../../components/loader';
import { getActiveNumbers, getRingGroups,postAutoAttendantAction,postDialPlan, postDialPlanNode} from '../../../services/twilio/twilioPhoneService';
import { getAllActiveNumbers } from '../../../services/userService';
import { formatPhoneNumber } from '../../../utils';
import CommonButton from '../../Buttons/commonButton';
import MuiDropDown from '../../InputFields/DropDown/autoComplete';
// import { forEach } from 'lodash';

export default function AddEDitDialPlanModal({open,setOpen,selectedRow,action,setAction,reloadList,ringGroupNumbers}) {
    const {userId,tenant}=useSelector((state)=>state.login.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const dispatch=useDispatch()
    const history=useHistory()
//   const [open, setOpen] = React.useState(false);
const [selectedNumber,setSelectedNumber]=useState([])
const initialData={
  dialplanName:'',
  dialplanDescription:'',
  assignedPhoneNumberId:null
}
const [formData,setFormData]=useState(initialData)
const [numbersList,setNumbersList]=useState([])
const [selectedRingGroupMember,setSelectedRingGroupMember]=useState([])
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedNumber([])
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNumber(null)
    setFormData([])
  };
useEffect(()=>{
  console.log(action,selectedRow)
  if(open && action==='Edit')
{
  setFormData((state)=>({
    dialplanId:selectedRow?.dialplanID_ids,
  dialplanName:selectedRow?.dialplanName_chr,
  assignedPhoneNumberId:selectedRow?.assignedPhoneNumberID_lng,
}))
const indexVal=numbersList.findIndex((data)=>data.id===selectedRow?.assignedPhoneNumberID_lng)
setSelectedNumber(numbersList[indexVal])
console.log('selectedNumber',numbersList,indexVal,selectedNumber)
}
else{
  setFormData(initialData)
}
},[open,action,selectedRow,numbersList])
console.log('final form data',formData)
const handleChange=(e)=>{
    const {name,value}=e.target
    if(name==='Extension'){
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state)=>({
        ...state,
        [name]:onlyNums
    }))
    }else
   { 
    setFormData((state)=>({
        ...state,
        [name]:value
    }))
  }

}
const createAutoAttendentAsFirstNode=(id)=>{
  const dialPlanId=id
  const payload={
    dialPlanID:parseInt(dialPlanId),
    parentNodeID:null,
    actionTypeID:1,
    actionParameters:null,

  }
  dispatch(postDialPlanNode(payload,(res)=>{
    console.log(res)
    const id=res.split(':')[1].trim()
    const payload={
      nodeId_lng:parseInt(id),
      digit_chr:null,
      description_chr:"",
      groupOrPhoneId_lng:null,
      toNodeId_lng:null,
    }
    dispatch(postAutoAttendantAction(payload,(res)=>{
      console.log(res)
  handleClose()
  history.push(`/${tenant}/dial-plans/${parseInt(dialPlanId)}`)

    }))
  }))
}
const handleOnlySave=(e)=>{
  e.preventDefault()
  dispatch(postDialPlan(formData,(res)=>{
    console.log(res)
   
    if(res?.includes('Cannot insert duplicate key row')){
      NotificationManager.error('Phone number already used in another dial plan.')
    }else{
      reloadList()
      const id=res.split(':')[1].trim()
      console.log('ididid',id)
      if(action=='Add'){
        createAutoAttendentAsFirstNode(id)
      }else{
        handleClose()
    // history.push(`/${tenant}/dial-plans/${parseInt(id)}`)
      }
    }
    
  },(err)=>{
    console.log('95 err',err.data)
    if(err.includes('AssignedPhoneNumberID_lng must be unique') || err.includes('Cannot insert duplicate key row')){
      NotificationManager.error('Phone number already used in another dial plan.')
    }
  }))
}
const handleSubmit=(e)=>{
console.log(formData)
e.preventDefault()
dispatch(postDialPlan(formData,(res)=>{
  console.log(res)
 
  if(res?.includes('Cannot insert duplicate key row')){
    NotificationManager.error('Phone number already used in another dial plan.')
  }else{
    reloadList()
    const id=res.split(':')[1].trim()
    console.log('ididid',id)
    if(action=='Add'){
      createAutoAttendentAsFirstNode(id)
    }else{
      handleClose()
  history.push(`/${tenant}/dial-plans/${parseInt(id)}`)
    }
  }
  
},(err)=>{
  console.log('95 err',err.data)
  if(err.includes('AssignedPhoneNumberID_lng must be unique') || err.includes('Cannot insert duplicate key row')){
    NotificationManager.error('Phone number already used in another dial plan.')
  }
}))
}
useEffect(()=>{
  dispatch(getAllActiveNumbers({
    Page_Index_int: 1,
Page_Size_int: 20,
Sort_Expression_Delim_mem: 'assignedUsers asc',
Paginate_ysn:false,
  },(res)=>{
    console.log('92',res)
    setNumbersList(res?.tblActiveNumbers)
  }))
},[])
  return (
    
      <Dialog
        open={open}
      >
        {spinner?<Loader/>:null}
        <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">{action} Dial Plan</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
          <Grid item xs={12}><TxtField name='dialplanName' required={true} label='Name'  variant='outlined' value={formData?.dialplanName} onChange={handleChange}/></Grid>
              {/* <Grid item xs={12}>
              <TxtField
              name='Extension'
              required={true}
              label='Extension '
              value={formData?.Extension}
              onChange={handleChange}
              variant='outlined'
              inputProps={{ maxLength: 3 }}
            /></Grid> */}
            <Grid item xs={12}>
            <MuiDropDown
            fullWidth={true}
              // margin='dense'
              // required={true}
              name='assignedPhoneNumberId'
              size="small"
              label="Phone Number"
              options={numbersList}
              getOptionLabel={(option) => formatPhoneNumber(option?.twilioNumber)}
              value={selectedNumber}
              onChange={(event, value) => {
                // console.log('select sender',value)
                setSelectedNumber(value)
                if (value!==null) {
                  setFormData((state) => ({
                    ...state,
                    assignedPhoneNumberId: value.id,
                  }))
                }else{
                  setFormData((state) => ({
                    ...state,
                    assignedPhoneNumberId: null,
                  }))
                }
              }}
              renderOption={(option) => (<div><div>{option.assignedUsers}</div><p className='text-xs'>{formatPhoneNumber(option?.twilioNumber)}</p></div>)}
              style={{ 
                // width: '250px', 
                background: 'white' }}

            />
          </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className='flex items-center gap-2'>
          <div>
          <DefaultButton onClick={handleClose} color="primary">
            Cancel
          </DefaultButton>
          </div>
          {action==='Edit'?<div className='w-[90px] h-[38px] cursor-pointer flex items-center justify-center rounded-lg bg-[#263003] text-white hover:bg-[#95B632] hover:text-[#263003]' onClick={handleOnlySave}>
            Save
         </div>:null}
         <div>
         <CommonButton type='submit'>Save & Open</CommonButton>
         </div>
          </div>
        </DialogActions>
        </form>
      </Dialog>
  );
}
