// import ActionConstants from '../config/AppConstants';

const initialState = {
//   open: false,
  To: '',
  Subject: '',
  Body: '',
  lastUpdated:new Date(),
};

export default function mailState(
  state = initialState,
  { type, payload }
) {
  switch (type) {
  case 'MAIL_STATE_CHANGE': {
    const lastUpdated = Date.now();
    const stateObject = {lastUpdated}

    if(payload){
      const {To, Subject, Body} = payload;
      // console.log(state, 'state')
      // console.log('check payload',payload)
      if(To !== undefined)
        stateObject['To'] = To
      if(Subject !== undefined)
        stateObject['Subject'] = Subject
      if(Body !== undefined)
        stateObject['Body'] = Body
    }
    return {
      ...stateObject
    };
    //   return initialState
  }
  // case ActionConstants.MAIL_MODAL_OPEN: {
  //   if (payload) {
  //     const { customers = [], Body = '', Subject = '', massOption = false} = payload;
  //     // const customers = payload.customers || []
  //     // const To = payload.To || []
  //     // const Body = payload.Body || ''
  //     // const Subject = payload.Subject || ''
  //     // console.log(typeof To)
  //     return {
  //       open: true,
  //       // To,
  //       customers,
  //       Body,
  //       Subject,
  //       massOption
  //     };
  //   }
  //   return {
  //     open: true,
  //     customers: [],
  //     // To:[]
  //   };
  // }
  // case ActionConstants.MAIL_MODAL_CLOSE: {
  //   return initialState;
  // }

  default:
    return { ...state };
  }
}
