import React, { useEffect, useState } from 'react';

import {Button,TextField} from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { ImSpinner8 } from 'react-icons/im';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import CancelButton from '../../components/Buttons/cancelButton';
import SaveButton from '../../components/Buttons/saveButton';
import constant_label from '../../constants';
import { activateDNDList } from '../../services/report/reportService';
import { isValidEmail } from '../../utils';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    root: {
    },
    title:{
        display:'flex',
        alignItems:'center'
    }
  }));
export default function DNDListDialog(props) {
    const classes = useStyles();
    const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const userId=useSelector((state)=>state?.login?.loginData?.userId)
    const dispatch=useDispatch()
const {label,isDialogOpen,handleSubmitData,isOpenDNDListDialog,setIsOpenDNDListDialog,selectedEmail,reloadList}=props;
// console.log('selectedEmail',selectedEmail);
const [initState,setInitState]=useState({
  email:"",
  comments:""
});
const [initError,setInitError]=useState({
  isValidEmail:true,
  isValidComment:true
})
useEffect(()=>{
  const email=selectedEmail?.trim();
  // console.log('trim email',email)
  setInitState((state)=>({
    ...state,email:email
  }))
},[selectedEmail])
// const isValidEmail = (email) => {
//   // A simple regex pattern to check for a valid email format
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailPattern.test(email);
// };
const isValidComment = (comment) => {
  return comment.length >= 2;
};
  const handleSubmit = (event) => {
    event.preventDefault();
    const email=initState?.email.trim();
    if (isValidEmail(email) && isValidComment(initState?.comments)) {
      // console.log('Email and comment are valid. Submitting...');
      const payload={
        calling_UserID_chr:userId,
        email_chr:email.toLowerCase(),
        comment_chr:initState?.comments,
       }
      //  console.log('payload',payload)
      handleSubmitData(payload)
      setInitState({
              email:"",
              comments:""
            })
      //  dispatch(activateDNDList(payload,
      //   (res)=>{
      //     setIsOpenDNDListDialog(false);
      //     // console.log('check dnd res',res)
      //     NotificationManager.success('Email Added Succesfully !')
      //     setInitState({
      //       email:"",
      //       comments:""
      //     })
      //     reloadList();
      //     // console.log('finally',isOpenDNDListDialog)  
      //   }),(err)=>{
      //     NotificationManager.error('please try again !')
      //   })
      //  setIsOpenDNDListDialog(false);
      } 
    else {
      // console.log('Please correct the input fields'); 
      NotificationManager.error('Please fill all mandatory fields !')
     };
  }
  const handleCancel = () => {
    setInitState({
      email:"",
      comments:""
    })
    setInitError((state)=>({
    // ...state,
    isValidEmail:true,
    isValidComment:true
    }))
    setIsOpenDNDListDialog(false);
  };
const handleChange=(e)=>{
const {name,value}=e.target;
setInitState((state)=>({
    ...state,
    [name]:value
}))
}
// console.log('initState',initState)
// console.log('initError',initError)
const handleBlur=(e)=>{
  console.log('blur called')
  const {name,value}=e.target;
  // console.log('target',name,value)
 if(name=='email'){
    if (!isValidEmail(value)) {
      setInitError((state)=>({
        ...state,isValidEmail:false
      }))
    }
    else{
      setInitError((state)=>({
        ...state,isValidEmail:true
      }))
    }
  }
  if(name=='comments'){
    if (!isValidComment(value)) {
      setInitError((state)=>({
        ...state,isValidComment:false
      }))
    }
    else{
      setInitError((state)=>({
        ...state,isValidComment:true
      }))
    }
 }
}
  return (
    <div>
      <Dialog
      fullWidth
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
            {spinner ? (
            <>
              <div className="fixed z-40 min-h-full min-w-full bg-black opacity-50 top-0 left-0"></div>
              <div class="fixed inset-x-1/2 top-2/4	z-50 text-center">
                <div className='animate-bounce'>
        <img src='/connect_fav_icon.svg' className="h-12 w-12 max-w-fit animate-spin " />
        </div>
              </div>
            </>
          )  : null}
          <form onSubmit={handleSubmit}>
        <div className='flex item-center justify-between pr-[16px]'>
        <DialogTitle id="alert-dialog-slide-title">{label}</DialogTitle>         
        <DialogActions>
          <CancelButton onClick={handleCancel}/>
          <SaveButton/>
          {/* <Button onClick={handleCancel} color="primary">
            cancel
          </Button>
          <Button type='submit' color="primary" variant='contained'>
            save
          </Button> */}
        </DialogActions>
            </div>
        <DialogContent className={classes.content}>
           <label>Email*:</label>
           <input name='email' className='w-full border p-2' value={initState?.email} onChange={handleChange} onBlur={handleBlur}/>
          {initError?.isValidEmail===true?null: <p className='pt-1 text-xs text-red-500'>*{constant_label?.err_req_email} (Please enter vaild email)</p>}
        </DialogContent>
        <DialogContent>
          <label>Comments*:</label>
           <textarea  name='comments' className='w-full border p-2' value={initState?.comments} rows="4" onChange={handleChange} onBlur={handleBlur}/>
          {initError?.isValidComment===true? null:  <p className='pt-1 text-xs text-red-500'>*{constant_label?.err_dnd_comment}</p>}
        </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
