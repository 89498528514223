import { serviceCall, serviceCallWithToken } from '../serviceCall';

export const addNewUnreadNotification = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({type:'ADD_NEW_UNREAD_NOTIFICATION', payload:{notification:data}})

  }
}

export const getUnreadNotifications = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Customer/GetUnreadCustomer',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({
          type: 'ADD_UNREAD_NOTIFICATIONS',
          payload: { notifications: response?.data?.tblUnreadCustomer || [] },
        });
        if (response && response.data?.tblUnreadCustomer) {
          callback && callback(response.data?.tblUnreadCustomer);
          
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response);
      });
  };
};

export const makeNotificationRead = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Customer/PostPutCustomerRead',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response) {
          callback && callback(response);
         
        //   dispatch({
        //     type: 'ADD_UNREAD_NOTIFICATIONS',
        //     payload: { notifications: response?.data?.tblUnreadCustomer },
        //   });
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response);
      });
  };
};
