import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  search: {
    marginBottom: 20,
  },
});

const invoiceData = [
  { status: "paid", paymentDate: "jun 1, 2024", Amount: "$100" },
  { status: "paid", paymentDate: "jun 1, 2024", Amount: "$500" },
  { status: "paid", paymentDate: "aug 1, 2024", Amount: "$100" },
  { status: "paid", paymentDate: "jun 1, 2024", Amount: "$300" },
  { status: "failed", paymentDate: "july 1, 2024", Amount: "$100" },
  // Add more data here if needed
];

const InvoiceTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('paymentDate');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterData = (data) => {
    return data.filter(row => 
      row.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.paymentDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.Amount.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const filteredData = filterData(invoiceData);
  const sortedData = stableSort(filteredData, getComparator(order, orderBy));
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedData.length - page * rowsPerPage);


  const handleView = (row) => {
    // Add view logic here
    console.log('View:', row);
  };

  const handleDelete = (row) => {
    // Add delete logic here
    console.log('Delete:', row);
  };
  return (
    <div>
      <TextField
      size='small'
        className={classes.search}
        variant="outlined"
        placeholder="Search…"
        value={searchQuery}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'status' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sortDirection={orderBy === 'paymentDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'paymentDate'}
                  direction={orderBy === 'paymentDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('paymentDate')}
                >
                  Payment Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sortDirection={orderBy === 'Amount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Amount'}
                  direction={orderBy === 'Amount' ? order : 'asc'}
                  onClick={() => handleRequestSort('Amount')}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
              
                  Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.status}
                </TableCell>
                <TableCell align="right">{row.paymentDate}</TableCell>
                <TableCell align="right">{row.Amount}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleView(row)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row)}>
                    <GetAppIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default InvoiceTable;
