import React, { useEffect, useState } from 'react';

import { Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import DefaultButton from '../../../components/Buttons/defaultButton';
import SaveButton from '../../../components/Buttons/saveButton';
import TxtField from '../../../components/InputFields/TextFields/TextField';
import Loader from '../../../components/loader';
import { getListOfUsers } from '../../../services/customer/customerService';
import { assignRingGroupNumber, getActiveNumbers, getRingGroups, postRingGroup, putTwilioNumber, unassignRingGroupNumber } from '../../../services/twilio/twilioPhoneService';
import { getAllActiveNumbers } from '../../../services/userService';
import { formatPhoneNumber } from '../../../utils';
import MuiDropDown from '../../InputFields/DropDown/autoComplete';
// import { forEach } from 'lodash';

export default function AddEDitRingGroupModal(props) {
  const {open,setOpen,selectedRow,action,setAction,reloadList,ringGroupNumbers,maxExt}=props;
    const {userId}=useSelector((state)=>state.login.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const dispatch=useDispatch()
//   const [open, setOpen] = React.useState(false);
const [membersList,setMembersList]=useState([])
const [selectedMember,setSelectedMember]=useState([])
const initialData={
  Name:'',
  Extension:''
}
const [formData,setFormData]=useState(initialData)
const [activeNumbers,setActiveNumbers]=useState([])
const [selectedRingGroupMember,setSelectedRingGroupMember]=useState([])
const [suggestion,setSuggestion]=useState('')
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRingGroupMember([])
    setSuggestion('')
  };
useEffect(()=>{
  console.log(action,selectedRow)
  if(open && action==='Edit')
{
  setFormData((state)=>({
  ID:selectedRow?.id,
  Name:selectedRow?.groupName_chr,
  Extension:selectedRow?.extension,
}))
const payload={
  RingGroupID:selectedRow?.id,
  Paginate_ysn:false,
}
dispatch(getRingGroups(payload,(res)=>{
  console.log(res)
  setSelectedRingGroupMember(res.tblRingGroupNumbers)
}))
}
else{
  setFormData(initialData)
}
},[open,action,selectedRow])
useEffect(()=>{
  if(membersList?.length)
    {
      const matchingIndexes = membersList?.reduce((acc, item2, index) => {
      const matchingItem = selectedRingGroupMember.find(item1 => item1.phoneNumber === item2.twilioNumber);
      if (matchingItem) {
        acc.push(index);
      }
      return acc;
    }, []);
  console.log('788',matchingIndexes)
  const selectedRingGroupNumbers=matchingIndexes.map((pos)=>(membersList[pos]))
  setSelectedMember(selectedRingGroupNumbers)  
}
},[membersList,selectedRingGroupMember])
console.log('final form data',formData)
const handleChange=(e)=>{
    const {name,value}=e.target
    if(name==='Extension'){
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state)=>({
        ...state,
        [name]:onlyNums
    }))
    if(value===''){
      setSuggestion('')
    }else{
      setSuggestion(`Available extension : ${parseInt(maxExt)+1}`)

    }
    }else
   { 
    setFormData((state)=>({
        ...state,
        [name]:value
    }))
  }

}
// const handleSubmit=(e)=>{
// console.log(formData)
// e.preventDefault()
// console.log('selectedMemmebers',selectedMember)
// dispatch(postRingGroup(formData,(res)=>{
//   if(selectedMember.length)
//   {selectedMember.map((res,index)=>{
 
//     const payload={
//       Calling_UserID_chr:userId,
//       ID:res.id,
//       PhoneNumber:res.phoneNumber,
//       Extension:res.extension,
//       AreaCode:res.areaCode,
//       CountryCode:res.CountryCode,
//       RingGroupID:formData?.ID
      
//     }
//     console.log('103',payload)
//     dispatch(putTwilioNumber(payload,(res)=>{
//     console.log('response twilio no.',res)
//   },(err)=>{
//     console.log('error twilio no.',err)
//   }))
//   })}
//   handleClose();
//   reloadList();
// },(err)=>{
//   console.log('error ring grp 22',err)
//   if(err.includes('is already exist.')){
//     NotificationManager.error(`Group is alreay exist with same name : ${formData?.Name}.`)
//   }
//   else if((err.includes('already exists in the table.'))||(err.includes('already exists in the Active Numbers table'))){
//     NotificationManager.error('Given extension already in use.')
//   }
  
// }))


// }
const handleSubmit=(e)=>{
  console.log(formData)
  e.preventDefault()
  console.log('selectedMemmebers',selectedMember)
  dispatch(postRingGroup(formData,(res)=>{
    console.log('ring res',res.split[":"])
    const extractDigitsAfterColon = (str) => {
      const colonIndex = str.indexOf(':');
      if (colonIndex !== -1) {
        const digits = str.substring(colonIndex + 1).trim();
        return digits;
      }
      return null;
    };
    const grpID=extractDigitsAfterColon(res)
    if(selectedMember.length)
    {
      selectedMember.map((res,index)=>{
    const payload={
      // calling_UserID_chr:userId,
      phoneNumberID:res.id,
      ringGroupID:grpID
      
    }
    console.log('103',payload)

    dispatch(assignRingGroupNumber(payload,(res)=>{
      console.log('response twilio no.',res)
    },(err)=>{
      console.log('error twilio no.',err)
    }))
    })}
    handleClose();
    reloadList();
  },(err)=>{
    console.log('error ring grp 22',err)
    if(err.includes('is already exist.')){
      NotificationManager.error(`Group is alreay exist with same name : ${formData?.Name}.`)
    }
    else if((err.includes('already exists in the table.'))||(err.includes('already exists in the Active Numbers table'))){
      NotificationManager.error('Given extension already in use.')
    }
    
  }))
  
  
  }
useEffect(()=>{
  dispatch(getAllActiveNumbers({
    Page_Index_int: 1,
Page_Size_int: 20,
Sort_Expression_Delim_mem: 'createdDate asc',
Paginate_ysn:false,
  },(res)=>{
    console.log('92',res)
    setMembersList(res?.tblActiveNumbers)
  }))
},[])
console.log('selectedRingGroupMember',maxExt)
  return (
    
      <Dialog
        open={open}
      >
        {spinner?<Loader/>:null}
        <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">{action} Ring Group</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            
            <Grid item xs={12}>
              <TxtField
              required={true}
              name='Extension'
              label='Extension'
              value={formData?.Extension}
              onChange={handleChange}
              variant='outlined'
              inputProps={{ maxLength: 3 }}
              helperText={suggestion}
              // helperText='The extension can be dialed internally, dialed from dial plan, and calls can be transferred to it,Extensions must be unique.'
              
            /></Grid>
            <Grid item xs={12}><TxtField name='Name' required={true} label='Name'  variant='outlined' value={formData?.Name} onChange={handleChange}/></Grid>
            <Grid item xs={12}><Autocomplete
            multiple
            size="small" 
            value={selectedMember}          
            options={membersList}
            getOptionLabel={(option) => `${option.extension!==null?option.extension:''} ${option.assignedUsers}`}
            onChange={(event,value)=>{
              console.log('selected member',value)
              if (action!=='Add' && value.length < selectedMember.length) {
                const removedOption = selectedMember.find(opt => !value.includes(opt));
                console.log(`Removed: ${removedOption.id}`);
                const payload={
                  ringGroupID:formData?.ID,
                  phoneNumberID:removedOption.id
                }
                dispatch(unassignRingGroupNumber(payload,(res)=>{

                },(err)=>{console.log(err)}))
              }
              setSelectedMember(value)
             }
            }
            renderInput={(params) => (
              <TextField
               {...params}
              variant="standard"
              label="Group Members"
            />)}
            renderOption={(option)=>(<div><div>{option?.assignedUsers}</div><p className='text-xs'>{option.extension? `${option.extension} /`:null} {formatPhoneNumber(option.twilioNumber)}</p></div>)}

            
            
            /></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='grid grid-cols-12'>
          <div>
          <DefaultButton onClick={handleClose} color="primary">
            Cancel
          </DefaultButton>
          </div>
         <div>
         <SaveButton/>
         </div>
        </DialogActions>
        </form>
      </Dialog>
  );
}
