import ActionConstants from '../config/AppConstants';

const initialState = {
  // open:false,
  mailTemplateModalOpen:false,
  smsTemplateModalOpen:false,
  customer:{},
  customers:[],
  // emailTo:'',
  messageTo:''
};

export default function templateModalState(state = initialState, {type, payload}) {
  switch (type) {
  case 'TEMPLATE_MODAL_OPEN': {
    if(payload){
      const {title,fromMailModal,isFromSmsModal} = payload;
      return {
        templateTitle:title,
        templateModalOpen:true,
        fromMailModal:fromMailModal,
        isFromSmsModal:isFromSmsModal,
      }

    }
    return {
      templateTitle:'Email Templates',
      templateModalOpen:true

    }
  }
  case 'TEMPLATE_MODAL_CLOSE': {
    return {
      templateModalOpen:false,
      templateTitle:'',

    }
  }

  case ActionConstants.MAIL_TEMPLATE_MODAL_OPEN: {
    if(payload){
      const {customers=[], massOption = false} = payload
      return {
        mailTemplateModalOpen:true,
        customers,
        massOption
        // emailTo:payload.emailTo
      }
    }
    return {
      mailTemplateModalOpen:true,
      massOption:false,
    }
            
  }
  case ActionConstants.MAIL_TEMPLATE_MODAL_CLOSE:{
    return {
      mailTemplateModalOpen:false
    }
  }

  case ActionConstants.SMS_TEMPLATE_MODAL_OPEN: {
    if(payload){
      const {customers = [], massOption=false} = payload

      return {
        smsTemplateModalOpen:true,
        // messageTo:payload.messageTo
        customers,
        massOption
      }
    }

    return {
              
      smsTemplateModalOpen:true,
    }
  }
  case ActionConstants.SMS_TEMPLATE_MODAL_CLOSE: {
    return {
      smsTemplateModalOpen:false,
    }
  }
  default:
    return { ...state };
  }
}
  