const constant_label = {
  //---Drawer--
    lbl_drwr_dialsight:'DialSight',
    lbl_drwr_dialsight_comm:'DialSight Communication',
    lbl_drwr_dashboard:'Dashboard',
    lbl_drwr_engage:"Engage",
    lbl_drwr_customers:'Customers',
    lbl_drwr_stage1:'Stage 1',
    lbl_drwr_stage2:'Stage 2',
    lbl_drwr_stage3:'Stage 3',
    lbl_drwr_dnd_list:'DND List',
    lbl_drwr_mass_email:'Mass Email',
    lbl_drwr_mass_sms:'Mass SMS',
    lbl_drwr_templates:'Templates',
    lbl_drwr_email:'Email',
    lbl_drwr_sms:'SMS',
    lbl_drwr_reporting:'Reporting',
    lbl_drwr_email_logs:'Email Logs',
    lbl_drwr_sms_logs:'SMS Logs',
    lbl_drwr_voice_call_logs:'Voice Call Logs',
    lbl_drwr_video_call_logs:'Video Call Logs',
    lbl_drwr_Admin:'Admin',
    lbl_drwr_Setting:'Setting',
    lbl_drwr_users:'Users',
    lbl_drwr_roles:'Roles',
    lbl_drwr_agency:'Agency',
    lbl_drwr_buy_phone:'Buy Phone Number',
    lbl_drwr_permissons:'Permissions ',
    lbl_drwr_permisson_area:'Permissions Area',

    //---Page headings--
    lbl_hd_customer_overview:'Customer Overview',
    lbl_hd_dnd_list:'DND List',
    lbl_hd_bounce_list:'Bounce List',
    lbl_hd_email_templates:'Email Templates',
    lbl_hd_sms_templates:'SMS Templates',
    lbl_hd_sms_Campaigns:'SMS Campaigns',
    lbl_hd_email_logs:'Email Optimization',
    lbl_hd_call_logs:'Call Logs',
    lbl_hd_sms_logs:'SMS Logs',
    lbl_hd_users:'Users',
    lbl_hd_roles:'Roles',
    lbl_hd_add_agency:'Add New Agency',
    lbl_hd_agency_Doc:'Agency Documents',
    lbl_hd_buy_phone:'Buy Phone Number',
    //--CRM--
    lbl_hd_deal_stages:'Deal Stages',
    lbl_hd_deal_stage_1:'Site Visit',
    lbl_hd_deal_stage_2:'Quotation',
    lbl_hd_deal_stage_3:'Negotiation',
    lbl_hd_deal_stage_4:'Re-negotiation',
    lbl_hd_deal_stage_5:'Deal Win',
    lbl_hd_deal_stage_6:'Documentation',
    lbl_hd_deal_stage_7:'Deal Lose',
    lbl_hd_lead_stages:'Lead Stages',
    lbl_hd_lead_stage_1:'New Lead',
    lbl_hd_lead_stage_2:'Unassigned',
    lbl_hd_lead_stage_3:'In Progress',
    lbl_hd_lead_stage_4:'Processed',
    lbl_hd_lead_stage_5:'Good Lead',
    lbl_hd_lead_stage_6:'Junk Lead',
    lbl_hd_all_leads:'All Leads',

    //---Other

    lbl_txt_sign_in:'Sign in',
    lbl_txt_remember_me:'Remember me',
    lbl_txt_forgot_password:'Forgot password',
    lbl_txt_email_address:'Email Address',
    lbl_txt_email:'Email',
    lbl_txt_password:'Password',
    lbl_txt_reset_password:'Reset Password',
    lbl_txt_change_password:'Change Password',
    lbl_txt_Search:'Search...',
    lbl_txt_add_customer:'Add Customer',
    lbl_txt_edit_customer:'Edit Customer',
    lbl_txt_mandatory_field:'Please fill all mandatory fields marked as(*)',
    lbl_txt_actions:'Actions',
    lbl_txt_customer_name:'Customer Name',
    lbl_txt_name:'Name',
    lbl_txt_phone:'Phone',
    lbl_txt_country:'Country',
    lbl_txt_state:'State',
    lbl_txt_city:'City',
    lbl_txt_zip_code:'Zip Code',
    lbl_txt_role:'Role',
    lbl_txt_stage:'Stage',
    lbl_txt_industry:'Industry',
    lbl_txt_project:'Project',
    lbl_txt_designation:'Designation',
    lbl_txt_comment:'Comments',
    lbl_txt_type_comment:'Type your comment',
    lbl_txt_deal_Stage:'Deal Stage',
    lbl_txt_lead_Stage:'Lead Stage',
    lbl_txt_category:'Category',
    lbl_txt_file_name:'File Name',
    lbl_txt_account_owner:'Account Owner',
    lbl_txt_last_updated_dtm:'Last Updated Date/Time',
    lbl_txt_updated_by:'Updated By',
    lbl_txt_added_by:'Added By',
    lbl_txt_added_date_time:'Added Date/Time',
    lbl_txt_tmp_name:'Template Name',
    lbl_txt_tmp_sub:'Template Subject',
    lbl_txt_created_by:'Created By',
    lbl_txt_assigned_number:'Assigned Number',
    lbl_txt_add_user:'Add User',
    lbl_txt_edit_user:'Edit User',
    lbl_txt_first_Name:'First Name',
    lbl_txt_last_Name:'Last Name',
    lbl_txt_assign_number:'Assign Number',
    lbl_txt_user_agency:'User Agency',
    lbl_txt_agencies:'Agencies',
    lbl_txt_avl_no:'Available Numbers',
    lbl_txt_select_template:'Select template',
    lbl_txt_var:'Variables',
    lbl_txt_new_msg:'New Message',
    lbl_txt_agency_info:'Agency Information',
    lbl_txt_agency_name:'Agency Name',
    lbl_txt_contact_name:'Contact Name',
    lbl_txt_email_limit:'Email Limit',
    lbl_txt_ext:'Ext',
    lbl_txt_specific_person:'Specific Person',
    lbl_txt_email_subject:'Email Subject',
    lbl_txt_attachment_upload:'Attachment Upload',
    lbl_txt_sms_sent:'SMS Sent',
    lbl_txt_sms_activities:'SMS Activities',
    lbl_txt_queued:'Queued',
    lbl_txt_delivered:'Delivered',
    lbl_txt_undelivered:'Undelivered',
    lbl_txt_from:'From',
    lbl_txt_to:'To',
    lbl_txt_body:'Body',
    lbl_txt_status:'Status',
    lbl_txt_sms_info:'SMS Information',
    lbl_txt_switch_tenant:'Switch Tenant',
    lbl_txt_doc_upload:'Upload Documents',

    btn_login:'Login',
    btn_loging_in:'Loging in ...',
    btn_sign_in:'Sign in',
    btn_signing_in:'Signing in ...',
    btn_submit:'Submit',
    btn_save: 'Save',
    btn_cancel: 'Cancel',
    btn_send:'Send',
    btn_yes:'Yes',
    btn_no:'No',
    btn_mass_import:'Mass Import',
    btn_mass_email:'Mass Email',
    btn_mass_sms:'Mass SMS',
    btn_add_customer:'Add Customer',
    btn_add_another:'Add Another',
    btn_add_template:'Add Template',
    btn_add_user:'Add User',
    btn_add_role:'Add Role',
    btn_browse_file:'Browse files',
    btn_clear_all:'Clear All',
    btn_clear_selected:'Clear Selected',
    btn_select_template:'Select Template',
    btn_apply_template:'Apply Template',
    btn_add_agency:'Add Agency',
    btn_save_next:'Save & Next',
    btn_update_next:'Update & Next',

    err_req_customer_name:'Customer Name is required',
    err_req_email:'Email is required',
    err_req_phone:'Phone is required',
    err_dnd_comment:'Comment is required',
    err_req_first_name:'First Name is required',
    err_req_last_name:'Last Name is required',
    err_req_role:'Role is required',
    err_req_agency:'Agency is required',
    err_specify_recipient:'Please specify atleast one recipient',
    err_enter_email_subject:'Please enter email subject and body',
    err_msg_permission:'You are not allowed to perform this operation. Please contact the admin to request permission.',
    err_msg_for_call_service:`We're sorry, but it seems that you don't have a registered phone number to access our call service.Please contact the admin.`,
    err_msg_for_sms_service:`We're sorry, but it seems that you don't have a registered phone number to access our sms service.Please contact the admin.`,
    err_msg_no_file:'File not selected !',
  };
  export default constant_label;
  