import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';

import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import { resubscribeEmails, unsubscribeEmails } from '../../services/mail/mailService';

const Unsubscribe = () => {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const [email, setEmail] = useState('');
    const { tenantName } = useParams();
    const history=useHistory()
    const dispatch=useDispatch()
    const location = useLocation();
    useEffect(() => {
        
        const params = new URLSearchParams(location.search);
        
        // Get the email parameter
        const emailParam = params.get('email');
        
        if (emailParam) {
          setEmail(emailParam);
        }
      }, []);
      useEffect(() => {
        localStorage.setItem('domain', tenantName)
        dispatch({
          type: 'SAVE_TENANT',
          data: {
            domain:tenantName
          },
        });
      }, [])
      const unsubscribeClick=(event)=>{
        event.preventDefault()
        console.log('inside submit',email,tenantName)
        dispatch(unsubscribeEmails({email:email},(res)=>{
            console.log('check res 35',res)
            history.push(`/${tenantName}/re-subscribe?email=${email}`)
        },(err)=>{console.log('check error 39',err)
          
        }))
      }
      const reSubscribeClick=(event)=>{
        event.preventDefault()
         dispatch(resubscribeEmails({email:email},(res)=>{
            console.log('35',res)
            history.push(`/${tenantName}/re-subscribe/success`)
        },(err)=>{console.log('36',err)

          swal({
            title: "Error",
            text: err,
            icon: "error",
            dangerMode: true,
          });
        }))
      }
    return (
      <>
      {spinner?<Loader/>:null}
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="flex flex-col gap-6 bg-white rounded-lg shadow-lg p-6 max-w-md text-center">
                <h1 className="text-2xl font-bold text-gray-800">Unsubscribe</h1>
                <p className="text-gray-600 ">We're sorry to see you go. If you no longer wish to receive emails from us, please click the button to confirm your unsubscribe request.</p>
               <form className='flex flex-col gap-6' onSubmit={unsubscribeClick}>
                <TxtField variant='outlined' type="email" name="email" label='Email' value={email} disabled={true}/>
                <button
                 className="inline-block bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                //  onClick={unsubscribeClick}
                 >Unsubscribe</button>
               </form>

                <p className="text-gray-600">If you change your mind, you can <p 
                className="text-blue-500 hover:underline hover:cursor-pointer"
                onClick={reSubscribeClick}
                >resubscribe here</p>.</p>
            </div>
        </div>
        </>
    );
};

export default Unsubscribe;
