import React, { useEffect, useState, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { MdSend } from 'react-icons/md';
import { useSelector } from 'react-redux';

import constant_label from '../../constants';
import { convertTimeZone, currentDateTime, formatDate } from '../../utils';
import DefaultButton from '../Buttons/defaultButton';
import TxtField from '../InputFields/TextFields/TextField';
const Comments = ({ setCustomerData, commentList, onSubmitForm }) => {
  const { email } = useSelector((state) => state?.login?.loginData)
  const [currentDtm, setCurrentDtm] = useState('')
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [currentComment, setCurrentComment] = useState([])
  const commentListRef = useRef(null);
  useEffect(() => {
    setComments(commentList)
  }, [commentList])
  const handleSendMessage = () => {
    if (newComment.trim() !== '') {
      console.log(newComment)
      const currentDateTime = new Date();
      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      const formattedDtm = currentDateTime.toLocaleString('en-US', options);
      console.log('formattedDtm', formattedDtm)
      setCustomerData((state) => ({
        ...state,
        comments_chr: newComment
      }))
      setCurrentComment([{ comment_chr: newComment, email: email }])
      setCurrentDtm(formattedDtm)
      setNewComment('')
    } else {
      setCustomerData((state) => ({
        ...state,
        comments_chr: null
      }))
    }
  };
  const handleCommentChange = (e) => {
    setNewComment(e.target.value)
  }
  console.log('commentList', currentComment)
  useEffect(() => {
    // Scroll to the bottom when the component initially renders
    commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    // Scroll to the bottom whenever new comments are added
    commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
  }, [comments, newComment]);
  console.log('commenter', email)
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '10px', Height: '200px' }}>
          <Typography variant="subtitle1" gutterBottom>
            {constant_label.lbl_txt_comment}
          </Typography>
          <div
            ref={commentListRef}
            style={{

              overflowY: 'auto',

            }}
            className='border h-[200px] rounded  p-1 flex flex-col gap-y-1'
          >
            {comments.map((comment, index) => (
              <div
                key={index}
                style={{
                  // textAlign: message.sender === 'user' ? 'right' : 'left',
                  marginBottom: '10px',
                }}
                className='p-2 bg-gray-100 rounded'
              >
                <p style={{ display: 'inline-block' }} className='text-md'>
                  {comment.comment_chr}
                </p>
                <div className=' flex items-center justify-between text-xs'>
                  <p>{comment.email} </p>
                  <p>{convertTimeZone(comment.lastUpdatedDateTime_dtm)}</p>
                  </div>
              </div>
            ))}
            {currentComment.map((comment, index) => (
              <div
                key={index}
                style={{
                  // textAlign: message.sender === 'user' ? 'right' : 'left',
                  marginBottom: '10px',
                }}
                className='p-2 bg-gray-100 rounded'
              >
                <p style={{ display: 'inline-block' }} className='text-md'>
                  {comment.comment_chr}
                </p>
                <p className='text-xs'>
                  {`${comment.email} ${currentDtm} `}

                </p>
              </div>
            ))}
          </div>
          <div className='flex items-center'>
            <TxtField
              size='small'
              label={constant_label.lbl_txt_type_comment}
              fullWidth
              value={newComment}
              onChange={handleCommentChange}
            />
           {newComment && <div className='w-[50px]'>
            <DefaultButton onClick={handleSendMessage}>
              <MdSend size={24} />
            </DefaultButton>
            </div>}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Comments;
