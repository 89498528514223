import React from 'react';
import { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  // dialog:{
  //     zIndex: theme.zIndex.drawer + 2,
  // }
}));

function Prompt({ isPromptOpen,handleClose, handleModalClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={isPromptOpen}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Do you want to discard?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              handleModalClose();
              // dispatch({type:'MAIL_MODAL_CLOSE'})
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button onClick={() => handleClose()} color="primary" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Prompt;
