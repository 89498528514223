import { serviceCall, serviceCallWithToken, serviceCallWithToken2 } from '../serviceCall';


export const sendTextMessage = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/SendSMS',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response);
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
      });
  };
};
export const uploadSMSFile = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/UploadSMSFile',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response);
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
      });
  };
};

export const sendEmail = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCall({
      url: '/api/Email/SendEmail',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          callback && callback(response);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

export const makeVoiceCall = (data, callback, errorCallBack) => {
  // console.log(data,'data')
  return async (dispatch) => {
    serviceCall({
      url: '/api/VoiceCall/MakeVoiceCall',
      method: 'post',
      headers:{
        'Content-Type': 'application/json',
        // 'accept': '*/*'
      },
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          callback && callback(response);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response);
      });
  };
};
export const getTokenForMessageService = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Token/Generate',
      method: 'get',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response);
      });
  };
};
export const getConversationAllMessages = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/GetConversationAllMessages',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };
};
export const createConversation = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken2({
      url: '/api/SMS/CreateConversation',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const sendConversationMessage = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/SendConversationMessage',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const uploadConversationMedia = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/SMS/UploadConversationMedia',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getConversationsByPhoneNumber = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    // dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/GetConversationsByPhoneNumber',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response?.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };
};