const innitialData = {
   isNotificationOpen:false,
   data:{}
  };
  export default function notificationModal(state = innitialData, action) {
    switch (action.type) {
       case 'IS_NOTIFICATION_OPEN':{
        return{
          ...state,
          isNotificationOpen:action.isNotificationOpen,
          data:action.data
        }
      }
      default:
        return { ...state };
    }
  }