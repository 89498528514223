import ActionConstants from '../config/AppConstants';

const initialState = {
  open:false,
  customers:[],
  Body:''
};

export default function smsModalState(state = initialState, {type,payload}) {
  switch (type) {
  case ActionConstants.SMS_MODAL_OPEN: {

    if(payload){
      // let To = payload.To || ''
      const {customers = [], Body = '', massOption=false} = payload 
      // let Body = payload.Body || ''
      return {open:true,customers,Body, massOption}
    }

    return {
      open:true
    }
        
  }
  case ActionConstants.SMS_MODAL_CLOSE:{
    return {
      open:false
    }
  }

  default:
    return { ...state };
  }
}
  