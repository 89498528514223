import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TxtField from '../../InputFields/TextFields/TextField';

export default function InvoiceAddress({open,setOpen}) {
//   const [open, setOpen] = React.useState(false);
const [formData, setFormData] = useState({
    firstName: 'Vivek',
    lastName: 'Sharma',
    companyName: 'Load Karma',
    email: 'info@loadkarma.com',
    streetAddress: '3753 HOWARD HUGHES PKWY',
    streetAddress2: 'SUITE 200-803',
    country: 'United States',
    city: 'Las Vegas',
    state: 'NV',
    postalCode: '89169',
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log(formData);
  };
  return (
    <div>
      <Dialog
      fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Invoice Address"}</DialogTitle>
        <DialogContent>
        <form onSubmit={handleSubmit} className="p-4 bg-white shadow-md rounded-md">
      <div className="mb-4">
        <TxtField
          label="First Name"
          name="firstName"
        //   variant='outlined'
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
         <TxtField
          label="Last Name"
          name="lastName"
        //   variant='outlined'
          value={formData.lastName}
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
         <TxtField
          label="Company Name"
          name="companyName"
          value={formData.companyName}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
         <TxtField
          label="Email Address"
          name="email"
          value={formData.email}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
         <TxtField
          label="Street Address"
          name="streetAddress"
          value={formData.streetAddress}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
         <TxtField
          label="Street Address 2"
          name="streetAddress2"
          value={formData.streetAddress2}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
        <TxtField
          label="Country"
          name="country"
          value={formData.country}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
        <TxtField
          label="City"
          name="city"
          value={formData.city}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
        <TxtField
          label="State"
          name="state"
          value={formData.state}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      <div className="mb-4">
         <TxtField
          label="Postal Code"
          name="postalCode"
          value={formData.postalCode}
        //   variant='outlined'
          onChange={handleChange}
          />
      </div>
      
    </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
