import React, { useCallback, useEffect, useState } from "react";

import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { MdModeEdit } from "react-icons/md";
import { useDispatch,useSelector } from "react-redux";

import MuiSearch2 from "../../components/InputFields/SearchFields/muiSearch2";
import Loader from "../../components/loader";
import Table from "../../components/Table";
import constant_label from "../../constants";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { getAllActiveNumbers } from "../../services/userService";
import { convertTimeZone, formatPhoneNumber } from "../../utils";
import ExtDetails from "./extDetailModal";

function DirectExtensions(){
    const dispatch=useDispatch();
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const [pageCount, setPageCount] = useState(1);
    const [totalRows, setTotalRows] = useState(0)
    const [rows, setRows] = useState([]);
    const fetchIdRef = React.useRef(0);
    const [tableState, setTableState] = useState({
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: 'lastUpdatedDateTime_dtm desc',
      });
      const [open,setOpen]=useState(false)
      const [selectedRow,setSelectedRow]=useState({})
      const [maxExt,setMaxExt]=useState(0)
      const [searchedVal, setSearchedVal] = useState('');
      const [globalSearchQuery,setglobalSearchQuery]=useState('')
      const onClickEdit=(row)=>{
        console.log(row)
        setOpen(true)
        setSelectedRow(row)
      }
      const tableColumns = [
        {
            accessor: 'acction',
            Header: constant_label?.lbl_txt_actions,
            disableSortBy:true,
            sortable: false,
            filterable: false,
            headerAlign: 'right',
            align: 'right',
            width: 70,
            Cell: ({ row: { original }, value, column }) => {
              return (
                <div s style={{ width: '100%' }}>
                 
                    <Tooltip title="Edit" arrow size='small'>
                      <IconButton
                        color="primary"
                        // className={classes.icons}
                        aria-label="edit"
                        onClick={(e) => onClickEdit(original)}
                      >
                        <MdModeEdit className="h-20px" fontSize="small" size={20} />
                      </IconButton>
                    </Tooltip>
                  
                 
                </div>
              );
            },
            flex: 1,
          },
        {
          accessor: 'phoneNumber',
          Header: 'Phone Number',
          minWidth: 140,
          width: 140,
          sticky: 'left',
          sortable: true,
          Cell: ({ value }) => {
            return <span>{formatPhoneNumber(value)}</span>;
          },
        },
        {
            accessor: 'extension',
            Header: 'Extension',
            width: 100,
            sortable: true,
            // Cell: ({ value }) => {
            //   return <span className=" w- full flex justify-center">{value}</span>
              
            // },
          },
        {
          accessor: 'assignedUsers',
          Header: 'Assigned To',
          // width: 150,
          sortable: true,
          Cell: ({ value }) => {
            return <Tooltip title={value}  placement="top-start">
              <span className="cursor-pointer">{value}</span>
            </Tooltip>;
          },
        },
        {
          accessor: 'email',
          Header: 'email',
          // width: 150,
          sortable: true,
          Cell: ({ value }) => {
            return <Tooltip title={value}  placement="top-start">
              <span className="cursor-pointer">{value}</span>
            </Tooltip>;
          },
        },
        {
          accessor: 'lastUpdatedDateTime_dtm',
          Header: constant_label?.lbl_txt_last_updated_dtm,
          width: 100,
          sortable: true,
          Cell: ({ value }) => {
            return convertTimeZone(value)
            
          },
        },
        
        ]
      const calculatePageCount = (rowsData, pageSize) => {
        const totalRows = parseInt(rowsData?.[0]?.['user_Count_Including_Criteria_int']);
        // const totalRows = parseInt(rowsData);
    
        setPageCount(Math.ceil(totalRows / pageSize));
      };
    const onTableStateChange = useCallback(
        ({ pageIndex: Page_Index_int, pageSize: Page_Size_int, globalFilter: Where_GeneralCriteria_Delim_mem, sortBy }) => {
          const fetchId = ++fetchIdRef.current;
          if (fetchId !== fetchIdRef.current) {
            return;
          }
          setTableState((state) => {
            const sort = sortBy?.[0];
            let sortingField = 'lastUpdatedDateTime_dtm';
            let sortingOrder = 'desc';
    
            if (sort) {
              sortingField = sort['id'];
              sortingOrder = sort['desc'] ? 'desc' : 'asc';
            }
            const Sort_Expression_Delim_mem = `${sortingField} ${sortingOrder}`;
            return {
              ...state,
              Page_Index_int: Page_Index_int + 1,
              Page_Size_int: Page_Size_int || 20,
              Where_GeneralCriteria_Delim_mem,
              Sort_Expression_Delim_mem: Sort_Expression_Delim_mem || '',
            };
          });
        },
        []
      );
      const fetchTableData = (callback) => {
        dispatch(getAllActiveNumbers(tableState, (data) => {
          console.log('check ress here',data)
          // setActiveNumberList(res?.tblActiveNumbers)
          callback && callback(data?.tblActiveNumbers);
          setRows(data?.tblActiveNumbers);
          calculatePageCount(data?.tblActiveNumbers,tableState?.Page_Size_int)
          const mExt=data?.table1[0].maxExtension
          const grpExt=data?.table2[0].groupMaxExtension
          if(mExt>grpExt)
          {
            setMaxExt(mExt)
          }
          else{
            setMaxExt(grpExt)
          }

        }))
        };
      useUpdateEffect(() => {
        fetchTableData();
        // sessionStorage.setItem('newLead-Page_Index_int', tableState?.Page_Index_int);
      }, [tableState]);
      const updatedValue = sessionStorage.getItem('directExtPage-global-search-query');
  React.useEffect(() => {
    setSearchedVal(updatedValue);
    setglobalSearchQuery(updatedValue);
    const updatedPageIndex = sessionStorage.getItem('directExtPage-Page_Index_int');
    const newTableState = { ...tableState };
    newTableState['Where_GeneralCriteria_Delim_mem'] = updatedValue;
    setTableState(newTableState);
  }, [updatedValue]);
  useEffect(()=>{
    if(searchedVal===""){
      // setSearchedVal('')
      setglobalSearchQuery('')
  
    }
  },[searchedVal])
      const requestSearch = (e ) => {
        const { name, value } = e.target;
      setSearchedVal(value);
    
    }
    
    const handleSearch=()=>{
      const searchQuery=searchedVal.trim()
        setglobalSearchQuery(searchQuery)
        sessionStorage.setItem(
          "directExtPage-global-search-query",searchQuery
        );
      }
      const handleCloseIcon=()=>{
        setSearchedVal('')
        setglobalSearchQuery('')
        sessionStorage.setItem(
          "directExtPage-global-search-query",''
        );
      }
      const handleKeyPress=(event)=>{
        if (event.key === "Enter") {
          event.preventDefault();
      const searchQuery=searchedVal.trim()
          setglobalSearchQuery(searchQuery)
        sessionStorage.setItem(
          "directExtPage-global-search-query",searchQuery
        );
        }
      }
    return <>
    {spinner?<Loader/>:null}
    <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography variant="h5" component="h5">
            Direct Extensions
        </Typography>

        </Grid>
        <Grid item xs={3}>
            <MuiSearch2 
            name="Where_GeneralCriteria_Delim_mem"
            onChange={requestSearch}
            value={searchedVal}
            handleSearch={handleSearch}
            handleCloseIcon={handleCloseIcon}
            onKeyDown={handleKeyPress}/>
        </Grid>
        <Grid item xs={9}>
            
        </Grid>
        <Grid item xs={12}>
        <Table
            pageCount={pageCount}
            totalRows={totalRows}
            columns={tableColumns}
            data={rows}
            onTableStateChange={onTableStateChange}
            globalSearchQuery={globalSearchQuery}
            />
        </Grid>
    </Grid>
    <ExtDetails open={open} setOpen={setOpen} data={selectedRow} maxExt={maxExt} fetchTableData={fetchTableData}/>
    </>
}
export default DirectExtensions;