// import AsyncStorage from '@react-native-async-storage/async-storage';

import axios from 'axios';
import jwt_decode from "jwt-decode";
import moment from 'moment';

import ReduxStore from './ReduxStore';

const configData = window.config;

const reduxStoreInstance = ReduxStore.getDefaultStore();
const store = reduxStoreInstance.store; // Access the Redux store
const state = store.getState();
const isTokenApiCall=state?.getTokens?.isTokenApiCall
console.log('check redux state',isTokenApiCall)
// async function getAccessUsingRefresh(accessToken, refreshToken) {
//     console.log('is refresh called')
//     const url=configData.AUTH_API_URL+`/api/Account/RefreshToken`
//     const payload = {
//         token: accessToken,
//         refreshToken: refreshToken
//     }
//    return await axios.post(url, payload, {
//         headers: {
//             'Content-Type': 'application/json-patch+json',
//             'Access-Control-Allow-Origin': '*',
//             'x-domain-name': configData.DOMAIN_NAME
//         },
//     }).then(res => {
//         console.log('refresh response',res.status,res)  
//         if(res.status!==200) 
//         {
//             window.sessionStorage.clear();
//             window.localStorage.clear()
//             store.dispatch({type:'UNAUTH_USER'})
//             store.dispatch({type:'RESET_STORE'})
//             window.location.origin("/login");
//         } else
//         { return res}
//     }).catch((err) =>{
//         console.log('err res',err)
//         console.log('err res1',err?.response)
//         window.sessionStorage.clear();
//         window.localStorage.clear()
//         store.dispatch({type:'UNAUTH_USER'})
//           store.dispatch({type:'RESET_STORE'})
//           window.location.origin("/login");
//          })
// }
let lastApiCallTime = null;
let cachedResponse = null;

const syncFunctionGetAccessUsingRefresh = async (accessToken, refreshToken) => {
    // Throttle the API call to once every 5 minutes
    const throttleDuration = 5 * 60 * 1000; // 5 minutes in milliseconds

    // Check if enough time has passed since the last API call
    if (lastApiCallTime && moment().diff(lastApiCallTime) < throttleDuration) {
        console.log('API call throttled. Returning cached response.');
        return cachedResponse; // Return the cached response or handle as needed
    }

    console.log('is refresh called');
    const url = configData.AUTH_API_URL + `/api/Account/RefreshToken`;
    const payload = {
        token: accessToken,
        refreshToken: refreshToken
    };

    try {
        const response = await axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                'Access-Control-Allow-Origin': '*',
                'x-domain-name': configData.DOMAIN_NAME
            },
        });

        console.log('refresh response', response.status, response);

        if (response.status !== 200) {
            handleErrorResponse();
        } else {
            // Update the last API call time and cached response
            lastApiCallTime = moment();
            cachedResponse = response;
            return response;
        }
    } catch (error) {
        handleErrorResponse(error);
    }
};

const handleErrorResponse = (error) => {
    console.log('err res', error);
    console.log('err res1', error?.response);
    window.sessionStorage.clear();
    window.localStorage.clear();
    store.dispatch({ type: 'UNAUTH_USER' });
    store.dispatch({ type: 'RESET_STORE' });
    window.location.origin('/login');
};

async function getVerifiedKeys(keys) {
    console.log('Loading keys from storage 20', keys)

    if (keys) {
        console.log('checking access 00',isTokenExpired(keys.access), keys)
        
        if (!isTokenExpired(keys.access)) 
        {
            console.log('returning access')

            return keys
        } 
        // else if(!isTokenApiCall){
            else{
                if(isTokenApiCall)
                {
                    return
                }
            console.log('access expired')

            console.log('checking refresh expiry')

            console.log('fetching access using refresh',keys)
             store.dispatch({type:'TOKEN_API_CALL',isTokenApiCall:true})  
            const response = await syncFunctionGetAccessUsingRefresh(keys?.access, keys?.refresh)
            store.dispatch({type:'TOKEN_API_CALL',isTokenApiCall:false})
            console.log(response.data, 'finres 10')
            const updatedTokens = {access:response?.data?.accessToken, refresh:response?.data?.refreshToken}
             localStorage.setItem('keys', JSON.stringify(updatedTokens))
             localStorage.setItem('token', JSON.stringify(updatedTokens?.access))
             localStorage.setItem('refreshToken', JSON.stringify(updatedTokens?.refresh))
             store.dispatch({type:'GET_TOKENS',token:response?.data?.accessToken,refreshToken:response?.data?.refreshToken})
            return response

        }

    } else {
        // console.log('access not available please login')
        return null
    }
}

// function isTokenExpired(token) {
//     var decoded = jwt_decode(token)
// //   console.log('check expire date',decoded.exp,Date.now() / 1000)
//   const timestamp = decoded.exp; // Replace this with your Unix timestamp
// const utcTime = new Date(timestamp * 1000);
// // console.log(utcTime.toISOString());
// const currentUTCDate = new Date();
// const currentUTCString = currentUTCDate.toISOString();
// // console.log(currentUTCString);
// const utcTime1 = new Date(utcTime);
// const utcTime2 = new Date(currentUTCString);
// // console.log('utcTime < currentUTCString',utcTime1.toISOString().slice(0, 16),utcTime2.toISOString().slice(0, 16),utcTime1.toISOString().slice(0, 16) <= utcTime2.toISOString().slice(0, 16))   
//     if (utcTime1.toISOString().slice(0, 16) <= utcTime2.toISOString().slice(0, 16)) {
//         // console.log('token expired')
//         return true
//     } else {
//         // console.log('token not expired')
//         return false
//     }
// }

    
      const isTokenExpired = (token) => {        
        try {
          const decoded = jwt_decode(token);
          if (!decoded || !decoded.exp) {
            // If the token cannot be decoded or doesn't have an expiration time, consider it expired.
            console.log('The token has expired.');
            return true
          }
  
          // Get the current time in seconds
          const currentTime = Math.floor(Date.now() / 1000);
  
          // Check if the expiration time (exp) in the token is greater than the current time
          console.log('compare time',decoded.exp,currentTime)
          if (decoded.exp <= currentTime) {
            console.log('The token has expired.');
            // console.log(decoded.exp)
            return true
          } else {
            console.log('The token is still valid.');
            return false
          }
        } catch (error) {
          // If there's an error decoding the token, consider it expired.
          console.log('The token has expired.',error);
        }
      };
  

export const setCredentials = async keys => {
    try {
         localStorage.setItem('keys', JSON.stringify(keys))
    } catch (e) {
        // console.log(e)
    }
}

export const getCredentials = async () => {
   
    try {
        const credentials = localStorage.getItem('keys')
        // const credentials={access:state?.getTokens?.token,refresh:state?.getTokens?.refreshToken}
        // console.log('before verify',credentials)
        var credData={}
        const cred = await getVerifiedKeys(JSON.parse(credentials))
        console.log('check again cred',cred)
        if(cred?.data){
        credData={access:cred?.data?.accessToken,refresh:cred?.data?.refreshToken}

        }else{
            credData=cred
        }
        console.log('after verify',credData)
        
        if (Object.keys(credData)?.length >= 1) {
            return credData
        } else {
            return null
        }
    } catch (e) {
        // console.log(e)
    }

}