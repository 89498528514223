import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import AppConstants from '../../config/AppConstants';
import { elasticSearchServiceCall, serviceCallWithToken } from '../serviceCall';
export const getAllMessageList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/SMS/GetSMSLogs',
      method: 'get',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          Array.isArray(response.data) &&
            response.data?.forEach((element, index) => {
              response.data[index]['id'] = index + 1;
            });
          callback && callback(response.data);

          dispatch({
            data: response.data,
            type: AppConstants.GET_ALL_SMS_LOG,
          });
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response?.data);
      });
  };
};
export const getSMSAnalytics = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/GetSMSLogsAnalytics',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const getSMSStatus = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/SMS/getSMSStatus',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const GetCallLogs = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Call/GetCallLogs',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
 
};
export const GetEmailLogs = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    elasticSearchServiceCall({
      url: '/api/EmailLogs/GetEmailLogs',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const GetEmailLogsCount = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    elasticSearchServiceCall({
      url: '/api/EmailLogs/count',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const GetEmailLogsById = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    elasticSearchServiceCall({
      url: '/api/EmailLogs/GetEmailLogsById',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const GetCallLogsAnalytics = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Call/GetCallLogsAnalytics',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const getVoiceCallLogsList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/VoiceCall/GetVoiceCallLogs',
      method: 'post',
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          Array.isArray(response.data) &&
            response.data?.forEach((element, index) => {
              response.data[index]['id'] = index + 1;
            });

          callback && callback(response.data);

          dispatch({
            data: response.data,
            type: AppConstants.GET_VOICE_CALL_LOG,
          });
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const getEmailLogsList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailEvents',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          Array.isArray(response.data) &&
            response.data?.forEach((element, index) => {
              response.data[index]['id'] = index + 1;
            });
          callback && callback(response.data);

          dispatch({
            data: response.data,
            type: AppConstants.GET_ALL_EMAIL_LOG,
          });
        }
      })
      .catch((error) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error.response?.data);
      });
  };
};
export const getEmailAnalytics = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailEventAnalytics',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          // console.log(response, 'response');
          Array.isArray(response.data) &&
            response.data?.forEach((element, index) => {
              response.data[index]['id'] = index + 1;
            });
          callback && callback(response.data);

          dispatch({
            data: response.data,
            type: AppConstants.GET_ALL_EMAIL_LOG,
          });
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      });
  };
};
export const getLogDetail = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailLogsByEvent',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const getEmailEventList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailEventTypes',
      method: 'get',
      data: data,
    })
      .then((response) => {
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        // dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const activateDNDList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/ActivateDND',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};
export const GetEmailDNDList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailDNDList',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const RemoveEmailFromDND = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Email/RemoveEmailFromDND',
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        callback && callback();
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const addToBounceList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/ActivateBounce',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
      });
  };
};
export const getEmailBounceList = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GetEmailBounceList',
      method: 'get',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        if (response && response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error && error.response && error?.response?.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const removeEmailFromBounce = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCallWithToken({
      url: '/api/Email/RemoveEmailFromBounce',
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        callback && callback();
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const putBulkBounceEmails = (data, headers, callback, errorCallBack, method) => {
  // console.log('dnd data',data)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/PutBulkBounceEmails',
      method: method || 'put',
      data,
      headers,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        // dispatch(toggleLoader(false));
        console.log('dnd data',response.status)
        callback && callback(response && response.data);
      })
      .catch((error) => {
        console.log('dnd err',error)
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback(error?.response?.data);
        errorCallBack && errorCallBack(error?.response?.data);
        
      });
  };
};
