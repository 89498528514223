import ActionConstants from '../config/AppConstants';

const initialState = {
  show:false,
};

export default function exitPrompt(state = initialState, action) {
  switch (action.type) {
  case 'SHOW_EXIT_PROMPT': {
    return {
      show:true
    };
  }
  case 'HIDE_EXIT_PROMPT':{
    return {
      show:false
    }
  }

  default:
    return { ...state };
  }
}
  