import ActionConstants from '../config/AppConstants';
const innitialData = {
  isSelected: false,
  Subject: '',
  TextArea: '',
};
export default function editorContent(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.TEXT_AREA: {
      // console.log('check redux',action)
      return {
        isSelected: action.isSelected,
        Subject: action.Subject,
        TextArea: action.TextArea,
      };
    }
    default:
      return { ...state };
  }
}
