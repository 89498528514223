import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import { Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { data } from 'autoprefixer';
import { BsChevronDown } from 'react-icons/bs';
import { CgAsterisk } from 'react-icons/cg';
import { ImSpinner8 } from 'react-icons/im';
import { NotificationManager } from 'react-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createEditor, Transforms } from 'slate';
import { withReact } from 'slate-react';

import constant_label from '../../../constants';
import { createParagraphNode } from '../../../editor/utils/paragraph';
import { addEditTemplate, getEmailTemplate } from '../../../services/template/templateService';
import FormValidator from '../../../validations/FormValidator';
import CancelButton from '../../Buttons/cancelButton';
import SaveButton from '../../Buttons/saveButton';
import SMSTextEditor from '../../SMSEditor/SMSEditor';
import TextEditor from '../../TextEditor/EditorWithUseQuill';
import ConfirmationDialog from '../ConfirmationDialog';
import Prompt from '../Prompt';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  clickable: {
    cursor: 'pointer',
  },
  paper: {
    minWidth: '90%',
  },
}));

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

function AddEditTemplateModal(props) {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const forceUpdate = useForceUpdate();
  const [showVariableOptions, setShowVariableOptions] = useState(false);
  const classes = useStyles();
  const keys = ['body_chr', 'lastUpdatedByUserID_chr', 'lastUpdatedDateTime_dtm', 'subject_chr'];
  const editorRef = useRef();
  const [isAddEditTemp, SetIsAddEditTemp] = useState(true)
  const [templateBody, setTemplateBody] = useState('')
  const textareaRef = useRef(null)
  const [newCursorPosition, setNewCursorPosition] = useState(null);
  if (!editorRef.current) editorRef.current = withReact(createEditor());
  const editor = editorRef.current;
  const [isVar, setIsVar] = useState(false);
  const [value, setValue] = useState([
    createParagraphNode([
      {
        text: '\n\n\n\n',
      },
      // createLinkNode('https://karlworks.com', 'This is my Link')
    ]),
    // createImageNode('Nicolas Cage GIF', 'https://www.placecage.com/gif/600/250')
  ]);

  const templateBodyChr = useSelector((state) => state?.template?.templateBody);
  // console.log(bodyChr, 'bodyChr');
  const { selectedTemplate, templateType, action, setSelectedTemplate, emailTemplateID_ids } = props;

  const { userId } = props.data;

  const [templateData, setTemplateData] = useState({
    calling_UserID_chr: userId,
    templateName_chr: '',
    body_chr: '',
    subject_chr: '',
    isDeleted_ysn: true,
  });

  const ITEMS = [
    {
      content: 'Name',
    },
    {
      content: 'Phone',
    },
    {
      content: 'Email',
    },
  ];
  console.log('selectedTemplate', selectedTemplate, props);
  useEffect(()=>{
    if(props.action==='add')
    {dispatch({ type: 'ADD_EDIT_TEMP', data: '' })}
  },[])
  useEffect(() => {
    // if (selectedTemplate) {
    if ((selectedTemplate) && (Object.keys(selectedTemplate).length !== 0)) {
      console.log('selected 1', selectedTemplate);
      const templateId_lng =
        selectedTemplate?.emailTemplateID_ids || selectedTemplate?.smsTemplateID_ids || selectedTemplate?.scriptTemplateID_ids;
      const templateName_chr =
        selectedTemplate?.emailTemplateName_chr ||
        selectedTemplate?.smsTemplateName_chr ||
        selectedTemplate?.scriptTemplateName_chr;
      const subject_chr = selectedTemplate?.subject_chr;

      const body_chr = selectedTemplate?.body_chr;
      const data = { ...templateData, templateId_lng, templateName_chr, subject_chr, body_chr };
      console.log('template data', data);
      // keys.forEach((prop) => (data[prop] = selectedTemplate[prop]));
      // if (data['body_chr']) {
      //   try {
      //     const value = JSON.parse(data['body_chr']);
      //     data['body_chr'] = value;
      //     // data['body_chr'] = bodyChr;

      // console.log(value, '114');
      //   } catch (err) {
      //     data['body_chr'] = [{ children: [{ text: data['body_chr'] }], type: 'paragraph' }];

      //   }
      // console.log('called body_chr', data['body_chr']);
      // }
      dispatch({ type: 'ADD_EDIT_TEMP', data: body_chr })
      setTemplateData(data);
    }
  }, [selectedTemplate]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const regUserFormValidator = new FormValidator();

  const handleClose = () => {
    dispatch({ type: 'VARIABLE_LIST_MODAL_CLOSE' });

    props.onClickAdd();
  };

  const insertPlaceholder = (text) => {
    const textToAdd = {
      children: [{ text: text }],
      type: 'span',
    };

    Transforms.insertNodes(editor, textToAdd);
  };
  const [variable, setVariable] = useState('');
  console.log('anchorEl 185', anchorEl, anchorEl?.dataset?.name);
  const handleOptionClick = (optionValue) => {
    console.log('anchorEl 187', anchorEl, anchorEl?.dataset?.name);

    if (props.templateType === 1) {
      if (anchorEl?.dataset?.name === 'add_to_body') {
        setIsVar(!isVar);
        setAnchorEl(null);
        setVariable(`{{${optionValue.toLowerCase()}}}`);
        return insertPlaceholder(`{{${optionValue.toLowerCase()}}}`);
      }
      //else add to subject input
      let { subject_chr } = templateData;
      subject_chr = (subject_chr || '') + `{{${optionValue.toLowerCase()}}}`;
      setAnchorEl(null);
      setTemplateData((state) => ({ ...state, subject_chr }));
    }
    else {// Get the current cursor position
      const cursorPosition = textareaRef.current.selectionStart;

      // Insert the selected option at the cursor position
      console.log('templateData.body_chr11', templateData.body_chr)
      const updatedValue =
        (templateData.body_chr).slice(0, cursorPosition) + `{{${optionValue}}}` + (templateData.body_chr).slice(cursorPosition);

      // Update the state with the modified value
      //  setTextareaValue(updatedValue);
      setAnchorEl(null)
      setTemplateData((state) => ({
        ...state,
        body_chr: updatedValue
      }))

      // Move the cursor position to the end of the inserted text
      const newCursorPosition = cursorPosition + (`{{${optionValue}}}`).length;

      // Set the new cursor position in the state
      setNewCursorPosition(newCursorPosition);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemplateData((prevState) => {
      const template_data = { ...prevState };
      template_data[name] = value;
      return template_data;
    });
  };
  // console.log(templateData, 'templateData');
  const handlePrompt = () => {
    setIsPromptOpen((state) => !state);
  };

  const onSubmitForm = (event) => {
    // console.log('called onSubmitForm');
    event.preventDefault();

    if (regUserFormValidator.allValid()) {
      // console.log('true');
      const data = { calling_UserID_chr: userId };
      const { templateName_chr, templateId_lng, body_chr } = templateData;
      keys.forEach((prop) => (data[prop] = templateData[prop]));

      if (templateType === 1) {
        data['emailTemplateName_chr'] = templateName_chr;
        data['emailTemplateId_lng'] = templateId_lng;
        data['body_chr'] = body_chr;
      } else if (templateType === 2) {
        data['smsTemplateName_chr'] = templateName_chr;
        if (props.action === 'edit') data['smsTemplateId_lng'] = templateId_lng;
        data['body_chr'] = body_chr;
      } else if (templateType === 3) {
        data['scriptTemplateName_chr'] = templateName_chr;
        if (props.action === 'edit') data['scriptTemplateId_lng'] = templateId_lng;
      }
      console.log('template data', data);
      if (props.action === 'add') {
        props.addEditTemplate(
          data,
          (res) => {
            NotificationManager.success('Template Added successfully !');
            props.reloadList();
            handleClose();
          },
          (err) => {
            NotificationManager.error(err);
          },
          props.templateType
        );
      } else {
        props.addEditTemplate(
          data,
          (res) => {
            // if (typeof res === 'string') {
            //   NotificationManager.success(res);
            // } else {
            //   NotificationManager.success('Edit Succesfully !');
            // }
            NotificationManager.success('Template Edit successfully !');

            props.reloadList();
            handleClose();
          },
          (err) => {
            NotificationManager.error(err);
          },
          props.templateType
        );
      }
    } else {
      // console.log('invalid');
      NotificationManager.error(constant_label?.lbl_txt_mandatory_field)
      regUserFormValidator.showMessages();
      forceUpdate();
    }
    dispatch({ type: 'ADD_EDIT_TEMP', data: '' })
    setTemplateData({});
  };

  const handleTextAreaChange = (e) => {
    const { value } = e.target
    console.log(value)
    setTemplateData((state) => ({
      ...state,
      body_chr: value
    }))
  }
  useEffect(() => {
    if (newCursorPosition !== null) {
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      textareaRef.current.focus();

      // Reset the cursor position after it's set
      setNewCursorPosition(null);
    }
  }, [newCursorPosition]);
  return (
    <div>
      {/* {isPromptOpen && <Prompt isPromptOpen={isPromptOpen} handleClose={handlePrompt} handleModalClose={handleClose} />} */}
      {isPromptOpen && (<ConfirmationDialog
        onHandleModel={() => setIsPromptOpen((state) => !state)}
        isOpenDialog={isPromptOpen}
        action={handleClose}
        title={'Discard'}
        content={'Do you want to discard ?'}
      />)
      }
      <Dialog
        open={props.isOpenDialog}
        // onEscapeKeyDown={handlePrompt}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
        classes={{ paper: classes.paper }}
      >
        {spinner ? (
          <>
            <div className="fixed z-40 min-h-full min-w-full bg-black opacity-50  top-0 left-0"></div>
            <div className="fixed inset-x-1/2 top-2/4	z-50 text-center ">
              <div className='animate-bounce'>
        <img src='/connect_fav_icon.svg' className="h-12 w-12 max-w-fit animate-spin " />
        </div>
            </div>
          </>
        ) : null}
        <form noValidate onSubmit={onSubmitForm}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'sticky',
              top: 0,
              zIndex: 2,
              background: 'white',
            }}
          >
            <DialogTitle id="form-dialog-title">
              {props.action === 'add' ? 'Add Template' : 'Edit Template'}
            </DialogTitle>
            <DialogActions>
              <CancelButton onClick={handlePrompt} />
              <SaveButton />
            </DialogActions>
          </div>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DialogContentText>Please fill all mandatory fields marked as(*)</DialogContentText>
            </div>
            <div className="container-fluid">
              {/* <Grid container style={{ display: 'flex', padding: 0 }} lg={12}> */}
              <Grid container spacing={2} className="flex p-0" lg={12}>
                <Grid item lg={12} xs={12} sm={12} className="flex items-center p-0">
                  <CgAsterisk />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="templateName_chr"
                    className="textfield w-4/5"
                    multiline
                    maxRows={4}
                    InputLabelProps={{ shrink: true }}
                    label="Template Name"
                    fullWidth
                    name="templateName_chr"
                    value={templateData.templateName_chr}
                    onChange={handleChange}
                  />

                  {regUserFormValidator.message(
                    'Template Name',
                    templateData.templateName_chr,
                    'required',
                    'text-danger'
                  )}
                </Grid>
                {props.templateType === 1 && (
                  <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <CgAsterisk />
                    <div className="flex w-full">
                      <TextField
                        margin="dense"
                        id="subject_chr"
                        className="textfield "
                        multiline
                        minRows={2}
                        maxRows={4}
                        label="Subject"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="subject_chr"
                        value={templateData.subject_chr}
                        onChange={handleChange}
                      />
                      <div className="flex items-center cursor-pointer" >
                        <div className='flex items-center'
                          onClick={(e) => {
                            setShowVariableOptions((state) => !state);
                            setAnchorEl(e.currentTarget);
                          }}>
                          <span className="text-gray-light2 mr-1">Variables</span>
                          <BsChevronDown />
                        </div>

                        {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            {ITEMS.map((item, index) => (
                              <MenuItem
                                onClick={(e) => {
                                  // console.log(e.target, 'target');
                                  handleOptionClick(item.content);
                                }}
                                key={index}
                              >
                                {item.content}
                              </MenuItem>
                            ))}
                          </Menu> */}

                      </div>
                    </div>
                    {regUserFormValidator.message('Subject', templateData.subject_chr, 'required', 'text-danger')}
                  </Grid>
                )}
                <Grid item xs={12} sm={12} lg={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                  <CgAsterisk />

                  {/* <RichTextEditor
                      templateBody={true}
                      value={templateData.body_chr || ''}
                      setBodyInputValue={({ value }) =>
                        setTemplateData((state) => ({
                          ...state,
                          body_chr: value,
                        }))
                      }
                    /> */}
                  <div className="w-full border mb-4">
                    <div
                      className="flex relative items-center p-2 cursor-pointer rounded-lg mr-2"
                      data-name={'add_to_body'}
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        console.log('varaible list', e.currentTarget)
                      }}
                    >
                      <span className=" mr-1">Variables</span>
                      <BsChevronDown />
                    </div>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                      {ITEMS.map((item, index) => (
                        <MenuItem
                          onClick={(e) => {
                            // console.log(e.target, 'target');
                            handleOptionClick(item.content);
                          }}
                          key={index}
                        >
                          {item.content}
                        </MenuItem>
                      ))}
                    </Menu>

                    {/* <Editor
                        showToolbar={templateType === 1}
                        ref={editorRef}
                        //  value={messageBody}
                        //  onChange={setMessageBody}
                        className="h-[320px] overflow-auto "
                        value={templateData.body_chr}
                        onChange={(value) => {
                          setTemplateData((data) => ({ ...data, body_chr: JSON.stringify(value) }));
                        }}
                      /> */}
                    {
                      props.templateType === 1 ?
                        <TextEditor isVar={isVar} isAddEditTemp={isAddEditTemp} selectedVariable={variable} templateBody={templateData.body_chr} setTemplateData={setTemplateData} />
                        :
                        // <SMSTextEditor isVar={isVar} isAddEditTemp={isAddEditTemp} selectedVariable={variable} templateBody={templateData.body_chr} setTemplateData={setTemplateData}/>
                        <textarea
                          ref={textareaRef}
                          selectedVariable={variable}
                          onChange={handleTextAreaChange}
                          value={templateData?.body_chr}
                          className='w-full h-[260px] max-h-[260px]  border-t p-1'
                        />
                    }
                  </div>
                  {regUserFormValidator.message('Body', templateData.body_chr, 'required', 'text-danger')}
                </Grid>
              </Grid>
              {/* </Grid> */}
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = {
  addEditTemplate,
};
const mapStateToProps = (state) => {
  return {
    data: state.login.loginData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTemplateModal);
