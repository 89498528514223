import React, { useEffect, useState } from 'react';

import { TextField, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'

import CommonButton from '../../components/Buttons/commonButton';
import DefaultButton from '../../components/Buttons/defaultButton';
import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import AdminDetails from './adminDetails';
import OrganizationDetail from './organizationDetails';

import RegistrationForm1 from './index';
import '../../../src/DialSightStyles.css'
function RegistrationForm() {
  const history=useHistory()
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const [organizationDetails, setOrganizationDetails] = useState({
    name: '',
    companyDescription: '',
    website: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    country_lng:1,
    state: '',
    state_lng: '',
    city: '',
    zipCode: '',
    companyID: '',
    companyPhone: '',
    companyEmail: '',
  });
  const [adminDetails, setAdminDetails] = useState({});
const [isAdminForm,setIsAdminForm]=useState(false)
  const handleOrganizationChange = (event) => {
    const { name, value } = event.target;
    setOrganizationDetails({
      ...organizationDetails,
      [name]: value,
    });
  };

  const handleAdminChange = (event) => {
    const { name, value } = event.target;
    setAdminDetails({
      ...adminDetails,
      [name]: value,
    });
  };

  const handleOrganizationSubmit = (event) => {
    event.preventDefault();
    console.log('Organization Details:', organizationDetails);
  };

  const handleAdminSubmit = (event) => {
    event.preventDefault();
    console.log('Admin Details:', adminDetails);
  };

  useEffect(()=>{
    window.scrollTo({
      top: 20,
      behavior: 'smooth' // Optional, smooth scrolling effect
    });
  },[isAdminForm])
  const handleClickLogin=()=>{
   history.push('/')  
  }
  return (
    <div className='h-[100vh] relative'>
      {/* <Container  > */}
      {  spinner?
           <>
           <div className="fixed z-40 min-h-full min-w-full  opacity-50 top-0 left-0"></div>
           <div class="fixed inset-x-1/2 top-2/4	z-50 text-center left-[47vw]">
             <div className='animate-bounce'>
        <img src='/connect_fav_icon.svg' className="h-12 w-12 max-w-fit animate-spin " />
        </div>
           </div>
         </>:null
        }
      <Grid container spacing={1} className='h-screen'>
            <Grid  item xs={8} md={7} lg={8} className='hidden sm:block'>
           <div className='backgroundRegisterPage relative'>
           <div className="absolute top-10 w-[500px] flex flex-col gap-16">
                
                <div className="flex flex-col">
                <img src="/connect_logo_2.svg"/>
               <div className="pt-4 w-full text-right">
               <i className="text-lg">
                "Empowering Connections, One Click Away."
                </i>
               </div>
                </div>
                <div className='mx-[150px]'>
                <CommonButton onClick={handleClickLogin}>Login</CommonButton>
                {/* <div className='border text-lg font-bold cursor-pointer px-4'>
                  Login
                </div> */}
                </div>
            </div>
           </div>
            </Grid>
        <Grid item xs={12} sm={4} md={5} lg={4}>
          <Paper elevation={1} style={{ padding: '20px'}} className='h-screen min-h-[662px]'>
          <div  className='text-[#263003] flex items-center justify-center'>
          <Typography variant="h5" gutterBottom>
              Join Us
            </Typography>
          </div>
         { isAdminForm?
          <AdminDetails
          isAdminForm={isAdminForm}
          setIsAdminForm={setIsAdminForm}
          adminDetails={adminDetails}
          setAdminDetails={setAdminDetails}
          organizationDetails={organizationDetails}
          setOrganizationDetails={setOrganizationDetails}
          /> :<OrganizationDetail isAdminForm={isAdminForm} setIsAdminForm={setIsAdminForm} organizationDetails={organizationDetails} setOrganizationDetails={setOrganizationDetails}/>}
          </Paper>
        </Grid>
      </Grid>
      {/* </Container> */}
    </div>
  );
};

export default RegistrationForm;
