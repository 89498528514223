import axios from 'axios';

import { getCredentials } from '../credentials';
import { userIdToken } from '../userIdToken';


const configData = window.config
const userId =configData?.CALLING_USER_ID;
const domainName =configData?.DOMAIN_NAME;
export async function serviceCallAuthWithToken(requestData) {
  const newToken= await getCredentials()
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('service call final domain',userId,domain_Name)
  return new Promise((resolve, reject) => {
    const url = configData.AUTH_API_URL + requestData.url;
    
    const request = {
      method: requestData.method || 'get',
      headers: {
        'x-domain-name':domain_Name,
        Authorization: 'Bearer  ' + newToken.access,
        'Content-Type': 'application/json-patch+json',
        'Access-Control-Allow-Origin': '*',
        ...requestData.headers
      },
      url: url,
    };
    if (requestData.method === 'post'  || requestData.method === 'put') {
      request['data'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};

    } else{ 
      request['params'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};
     }
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log('service-call-auth',err)
        if(err.response?.status === 401){
          // history.push('/login')
          // localStorage.removeItem('demo_data')
          // resetStore()
          // localStorage.removeItem('token')
          // store.store.dispatch({type:'RESET_STORE'})
          // window.location.replace("/login");
          // localStorage.clear();
          //  localStorage.clear();
          //  logout();
          // dispatch({type:'UNAUTH_USER'})
        }
        reject(err);
      });
  });
}
export function serviceCallAuth(requestData) {
  const userId =configData?.CALLING_USER_ID;
const domainName =configData?.DOMAIN_NAME;
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('auth domain',domain_Name,getKeys)
  return new Promise((resolve, reject) => {
    const url = configData.AUTH_API_URL + requestData.url;
    const request = {
      method: requestData.method || 'get',
      headers: {
      'x-domain-name':domain_Name,
        ...requestData.headers} || {},
      // data: requestData.data || {},
      url: url,
      // params:{...requestData.data} || {}
    };
    // if (requestData.method === 'post'|| requestData.method === 'put')
    if (requestData.method === 'post'|| requestData.method === 'put')
     {
      request['data'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};

    } else{ 
      request['params'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};

    }
    console.log('config',request)
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function serviceCallAuth2(requestData) {
  const userId =configData?.CALLING_USER_ID;
const domainName =configData?.DOMAIN_NAME;
  const getKeys=userIdToken()
  const user_Id=getKeys.userId;
  const domain_Name=getKeys?.DOMAIN_NAME;
  console.log('auth domain',domain_Name,getKeys)
  return new Promise((resolve, reject) => {
    const url = configData.API_URL + requestData.url;
    const request = {
      method: requestData.method || 'get',
      headers: {
      'x-domain-name':domain_Name,
        ...requestData.headers} || {},
      // data: requestData.data || {},
      url: url,
      // params:{...requestData.data} || {}
    };
    // if (requestData.method === 'post'|| requestData.method === 'put')
    if (requestData.method === 'post'|| requestData.method === 'put')
     {
      request['data'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};

    } else{ 
      request['params'] = {...requestData.data,Calling_UserID_chr:user_Id} || {};

    }
    console.log('config',request)
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}