import React, { useState, useEffect, useCallback ,useRef} from "react";

import { Badge, Grid, IconButton, InputAdornment, LinearProgress, TextField, Typography } from "@material-ui/core";
// import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { Skeleton } from "@material-ui/lab";
import {Client} from "@twilio/conversations";
import axios from "axios";
import { debounce } from "lodash";
import { FaFile, FaFilePdf, FaRegUserCircle, FaUser, FaUserCircle } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { MdAdd, MdAttachFile, MdClose, MdDownload, MdOutlineAttachFile, MdOutlineReportGmailerrorred, MdSend, MdSms } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";

import CommonButton from "../../components/Buttons/commonButton";
import DocumentPreview from "../../components/IFrrameDocViewer/component";
import TxtField from "../../components/InputFields/TextFields/TextField";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { getListOfUsers } from "../../services/customer/customerService";
import { getFCMBroadcastMessage, markAsReadUnread } from "../../services/firebase/firebase";
import { createConversation, getConversationAllMessages, getConversationsByPhoneNumber, getTokenForMessageService, sendConversationMessage } from "../../services/message/messagingService";
import { getAllMessageList } from "../../services/report/reportService";
import { getAllActiveNumbers, getUserList } from "../../services/userService";
import { AllformatPhoneNumber, INformatPhoneNumber, convertTimeZone, formatPhoneNumber } from "../../utils";
import AlternatingSkeletons from "./smsSkeleton/component";


function convertSmsApiTimeFormatToOurStandardFormat(inputTime) {
    // Parse the input time string to a Date object
    const date = new Date(inputTime);
  
    // Get individual components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
  
    // Construct the formatted time string
    const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  
    return formattedTime;
  }
const OneToOneMessage = () => {
    const contentEndRef = useRef(null);
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const fcmToken=useSelector((state)=>state?.firebase.token)
  const {isMessagePing,smsPingFrom,notificationsCount}=useSelector((state)=>state?.firebase)
    const {token,twilioNumber,domain,userId}=useSelector((state)=>state?.login.loginData)
    const {conversationNumbers,unreadSms,smsCount,savedContact}=useSelector((state)=>state.smsConversations)
    const dispatch=useDispatch()
    const [smsToken,setSmsToken]=useState('')
    const [isNewConv,setIsNewConv]=useState(true)
    const [conversationSid,setConversationSid]=useState('')
    const [messages,setMessages]=useState([])
    const [msgPayload,setMsgPayload]=useState(
        {identity:twilioNumber,
        conversationSid:conversationSid,
        messageBody:''}
    )
    const initialState={
        Page_Index_int: +1,
        Page_Size_int: 500,
        Where_GeneralCriteria_Delim_mem: '',
        // Sender_Sms_Criteria:twilioNumber,
        Sort_Expression_Delim_mem: 'lastUpdatedDateTime_dtm desc',
        // Status:'received',
        Paginate_ysn:false,
        Return_All_Rows_ysn:true,
      }
    const [tableState,setTableState]=useState(initialState)
    const [contactList,setContactList]=useState([])
    // const [conversationNumbers,setConversationNumbers]=useState([])
    const [filteredContactList,setFilteredContactList]=useState([])
    const [selectedContact,setSelectedContact]=useState({})
    const [conversationClientInstance,setConversationClientInstance]=useState(null)
   const fileInputRef=useRef(null)
   const [fileUrl,setFileUrl]=useState(null)
   const [previewUrl, setPreviewUrl] = useState('');
  const [attachedFile,setAttachedFile]=useState(null)
  const [previewDoc,setPreviewDoc]=useState({
    url:'',
    open:false,
    fileName:'',
  })
    const getTwilioSmsToken=()=>{
        const payloadData={
            role:twilioNumber,
        }
        dispatch(getTokenForMessageService(payloadData,(res)=>{
            console.log('25 smsres',res);
            setSmsToken(res?.token)
        }))
    }
    // useEffect(()=>{
    //     getTwilioSmsToken()
    // },[])
useUpdateEffect(()=>{
    console.log('33 smstoken',smsToken)
    if(smsToken)
    {
        const client = new Client(smsToken);
        console.log('36 check client',client)
        setConversationClientInstance(client)
        dispatch({type:'CLIENT_INSTANCE',conversationClientInstance:client})
        client.on("stateChanged", (state) => {
        console.log('39 check state',state)

            if (state === "failed") {
                // The client failed to initialize
                return;
            }
        
            if (state === "initialized") {
                // Use the client
                console.log('client initialized')
                client.setPushRegistrationId('fcm',fcmToken)
            }
            if(state==='connected'){
                console.log('50 check connected')
                // client.setPushRegistrationId('fcm',fcmToken)
            }
        });
       

    }
},[smsToken])
useEffect(()=>{
    
    if(twilioNumber && selectedContact?.convPh && selectedContact?.convSid)
    {
      setConversationSid(selectedContact?.convSid)
      setMsgPayload((state)=>({
        ...state,
        conversationSid:selectedContact?.convSid,
    }))
        
    }
    else if(selectedContact?.convPh){
      const payloadData={
        twilioNumber:twilioNumber,
        phoneNumber:selectedContact?.convPh,
    }
    dispatch(createConversation(payloadData,(res)=>{
    console.log('58 check res',res)
    setConversationSid(res?.conversationSid)
    setMsgPayload((state)=>({
      ...state,
      conversationSid:res?.conversationSid,
  }))
    
}))
    }
   
},[selectedContact])
const fetchAllMessages=()=>{
    dispatch({type:'IS_MSG_PING',isMessagePing:false,smsPingFrom:''})
    const payloadData={
        conversationSid:conversationSid,
        limit:1000,
    }
    if(conversationSid){
        dispatch(getConversationAllMessages(payloadData,(res)=>{
         console.log('74 messages',res?.messages)
         setMessages(res?.messages)
         dispatch({type:'IS_MSG_PING',isMessagePing:false,smsPingFrom:''})
        }))
    }
}
useEffect(()=>{
    fetchAllMessages()
},[conversationSid])

const getAllActiveConversations=async()=>{
 new Promise((resolve, reject) => {
   dispatch((getConversationsByPhoneNumber({phoneNumber:twilioNumber},(res)=>{
     console.log('Appjs 76 res',res)
     const filteredData = res.map(item => ({
      convSid: item?.conversationSid,
      convPh: item?.messagingBinding.address,
      latestMessage:item?.latestMessage,
      unreadCount:item?.unreadCount
    }));
     console.log('line 167',filteredData)
     
    const addContactNameArr = filteredData.map((data) => {
      const contact = savedContact.find((item) => item.phNumber === data.convPh);
      if (contact) {
          return {
              ...data,
              contactName: contact.name
          };
      } else {
          return data; // Return original data if no match found
      }
  });
    console.log('addContactName',addContactNameArr)
     dispatch({type:'CONV_LIST',conversationNumbers:addContactNameArr})

   },(err)=>{console.log(err)})))
   resolve()
 })
}
useEffect(()=>{
  getAllActiveConversations()
},[])
useEffect(async()=>{
    if(isMessagePing){
        console.log('1 sec complete')
        fetchAllMessages()
        // dispatch({type:'IS_MSG_PING',isMessagePing:false ,smsPingFrom:''})
    } 
},[isMessagePing])
const sendMessage=()=>{
  // console.log('send message',selectedContact,conversationNumbers)
  const indexVal=conversationNumbers.findIndex((data)=>data.convPh===selectedContact.convPh)
  // console.log('send message',indexVal)
   const cloneConv=[...conversationNumbers]
  
if (indexVal !== -1) {
  cloneConv[indexVal].latestMessage = msgPayload?.messageBody;
}
   console.log('send message',cloneConv)
  if(msgPayload?.messageBody && msgPayload?.mediaSid){
    const payloadData= {
        identity: msgPayload?.identity,
        conversationSid: msgPayload.conversationSid,
        mediaSid: msgPayload?.mediaSid,
        messageBody:null
    
    }
    dispatch(sendConversationMessage(payloadData,(res)=>{
      const payloadData= {
        identity: msgPayload?.identity,
        conversationSid: msgPayload.conversationSid,
        messageBody: msgPayload?.messageBody,
    
    }
      dispatch(sendConversationMessage(payloadData,(res)=>{
        console.log('86 res',res)
        dispatch({type:'CONV_LIST',conversationNumbers:cloneConv})
        setMsgPayload((state)=>({
            ...state,
            messageBody:'',
            mediaSid:'',
        }))
        setAttachedFile(null)
        setPreviewUrl('')
        fetchAllMessages()
    }
))
  }
))
  }
    else if(msgPayload?.messageBody|| msgPayload?.mediaSid)
    {
    // console.log('body',msgPayload?.messageBody.length)
    const clonePayload={...msgPayload}
     const payload={
      ...clonePayload,
      mediaSid:(clonePayload?.mediaSid) ? clonePayload?.mediaSid.trim():null,
      messageBody:(clonePayload?.messageBody.length) ? clonePayload?.messageBody.trim():null,

     }
    dispatch(sendConversationMessage(payload,(res)=>{
        console.log('86 res',res)
        dispatch({type:'CONV_LIST',conversationNumbers:cloneConv})
        setMsgPayload((state)=>({
            ...state,
            messageBody:'',
            mediaSid:'',
        }))
        setAttachedFile(null)
        setPreviewUrl('')
        fetchAllMessages()
    }
))
}
}

const onChangeMsg=(event)=>{
    const {value}=event.target
    setMsgPayload((state)=>({
        ...state,
        messageBody:value,
    }))
}
useEffect(()=>{
    const payload={
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: 'twilioNumber desc,normalizedUserName asc',
        Include_Deleted_byt:1,
        Paginate_ysn:false,
        Return_All_Rows_ysn:true
    }
    dispatch(
        getUserList(payload, (res) => {
            console.log('res 164',res)
          const response = res?.aspNetUsers
          const callerList = response.filter(item => item.twilioNumber);
          
          setContactList(callerList)
        })
      );
},[])
const [searchedContact,setSearchedContact]=useState('')
const [cL,setCl]=useState([])
const handleContactClick=(row)=>{
  setIsNewConvStarted(true)
    setIsNewConv(false)
    setMsgPayload((state)=>({
        ...state,
        messageBody:''
    }))
   
    setSelectedContact(row)
    console.log('check 309',row.convPh,selectedContact,parseInt(row.convPh.trim())===parseInt(selectedContact.convPh))
    if(!(parseInt(row.convPh.trim())===parseInt(selectedContact.convPh)))
      {
    setMessages([])
        
      }
    

    const filterSelectedUsersUnreadSms=unreadSms.filter((data)=>data.from_number===row.convPh)
    console.log('check 294',filterSelectedUsersUnreadSms)
    // Find the index of the object you want to update
  const indexToUpdate = conversationNumbers.findIndex(item => item.convPh === row.convPh);
  // Create a new updated array
  const updatedArray = conversationNumbers.map((item, index) => {
    if (index === indexToUpdate) {
      return { ...item, unreadCount: 0 };
    }
    return item;
  });
  handleMarkAllRead(row?.convPh,row.unreadCount)

  

}
const handleMarkAllRead=(phNumber,unreadCount)=>{
const payload= {
  calling_UserID_chr: userId,
  messageID_ids:[],
  fromPhone_chr: phNumber,
  notificationType_chr:'Sms',
  isMessageRead_ysn:true,
}
// console.log('74',msgIds)
const calculateUnreadCounts = (data) => {
  const unreadCounts = {};

  data.forEach((item) => {
    const type = item.notificationType_chr;
    if (!unreadCounts[type]) {
      unreadCounts[type] = 0;
    }
    unreadCounts[type] += item.totalUnreadCount;
  });

  return unreadCounts;
};
if(unreadCount)
{
  dispatch(markAsReadUnread(payload,(res)=>{
  allNotificationMessages()
  // dispatch({type:'CONV_LIST',conversationNumbers:updatedArray})
  getAllActiveConversations()
  console.log('check resp',res?.tblUnreadCount)
 const data= calculateUnreadCounts(res?.tblUnreadCount)
 const totalUnreadNotificationCount=data.SMS+data.Call
 dispatch({type:'FIREBASE_PING',notificationsCount:totalUnreadNotificationCount})
 dispatch({type:'UPDATE_SMS_COUNT',smsCount:data.SMS})
}))
}
}
const allNotificationMessages=async()=>{
  const payload={
    Page_Index_int: +1,
    Page_Size_int: 5,
    Where_GeneralCriteria_Delim_mem: '',
    Sort_Expression_Delim_mem: '',
    UserId_chr:userId,
    Paginate_ysn:false,
  }
new Promise((resolve, reject) => {
  dispatch(getFCMBroadcastMessage(payload,(res)=>{
    console.log('check response',res)
    const totalUnreadNotificationCount=(res?.tblFCMBroadcastMessage.length)?res?.tblFCMBroadcastMessage[0].message_Count_Excluding_Criteria_int:0
    const unreadMsg=res.tblFCMBroadcastMessage.filter((data)=>data.isMessageRead_ysn===false)
    const unreadSMS = res.tblFCMBroadcastMessage.filter(data => data.notificationType_chr === 'SMS' && data.title_chr==='New message' && data.isMessageRead_ysn===false);
    console.log('urm',unreadMsg.length,unreadSMS)
    // Function to extract the number before the colon and add it as a new key in each object
    const updatedUnreadSMS = unreadSMS.map(message => {
      // Extract the number before the colon
      const fromNumber = message.messageBody_chr.split(':')[0].trim();

      // Return a new object with all the original properties and the new key
      return { ...message, from_number: fromNumber };
    });
    console.log('urm',updatedUnreadSMS)
    const calculateUnreadCounts = (data) => {
      const unreadCounts = {};
    
      data.forEach((item) => {
        const type = item.notificationType_chr;
        if (!unreadCounts[type]) {
          unreadCounts[type] = 0;
        }
        unreadCounts[type] += item.totalUnreadCount;
      });
    
      return unreadCounts;
    };
    
    const unreadCountTable=res.table1
    const data=  calculateUnreadCounts(unreadCountTable)
    console.log('dataaa',data)
    dispatch({type:'ADD_NOTIFICATIONS',allNotifications:res.tblFCMBroadcastMessage})
    dispatch({type:'FIREBASE_PING',notificationsCount:totalUnreadNotificationCount})
    dispatch({type:'UPDATE_SMS_COUNT',smsCount:data.SMS,unreadSms:updatedUnreadSMS})
}))
  resolve()
})
}
const isNumericString = (str) => {
    const regex = /^\d+$/;
    return regex.test(str);
  };
  const handlePaste = (event) => {
    console.log('paste fun',event.target.value)
    event.preventDefault(); // Prevent default paste behavior
    const paste = event.clipboardData.getData('text/plain');
    const onlyNums = paste.replace(/\D/g, ''); // Remove non-numeric characters
    console.log('paste fun1',onlyNums)
    setSearchedContact(onlyNums.trim())
  };
const handleSearchContact=(e)=>{
    console.log('rslt 185',contactList)
const {value}=e.target
setSearchedContact(value)
const contact=value.trim()
console.log('231 line',isNumericString(contact),contact.length)
if(( ( extractDigits(contact).length===10 || (extractDigits(contact).startsWith(1) && extractDigits(contact).length===11))||(isNumericString(contact)) && (contact.length===10)) || ((contact.startsWith('+1'))&&(contact.length===12))){
    console.log(true)
    setCl([{
        name:contact,
        phNumber:formatPhoneNumber(contact),
    }])
}
else if(contact.startsWith('91')||contact.startsWith('+91') && contact.length===13 ){
    console.log('indian',INformatPhoneNumber(contact))
    setCl([{
        name:contact,
        phNumber:INformatPhoneNumber(contact),
    }])
}
else{
    filterUsers(contact)
}

}
const filterUsers = (query) => {
    if (!query) {
      setFilteredContactList(contactList);
      return;
    }

    const lowercasedQuery = query.toLowerCase();
    const filtered = contactList.filter(user =>
      user.firstName.toLowerCase().includes(lowercasedQuery) ||
      user.lastName.toLowerCase().includes(lowercasedQuery) ||
      user.twilioNumber.includes(lowercasedQuery)
    );
    const finalArr=filtered.map((data)=>({name:`${data.firstName} ${data.lastName}`,phNumber:data.twilioNumber}))
   console.log('finalArr',finalArr)
    setCl(finalArr)
    setFilteredContactList(filtered);
  };
const handleClickNewConv=()=>{
  setIsNewConvStarted(true)
    setIsNewConv(true)
    setSelectedContact({})
    setAttachedFile(null)
    
}
useEffect(()=>{
if(isNewConv){
    setConversationSid('')
    setSearchedContact('')
    setMessages([])
    setMsgPayload((state)=>({
        ...state,
        messageBody:''
    }))
}  
},[isNewConv])
const extractDigits = (str) => {
    // Use regex to match all digits
    const matchedNumbers = str.match(/\d+/g);
    // Join the matched numbers to form a continuous string
    return matchedNumbers ? matchedNumbers.join('') : '';
  };
const onClickSearchedValue=(data)=>{
console.log('check item',data)
if(( twilioNumber===data.phNumber.trim()) ||  twilioNumber===`+${extractDigits(data.phNumber).trim()}`){
  NotificationManager.error(`The recipient's number should not be the same as the sender's.`)
  setSearchedContact('')
    }
else
{
  setIsNewConv(false)
 if(extractDigits(data.phNumber).length===10)   
{
    setSelectedContact({
      contactName:data.phNumber,
    convPh:`+1${extractDigits(data.phNumber)}`,
    convSid:''
})
}
else{
    setSelectedContact({
      contactName:data.contactName,
        convPh:`+${extractDigits(data.phNumber)}`,
        convSid:'',
    }) 
}}
}
console.log('check 262',contactList,'rslt',filteredContactList)
 // Scroll to the bottom when content changes
 useEffect(() => {
    if (contentEndRef.current) {
    //   contentEndRef.current.scrollIntoView({ behavior: 'smooth' });
      contentEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages,isMessagePing]);
  const handleClickAttachment=(event)=>{
    event.preventDefault()
  fileInputRef.current.click()
  }
  const handleAttachment = (event) => {
    event.preventDefault();
    const fileInput = event.target;
    const file = fileInput.files[0];
    
    const maxSize = 15 * 1024 * 1024;
    if (file && file.size < maxSize) {
      handleDocUpload(file);
      setAttachedFile(file);
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setPreviewUrl('');
        console.log('Selected file is not an image.', file);
      }
    } else {
      NotificationManager.error('File size exceeds 15MB. Please upload a smaller file.');
    }
    
    // Reset the file input value
    fileInput.value = '';
  };
  
  const handleDocUpload = (attachment) => {
    console.log('handleDocUpload',attachment)
    const fileName=attachment.name
    const url= `${window.config?.API_URL}/api/SMS/UploadConversationMedia`;
    const config = {
      headers: {
        Authorization: "Bearer" + " " + token,
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-domain-name": domain,
      },
      params: {
        fileName: fileName,
      },
    };
    if (attachment) {
    //   if (file.type === 'application/pdf')
        if (attachment)
         {
        const docFormData = new FormData();
        docFormData.append('file', attachment);
        
        
        try{
          dispatch({ type: 'SPINNER_STATE', isLoading: true });
          axios.post(url,docFormData,config)
          .then((res)=>{
            if(res?.status===200){
              dispatch({ type: 'SPINNER_STATE', isLoading: false });
              console.log('newurl',res.data)
              setFileUrl(res.data.url)
              setMsgPayload((state)=>({
                ...state,
                mediaSid:res.data.sid

              }))
             
            }
          })
          .catch((error) => {
            console.log(error)
            dispatch({ type: 'SPINNER_STATE', isLoading: false });
          });
        }catch(err){

        }
      } else {
        // NotificationManager.error('Please select a PDF file.');
        // e.target.value = null;
      }
    }
  };
  const handleKeyPress = (event) => {
    // event.preventDefault()
    if (event.key === 'Enter') {
        sendMessage()
    }
  };
  const handleCloseClick=()=>{
    setPreviewUrl('')
    setAttachedFile(null)
  }
  const onButtonClick = (url,fileName) => {
    const pdfUrl = url;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = fileName; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};



  const previewAndDownload = (url,file) => {
    setPreviewDoc({
        open:true,
        url:url,
        fileName:file,
    })
    console.log('check file info',url,file)
    // onButtonClick(url,file)
  };


  // Function to convert 24-hour time to 12-hour format
  const convertTo12HourFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(':');
    console.log('check hours',hours)
    // Parse hours to an integer
    let parsedHours = hours;
    // Determine AM or PM suffix based on the hour
    const period = hours >= 12 ? 'PM' : 'AM';
    // Convert hours to 12-hour format
     parsedHours = hours % 12 || 12; // Handle midnight (0) as 12
    // Return the formatted time
    return `${parsedHours}:${minutes} ${period}`;
  };


  const extractTime = (dateString) => {
    // Split the date string by space
    const parts = dateString.split(' ');
    // Extract the time part (last element in the parts array)
    const timeString = parts[parts.length - 1];
    // Return the extracted time string
    return (timeString);
  };
   const [isNewConvStarted,setIsNewConvStarted]=useState(false)
    const handleAddButtonClick=()=>{
      setIsNewConvStarted(true)
    }

    useEffect(()=>{
   if(!isNewConv && unreadSms){
    console.log(selectedContact.convPh,'unreadSms',unreadSms)
    const filterSelectedUsersUnreadSms=unreadSms.filter((data)=>data.from_number===selectedContact.convPh)
    console.log('check 294',filterSelectedUsersUnreadSms)
  
    const msgIds = filterSelectedUsersUnreadSms.filter(message => message.isMessageRead_ysn === false).map(message => message.messageID_ids);

    const payload= {
      calling_UserID_chr: userId,
      messageID_ids: msgIds
    }
    if(msgIds.length){
      dispatch(markAsReadUnread(payload,(res)=>{
       
        const myFun = async () => {
          try {
            await allNotificationMessages();
           
            
            setTimeout(async () => {
             
                const indexToUpdate = conversationNumbers.findIndex(item => item.convPh === selectedContact.convPh);
                // Create a new updated array
                const updatedArray = conversationNumbers.map((item, index) => {
                  if (index === indexToUpdate) {
                    return { ...item, unreadCount: 0 };
                  }
                  return item;
                });
               
                //  dispatch({type:'CONV_LIST',conversationNumbers:updatedArray})
                await getAllActiveConversations()
             
            }, 3000);
            
            // await getAllActiveConversations();
          } catch (error) {
            console.error('Error occurred:', error);
          }
        };
      myFun()
      }))
    }
   }
    },[unreadSms])
    useEffect(()=>{
      const payload={
        Page_Index_int: +1,
        Page_Size_int: 20,
        Where_GeneralCriteria_Delim_mem: '',
        Sort_Expression_Delim_mem: 'lastUpdatedDateTime_dtm desc',
        Paginate_ysn:false
      }
      dispatch(getAllActiveNumbers(payload,(res)=>{
        console.log('check numbers',res?.tblActiveNumbers)
        const list=res.tblActiveNumbers.map((data)=>({name:data.assignedUsers,phNumber:data.twilioNumber}))
        dispatch({type:'SAVE_CONTACT',savedContact:list})
      }))
    },[])
    console.log('final conv',conversationNumbers)
    console.log('selectedContact',selectedContact)
    return (<div className="p-2 bg-white h-screen grid grid-cols-12 gap-2">
        <div className="col-span-3 w-ful h-[85vh] overflow-auto flex flex-col gap-2 border-r">
                
                <div className="w-full pr-2 flex flex-col gap-2 ">
               <div> <CommonButton onClick={handleClickNewConv}><MdAdd size={18}/> New Conversation</CommonButton></div>
                <div style={{fontSize:18}}>Text Messages</div>
                {conversationNumbers?.length? 
                <div>{
                conversationNumbers?.map((data,index)=><div key={index} className={`w-full flex items-center gap-2 p-2 border-b hover:bg-gray-100 ${data?.convSid===selectedContact?.convSid?'bg-gray-100':""} truncate`} onClick={()=>handleContactClick(data)}>
                 <div>
                     <FaUserCircle size={24}/>
                 </div>
                  <div className="w-full ">
                  <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                  {(data?.contactName && (data?.contactName!=='Not Assigned'))?data.contactName:formatPhoneNumber(data?.convPh)}
                  {/* {formatPhoneNumber(data?.convPh)} */}
                  
                  {(data?.unreadCount) ?
                 <div className="bg-green-500 rounded-full text-white text-xs p-0.5 w-[25px] text-center">{data?.unreadCount}</div>
                  :null}
                  </div>
                  {(data?.latestMessage!==null && data?.latestMessage!=="")?<div className="text-xs px-1 ">{data?.latestMessage}</div>:<div className="text-xs flex items-center gap-1"><MdAttachFile/> attachment</div>}
                 
                  
                   </div>
                  </div>
                </div>
                )}
                </div>
                :<div className="p-2  flex flex-col gap-2">No Conversations</div>
                 }
                </div>
              </div>
             {isNewConvStarted ? <div className="col-span-9 border-t">
                {isNewConv?<div className="col-span-9 pt-4 flex flex-col gap-2 relative">
                    <div className="text-xl">New Text Message</div>
                    <div className="">
                        <TxtField 
                        size='medium' 
                        placeholder='Enter a name or phone number' 
                        variant='outlined' 
                        value={searchedContact} 
                        onChange={handleSearchContact}
                        // onPaste={handlePaste}
                        />
                        </div>
                    { searchedContact?.length ? 
                    <div
                     className="w-full p-2 rounded  bg-white sticky left-0 border" style={{bottom:'-68px'}}
                     >
                        {((searchedContact?.length===10)||( cL.length))?<div>
                        {
                            cL.map((item,index)=><div key={index}
                             className="p-2 flex items-center gap-2 hover:bg-gray-100 cursor-pointer"
                             onClick={()=>onClickSearchedValue(item)}
                             >
                            <div>
                        <FaUserCircle size={24}/>
                    </div>
                    <div>
                    <div>
                    {item?.name}
                    </div>
                    <div className="text-xs">
                    {AllformatPhoneNumber(item?.phNumber)}
                    </div>
                    </div>
                            </div>)
                        }
                        </div>:<div className="text-lg p-2 flex items-center">No option</div>}
                        </div>
                        :null}
                </div>
                :
                <div className="flex items-center gap-x-2 p-2 bg-[#111] text-white  rounded-t">
                    <div>
                    <FaRegUserCircle size={36}/>
                    </div>
                    <div>
                    <div>{selectedContact?.contactName}</div>
                    <div className="text-xs">{AllformatPhoneNumber(selectedContact?.convPh)}</div>
                    </div>
                </div>
                }
             {!isNewConv? <div className={`${isNewConv?'h-[50.6vh]':previewUrl || attachedFile?'h-[47vh]':'h-[59vh]'} my-2 px-2 rounded overflow-auto`}>
               {
              //  !spinner?
               messages?.map((msg,index)=><div>
                {
                  index > 0 ?( convertTimeZone(convertSmsApiTimeFormatToOurStandardFormat(msg?.dateUpdated)).replace(/\d{2}:\d{2}$/, '')!==convertTimeZone(convertSmsApiTimeFormatToOurStandardFormat(messages[index-1].dateUpdated)).replace(/\d{2}:\d{2}$/, ''))?<div className="w-full flex items-center justify-center mb-4">
                    <div className="px-2 py-2 rounded-lg bg-gray-200">
                    {convertTimeZone(convertSmsApiTimeFormatToOurStandardFormat(msg?.dateUpdated)).replace(/\d{2}:\d{2}$/, '')}
                  </div>
                  </div>:null :null
                }
               <div key={index} className={`w-full flex items-center gap-2  ${msg?.author===twilioNumber?"justify-end  ":""}`}>
                
                
                <div className={`min-w-[200px] max-w-[50vw] flex flex-col p-2  mb-2 rounded-lg ${msg?.author===twilioNumber?"bg-green-100":"bg-gray-100"}`}>
                
                <div>
                {
                (msg?.body!==null) ? 
                msg?.body:msg?.media?.content_type.startsWith('image/') ? 
                <img src={msg?.media?.mediaUrl}
                 width={200} alt='Not available'
                  onClick={()=>previewAndDownload(msg?.media?.mediaUrl,msg?.media?.filename)}
                  className="hover:cursor-pointer"
                  />
                :
                 <div>
                    {
                        msg?.media?.filename!==null?<div className="flex items-center gap-2 bg-white rounded-full p-2 hover:bg-gray-100 hover:cursor-pointer" onClick={()=>previewAndDownload(msg?.media?.mediaUrl,msg?.media?.filename)}>

                            {msg?.media?.filename}
                            <MdDownload size={24}/>
                        </div>
                        :
                        <div className="flex items-center gap-2 bg-white rounded-full p-2">Media not supported <MdOutlineReportGmailerrorred size={24}/></div>

                    }
                    </div>
                    }


                </div>
                 <div className="text-xs text-right">
               {extractTime(convertTimeZone(convertSmsApiTimeFormatToOurStandardFormat(msg?.dateUpdated)))}
                
                 
                 </div>
                </div>
               </div>
              </div>
               )
              //  :<AlternatingSkeletons/>
               }
               <div ref={contentEndRef} />
              </div>:
              <div className="h-[50.6vh] my-2">

              </div>
              }
              <div>
              {(!isNewConv && spinner)? <LinearProgress color="secondary" />:<div className="w-full h-[4px]"> </div>}

              <div className="pt-2 border-t flex flex-col gap-2">
                <div className="flex items-center gap-x-2">
                    <div className="">Send from </div>
                    <div className="text-gray-500">{formatPhoneNumber(twilioNumber)}</div>
                    </div>
                {attachedFile && attachedFile?.type?.startsWith('image/') ? (
                    <div className=" flex">
                        <img
                            src={previewUrl}
                            alt="Image Preview"
                            style={{ maxWidth: '100px', maxHeight: '100px', }}
                        />
                        <MdClose onClick={handleCloseClick}/>
                    </div>
                ): attachedFile ? <div className="h-[66.66px] flex">
                    {
                attachedFile?.type==='application/pdf'?
                <div className=" flex flex-col gap-2">
                <FaFilePdf  color={'#FFC107'} size={24}/> 
                <div className="text-xs">{attachedFile?.name}</div>
                </div>
                : attachedFile?.type==='application/xlsx'?<div>
                <FaFile size={36} color={'#FFC107'}/> 
                <div className="text-xs">{attachedFile?.name}</div>
                </div>: null 

                    }
                    <MdClose onClick={handleCloseClick}/>
            </div>:null}
            <TextField
               multiline={true}
                fullWidth
                variant="outlined"
                placeholder='Write a message'
                onChange={onChangeMsg}
                value={msgPayload?.messageBody}
                InputProps={
                    // query.length > 1 ?
                     {
                        startAdornment: (
                            <InputAdornment position="start">
                                 <input
                                     type="file"
                                     accept=".png, .jpg, .jpeg, .pdf"
                                     style={{ display: 'none' }}
                                     ref={fileInputRef}
                                     onChange={handleAttachment}
                                 />
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickAttachment}
                                    //  onMouseDown={onMouseDown}
                                    edge="start"
                                    disabled={isNewConv}
                                >
                                  <MdOutlineAttachFile />
                                </IconButton>
                                 
                             
                            </InputAdornment>
                          ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={sendMessage}
                                    //  onMouseDown={onMouseDown}
                                    edge="end"
                                    disabled={isNewConv}
                                >
                                    <MdSend size={24} />
                                </IconButton>
                            </InputAdornment>
                        )
                    } 
                    // : {}
                }
                onKeyDown={handleKeyPress}
            />    
            </div>
            </div>
             </div> 
             :
             <div className="col-span-9 border-t h-[85vh] flex flex-col items-center justify-center">
                <IconButton onClick={handleAddButtonClick}>
                <MdAdd size={96}/>
                </IconButton>
                <div className="text-lg">
                Click to start new conversation
                </div>
             </div>}
            {previewDoc?.open && <DocumentPreview open={previewDoc?.open} onClose={()=>setPreviewDoc({open:false,url:''})} url={previewDoc?.url} fileName={previewDoc?.fileName}/>}
        </div>)
};

export default OneToOneMessage;
