import React, { useEffect } from 'react';

import { AiFillEyeInvisible } from 'react-icons/ai';
import { NotificationManager } from 'react-notifications';

export default function NotPermitted() {
  useEffect(() => {
    NotificationManager.error(
      'You are not allowed to perform this operation. Please contact the admin to request permission.'
    );
  }, []);

  return (
    <div className="mt-48 flex flex-col justify-center items-center h-full">
      <p className="text-5xl lg:text-9xl font-extrabold">403</p>
      <div className="flex flex-row items-center mt-10">
        <AiFillEyeInvisible className="text-gray-400 text-lg mr-4" />
        <span className="text-xl">Access Not Granted</span>
      </div>
      <p className="text-sm mt-4 ml-2 text-center">
        You tried to access a page you did not have prior permission for.
        <br />
        Please contact the admin to request permission.
      </p>
    </div>
  );
}
