import ActionConstants from '../config/AppConstants';
const innitialData = {
    messagingServiceSid:'',
    sid:'',
    useCase:'',
    description:'',

};
export default function smsCampaign(state = innitialData, action) {
  switch (action.type) {
    case 'SMS_CAMPAIGN_IDS': {
      return {
        messagingServiceSid: action.messagingServiceSid,
        sid: action.sid,
        useCase:action.useCase,
        description:action.description,
      };
    }
    default:
      return { ...state };
  }
}
