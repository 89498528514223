import {serviceCallWithToken, serviceCallWithToken2} from '../serviceCall'

export const getCampaignUseCase = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/getCampaignUseCase',  
        method:'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
 
  export const postSMSCampaign = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/PostSMSCampaign',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getCampaignList = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/GetCampaignsList',  
        method:'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const GetMessagingCampaign = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/GetMessagingCampaign',  
        method:'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const getUnassignedPhoneNumbers = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/GetUnassignedPhoneNumbers',  
        method:'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };

  export const getCampaignPhoneNumbers = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/GetCampaignPhoneNumbers',  
        method:'get',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const postCampaignPhoneNumbers = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken2({
        url: '/api/SMS/PostCampaignPhoneNumbers',  
        method: method || 'post',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  export const deleteCampaignPhoneNumber = (data, callback, errorCallBack, method) => {
    return async (dispatch) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      serviceCallWithToken({
        url: '/api/SMS/DeleteCampaignPhoneNumber',  
        method: method || 'delete',
        data: data,
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          callback && callback(response && response.data);
        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          errorCallBack && errorCallBack(error?.response?.data);
        });
    };
  };
  