import ActionConstants from '../config/AppConstants';
const innitialData = {
  Body:''
};
export default function BulkSmsEditor(state = innitialData, action) {
  switch (action.type) {
    case ActionConstants.BULK_SMS_EDITOR: {
      return {
        Body: action.Body,
      };
    }
    default:
      return { ...state };
  }
}
