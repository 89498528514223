import React, { useRef } from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import constant_label from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:'1px',
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 300,
    height:38,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function MuiSearch2(props) {
  const classes = useStyles();
  const {name,placeholder,autoFocus,value,onChange,handleSearch,handleCloseIcon,onKeyDown,ref}=props;
//   console.log('autoFocus',autoFocus)
const inputRef=useRef() 
const focusInput = () => {
  if (inputRef.current) {
    inputRef.current.focus();
  }
};
  return (
    <Paper component="form" className={classes.root} elevation={3} style={props.style}>
      {/* <IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
          <InputBase
              inputRef={inputRef}
              className={classes.input}
              placeholder={placeholder ? placeholder : constant_label?.lbl_txt_Search}
              name={name}
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
              inputProps={{ 'aria-label': 'search google maps' }}
          />
     { value?.length?(<IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={()=>{
      focusInput()
      handleCloseIcon()
      }}>
        <CloseIcon />
      </IconButton>):null}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
