import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { userIdToken } from '../../userIdToken';
import { serviceCallWithToken, serviceCallWithToken2, serviceCallWithToken3 } from '../serviceCall';
import { serviceCallAuth, serviceCallAuth2 } from '../serviceCallAuth';

export const sendMail = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/SendEmail',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const uploadSignature = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/PutSignature',
      method: 'put',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
        // NotificationManager.success('Signature Created Succesfully !');
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        console.log(error, 'err');
        errorCallBack && errorCallBack(error?.response?.data);
        NotificationManager.error('Please try again !');
      });
  };
};
export const getListOfUserSignatures = (data, callback, errorCallBack) => {
  const userId=window.config.CALLING_USER_ID
  const user_Id=userId?userId:userIdToken().userId
  console.log('499',userId,userIdToken().userId)
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: `/api/Email/GetListOfUserSignatures`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        callback && callback(response.data);
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
  dispatch({ type: 'SPINNER_STATE', isLoading: false });

      });
  };
};
export const getSignature = (data, callback, errorCallBack) => {
  console.log('signature', data);
  const userId=window.config.CALLING_USER_ID
  const user_Id=userId?userId:userIdToken().userId
  return async (dispatch) => {
    serviceCallWithToken({
      url: `/api/Email/GetSignature`,
      method: 'get',
      data: data,
    })
      .then((response) => {
        callback && callback(response?.data);
        console.log(response?.data?.tbl_Signatures, 'signature');
        dispatch({ type: 'SIGNATURE', isSigned: true, userSignature: response?.data?.tbl_Signatures[0]?.signBody_chr });
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const deleteUserSignature = (data, callback, errorCallBack) => {
  console.log(data);
  return async (dispatch) => {
    serviceCallWithToken({
      url: `/api/Email/DeleteSignature`,
      method: 'delete',
      data: data,
    })
      .then((response) => {
        // dispatch(toggleLoader(false));
        callback && callback();
        NotificationManager.success('Signature Deleted !');
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const generateEmailSample = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken({
      url: '/api/Email/GenerateEmailSample',
      method: 'post',
      data: data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const isSpam = (data,body, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken3({
      url: '/api/Email/IS_SPAM',
      method: 'post',
      query:data.query,
      subject: data.subject,
      data:body.replace(/<[^>]+>/g, ''),
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const emailValidation = (body, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallWithToken2({
      url: '/api/Email/EmailValidation',
      method: 'post',
      data:body,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const unsubscribeEmails = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth2({
      url: '/api/Email/Unsubscribe',
      method: 'post',
      data:data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};
export const resubscribeEmails = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    dispatch({ type: 'SPINNER_STATE', isLoading: true });
    serviceCallAuth2({
      url: '/api/Email/Resubscribe',
      method: 'post',
      data:data,
    })
      .then((response) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        callback && callback(response.data);
      })
      .catch((error) => {
        dispatch({ type: 'SPINNER_STATE', isLoading: false });
        errorCallBack && errorCallBack(error?.response?.data);
      });
  };
};

