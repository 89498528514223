import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Tooltip } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash.debounce';
import { AiFillCaretDown } from 'react-icons/ai';
import { BiDetail } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';
import { MdModeEdit, MdMoreVert, MdRemoveRedEye } from 'react-icons/md';
import { NotificationManager } from 'react-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useUpdateEffect from '../../../src/hooks/useUpdateEffect';
import CommonButton from '../../components/Buttons/commonButton';
import MuiSearch from '../../components/InputFields/SearchFields/muiSearch';
import MuiSearch2 from '../../components/InputFields/SearchFields/muiSearch2';
import Loader from '../../components/loader';
import AddEditTemplateModal from '../../components/Modals/AddEditTemplateModal';
import ConfirmationDialog from '../../components/Modals/ConfirmationDialog';
import Table from '../../components/Table';
import constant_label from '../../constants';
import { getCampaignList } from '../../services/smsCampaign/smsCampaignService';
import { deleteTemplate, getSMSTemplate } from '../../services/template/templateService';
import { convertSlateJSONToString, convertTimeZone } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',

    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    [theme.breakpoints.up('lg')]: {
      '&  .MuiDataGrid-window ': {
        overflowX: 'hidden',
        // overflowX:props=>{if(props.open)
        //   {return 'scroll'}
        // else{return 'hidden'}
        // }
      },
    },

    '& .MuiDataGrid-colCellTitle': {
      fontSize: '14px',
      fontFamily: "'Rubik', sans-serif",
      color: '#333',
    },
    '& .MuiDataGrid-columnsContainer': {
      border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-dataContainer': {
      borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-cell': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: "'Rubik', sans-serif",
      borderBottom: '0',
    },
    '& .MuiDataGrid-row': {
      color: '#444',
      backgroundColor: '#ffffff',
      outline: 'none',
    },

    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#f7f7f7',
    },
  },

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radio: {
    '& .MuiIconButton-root': {
      padding: '0px',
    },
    '&$checked': {
      color: '#263003',
    },

    '& .MuiSvgIcon-root': {
      height: '15px',
      width: '15px',
    },
  },

  add_btn: {
    float: 'right',
  },

  flex: {
    flexGrow: 1,
    display: 'flex',
    height: '20px',
  },

  heading: {
    display: 'flex',
    justifyItems: 'center',
    columnGap: '4px',
    fontWeight: 'bold',
    marginTop: 30,
  },
  icons: {
    padding: '0px 3px',
    '&:focus': {
      outline: 'none',
    },
  },
  moreVertIcon: {
    [theme.breakpoints.up('768')]: {
      display: 'none',
    },
  },
}));
function SMSCampaignList(props) {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const [sortModel, setSortModel] = useState([{ field: 'usecase_chr', sort: 'asc' }]);
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [action, setAction] = useState('add');
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const classes = useStyles(props);
  const [selectedRadioValue, setSelectedRadioValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchedVal, setSearchedVal] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const smsPermissions = useSelector((state) => state?.login?.loginData?.permissions)?.SMS;
  const [globalSearchQuery,setglobalSearchQuery]=useState('')
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const {tenant}=useSelector((state)=>state.login.loginData)
  const history=useHistory()
  
console.log('smsPermissions',smsPermissions)
  const debouncedReloadList = useCallback(
    debounce((query) => {
      setPage(1);
      reloadList(query);
    }, 1200),
    []
  );
  const [tableState, setTableState] = useState({
    Calling_UserID_chr: props.profileData?.userId,
    Page_Index_int: +1,
    Page_Size_int: 20,
    Where_GeneralCriteria_Delim_mem: '',
    Sort_Expression_Delim_mem: '',
  });
  useEffect(() => {
    const updatedValue = JSON.parse(
      sessionStorage.getItem("SMSCampaignPage-global-search-query")
    );
    setSearchedVal(updatedValue);
    // const newTableState={...tableState}
    // newTableState['Where_GeneralCriteria_Delim_mem']=updatedValue
    // setTableState(newTableState)
    dispatch({type:'SMS_CAMPAIGN_IDS',messagingServiceSid:'',sid:''})
  }, []);
  // const requestSearch = ({ target: { value } }) => {
  //   const searchedVal = value.trim();
  //   setSearchedVal(searchedVal);
  //   debouncedReloadList({ searchTerm: searchedVal, pageNumber: 1 });
  // };
  useEffect(()=>{
    if(searchedVal===""){
      // setSearchedVal('')
      setglobalSearchQuery('')
    }
  },[searchedVal])
  const requestSearch = (e) => {
    const { name, value } = e.target;
    const searchedVal = value.trim();
    setSearchedVal(searchedVal);
    
    }

  const calculatePageCount = (rowsData, pageSize) => {
    const totalRows = parseInt(rowsData[0]['total_Count']);
    console.log(totalRows, 'totalRows', pageSize);
    setPageCount(Math.ceil(totalRows / pageSize));
  };

  const onTableStateChange = React.useCallback(
    ({ pageIndex: Page_Index_int, pageSize: Page_Size_int, globalFilter: Where_GeneralCriteria_Delim_mem, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      const sort = sortBy?.[0];
      let sortingField = 'usecase_chr';
      let sortingOrder = 'asc';

      if (sort) {
        sortingField = sort['id'];
        sortingOrder = sort['desc'] ? 'desc' : 'asc';
      }
      const Sort_Expression_Delim_mem = `${sortingField} ${sortingOrder}`;
      if (fetchId !== fetchIdRef.current) {
        return;
      }
      setTableState((state) => ({
        // Calling_UserID_chr: userId,
        ...state,
        Page_Index_int: Page_Index_int + 1,
        Page_Size_int: Page_Size_int || 20,
        Where_GeneralCriteria_Delim_mem,
        Sort_Expression_Delim_mem: Sort_Expression_Delim_mem || '',
      }));
    },
    []
  );

  const fetchTableData = (callback) => {
  
  dispatch(getCampaignList(tableState, (data) => {
    console.log('247',data)
      callback && callback(data?.smsCampaignsTable);
      setRows(data?.smsCampaignsTable);
      calculatePageCount(data?.smsCampaignsTable, tableState?.Page_Size_int);
 
    }))
   
  };

  useUpdateEffect(() => {
    fetchTableData();
  }, [tableState]);

  const reloadList = async (query = {}, paginate = false) => {
    setIsLoading(true);
    setRows([]);
    const { searchTerm = searchedVal, pageNumber = page, pageSize } = query;
    const { userId } = props.profileData;
    const sortingQuery = `${sortModel[0]['field']} ${sortModel[0]['sort']}`;

    const data = {
      Calling_UserID_chr: userId,
      Where_GeneralCriteria_Delim_mem: searchTerm,
      Sort_Expression_Delim_mem: sortingQuery,

      Return_All_Rows_ysn: true,
      Page_Index_int: pageNumber,

      Page_Size_int: pageSize || rowsPerPage,
    };
  dispatch({type:'TOKEN_API_CALL',isTokenApiCall:true})
    await props.getSMSTemplate(data, (rowsData) => {
      let totalRows = 0;
      if (rowsData) totalRows = parseInt(rowsData[0]['total_Count']);

      setTotalRows(totalRows);

      setRows(rowsData);
      setIsLoading(false);
  dispatch({type:'TOKEN_API_CALL',isTokenApiCall:false})
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let hiddenColumns = ['acction'];
    if (smsPermissions?.editSmsTemplate || smsPermissions?.deleteSmsTemplate) {
      // hiddenColumns = ['acction']
      hiddenColumns = [];
      // setHiddenColumns()
    }

    setHiddenColumns(hiddenColumns);

    console.log(smsPermissions, 'smsPermissions');
  }, [smsPermissions]);

  const handlePageChange = (pageData) => {
    const { pageSize, page } = pageData;
    setRows([]);
    setIsLoading(true);
    setPage(page);

    // setRows([])
    // setIsLoading(true)
    // setPage(page);
    reloadList({ pageNumber: page });
  };

  const onClickAdd = () => {
    // console.log('add is called')
    selectedTemplate && setSelectedTemplate({});
    setIsOpenDialog((state) => !state);
    setAction('add');
    // console.log('add called',isOpenDialog)

  };

  // const debouncedReloadList = useCallback(
  //   debounce((query) => reloadList(query), 2000),
  //   []
  // );

  // const requestSearch = (searchedVal) => {
  //   searchedVal = searchedVal.trim();
  //   setSearchedVal(searchedVal);
  //   debouncedReloadList({ searchedTerm: searchedVal, pageNumber: 1 });
  //   // reloadList({searchedTerm:searchedVal,pageNumber:1})
  // };

  const handleSortModelChange = (newModel) => {
    if (newModel['sortModel'].length) {
      setSortModel(newModel['sortModel']);
    } else {
      let sort;
      if (sortModel[0]['sort'] === 'desc') {
        sort = 'asc';
      } else {
        sort = 'desc';
      }
      setSortModel((array) => [{ ...array[0], sort }]);
    }
    // reloadList()
    // setState(
    //   (state) => ({ sortModel: [{ ...state.sortModel[0], sort }] }),
    //   () => reloadList()
    // );
    // setSortModel(array=>([{...array[0],sort}]))
  };

  // const onHandleModel = async (name) => {
  //   let value = state[name];
  //   await setState({
  //     [name]: !value,
  //   });
  // };
  const onClickEdit = (row) => {
    // setSelectedTemplate(row);
    setIsOpenDialog((state) => !state);
    // setAction('edit');
    dispatch({type:'SMS_CAMPAIGN_IDS',messagingServiceSid:row.messagingServiceSid_chr,sid:row.siD_ids})
    history.push(`/${tenant}/sms-campaign-detail`)
  };

  const onClickDelete = async (row) => {
    await setSelectedTemplate(row);
    await setIsOpenDeleteDialog((state) => !state);
  };

  const deleteSMSTemplate = async () => {
    const { userId } = props.profileData;
    // const { selectedTemplate } = state;
    await props.deleteTemplate(
      {
        Calling_UserID_chr: userId,
        SMSTemplateID_lng: selectedTemplate.smsTemplateID_ids,
      },

      (res) => {
        NotificationManager.success(res);
        reloadList();
      },
      (err) => {
        NotificationManager.error(err);
      },
      2
    );
  };
  const tableColumns = [
    {
      accessor: 'acction',
      Header: constant_label?.lbl_txt_actions,
      disableSortBy:true,
      sortable: false,
      filterable: false,
      headerAlign: 'right',
      align: 'right',
      width: 110,
      Cell: ({ row: { original }, value, column }) => {
        return (
          <div className={`${classes.flex} `}>
            {/* {smsPermissions?.editSmsTemplate ? ( */}
              <Tooltip title="View Campaign Detail">
                <IconButton aria-label="edit" className={classes.icons} onClick={(e) => onClickEdit(original)}>
                  <MdRemoveRedEye color="#263003" className="text-theme-green h-20px" fontSize="small" size={20} />
                </IconButton>
              </Tooltip>
            {/* ) : null} */}

            {/* {smsPermissions?.deleteSmsTemplate ? ( */}
              {/* <Tooltip title="Delete">
                <IconButton aria-label="delete" className={classes.icons} onClick={(e) => onClickDelete(original)}>
                  <FaTrash color="#263003" className="text-theme-green h-16px" />
                </IconButton>
              </Tooltip> */}
            {/* ) : null} */}
          </div>
        );
      },
      flex: 1,
    },
    {
      accessor: 'usecase_chr',
      Header: 'Campaign Use Case',
      width: 350,
      // sortDirection: "asc",
      sortable: true,
    },
    {
        accessor: 'description_chr',
        Header: 'Campaign Description',
        width: 350,
        // sortDirection: "asc",
        sortable: true,
      },
    {
      accessor: 'lastUpdatedDateTime_dtm',
      Header: constant_label?.lbl_txt_last_updated_dtm,
      sortable: true,
      // width: 250,
      Cell: ({ value }) => {
       return <span>{convertTimeZone(value)}</span>;
      },
    },
  ];

  const handleSearch=()=>{
    const searchQuery=searchedVal.trim()
      setglobalSearchQuery(searchQuery)
      sessionStorage.setItem(
        "SMSCampaignPage-global-search-query",
        JSON.stringify(searchQuery)
      );
    }
    const handleCloseIcon=()=>{
      setSearchedVal('')
      setglobalSearchQuery('')
      sessionStorage.setItem(
        "SMSCampaignPage-global-search-query",
        JSON.stringify('')
      );
    }
    const handleKeyPress=(event)=>{
      if (event.key === "Enter") {
        event.preventDefault(); 
    const searchQuery=searchedVal.trim()
      setglobalSearchQuery(searchQuery)
      sessionStorage.setItem(
        "SMSCampaignPage-global-search-query",
        JSON.stringify(searchQuery)
      );
      }
    }
  return (
    <>
      {spinner ? <Loader /> : null}
      <div className="" component="main">
        <div className="mb-5">
          <Typography variant="h5" gutterBottom className={classes.heading}>
            {constant_label?.lbl_hd_sms_Campaigns}
          </Typography>
          <div className="  justify-content-end mb-2">
           
            {/* <Button
              className={classes.add_btn}
              variant="contained"
              color="primary"
              onClick={onClickAdd}
            >
              Add
            </Button> */}
          </div>
          <Grid
            style={{
              // backgroundColor: "#9BA3B9",
              padding: '20px 0px',
              borderRadius: '8px 8px 0px 0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            container
            item
          >
            <Grid
              container
              item
              className={classes.grid}
              lg={6}
              md={8}
              sm={8}
              xs={8}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="flex gap-x-2">
             
                 <MuiSearch2
                  name="Where_GeneralCriteria_Delim_mem"
                  // autoFocus={isFocused}
                  value={searchedVal}
                  onChange={requestSearch}
                  handleSearch={handleSearch}
                  handleCloseIcon={handleCloseIcon}
                  onKeyDown={handleKeyPress}
                 />
              
               {/* {(smsPermissions?.addSmsTemplate) ?<CommonButton onClick={onClickAdd}>
                <IoIosAdd size={24} className="hidden md:inline" />
                  <span>
                    {constant_label?.btn_add_template}
                   </span>
                </CommonButton>:null} */}
              </div>
            </Grid>

          </Grid>
          <Table
            hiddenColumns={hiddenColumns}
            pageCount={pageCount}
            totalRows={totalRows}
            columns={tableColumns}
            data={rows}
            // fetchData={fetchTableData}
            onTableStateChange={onTableStateChange}
            globalSearchQuery={globalSearchQuery}
          />
        </div>
        {/* {isLoading && <Loader type="full-screen" />} */}

        {isOpenDeleteDialog && (
          <ConfirmationDialog
            onHandleModel={() => setIsOpenDeleteDialog((state) => !state)}
            isOpenDialog={isOpenDeleteDialog}
            action={() => deleteSMSTemplate(selectedTemplate)}
            title={'Delete Template'}
            content={'Are you sure want to delete ?'}
          />
        )}
      </div>
    </>
  );
}


export default (SMSCampaignList);
