const innitialData = {
   conversationNumbers:[],
   unreadSms:[],
   smsCount:0,
   savedContact:[]
  };
  export default function smsConversations(state = innitialData, action) {
    switch (action.type) {
      case 'CONV_LIST': {
        return {
          ...state,
            conversationNumbers:action.conversationNumbers,
        };
      }
      case 'UPDATE_SMS_COUNT': {
        return {
          ...state,
            smsCount:action.smsCount,
            unreadSms:action.unreadSms
        };
      }
      case 'SAVE_CONTACT': {
        return {
          ...state,
          savedContact:action.savedContact,
        };
      }
      case 'CLEAR_CONVERSATION': {
        return {
          conversationNumbers: [],
          unreadSms: [],
          smsCount: 0,
        };
      }
      default:
        return { ...state };
    }
  }