import { ImSpinner8 } from 'react-icons/im';

function Loader() {
  return (
    <>
      <div className="fixed z-40 min-h-full min-w-full bg-black opacity-25 left-0"></div>
      <div className="fixed inset-x-1/2 top-2/4	z-50 text-center ">
        {/* <ImSpinner8 className="dark:text-white-600  inline h-12 w-12 animate-spin fill-[#263003] text-gray-200 " /> */}
        <div className='animate-bounce'>
        <img src='/connect_fav_icon.svg' className="h-12 w-12 max-w-fit animate-spin " />
        </div>
      </div>
    </>
  );
}
export default Loader;
