const innitialData = {
  isCallModalOpen: false,
  country_lng:'',
  phoneNumber: '',
  isCalling: false,
  callReceiverName:'',
  isReceiving:false,
};

export default function call(state = innitialData, action) {
  switch (action.type) {
  case 'CALL_MODAL_OPEN': {
    const {phoneNumber='', isCalling=false,  callReceiverName='',countryCode='',country_lng=''} = action.payload || {}

    return {
      isCallModalOpen: true,
      phoneNumber,
      country_lng,
      countryCode,
      isCalling,
      callReceiverName,

    };
  }
  case 'CALL_MODAL_CLOSE': {
    return {
      isCallModalOpen: false,
      isCalling: false,
    };
  }

  case 'START_CALL': {
    return {
      ...state,
      isCalling:true
    }
  }

  case 'STOP_CALL':{
    return {
      ...state,
      isCalling:false
    }
  }
  case 'CALL_RECEIVE':{
    const {isReceiving,From,call,device}=action.payload
    return {
      ...state,
      isReceiving:isReceiving,
      from:From,
      call:call,
      device:device
    }
  }
  case 'CLOSE_RECEIVE_MODAL':{
    const {isReceiving}=action.payload
    return {
      ...state,
      isReceiving:isReceiving,
      from:""
      }
  }


  default:
    return { ...state };
  }
}
