import React,{ lazy,Suspense,useEffect, useRef, useState } from 'react';

import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { saveAs } from "file-saver";
import { FaBuilding, FaDownload } from 'react-icons/fa';
import { IoIosDocument } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Loader from '../../components/loader';
import constant_label from '../../constants';
import { getCustomerDoc, getCustomersList,deleteCustomerDocs } from '../../services/customer/customerService';
import { convertTimeZone, standardformatDate } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
      border: 0,
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
  
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontSize: '14px',
        fontFamily: "'Rubik', sans-serif",
        color: '#333',
      },
      '& .MuiDataGrid-columnsContainer': {
        border: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
  
      '&  .MuiDataGrid-window ': {
        //   [theme.breakpoints.up('1450')]: {
        //     overflowX: (props) => {
        //       if (!props) return 'hidden';
        //       return props.open ? 'auto' : 'hidden';
        //     },
        //   },
      },
      '& .MuiDataGrid-dataContainer': {
        borderRight: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        borderLeft: `2px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "'Rubik', sans-serif",
        borderBottom: '0',
      },
      '& .MuiDataGrid-row': {
        color: '#444',
        backgroundColor: '#ffffff',
        outline: 'none',
      },
  
      '& .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f7f7f7',
        color: '#aeacb9',
      },
    },
    tableContainer: {
      overflow: 'auto',
    },
  
    clickable: {
      cursor: 'pointer',
    },
    icons: {
      padding: '0px 3px',
      '&:focus': {
        outline: 'none',
      },
    },
    paper: {
      marginTop: 20,
     padding:'1vw 1vw 1.5vw 1vw'
    },
    paper2: {
      padding:'1.5vh',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#C8C8C8',
      },
    },
    flex: {
      flexGrow: 1,
    },
    heading: {
      display:'flex',
      justifyItems:'center',
      columnGap:'4px',
      fontWeight: 'bold',
      marginTop: 15,
      // paddingBottom: 25,
    },
    // heading2:{
    //     borderBottom:'2px solid #BEBEBE'
    // }
  }));
function CustomerDoc(){
  const {domain,token} = useSelector((state) => state?.login?.loginData);
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const classes=useStyles()
    const { id } = useParams();
    const dispatch=useDispatch()
    const [selectedCustomer,setSelectedCustomerDetail]=useState({})
  const [customerDoc, setCustomerDoc] = useState([]);
  const [isLoadRequire, setLoadRequire] = useState(true);
    useEffect(() => {
      dispatch(getCustomersList({CustomerID_lng:id},(res)=>{
        console.log('selected customer',res)
        setSelectedCustomerDetail(res?.customersResponseTable[0])
      }))
      dispatch(
        getCustomerDoc({CustomerID_ids:id }, (res) => {
          console.log('docs11',res)
          setCustomerDoc(res)
        })
      );
    }, [isLoadRequire]);

    const getCustomerDocumentFile = (documentUrl, customerDocumentName_chr) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      axios({
        url: `${window.config?.API_URL}/api/Customer/GetCustomerDocumentFile`,
        method: "get",
        responseType: "blob",
        params: {
          strDocumentUrl: documentUrl,
        },
        headers: {
          Authorization: "Bearer" + " " + token,
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "x-domain-name": domain,
        },
      })
        .then((response) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
  
          saveAs(blob, customerDocumentName_chr);
          NotificationManager.success('Document downloaded successfully.')

        })
        .catch((error) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          console.log(error.message);
        });
    };
  
    const onClickDelete = (customerDocumentID_ids) => {
      dispatch({ type: 'SPINNER_STATE', isLoading: true });
      dispatch(
        deleteCustomerDocs({ CustomerDocumentID_ids: customerDocumentID_ids }, (res) => {
          dispatch({ type: 'SPINNER_STATE', isLoading: false });
          setLoadRequire((val) => !val);
          NotificationManager.success('Document deleted successfully.')
        })
      );
    };
    return <>
    {spinner ? <Loader/> : null}
<div className="container-fluid " component="main">
<Paper elevation={2} variant="outlined" className={classes.paper}>
       <Grid container spacing={3}>
          <Grid item xs={12}>
          <Typography variant="h6" className='border-b-2 flex items-center'>
            {/* {constant_label?.lbl_hd_agency_Doc} */}
            <IoIosDocument size={24} color='#263003'/>
            Customer Documents
          </Typography>
          </Grid>
           <Grid item xs={12} sm={6}>
           <b>Customer Name</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>{selectedCustomer?.customerName_chr}</b>
           </Grid>
           {/* <Grid item xs={12} sm={6}>
           <b>Contact Name</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>{selectedCustomer?.primaryContactName_chr}</b>
           </Grid> */}
           <Grid item xs={12} sm={6}>
           <b>Country</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>{selectedCustomer?.countryName_chr}</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>Email</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>{selectedCustomer?.email_chr}</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>Phone</b>
           </Grid>
           <Grid item xs={12} sm={6}>
           <b>{(selectedCustomer?.phone_chr)?(selectedCustomer?.phone_chr):'Not Provided'}</b>
           </Grid>
       {/* </Paper> */}
       <Grid item xs={12}>
        <Paper>
       <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#f1f5f9" }}>
            <TableRow>
              <TableCell align="left">Actions</TableCell>
              <TableCell align="left">Document Name</TableCell>

              <TableCell align="left">Uploaded Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {customerDoc?.map((res) => {
              return (
                <>
                  <TableRow key={res.agencyDocumentID_ids}>
                    <TableCell component="th" scope="row">
                      <Tooltip title="Download">
                        <IconButton
                          onClick={() => {
                            getCustomerDocumentFile(
                              res.customerDocumentUrl_chr,
                              res.customerDocumentName_chr
                            );
                          }}
                        >
                          <FaDownload className="cursor-pointer text-lg text-primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() =>
                            onClickDelete(res.customerDocumentID_ids)
                          }
                        >
                          <MdDelete className="cursor-pointer text-lg text-primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.customerDocumentName_chr}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {convertTimeZone(res.lastUpdatedDateTime_dtm)}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
        {
          customerDoc.length?null:<div className='h-64 flex items-center justify-center'>
            <Typography variant='h5'>
            No Documents
            </Typography>
            </div>
        }
        </Paper>
        </Grid>
        </Grid>
        </Paper>

    </div>

    </>
}
export default CustomerDoc