import React, { useEffect, useState } from 'react';

import { Checkbox, Chip,FormControlLabel,Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { FcBusinessman } from "react-icons/fc";
import { ImSpinner2 } from 'react-icons/im';
import { NotificationManager } from 'react-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import LoginButton from '../../../components/Buttons/loginButton';
import PasswordField from '../../../components/InputFields/TextFields/passwordField';
import ActionConstants from '../../../config/AppConstants';
import constant_label from '../../../constants';
import { setCredentials } from '../../../credentials';
import CustomHistoryHook from '../../../hooks/useHistory';
import { checkLogin } from '../../../services/userService';
import FormValidator from '../../../validations/FormValidator';
import '../styles.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:'5vw 3vh 8vw 3vh',
    gap:'5vh'
  },
  avatar: {
    backgroundColor: alpha('#263003',0.5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display:'flex',
    flexDirection:'column',
    gap:'2.5vh'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const loginFormValidator = new FormValidator();

function EnterPassword(props) {
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const {user,tenant,domain,authorized,tenants}=useSelector((state)=>state?.login?.loginData)
  const history = useHistory();
  const {customHistory}=CustomHistoryHook()
  const classes = useStyles();
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [hostName, setHostName] = useState('');
  const [values, setValues] =useState({
    password: '',
    showPassword: false,
  });
  const [allDir,setAllDir]=useState([])
  // useEffect(()=>{
  //   if(!tenant){
  //     history.push('/')
  //   }
  // },[])
  useEffect(()=>{
    setLoginData((state)=>({
      ...state,
      email:user
  }))
  },[user])
  const onChange = (e) => {
    const { name, value } = e.target;
    setLoginData((data) => ({ ...data, [name]: value }));
  };  
  const mapPermissions = (permissions) => {
    // console.log(permissions, "permissions");
    if (!Array.isArray(permissions)) {
      return {};
    }
    const result = {};
    permissions.forEach(({ area, permission }) => {
      result[area] = {};
      permission.forEach(({ name, hasPermission }) => {
        result[area][name] = hasPermission;
      });
    });
    return result;
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getUsersDir=async()=>{
    axios({
      url: window.config.COMMON_API_URL + '/api/Tenant/GetUserTenants',
      method: 'get',
      params: { UserEmailPhone: user ,DomainName:''},

    }).then((res)=>{
      console.log('all dir',res.data)
     if(res.data)
      { 
        setAllDir(res.data)
      }
      dispatch({type:'ALL_DIR',userDir:res.data ,userEmail:user})
      
    }).catch(() => {
      dispatch({ type: 'SPINNER_STATE', isLoading: false });
      setAllDir([]);
    })
  }
  useEffect(()=>{
    getUsersDir()
  },[])
  const onSubmitForm = (event) => {
    event.preventDefault();
    if ((!tenant) && (!loginData?.email)) {
      console.log('131',tenant,loginData?.email)
      return NotificationManager.error('Invalid credentials');
    }

    if (loginData.password.length) {
      props.checkLogin(
        {
          data: loginData,
          headers: {
            'x-domain-name':domain || tenant,
          },
        },
        (data) => {
          data["permissions"] = mapPermissions(data?.permissions?.aspNetPermission);
          localStorage.setItem('token', data?.token);
          localStorage.setItem('refreshToken', data?.refreshToken);
          setCredentials({access:data?.token, refresh:data?.refreshToken})
          localStorage.setItem('userId', data?.userId);
          localStorage.setItem("originalUserDetails", JSON.stringify(data));
          dispatch({type:'GET_TOKENS',token:data?.token,refreshToken:data?.refreshToken})
          customHistory(tenant,'/dashboard')
          const result = jwt_decode(data?.token);
          let permissions = [];
          if (result?.Permissions) {
            permissions = JSON.parse(result?.Permissions);
          }
          dispatch({
            data: {
              ...data,
              email: loginData.email,
              permissions: data?.permissions,
              domain: domain,
              tenant:tenant,
              tenants:allDir,
              isImpersonated:false
            },
            type: ActionConstants.SAVE_LOGIN_DATA,
          });
          dispatch({type:'TWILIO_NUMBER',payload:{twilioNumber:data?.twilioNumber}})
         setValues({ ...values, showPassword: false });
        },(err)=>{
          console.log('170',err)
          if(err==='User inactive')
          {swal({
                    title: "InActive",
                    text: "Your Account is Inactive, Please Contact Your Administrator",
                    icon: "error",
                    dangerMode: true,
                  });}
                  else if(err.includes('Your password is incorrect')){
                    swal({
                      title: "Incorrect Password",
                      text: err,
                      icon: "error",
                      dangerMode: true,
                    });
                  }
        }
      );
    } else {
      // loginFormValidator.showMessages();
      setValue(value + 1);
    }
  };
  return (
    <>
      {/* {spinner ? (
        <>
          <Loader />
        </>
      ) : null} */}
      <div component="main" className='mb-2'>
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}></Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Paper elevation={3}>
            <div className={classes.paper}>
            <Typography 
              variant='h5'
              className='flex flex-col gap-y-6 items-center justify-center'
              > 
              <img src="/connect_fav_icon.svg" alt="favIcon"width="32px"/>
              <img src='/connect_logo_2.svg' width='180px'/>
              Sign in
              </Typography>
              
              <div className='flex flex-col justify-center items-center gap-y-2'>
              
              {/* <Avatar className={classes.avatar}>
                <FcBusinessman size={45} className='mt-1'/>
              </Avatar> */}
              <Chip
              color="secondary"
               avatar={<Avatar className='capitalize'>{user?.charAt(0)}</Avatar>}
               label={user}
                // onClick={handleClick}
                 />
              </div>
              <form className={`${classes.form} mt-4`} 
              noValidate 
              onSubmit={onSubmitForm}
              >
                
                <PasswordField
                 variant="outlined"
                 required
                 fullWidth
                //  placeholder="Enter password"
                 name="password"
                 label="Enter Password"
                 type={values.showPassword ? 'text' : 'password'}
                 value={loginData.password}
                 hide={values}
                 onChange={onChange}
                 onClick={handleClickShowPassword}
                 onMouseDown={handleMouseDownPassword}
                 disabled={spinner}
                />
                {loginFormValidator.message('Password', loginData.password, 'required|password', 'text-danger')}
                <div className="flex items-center justify-end">
                  {/* <FormControlLabel
                    className="m-0 text-sm"
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Link to={`/${tenant}/forgot-password`} className="hover:no-underline hover:text-[#263003]">
                {constant_label?.lbl_txt_forgot_password}?
                  </Link>
                </div>
                <LoginButton>
                {spinner ? 
                  <div className='flex gap-x-1'>
                  <ImSpinner2 size={20} className='animate-spin'/>
                 { constant_label?.btn_signing_in} 
                  </div>
                  : 
                  constant_label?.btn_sign_in
                  }
                </LoginButton>
              </form>

            </div>
            </Paper>
          </Grid>
          <Grid item  xs={12} sm={3} md={3} lg={4}></Grid>
        </Grid>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  checkLogin,
};
const mapStateToProps = (state) => {
  return {
    data: state.login,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterPassword);
