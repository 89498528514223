import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';

import DefaultButton from '../../components/Buttons/defaultButton';
import SaveButton from '../../components/Buttons/saveButton';
import MuiDropDown from '../../components/InputFields/DropDown/autoComplete';
import TxtField from '../../components/InputFields/TextFields/TextField';
import Loader from '../../components/loader';
import { putTwilioNumber } from '../../services/twilio/twilioPhoneService';
import { getUserList, updateUser } from '../../services/userService';

export default function ExtDetails({open,setOpen,data,maxExt,fetchTableData}) {
    const {userId}=useSelector((state)=>state.login.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
    const dispatch=useDispatch()
//   const [open, setOpen] = React.useState(false);
const [suggestion,setSuggestion]=useState('')
const [formData,setFormData]=useState({})
const [userList,setUserList]=useState([])
const [currentSelectedUser,setCurrentSelectedUser]=useState(null)
const [selectedUserData,setSelectedUserData]=useState([])
const [prevSelectedUser,setPrevSelectedUser]=useState(null)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSuggestion('')
  };
const handleChange=(e)=>{
    const {name,value}=e.target
    if(name==='extension'){
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setFormData((state)=>({
        ...state,
        [name]:onlyNums
    }))
    if(value===''){
      setSuggestion('')
    }else{
      setSuggestion(`Available extension : ${parseInt(maxExt)+1}`)

    }
    }else
   { 
    setFormData((state)=>({
        ...state,
        [name]:value
    }))
  }

}
console.log('line 83',selectedUserData)
const updateCurrentUser=(data)=>{
  const assignedAgenciesIDs=selectedUserData.adminAgency.map((data)=>(data.agencyID_lng))
  const assignedUsersIDs=selectedUserData.adminUser.map((data)=>(data.userID_chr))
  const payload={
    ...selectedUserData.aspNetUsers[0],
    email:((selectedUserData.aspNetUsers[0].email).trim()).toLowerCase(),
    userId:selectedUserData.aspNetUsers[0].id,
    roleName:[selectedUserData.aspNetUsers[0].roleName],
    agencyID:selectedUserData.aspNetUsers[0].agencyId_lng,
    agencyIDs:assignedAgenciesIDs,
    userID_chr:assignedUsersIDs,
    activeNumberID:data.id,
    twilioNumber:data.twilioNumber,
  }
  dispatch(updateUser(payload,(res)=>{
    fetchTableData()
  },(err)=>{
    console.log(err)
  }))
}
const updatePreviousUser=()=>{
  const {aspNetUsers,adminAgency,adminUser }=prevSelectedUser
  const assignedAgenciesID=adminAgency.map((data)=>(data.agencyID_lng))
  const assignedUsersID=adminUser.map((data)=>(data.userID_chr))
  const prevUserPayload={
    ...aspNetUsers[0],
    email:((aspNetUsers[0].email).trim()).toLowerCase(),
    userId:aspNetUsers[0].id,
    roleName:[aspNetUsers[0].roleName],
    agencyID:aspNetUsers[0].agencyId_lng,
    agencyIDs:assignedAgenciesID,
    userID_chr:assignedUsersID,
    activeNumberID:'',
    twilioNumber:null,
    lastUpdatedByUserID:userId,
  }
  dispatch(updateUser(prevUserPayload,(res)=>{
    fetchTableData()
  },(err)=>{
    console.log(err)
  }))
}
console.log('line 126',currentSelectedUser)

const updateExtension=()=>{
  const payload={
    Calling_UserID_chr:userId,
    ID:formData.id,
    PhoneNumber:formData.phoneNumber,
    Extension:formData.extension,
    AreaCode:formData.areaCode,
    CountryCode:formData.CountryCode,
    
}
console.log(data.extension,formData.extension)
if('before submit',data.extension!==formData.extension)
  {
    dispatch(putTwilioNumber(payload,(res)=>{
      assignNumber(formData)
          handleClose();
          NotificationManager.success('Updating Successfuly !')
          fetchTableData()
      
  },(err)=>{
    console.log(err.includes('already exists in the table'))
    if(err.includes('already exists in the table')||err.includes('already exists in the Ring Groups table')){
      NotificationManager.error('Given extension already in use.')
    }
  
  }))
  }else{
    assignNumber(formData)
          handleClose();
          NotificationManager.success('Updating Successfuly !')
  }
}
const assignNumber = (data) => {
  // Check if a user is currently selected and no previous user is selected
  if (currentSelectedUser !== null && prevSelectedUser === null) {
    // Update the current user with the provided data
    updateCurrentUser(data);
  }
  // Check if no user is currently selected
  else if (currentSelectedUser === null) {
    // If a previous user is selected, update the previous user
    if (prevSelectedUser !== null) {
      updatePreviousUser();
    }
  }
  // Check if a user is currently selected and the previous user's aspNetUsers[0] is not the same as the current selected user
  else if (currentSelectedUser !== null && prevSelectedUser?.aspNetUsers[0] !== currentSelectedUser) {
    // Update both the current and previous users
    updateCurrentUser(data);
    updatePreviousUser();
  }
  // If none of the above conditions are met
  else {
    console.log('no prev user selected');
  }
};
const handleSubmit=(e)=>{
e.preventDefault()
console.log(formData)
updateExtension()
// assignNumber(formData)



}
// console.log('line 160',data)
useEffect(()=>{
  console.log('line 160',data)
  setFormData(data)
 
  if(open && data.assignedUsers!=='Not Assigned')
 { 
  const payload={
    ActiveNumberId_lng:0,
    Include_Deleted_byt:0,
    Paginate_ysn:false,
  }
  dispatch(
    getUserList(payload, (resp) => {
      console.log('hun ithe aa',Response)
      const users = [...resp.aspNetUsers]
      console.log('hun ithe aa',users)
      const payloadData = {
        UserID_chr: data.userId,
        Include_Deleted_byt: 0,
      }
      console.log('hun ithe aa',payloadData)
      console.log('line 164', payloadData, data)
      dispatch(getUserList(payloadData, (res) => {
        setSelectedUserData(res)
        setCurrentSelectedUser(res.aspNetUsers[0])
        setPrevSelectedUser(res)
        setUserList([...users, res.aspNetUsers[0]])
      }))
    })
  )
}else if(open && data.assignedUsers==='Not Assigned'){
  const payload={
    ActiveNumberId_lng:0,
    Include_Deleted_byt:0,
    Paginate_ysn:false,
  }
  dispatch(
    getUserList(payload, (res) => {
      
        setUserList(res.aspNetUsers)
      
    })
  )
}
  else if(!open){
    setPrevSelectedUser(null)
    setCurrentSelectedUser(null)
    setUserList([])
  }
},[open])
  return (
    
      <Dialog
        open={open}
      >
        {spinner?<Loader/>:null}
        <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Edit Extension</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
          <Grid item xs={12} >
            <MuiDropDown
            fullWidth={true}
              // margin='dense'
              size="small"
              label="Assigned To"
              options={userList}
              // groupBy={(option) => option?.agencyName_chr}
              getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
              value={currentSelectedUser}
              onChange={(event, value) => {
                console.log('line 122',value)
                if (value !== null) {
                  setCurrentSelectedUser(value)
                  dispatch(getUserList({
                    UserID_chr:value.id,
                    Include_Deleted_byt:0,
                  },(res)=>{
                    console.log('177',res)
                    setSelectedUserData(res)
                  }))
                  } 
                else{
                  setCurrentSelectedUser(null)
                }
              }}
              renderOption={(option) => (<div><div>{option.firstName} {option.lastName}</div><p className='text-xs'>{option.email}</p></div>)}
              style={{
                // width:'250px',
                background: 'white'
              }}

            />
          </Grid>
          <Grid item xs={12}>
              <TxtField
              name='extension'
              label='Extension'
              value={formData.extension}
              onChange={handleChange}
              variant='outlined'
              inputProps={{ maxLength: 3 }}
              helperText={suggestion}
            /></Grid>
            <Grid item xs={12}><TxtField 
            disabled={true}
            label='Phone Number' value={formData.phoneNumber} variant='outlined'/></Grid>
            
            {/* <Grid item xs={12}><TxtField label='Assigned To' value={formData.assignedUsers} variant='outlined'/></Grid> */}
            <Grid item xs={12}><TxtField
            disabled={true}
            label='Email' value={formData.email} variant='outlined'/></Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='grid grid-cols-12'>
          <div>
          <DefaultButton onClick={handleClose} color="primary">
            Cancel
          </DefaultButton>
          </div>
         <div>
         <SaveButton/>
         </div>
        </DialogActions>
        </form>
      </Dialog>
  );
}
