import ActionConstants from '../config/AppConstants';

const initialState = {
  open: false,
  To: '',
  Subject: '',
  Body: '',
};

export default function mailModalState(
  state = initialState,
  { type, payload }
) {
  switch (type) {
  case ActionConstants.MAIL_MODAL_OPEN: {
    if (payload) {
      const { customers = [], Body = '', Subject = '', massOption = false, showVariableTemplate = true} = payload;
      return {
        open: true,
        customers,
        Body,
        Subject,
        massOption,
        showVariableTemplate
      };
    }
    return {
      open: true,
      customers: [],
      // To:[]
    };
  }
  case ActionConstants.MAIL_MODAL_CLOSE: {
    return initialState;
  }

  default:
    return { ...state };
  }
}