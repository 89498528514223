const initialData = {
  allNotifications: [],
  unreadNotifications: [],
};
export default function login(state = initialData, action) {
  switch (action.type) {
  case 'ADD_NOTIFICATIONS': {
    return {
      ...state,
      allNotifications: action?.allNotifications || [],
    };
  }
  case 'ADD_UNREAD_NOTIFICATIONS': {
    return {
      ...state,
      unreadNotifications: action?.payload?.notifications || [],
    };
  }

  default:
    return { ...state };
  }
}
