import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import constant_label from '../../../constants';
const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        // borderRadius: '8px',
        // borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha('#fff',1 ),
        '&:hover': {
            // backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },

    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            // '&:focus': {
            //     width: '25ch',
            // },
        },
    },
    paper:{
        // borderRadius:'1px',
    }
}));
function MuiSearch(props) {
    const { name,placeholder,value, onChange } = props;
    const classes = useStyles();
    return <Paper elevation={3} className={classes.search}>
    {/* <div className={classes.search}> */}
        <div className={classes.searchIcon}>
            <SearchIcon />
        </div>
        <InputBase
            // autoFocus={true}
            autoComplete="off"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder?placeholder:constant_label?.lbl_txt_Search}
            classes={{
                // root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
        />
    {/* </div> */}
    </Paper>
}
export default MuiSearch