import { useEffect, useState } from "react";

import { Container, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { FaUser, FaUserEdit } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io"
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router'
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import CancelButton from "../../components/Buttons/cancelButton";
import CommonButton from "../../components/Buttons/commonButton";
import DefaultButton from "../../components/Buttons/defaultButton";
import SaveButton from "../../components/Buttons/saveButton";
import Comments from "../../components/CommentSection/component";
import MuiDropDown from "../../components/InputFields/DropDown/autoComplete";
import TxtField from "../../components/InputFields/TextFields/TextField";
import Loader from "../../components/loader";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import constant_label from "../../constants";
import { addCustomer, getCustomerComments, getCustomerRef, getCustomersList, getListOfUsers, getStateByCountryRef } from "../../services/customer/customerService";
import { sendMail } from "../../services/mail/mailService";
import FormValidator from "../../validations/FormValidator";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
});
const customerformValidator = new FormValidator();
function AddEditCustomePage(props) {
  const pathname = window.location.pathname
  const { customerId } = useParams()
  const history = useHistory()
  const { tenant, domain, userId,email,firstName,lastName } = useSelector((state) => state?.login?.loginData)
  const spinner = useSelector((state) => state?.spinnerState?.isLoading);
  const { isOpenDialog, action, customerType, onClickAdd, selectedCustomer, reloadList } = props;
  const initData = selectedCustomer;
  const [customerData, setCustomerData] = useState(initData)
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [customerTypeList, setCustomerTypeList] = useState([])
  const [customerStageList, setCustomerStageList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({})
  const [selectedState, setSelectedState] = useState({})
  const [selectedRole, setSelectedRole] = useState({})
  const [selectedStage, setSelectedStage] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState({})
  const [selectedDesignation, setSelectedDesignation] = useState({})
  const [isPromptOpen, setIsPromptOpen] = useState(false)
  const [ownerList, setOwnerList] = useState([])
  const [SelectedAccountOwner, setSelectedAccountOwner] = useState([])
  const [commentList, setCommentList] = useState([])
  const onInputValidate = (value, name) => {
    setFormDataError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };
  const initFormDataError = {
    customerName_chr: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onInputValidate,
    },
    email_chr: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onInputValidate,
    },
    phone_chr: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onInputValidate,
    },
  }
  const [previousEmail,setPreviousEmail]=useState('')
  const [formDataError, setFormDataError] = useState(initFormDataError);
  const dispatch = useDispatch()
  const getOwner=(ownerId)=>{
    dispatch(
      getListOfUsers({}, (data) => {
        const response = data?.aspNetUsers?.map((data) => data)
        setOwnerList(response)
        const customerOwner = data?.aspNetUsers.find((own) => (own?.id.toLowerCase()) === (ownerId.toLowerCase()))
        setSelectedAccountOwner(customerOwner)
        setPreviousEmail(customerOwner)
      })
    );
  }
  useEffect(() => {
    if (customerId !== 'add') {
      console.log('get customer detail', customerId)
      dispatch(getCustomersList({ CustomerID_lng: customerId }, (res) => {

        const newFormData = res.customersResponseTable[0]
        setCustomerData({ ...newFormData, comments_chr: null })
        dispatch(getCustomerRef({}, (data) => {
          console.log('check projects', data?.refIndustryType)
          setCountryList(data?.refCountry)
          setCustomerTypeList(data?.refCustomerType)
          setCustomerStageList(data?.refStage)
          setIndustryList(data?.refIndustryType)
          setDesignationList(data?.refDesignation)
          const country_lng = newFormData?.country_lng
          console.log('country_lng', country_lng)
          const country_index = data?.refCountry?.findIndex((country) => country?.countryID_ids === country_lng)
          console.log('country_index', country_index)
          setSelectedCountry(data?.refCountry[country_index])
          // getStateListData(country_lng)
          dispatch(getStateByCountryRef({ CountryID_lng: country_lng }, (data) => {
            console.log('state data', data?.refState)
            setStateList(data?.refState)
            const state_lng = newFormData?.state_lng
            const result = data?.refState.find((state) => state?.stateID_ids === state_lng)
            console.log('state result', result)
            setSelectedState(result)
          }))


          const customerType = newFormData?.type_lng
          const customer_type = data?.refCustomerType.find((role) => role?.customerTypeId_ids === customerType)
          console.log('customer_type', customer_type)
          setSelectedRole(customer_type)
          const customerStage = newFormData?.stage_lng
          const customer_stage = data?.refStage.find((stage) => stage?.customerStageId_ids === customerStage)
          console.log('customer_stage', customer_stage)
          setSelectedStage(customer_stage)
          const customerIndustry = newFormData?.industryType_lng
          const customer_industry = data?.refIndustryType.find((ind) => ind?.industryTypeId_ids === customerIndustry)
          setSelectedIndustry(customer_industry)
          const customerDesignation = newFormData?.designation_lng
          const customer_designation = data?.refDesignation.find((des) => des?.customerDesignationId_ids === customerDesignation)
          setSelectedDesignation(customer_designation)

        }))
        getOwner(newFormData?.accountOwner_chr)
      }))
      dispatch(
        getCustomerComments({ CustomerID_lng: customerId },
          (res) => {
            // console.log(res)
            setCommentList(res?.table)
          }
        )

      )
    } else {
      setCustomerData((state) => ({ ...state, comments_chr: null }))
      dispatch(getCustomerRef({}, (data) => {
        setCountryList(data?.refCountry)
        setCustomerTypeList(data?.refCustomerType)
        setCustomerStageList(data?.refStage)
        setIndustryList(data?.refIndustryType)
        setDesignationList(data?.refDesignation)
      }))
      // dispatch(
      //   getListOfUsers({}, (data) => {
      //     const response = data?.aspNetUsers?.map((data) => data)
      //     setOwnerList(response)
      //   })
      // );
      getOwner(userId)
    }
  }, [])
  console.log('check res', customerData)
  const handlePrompt = () => {
    console.log('cancel called')
    setIsPromptOpen(!isPromptOpen)
  }
  const getStateListData = async (countryID) => {
    dispatch(getStateByCountryRef({ CountryID_lng: countryID }, (data) => {
      console.log('state data', data?.refState)
      setStateList(data?.refState)
      const state_lng = customerData?.state_lng
      const result = data?.refState.find((state) => state?.stateID_ids === state_lng)
      console.log('state result', result)
      setSelectedState(result)
    }))
  }

  useEffect(() => {
    if (!customerData?.stage_lng) {
      setCustomerData((state) => ({
        ...state,
        customerStageName_chr: "New",
        stage_lng: 1

      }))
    }
  }, [])
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value

    }));
    if (name == "email_chr") {
      // console.log("name in If= email");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setFormDataError((prev) => ({
          ...prev,
          [name]: {
            ...prev[name],
            errorMsg: value,
          },
        }));
      } else {
        // console.log("emailregX not satisfied");
        setFormDataError((prev) => ({
          ...prev,
          [name]: {
            ...prev[name],
            errorMsg: true,
          },
        }));
      }
    }
    else if (name == "phone_chr") {
      const onlyNums = value.replace(/[^0-9*#]/g, '');
      setCustomerData((prevState) => ({
        ...prevState,
        [name]: onlyNums

      }));
      if (customerData?.phone_chr?.replaceAll("_", "").length >= 9) {
        setFormDataError((prev) => ({
          ...prev,
          [name]: {
            ...prev[name],
            errorMsg: value,
          },
        }));
      } else {
        setFormDataError((prev) => ({
          ...prev,
          [name]: {
            ...prev[name],
            errorMsg: true,
          },
        }));
      }
    }
    else {
      // console.log("name= ", name);
      setFormDataError((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          errorMsg: value,
        },
      }));
    }
  };
  console.log('selectedCountry', selectedCountry)
  const handleClose = () => {
    onClickAdd();
    // onClose();
  };
  const validateForm = () => {
    let isInvalid = false;
    Object.keys(formDataError).forEach((x) => {
      const errObj = formDataError[x];

      if (errObj.errorMsg === true) {
        isInvalid = true;
      } else if (errObj.isReq && !customerData[x]) {
        isInvalid = true;
        onInputValidate(true, x);
      }
    });
    return !isInvalid;
  };
  const handleSaveExit = (event) => {
    event.preventDefault();
    console.log('form submit 111')
    const user_Id = localStorage.getItem('userId')
    const isValid = validateForm();
    if (isValid) {
      console.log('valid', customerData)
      const comment = ((customerData?.comments_chr === null) || (customerData?.comments_chr === '')) ? null : customerData?.comments_chr
      const payloadData = {
        calling_UserID_chr: user_Id,
        customerName_chr: customerData?.customerName_chr,
        customerID_lng: customerData?.customerID_ids,
        email_chr: customerData?.email_chr,
        phone_chr: customerData?.phone_chr,
        city_chr: customerData?.city_chr,
        stage_lng: customerData?.stage_lng,
        country_lng: customerData?.country_lng,
        type_lng: customerData?.type_lng,
        designation_lng: customerData?.designation_lng,
        state_lng: customerData?.state_lng,
        industryType_lng: customerData?.industryType_lng,
        comments_chr: comment,
        zipCode_chr: customerData?.zipCode_chr,
        cadence_lng: customerData?.cadence_lng,
        accountOwner_chr: customerData?.accountOwner_chr,
      }
      console.log(customerId, 'see action')
      if (customerId === 'add') {
        // console.log('add block')
        dispatch(addCustomer(payloadData, (res) => {
          console.log('res', res);
          if (typeof res === 'string') {
            NotificationManager.success(res);
          } else {
            NotificationManager.success('Customer Added Succesfully !');
          }
          // sendNotification()
          onClickCancel()
        }, (err) => {

        }))
      }
      else {
        console.log('edit customer', payloadData)
        console.log('previousEmail',previousEmail?.email,SelectedAccountOwner?.email)
        dispatch(addCustomer(payloadData, (res) => {
          // console.log('res', res);
          if (typeof res === 'string') {
            NotificationManager.success(res);
          } else {
            NotificationManager.success('Customer Edited Succesfully !');
          }
          // ((SelectedAccountOwner.email).trim()!==(previousEmail.email).trim()) && sendNotification()
          onClickCancel()
        }, (err) => {
          console.log(err,'error in edit')

        }))

      }
    }
    else {
      // console.log('in else block')
      // customerformValidator.showMessages()
      NotificationManager.error('Please fill all mandatory fields marked as (*)')
    }
  }
  const onSubmitForm = (event) => {
    event.preventDefault();
    console.log('form submit 111')
    const user_Id = localStorage.getItem('userId')
    const isValid = validateForm();
    if (isValid) {
      console.log('valid', customerData)
      const comment = ((customerData?.comments_chr === null) || (customerData?.comments_chr === '')) ? null : customerData?.comments_chr
      const payloadData = {
        calling_UserID_chr: user_Id,
        customerName_chr: customerData?.customerName_chr,
        customerID_lng: customerData?.customerID_ids,
        email_chr: customerData?.email_chr,
        phone_chr: customerData?.phone_chr,
        city_chr: customerData?.city_chr,
        stage_lng: customerData?.stage_lng,
        country_lng: customerData?.country_lng,
        type_lng: customerData?.type_lng,
        designation_lng: customerData?.designation_lng,
        state_lng: customerData?.state_lng,
        industryType_lng: customerData?.industryType_lng,
        comments_chr: comment,
        zipCode_chr: customerData?.zipCode_chr,
        cadence_lng: customerData?.cadence_lng,
        accountOwner_chr: customerData?.accountOwner_chr,
      }
      console.log(customerId, 'see action')
      if (customerId === 'add') {
        // console.log('add block')
        dispatch(addCustomer(payloadData, (res) => {
          console.log('res', res);
          if (typeof res === 'string') {
            NotificationManager.success(res);
          } else {
            NotificationManager.success('Customer Added Succesfully !');
          }
          // sendNotification()
          const inputString = res?.data;

          // Use regular expression to extract the number
          const extractedNumber = parseInt(inputString.match(/\d+/)[0], 10);

          console.log(extractedNumber);
          navigateToDocUpload(extractedNumber)
        }, (err) => {

        }))
      }
      else {
        console.log('edit customer', payloadData)
        console.log('previousEmail',previousEmail)
        dispatch(addCustomer(payloadData, (res) => {
          // console.log('res', res);
          if (typeof res === 'string') {
            NotificationManager.success(res);
          } else {
            NotificationManager.success('Customer Edited Succesfully !');
          }
          navigateToDocUpload(customerId)
          // sendNotification()
          // ((SelectedAccountOwner.email).trim()!==(previousEmail.email).trim()) && sendNotification()
        }, (err) => {

        }))

      }
    }
    else {
      // console.log('in else block')
      // customerformValidator.showMessages()
      NotificationManager.error('Please fill all mandatory fields marked as (*)')
    }
  }
  // console.log('customerData final',customerData)
  const [selectedFile, setSelectedFile] = useState(null);
  const handleDocUpload = (e) => {
    const file = e.target.files[0];
    console.log('handleDocUpload', file)
    const url = `${window.config?.API_URL}/api/Customer/PostPutCustomerDocument`;
    const config = {
      headers: {
        Authorization: "Bearer" + " " + window.config.TOKEN,
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-domain-name": domain,
      },
    };
    if (file) {
      if (file.type === 'application/pdf') {
        setSelectedFile(file);
        const docFormData = new FormData();
        docFormData.append('CustomerDocuments[0].file', file);
        docFormData.append('CustomerDocuments[0].customerDocumentName_chr', file.name);
        docFormData.append('Calling_UserID_chr', userId);
        docFormData.append('CustomerID_ids', customerData?.customerID_ids);

        try {
          dispatch({ type: 'SPINNER_STATE', isLoading: true });
          axios.post(url, docFormData, config)
            .then((data) => {
              if (data?.status === 200) {
                dispatch({ type: 'SPINNER_STATE', isLoading: false });
                NotificationManager.success("document has been successfully uploaded !")
                // NotificationManager.success(agencyId ? "document has been successfully uploaded !" : null)
              }
            })
            .catch((error) => {
              // toast.error(
              //   typeof error.response === "string"
              //     ? error.response
              //     : "Operation failed"
              // );
              console.log(error)
              dispatch({ type: 'SPINNER_STATE', isLoading: false });
            });
        } catch (err) {

        }
      } else {
        NotificationManager.error('Please select a PDF file.');
        e.target.value = null;
      }
    }
  };
  const onClickCancel = () => {
    console.log('check pathname', pathname)
    if (pathname.includes('/customer')) {
      history.push(`/${tenant}/customers`)
    }
    else if (pathname.includes('leads/new-lead')) {
      history.push(`/${tenant}/leads/new-lead`)
    } else if (pathname.includes('/leads/unassigned')) {
      history.push(`/${tenant}/leads/unassigned`)
    } else if (pathname.includes('/leads/in-progress')) {
      history.push(`/${tenant}/leads/in-progress`)
    }
    else if (pathname.includes('/leads/processed')) {
      history.push(`/${tenant}/leads/processed`)
    } else if (pathname.includes('/leads/good-leads')) {
      history.push(`/${tenant}/leads/good-leads`)
    }
    else if (pathname.includes('/leads/junk-leads')) {
      history.push(`/${tenant}/leads/junk-leads`)
    }
    else if (pathname.includes('/deal/site-visit')) {
      history.push(`/${tenant}/deal/site-visit`)
    }
    else if (pathname.includes('/deal/quotation')) {
      history.push(`/${tenant}/deal/quotation`)
    } else if (pathname.includes('/deal/negotiation')) {
      history.push(`/${tenant}/deal/negotiation`)
    }
    else if (pathname.includes('/deal/re-negotiation')) {
      history.push(`/${tenant}/deal/re-negotiation`)
    }
    else if (pathname.includes('/deal/win')) {
      history.push(`/${tenant}/deal/win`)
    }
    else if (pathname.includes('/deal/lose')) {
      history.push(`/${tenant}/deal/lose`)
    }
    else if (pathname.includes('/deal/documentation')) {
      history.push(`/${tenant}/deal/documentation`)
    }
  }
  const navigateToDocUpload = (customerId) => {

    if (pathname.includes('/customer')) {
      history.push(`/${tenant}/customer/${customerId}/upload-doc`)
    }
    else if (pathname.includes('leads/new-lead')) {
      history.push(`/${tenant}/leads/new-lead/${customerId}/upload-doc`)
    } else if (pathname.includes('/leads/unassigned')) {
      history.push(`/${tenant}/leads/unassigned/${customerId}/upload-doc`)
    } else if (pathname.includes('/leads/in-progress')) {
      history.push(`/${tenant}/leads/in-progress/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/leads/processed')) {
      history.push(`/${tenant}/leads/processed/${customerId}/upload-doc`)
    } else if (pathname.includes('/leads/good-leads')) {
      history.push(`/${tenant}/leads/good-leads/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/leads/junk-leads')) {
      history.push(`/${tenant}/leads/junk-leads/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/site-visit')) {
      history.push(`/${tenant}/deal/site-visit/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/quotation')) {
      history.push(`/${tenant}/deal/quotation/${customerId}/upload-doc`)
    } else if (pathname.includes('/deal/negotiation')) {
      history.push(`/${tenant}/deal/negotiation/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/re-negotiation')) {
      history.push(`/${tenant}/deal/re-negotiation/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/win')) {
      history.push(`/${tenant}/deal/win/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/lose')) {
      history.push(`/${tenant}/deal/lose/${customerId}/upload-doc`)
    }
    else if (pathname.includes('/deal/documentation')) {
      history.push(`/${tenant}/deal/documentation/${customerId}/upload-doc`)
    }
  }
  console.log('check phone',formDataError?.phone_chr)
          
  const sendNotification=()=>{
    const fullName=`${firstName} ${lastName}`
    const subject='Regarding lead assignment'
    const body=`<html lang='en'><head><meta charset='UTF-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width, initial-scale=1.0'>
    <title>Lead Assignment Notification</title>
    </head>
    <body style='font-family: Arial, sans-serif;'>
    <p>Hi ${SelectedAccountOwner?.fullName},</p>
    <p>I hope this message finds you well.</p>
    <p>I wanted to inform you that I have assigned a lead to you. Below are the details:</p>
    <ul>
    <li><strong>Lead Name:</strong> [Lead Name]</li>
    <li><strong>Assigned By:</strong> [Your Name]</li>
    <li><strong>Assignment Date:</strong> [Date]</li>
    </ul>
    <p>Please take the necessary actions to follow up on this lead promptly. If you have any questions or need additional information, feel free to reach out to me.</p>
    <p>Best regards,<br>${fullName}</p></body>
    </html>"`
  console.log('check body',body)
    dispatch(sendMail({
      strFrom: email,
      strFromName:fullName,
      sendTos: [
        {
          strTo: SelectedAccountOwner?.email,
        },
      ],
      strSubject: subject,
      strBody: body,
      // attachmentsList: base64File,
    },(res)=>{
      console.log('email res',res)
      if(res?.response){
        swal({
          title: "Email  Succesfully Send !",
          text: `An email has been sent to ${SelectedAccountOwner?.email} regarding lead assignment.`,
          icon: "success",
          dangerMode: false,
        });
      }

    }))
  }
  return <>
    {spinner ? (

      <Loader />

    ) : null}
    <div className="m-2 px-2 bg-white">
      {(isPromptOpen && <ConfirmationDialog
        onHandleModel={handlePrompt}
        isOpenDialog={isPromptOpen}
        action={() => onClickAdd()}
        title={'Discard'}
        content={'Do you want to discard ?'}
      />)}
      <div>

        <form noValidate onSubmit={onSubmitForm}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4 items-center py-2" >
           <div className="lg:col-span-4">
           <Typography variant='h6' className="flex items-center text-lg" >
              {customerId === 'add' ? <div className="flex items-center gap-x-1">
                <FaUser size={18} />{constant_label?.lbl_txt_add_customer}
              </div> :
                <div className="flex items-center gap-x-1">
                  <FaUserEdit size={21} />{constant_label?.lbl_txt_edit_customer}
                </div>
              }
            </Typography>
           </div>
            {/* <div className="flex items-center gap-x-2"> */}
              <CancelButton
                onClick={onClickCancel}
              />
              
              {/* <DefaultButton onClick={()=>navigateToDocUpload(customerId)}>
                Upload Doc
              <IoIosDocument size={20}
              />
              </DefaultButton> */}
              <CommonButton onClick={handleSaveExit}>{customerId !=='add'?'Update & Exit':'Save & Exit'}</CommonButton>
<div className="lg:col-span-2">
<CommonButton onClick={onSubmitForm}>{customerId !=='add'? 'Update & Proceed to Document Upload' : 'Save & Proceed to Document Upload'}</CommonButton>

  </div>            
            {/* </div> */}
          </div>
        </form>
        <div>
          <Typography variant="subtitle2" className="pb-2">{constant_label?.lbl_txt_mandatory_field}</Typography>
          {/* <Container component="main" className="px-1"> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TxtField
                required={true}
                //  margin="dense"
                name="customerName_chr"
                label={constant_label?.lbl_txt_customer_name}
                variant="outlined"
                InputLabelProps={{ shrink: customerData?.customerName_chr?.length }}
                value={customerData?.customerName_chr}
                onChange={handleChange}
                error={formDataError?.customerName_chr?.errorMsg.length >= 0 ? false : true}
                helperText={
                  formDataError?.customerName_chr?.errorMsg.length >= 0
                    ? null
                    : constant_label?.err_req_customer_name
                }
              />
              {customerformValidator.message('Customer', customerData?.customerName_chr, 'required', 'text-danger')}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TxtField
                required={true}
                //  margin="dense"
                name="email_chr"
                label={constant_label?.lbl_txt_email}
                variant="outlined"
                InputLabelProps={{ shrink: customerData?.email_chr?.length }}
                value={customerData?.email_chr}
                onChange={handleChange}
                error={formDataError?.email_chr?.errorMsg.length >= 0 ? false : true}

                helperText={
                  formDataError?.email_chr?.errorMsg.length >= 0
                    ? null
                    : constant_label?.err_req_email
                }
              />
              {/* {customerformValidator.message('Email',
                customerData?.email_chr,
                'required|email',
                'text-danger')} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TxtField
                required={true}
                // margin="dense"
                id="phone_chr"
                label={constant_label?.lbl_txt_phone}
                name="phone_chr"
                variant="outlined"
                fullWidth
                type={'text'}
                InputLabelProps={{ shrink: customerData?.phone_chr?.length }}
                value={customerData?.phone_chr}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                error={formDataError?.phone_chr?.errorMsg.length >= 0 ? false : true}
                helperText={
                  formDataError?.phone_chr?.errorMsg.length >= 0
                    ? null
                    : constant_label?.err_req_phone
                }
              />
              {/* {customerformValidator.message(
                'Phone',
                customerData?.phone_chr,
                'required|phone',
                'text-danger'
              )} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiDropDown
              fullWidth={true}
              autoComplete='off-autcomplete-countryName_chr'
                name="countryName_chr"
                label={constant_label?.lbl_txt_country}
                options={countryList}
                getOptionLabel={(option) => option?.countryName_chr}
                value={selectedCountry}
                onChange={(event, value) => {
                  // console.log('value',value)
                  setSelectedCountry(value)
                  setCustomerData((state) => ({
                    ...state,
                    countryName_chr: value?.countryName_chr,
                    country_lng: value?.countryID_ids,

                  }))
                  getStateListData(value?.countryID_ids)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiDropDown
              fullWidth={true}
              autoComplete='autcomplete-off-stateName_chr'
                name="stateName_chr"
                label={constant_label?.lbl_txt_state}
                options={stateList}
                getOptionLabel={(option) => option.stateName_chr}
                value={selectedState}
                onChange={(event, value) => {
                  // console.log('value',value)
                  setSelectedState(value)
                  setCustomerData((state) => ({
                    ...state,
                    stateName_chr: value?.stateName_chr,
                    state_lng: value?.stateID_ids
                  })
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TxtField
                //  margin="dense"
                label={constant_label?.lbl_txt_city}
                name="city_chr"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: customerData?.city_chr?.length }}
                value={customerData?.city_chr}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TxtField
                //  margin="dense"
                label={constant_label?.lbl_txt_zip_code}
                name="zipCode_chr"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: customerData?.zipCode_chr?.length }}
                value={customerData?.zipCode_chr}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                          margin="dense"
                        //   select
                          label={constant_label?.lbl_txt_role}

                          name="customerTypeName_chr"
                        //   onChange={this.onSelectionChange}
                        //   value={this.state.customer_data.customerTypeName_chr}
                          fullWidth
                          variant="outlined"
                        >
                          {/* {this.state.categoriesList?.map((data, index) => (
                            <MenuItem key={data.customerDesignationId_ids} value={data.customerTypeName_chr}>
                              {data.customerTypeName_chr}
                            </MenuItem>
                          ))} */}
              {/* </TextField> */}
              <MuiDropDown
              fullWidth={true}
                name="customerTypeName_chr"
                label={tenant !== 'KBD' ? constant_label?.lbl_txt_role : 'Type'}
                options={customerTypeList}
                getOptionLabel={(option) => option?.customerTypeName_chr}
                value={selectedRole}
                onChange={(event, value) => {
                  console.log('role', value)
                  setSelectedRole(value)
                  setCustomerData((state) => ({
                    ...state,
                    customerTypeName_chr: value?.customerTypeName_chr,
                    type_lng: value?.customerTypeId_ids
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
                        //   select
                          label="Stage"
                          margin="dense"
                          name="customerStageName_chr"
                        //   onChange={this.onSelectionChange}
                        //   value={this.state.customer_data.customerStageName_chr}
                          fullWidth
                          variant="outlined"
                        >
                          {/* {this.state.stageList?.map((data, index) => (
                            <MenuItem key={data.customerStageId_ids} value={data.customerStageName_chr}>
                              {data.customerStageName_chr}
                            </MenuItem>
                          ))} */}
              {/* </TextField> */}
              <Autocomplete
                name="customerStageName_chr"
                options={customerStageList}
                groupBy={(option) => option.stageType_lng === 2 ? 'Deal Stages' : 'Lead Stages'}
                getOptionLabel={(option) => option?.customerStageName_chr}
                value={selectedStage}
                onChange={(event, value) => {
                  console.log('role', value)
                  setSelectedStage(value)
                  if (value !== null) {
                    setCustomerData((state) => ({
                      ...state,
                      customerStageName_chr: value?.customerStageName_chr,
                      stage_lng: value?.customerStageId_ids
                    }))
                  } else {
                    setCustomerData((state) => ({
                      ...state,
                      customerStageName_chr: '',
                      stage_lng: 1
                    }))

                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={constant_label?.lbl_txt_stage}
                    size="small"
                  />)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>              
              <MuiDropDown
              fullWidth={true}
              autoComplete='autcomplete-off-industryName_chr'
                name="industryName_chr"
                label={tenant !== 'KBD' ? (constant_label?.lbl_txt_industry) : 'Project'}
                options={industryList}
                getOptionLabel={(option) => option?.industryName_chr}
                value={selectedIndustry}
                onChange={(event, value) => {
                  console.log('industry', value)
                  setSelectedIndustry(value)
                  if (value !== null) {
                    setCustomerData((state) => ({
                      ...state,
                      industryName_chr: value?.industryName_chr,
                      industryType_lng: value?.industryTypeId_ids
                    }))
                  }
                  else {
                    setCustomerData((state) => ({
                      ...state,
                      industryName_chr: '',
                      industryType_lng: '',
                    }))
                  }
                }}
              />
            </Grid>
            {tenant !== "KBD" && <Grid item xs={12} sm={6}>
              <MuiDropDown
              fullWidth={true}
              autoComplete='autcomplete-off-customerDesignationName_chr'
                name="customerDesignationName_chr"
                label={constant_label?.lbl_txt_designation}
                options={designationList}
                getOptionLabel={(option) => option?.customerDesignationName_chr}
                value={selectedDesignation}
                onChange={(event, value) => {
                  console.log('industry', value)
                  setSelectedDesignation(value)
                  setCustomerData((state) => ({
                    ...state,
                    customerDesignationName_chr: value?.customerDesignationName_chr,
                    designation_lng: value?.customerDesignationId_ids
                  }))
                }}
              />

            </Grid>}
            <Grid item xs={12} sm={6}>
              <MuiDropDown
              fullWidth={true}
                // margin='dense'
                size="small"
                label="Account Owner"
                options={ownerList}
                getOptionLabel={(option) => option.fullName}
                value={SelectedAccountOwner}
                onChange={(event, value) => {
                  console.log('select sender', value)
                  setSelectedAccountOwner(value)
                  if (value) {
                    setCustomerData((state) => ({
                      ...state,
                      accountOwner_chr: value?.id,
                    }))
                  }
                }}
                renderOption={(option) => (<div><div>{option.fullName}</div><p className='text-xs'>{option.email}</p></div>)}

              />
            </Grid>


            {/* <Grid item xs={12} sm={12} lg={12}>
              <TxtField
                multiline={true}
                // margin="dense"
                label={constant_label?.lbl_txt_comment}
                fullWidth
                minRows={4}
                maxRows={4}
                variant="outlined"
                name="comments_chr"
                value={customerData?.comments_chr}
                onChange={handleChange}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Comments setCustomerData={setCustomerData} commentList={commentList} onSubmitForm={onSubmitForm} />
            </Grid>

          </Grid>
          {/* </Container> */}
        </div>
      </div>
    </div>
  </>
}
export default AddEditCustomePage