import ActionConstants from '../config/AppConstants';

const initialState = {
  To: '',
  Body: '',
  lastUpdated:new Date(),
};

export default function smsState(
  state = initialState,
  { type, payload }
) {
  switch (type) {
  case 'SMS_STATE_CHANGE': {
    const lastUpdated = new Date();
    const stateObject = {lastUpdated}

    if(payload){
      const {To, Body} = payload;
      // console.log(state, 'state')
      if(To)
        stateObject['To'] = To
      if(Body!== undefined)
        stateObject['Body'] = Body
    }
    return {
      ...stateObject
    };
  }

  default:
    return { ...state };
  }
}
